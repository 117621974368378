import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-photosliderviewpopup',
  templateUrl: './photosliderviewpopup.component.html',
  styleUrls: ['./photosliderviewpopup.component.css']
})
export class PhotosliderviewpopupComponent implements OnInit {
  photos;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: MasterService, public dialog: MatDialog,) {
    console.log(data.id);

    this.service.GetPartnerAlbum(data.id).subscribe((data: any) => {
      this.photos = data;
    });
   }

  ngOnInit(): void {
  }

}
