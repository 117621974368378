import { Component, OnInit ,EventEmitter} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-partnereventdeatils',
  templateUrl: './partnereventdeatils.component.html',
  styleUrls: ['./partnereventdeatils.component.css']
})
export class PartnereventdeatilsComponent implements OnInit {

  public Updateeventdetails: FormGroup;
  partner;
  program;
  fileToUpload: File = null;
  imageUrl: string;
  submited = new EventEmitter<string>();
  eid;
  Partnereventdetails; 
  loading = true;    
  pgname;
  UserId;
  selectfiyear;
  Eventpicture;
  constructor(private service: MasterService,private route: ActivatedRoute) { 
    this.eid = route.snapshot.params["id"];
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');
    this.UserId = localStorage.getItem('UserId');
    this.service.GetPartnerEvent(this.eid).subscribe((data:any) => {
      this.Partnereventdetails = data;
        this.loading=false;
    });

    this.service.GetEventPic(this.eid).subscribe((data: any) => {
      this.Eventpicture = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid").subscribe((data: any) => {
      this.partner = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid").subscribe((data: any) => {
      this.program = data;
    });
  }

  onselectpartner(partnerdpd) {
    if (partnerdpd == '' || partnerdpd.length == 0 || partnerdpd[0] == undefined)  {
      this.service.FillDropDown("PartnerProgram","distinct Program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid").subscribe((data: any) => {
      this.program = data;
    });
      } else {
        this.service.FillDropDown("PartnerProgram","distinct Program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid where PartnerProgram.userid='" + partnerdpd + "'")
        .subscribe((data: any) => {
          this.program = data;
       
        })
        }
  }

  ngOnInit(){
    this.Updateeventdetails = new FormGroup({
      eventname : new FormControl('', Validators.required),
      eventplace :  new FormControl('', Validators.required),
      eventdate :  new FormControl('', Validators.required),
      description :  new FormControl('', Validators.required),
      progname :  new FormControl('', Validators.required),
      partname :  new FormControl('', Validators.required),
      choosefile: new FormControl('', Validators.required)
  
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.Updateeventdetails.controls[controlName].hasError(errorName);
  }

}
 