import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-programteam',
  templateUrl: './programteam.component.html',
  styleUrls: ['./programteam.component.css']
})
export class ProgramteamComponent implements OnInit {
  UserId;
  pgname;
  pgid;
  selectfiyear;
  loading = true;
  teamlistcount;
  programteamlist;
  p=1;
  
  constructor(private service: MasterService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgname = localStorage.getItem('pgname');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    this.service.GetPartnerIDIFellowlist(this.pgid,this.UserId,'').subscribe((data: any) => {
      this.programteamlist = data;
      this.teamlistcount = data.length;
      this.loading = false;
    });

  }
  ngOnInit(): void {
  }

}
