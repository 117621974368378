import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ExcelService } from 'app/services/excel.service';

@Component({
  selector: 'app-idicomputerlist',
  templateUrl: './idicomputerlist.component.html',
  styleUrls: ['./idicomputerlist.component.css']
})
export class IdicomputerlistComponent implements OnInit {
  
  UserId;
  pgid;
  PartnerCenterList;
  centercount;
  state;
  city;
  loading = true;
  array = [];
  StrWhere = "";
  where = "";
  selected; selectedd;
  ComputerList;
  Computercount;
  statelist = new FormControl();
  citylist = new FormControl();
  statuslist = new FormControl();
  pgname;
  p=1;
  searchstatus=0;

  constructor(private service: MasterService, private router: Router,private exService: ExcelService) {
    
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');

    if (this.selected == "2024-2025") {
      this.service.GetPartnerComputerIDI(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.ComputerList = data;  
        this.Computercount = data.length;
        this.loading = false;
      })
    }
    else if (this.selected == "2017-2018" || this.selected == "2018-2019" || this.selected == "2019-2020" || this.selected == "2020-2021" || this.selected == "2021-2022" || this.selected == "2022-2023" || this.selected == "2023-2024") {
        this.service.OLDPartnerCenterComputer(this.pgid,this.UserId,this.selected,"").subscribe((data: any) => {
        this.ComputerList = data;  
      this.Computercount = data.length;
      this.loading = false;
      });
    }

    this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' ").subscribe(
      (data: any) => {
        this.state = data;
      });

    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' ").subscribe(
      (data: any) => {
        this.city = data;
      });
  }

  ngOnInit(): void {
  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";

    if (this.selected == 'null') {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("Center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("Center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("Center.status ='" + searchstatus + "'");
      }
  
      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
  
      }
      this.where = this.StrWhere;
  
      this.service.GetPartnerComputerIDI(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.ComputerList = data;  
        this.Computercount = data.length;
        this.loading = false;
      })
  
    } else if(this.selected == '2024-2025') {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("Center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("Center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("Center.status ='" + searchstatus + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
      }
      this.where = this.StrWhere;
  
      this.service.GetPartnerComputerIDI(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.ComputerList = data;  
        this.Computercount = data.length;
        this.loading = false;
      })
  
    }
    else if(this.selected == "2017-2018" || this.selected == "2018-2019" || this.selected == "2019-2020" || this.selected == "2020-2021" || this.selected == "2021-2022"|| this.selected == "2022-2023" || this.selected == "2023-2024"){

       if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("OLDPartnerCenterComputer.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("OLDPartnerCenterComputer.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("OLDPartnerCenterComputer.status ='" + searchstatus + "'");
      }
  
      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " and " + this.array[0].toString();
      }
      else {
        this.StrWhere = " and " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
  
  
      }
      this.where = this.StrWhere;
  
      this.service.OLDPartnerCenterComputer(this.pgid,this.UserId,this.selected,this.where).subscribe((data: any) => {
        this.ComputerList = data;  
      this.Computercount = data.length;
      this.loading = false;
      });
  
    }

  }

  getMultiSelectionWhere(where) {
    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }
  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }
  selectstatus(sts) {
    if (sts._selected) {
      this.statuslist.setValue([]);
    }
  }

  Export() {
    this.loading = true;
      if(this.selected == "2024-2025"){
        this.service.GetPartnerComputerIDI(this.pgid, this.UserId,this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno':0,
            'State': obj.state==null?'':obj.state,
            'City': obj.city==null?'':obj.city,
            'Fellow Name': obj.tlname==null?'':obj.tlname,
            'Center Name': obj.centname==null?'':obj.centname,
            'No of Computers':obj.noofcomputers==null?'':obj.noofcomputers
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno=i+1;
        }
        this.exService.exportAsExcelFile(new_list,'Partner Computer List');
      });

    }
    else if (this.selected=="2017-2018" || this.selected=="2018-2019" || this.selected=="2019-2020" || this.selected == "2020-2021" || this.selected == "2022-2023"|| this.selected == "2022-2023" || this.selected == "2023-2024") {
      this.service.OLDPartnerCenterComputer(this.pgid,this.UserId,this.selected,this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno':0,
            'State': obj.state==null?'':obj.state,
            'City': obj.city==null?'':obj.city,
            'Fellow Name': obj.tlname==null?'':obj.tlname,
            'Center Name': obj.centname==null?'':obj.centname,
            'No of Computers':obj.noofcomputers==null?'':obj.noofcomputers
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno=i+1;
        }
        this.exService.exportAsExcelFile(new_list,'Partner Computer List');
      });
    }   

  }

}

