import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-learningatmosphere',
  templateUrl: './learningatmosphere.component.html',
  styleUrls: ['./learningatmosphere.component.css']
})
export class LearningatmosphereComponent implements OnInit {

  UserId;
  centid;
  selected;
  pgid;
  Excellent = 0;
  Good = 0;
  Satisfactory = 0;
  Poor = 0;
  totalResponse = 0;
  loading = true;
  gender = new Array();
  genderlabel = new Array();
  piechart1;
  generalimpactofwholetraining;
  public pieChartLabels: Array<string> = ['Excellent', 'Good', 'Satisfactory', 'Poor'];
  public pieChartData: any[];
  pieChartColor: any = [
    {
      backgroundColor: ['#FFA500', '#0000FF', '#808080', 'FFFF00']
    }
  ]
  public piechartOptions: any = {
    pieceLabel: {
      render: function (args) {
        const label = args.label,
          value = args.value;
        return value;
      }
    }
  };
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  selectfiyear;
  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    //this.selected = localStorage.getItem('academicyear');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    let Excellent=0 , Good =0 , Satisfactory =0, Poor =0
    this.service.GetPartnerSancharakFeedback("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((data: any) => {
      this.generalimpactofwholetraining = data;
      this.loading=false
      var graph2 = document.getElementById("graph3");
      this.gender = new Array();
      this.genderlabel = new Array();
      if (this.piechart1) { this.piechart1.destroy(); }

      if (data.length != 0) {
        graph2.style.display = "block";

        Excellent  = (data[0].GreatAtmosphere).toFixed(2),
        Good = (data[0].GoodAtmosphere).toFixed(2),
        Satisfactory = (data[0].SomeExtentAtmosphere).toFixed(2),
        Poor = (data[0].NotatallAtmosphere).toFixed(2),
        this.gender.push(Excellent);
        this.genderlabel.push('GreatAtmosphere');
        this.gender.push(Good);
        this.genderlabel.push('GoodAtmosphere');
        this.gender.push(Satisfactory);
        this.genderlabel.push('SomeExtentAtmosphere');
        this.gender.push(Poor);
        this.genderlabel.push('NotatallAtmosphere');

        this.piechart1 = new Chart('canvaspie1', {
          type: 'pie',
          data: {
            labels: this.genderlabel,
            datasets: [
              {
                data: this.gender,
                backgroundColor: [
                  "#F4B400",
                  "#4285F4",
                  "#808080",
                  "#006400",
                ],
                fill: true
              }
            ]
          },
          options: {
            legend: {
              display: true

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black'
              }
            }
          }
        });
      }
      else {
        graph2.style.display = "none";
      }
    })
  }
  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }
  downloadCanvasss(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[2].toDataURL();
    anchor.download = "Learning Atmosphere.png";
  }
}

