import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'Login', pathMatch: 'full',},
  // { path: '', redirectTo: 'UnderMaintenance', pathMatch: 'full', },
  {
    path: '', component: AdminLayoutComponent,
    children: [{ path: '', loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule' }]
  },
  {
    path: 'Login', loadChildren: './login/login.module#LoginModule'
  },
  // {
  //   path: 'UnderMaintenance', loadChildren: './undermaintenance/undermaintenance.module#UndermaintenanceModule'
  // },


];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
