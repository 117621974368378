import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js';
import { Idiageenrollment } from 'app/ClassFiles/idiageenrollment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-idi-center-graph',
  templateUrl: './idi-center-graph.component.html',
  styleUrls: ['./idi-center-graph.component.css']
})
export class IdiCenterGraphComponent implements OnInit {
  public barChartType1: string;
  attsumamary: FormGroup;
  pgname;
  UserId;
  financialyear;
  pgid;
  centid;
  groupbatch;
  total14count;
  loading = false;
  total18count;
  total21count;
  idioverallgender;
  currmonthval;
  curryear;
  public stdchart: Chart; att = [];
  labels1 = []; dataobj1;
  chartOptions1 = {
    legend: {
      display: true,
      position: 'bottom',
    },
    plugins: {
      responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        anchor: 'end',
        color: 'black',
        font: {
          size: 8,
        }
      }
    }
  }
  chartData1 = [
    {
      label: [],
      data: [],
    }
  ];
  // CHART COLOR.
  colors1 = [
    {
      backgroundColor: '#5690F5'
    },
    {
      backgroundColor: '#F3D73A'
    }
    ,
    {
      backgroundColor: '#F05EC6'
    }
    ,
    {
      backgroundColor: '#F34346'
    }

  ]

   //line
   public lineChartType: string;
   public lineChartLegend: boolean = true;
   public lineChartData;
   public linechartLabels: Array<string> = ['AGrade', 'BGrade', 'CGrade', 'DGrade'];
   public linechartOptions: any = {
     responsive: true,
     legend: {
       possition: 'bottom',
     },
     plugins: {
       responsive: true,
       datalabels: {
         display: function (context) {
           return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
         },
         align: 'right',
         anchor: 'right',
         color: 'black',
         font: {
           size: 10,
         }
       }
     },
    
   };
   lineChartColor: any = [
     {
       fill: false,
       borderColor: 'rgba(129, 159, 247, 1)',
       pointBackgroundColor: 'rgba(129, 159, 247, 1)',
       pointBorderColor: 'rgba(129, 159, 247, 1)',
       lineTension: 0,
       pointRadius: 4,
     }
   ]

  // overall gender graph
  public pieChartLabels: Array<string> = ['Boys', 'Girls'];
  public pieChartType: string;
  public pieChartData: any[];
  pieChartColor: any = [
    {
      backgroundColor: ['#F18AE0',
        '#8DEEDB',
      ]
    }
  ]
  public piechartOptions: any = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
    },
    plugins: {
      responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
      }
    }
  };
  // loading = true;
  countdata;
  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute,private fb: FormBuilder) {
    this.pgname = localStorage.getItem('pgname');
    this.UserId = localStorage.getItem('UserId');
    this.financialyear = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');
    this.centid = route.snapshot.params["id"];

    var sysdate = new Date();
   
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this.currmonthval=monthNames[sysdate.getMonth()]; 
    this.curryear = sysdate.getFullYear();
    if(this.currmonthval == 'January'){
      this.currmonthval = 'December';
      this.curryear = sysdate.getFullYear()-1;
    } 
   

    if (this.financialyear == "2024-2025") {
   
      // AgeWise Enrolment Summary
      this.service.GetPartnerIDIEnrollAgeNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid + "' and center.centid= '"+this.centid+"'").subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.total14count = new Array();
        this.total18count = new Array();
        this.total21count = new Array();

        res.forEach(y => {
          this.groupbatch.push(y.groupbatch);
          this.total14count.push((y.total14));
          this.total18count.push((y.total18));
          this.total21count.push((y.total21));
        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,
            datasets: [
              {
                label: 'Below 18',
                data: this.total14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.total18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.total21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
        this.loading = false;
      });

      //Male-Female Summary 
      this.service.GetPartnerCenterwiseMaleFemaleRatioNewIDI(this.UserId,this.financialyear,this.centid,"where centid ='"+this.centid+"'").subscribe((res: any) => {
        this.chartData1 = []; 
        this.dataobj1 = res.data;
        this.labels1 = res.Batch;
        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
        }
        this.loading = false;
      });

      //Overall Gender Summary
      this.service.GetPartnerIDIOverallGenderNew("where Centerpartner.partnerid = '"+this.UserId+"'").subscribe((data: any) => {
        this.pieChartData = [
          ((data[0].Boys * 100 )  / (data[0].totstud)).toFixed(2)
          , ((data[0].Girls * 100) / (data[0].totstud)).toFixed(2)];
          this.loading = false;
      });

      this.service.GetPartnerIDICenterwiseAttendanceGraph(this.pgid, this.UserId,"where month ='"+this.currmonthval+"' and year = '"+this.curryear+"' and #data.centid='"+this.centid+"' ").subscribe((res: any) => {
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
      
      })
     
    }
    else if (this.financialyear == "2017-2018" || this.financialyear == "2018-2019" || this.financialyear == "2019-2020" || this.financialyear == "2020-2021" || this.financialyear == "2021-2022"|| this.financialyear == "2022-2023" || this.financialyear == "2023-2024") {
      // Age Enrollment Summary
      this.service.OLDPartnerIDIAgeEnrolmentSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: Idiageenrollment[]) => {
        console.log(this.UserId,this.financialyear,this.pgid);
        
        this.groupbatch = new Array();
        this.total14count = new Array();
        this.total18count = new Array();
        this.total21count = new Array();

        res.forEach(y => {
          this.groupbatch.push(y.groupbatch);
          this.total14count.push((y.total14));
          this.total18count.push((y.total18));
          this.total21count.push((y.total21));
        });

        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,
            datasets: [
              {
                label: '14-17',
                data: this.total14count,
                backgroundColor: "rgba(0,128,0, 0.9)",
                borderColor: 'rgba(0,128,0, 0.9)',
                fill: true,

              },

              {
                label: '18-21',
                data: this.total18count,
                backgroundColor: "rgba(204, 204, 0, 0.9)",
                borderColor: 'rgba(21, 230, 69, 0.9)',
                fill: true
              },

              {
                label: ' 21 Above',
                data: this.total21count,
                backgroundColor: "rgba(255, 51, 0, 0.9)",
                borderColor: 'rgba(0,128,0, 0.9)',
                fill: true
              }
            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  stepSize: 100,
                  max: 4500
                },
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
      //  GetPartnerIDIMaleFemaleRatioNew  
      this.service.OLDPartnerIDICenterMaleFemaleRatio(this.UserId, this.financialyear, this.centid).subscribe((res: any) => {
        // this.loading = false;
        this.chartData1 = [];
        this.dataobj1 = res.data;
        this.labels1 = res.Batch;

        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
        }
      });

      service.OLDPartnerIDICenterGender(this.UserId, this.financialyear, this.centid).subscribe((res: any) => {
        this.pieChartData = [
          ((res[0].boys * 100) / (res[0].boys + res[0].girls)).toFixed(2)
          , ((res[0].girls * 100) / (res[0].boys + res[0].girls)).toFixed(2)];
      })

      this.service.GetPartnerOLDIDIAttendanceCentwiseGraph(this.pgid,this.UserId,this.financialyear,"where month ='"+this.currmonthval+"' and year = '"+this.curryear+"' and OLDPartnerIDIAttendanceCentwise.centid='"+this.centid+"'").subscribe((res: any) => {
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
     
      })
    }
  }

  show(){
    if (this.financialyear == "2024-2025" ) {
      this.service.GetPartnerIDICenterwiseAttendanceGraph(this.pgid, this.UserId,"where month ='"+this.currmonthval+"' and year = '"+this.curryear+"' and #data.centid='"+this.centid+"' ").subscribe((res: any) => {
        if(res.length == 1){
          this.countdata = 1;
          this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }];
        }else if(res == ''){
          this.lineChartData = '';
        }
      })
    } 
    else
     if(this.financialyear == "2017-2018" || this.financialyear == "2018-2019" || this.financialyear == "2019-2020" || this.financialyear == "2020-2021" || this.financialyear == "2021-2022"|| this.financialyear == "2022-2023" || this.financialyear == "2023-2024"){
      this.service.GetPartnerOLDIDIAttendanceCentwiseGraph(this.pgid,this.UserId,this.financialyear,"where month ='"+this.currmonthval+"' and year = '"+this.curryear+"' and OLDPartnerIDIAttendanceCentwise.centid='"+this.centid+"'").subscribe((res: any) => {
        if(res.length == 1){
          this.countdata = 1;
          this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }];
        }else if(res == ''){
          this.lineChartData = '';
        }
      })
    }
   
  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType1 = 'bar';
    this.lineChartType = 'line';

    this.attsumamary = this.fb.group({
      monthcontrol: ['', Validators.required],
      yearcontrol: ['', Validators.required]
    });
  }

  downloadagewiseenrolmentgraph(event){
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "AgeWise Enrolment Summary Graph.png";
  }

  downloadmalefemalegraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[1].toDataURL();
    anchor.download = "Male-Female Summary Graph.png";
  }

  downloadoverallgendergraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[2].toDataURL();
    anchor.download = "Overall Gender Summary Graph.png";
  }

  downloadattendancesummamrygraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = "Attendance Summary Graph.png";
  }

}



