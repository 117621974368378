import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-pendamiceventmessage',
  templateUrl: './pendamiceventmessage.component.html',
  styleUrls: ['./pendamiceventmessage.component.css']
})
export class PendamiceventmessageComponent implements OnInit {
 
  constructor(public dialog: MatDialog) {
   }

  ngOnInit(): void {
  }

 
}
