
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Stateenroll } from 'app/ClassFiles/stateenroll';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-graphofstate',
  templateUrl: './graphofstate.component.html',
  styleUrls: ['./graphofstate.component.css']
})

export class GraphofstateComponent implements OnInit {

  UserId;
  centid;
  selected;
  pgid;
  public chart: Chart;
  statecount;
  Boyscount;
  Girlscount;
  private value;
  private value1;
  StateEnroll;
  StateEnrollgraph;
  centercount = 0;
  schoolcount = 0;
  studentcount = 0;
  
  sancharakcount = 0;
  computerscount = 0;
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  public barChartPlugins1 = [];
  abc = [];
  barChartPlugins = [pluginDataLabels];
  dept = [];
  centercountt = 0;
  schoolcountt = 0;
  studentcountt = 0;
  sancharakcountt = 0;
  computerscountt = 0;
  selectfiyear;
  loading = true;
  loaded;
  CenterComputercount: any;
  noofinfra: any;
  digicount = 0;
  totinfra: any;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {

    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    if (this.selectfiyear == "2024-2025") {
        // Statewise Enrolment Summary
        this.service.GetPartnerStatewiseEnrollmentSummary("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Stateenroll[]) => {
          this.StateEnroll = res;
          console.log(res);
          this.value = this.StateEnroll
          this.loading = false;
          for (let j = 0; j < this.StateEnroll.length; j++) {
            this.studentcount += this.value[j].student;
            this.digicount += this.value[j].totinfra;
            this.schoolcount += this.value[j].school;
            this.sancharakcount += this.value[j].sancharak;
            this.computerscount += this.value[j].computers;
          }
        });

        // this.service.GetMyEschoolPartnerwisecenterinfra("where CenterPartner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
        //   this.CenterComputercount = data;
        //   console.log(data);
        //   this.noofinfra= this.CenterComputercount.length;
        //   this.digicount=this.noofinfra;
          
        // });

        this.service.GetPartnerStatewiseEnrollmentSummaryGraph("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Stateenroll[]) => {
          this.StateEnrollgraph = res;
          this.value1 = this.StateEnrollgraph
          this.loading = false;
          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();

          res.forEach(y => {
            this.statecount.push(y.state);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));
          });

          //Graph
          if (this.chart) { this.chart.destroy(); }
          this.chart = new Chart('canvas', {
            type: 'bar',
            data: {
              labels: this.statecount,

              datasets: [
                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#4F87E2",
                  borderColor: '#4F87E2',
                  fill: true,

                },

                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#9BE24F",
                  borderColor: '#9BE24F',
                  fill: true
                }


              ],

            },
            options: {

              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",

                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: true,
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }

          });

        });
      
    }
    
    else if (this.selectfiyear == null) {
        // Statewise Enrolment Summary
        this.service.GetPartnerStatewiseEnrollmentSummary("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Stateenroll[]) => {
          // this.service.SahyogiStateWiseData(this.UserId, this.selectfiyear, this.pgid).subscribe((res: Stateenroll[]) => {
          this.StateEnroll = res;
          this.value = this.StateEnroll
          this.loading = false;

          for (let j = 0; j < this.StateEnroll.length; j++) {
            this.studentcount += this.value[j].student;
            this.schoolcount += this.value[j].school;
            this.digicount += this.value[j].totinfra;
            this.sancharakcount += this.value[j].sancharak;
            this.computerscount += this.value[j].computers;
          }

          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();

          res.forEach(y => {

            this.statecount.push(y.state);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));
          });
          if (this.chart) { this.chart.destroy(); }
          this.chart = new Chart('canvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.statecount,

              datasets: [
                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#4F87E2",
                  borderColor: '#4F87E2',
                  fill: true,

                },

                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#9BE24F",
                  borderColor: '#9BE24F',
                  fill: true
                }
              ],

            },
            options: {
              legend: {
                display: true,
                position: 'bottom',
              },

              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",

                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: true,
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      
    }

    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022"|| this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      // Statewise Enrolment Summary
      this.service.SahyogiStateWiseData(this.UserId, this.selectfiyear, this.pgid).subscribe((res: Stateenroll[]) => {
        this.StateEnroll = res;
        console.log(res);
        this.value = this.StateEnroll
        this.loading = false;
        for (let j = 0; j < this.StateEnroll.length; j++) {
          this.studentcount += parseInt(this.value[j].student);
          this.schoolcount += parseInt(this.value[j].school);
          this.digicount +=  parseInt(this.value[j].totinfra);
          this.sancharakcount += parseInt(this.value[j].sancharak);
          this.computerscount += parseInt(this.value[j].computers);
        }
        this.statecount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.statecount.push(y.state);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));


        });
        if (this.chart) { this.chart.destroy(); }
        this.chart = new Chart('canvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.statecount,

            datasets: [
              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              }

            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'afsf',

            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              datalabels: {
                anchor: "center",
                align: "center",
                font: {
                  size: 10,
                }
              }
            }

          }
        });
      });

      // this.service.GetMyEschoolPartnerwisecenterinfra("where CenterPartner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
      //   this.CenterComputercount = data;
      //   console.log(data);
      //   this.noofinfra= this.CenterComputercount.length;
      //   this.digicount=this.noofinfra;
        
      // });
    }
  }
  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }
  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "StateGraph.png";
  }

}