import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ExcelService } from 'app/services/excel.service';
import { MasterService } from 'app/services/master.service';
import { data } from 'jquery';

@Component({
  selector: 'app-pifschoollist',
  templateUrl: './pifschoollist.component.html',
  styleUrls: ['./pifschoollist.component.css']
})
export class PifschoollistComponent implements OnInit {
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  UserId;
  pgid; showMainContent;
  PartnerSchoolList;
  centercount;
  state;
  city;
  loading = true;
  array = [];
  StrWhere = "";
  statelist = new FormControl();
  citylist = new FormControl();
  searchstate; selectfiyear
  searchcity; academicyear; pgname; 
  searchstatus;
  PartnerSchoolListcount;
  p: number = 1;
  where = "";
  constructor(private exService: ExcelService, private service: MasterService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');


    this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid").subscribe(
      (data: any) => {
        this.state = data;
      });

    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid").subscribe(
      (data: any) => {
        this.city = data;
      });


      if(this.selectfiyear == "2022-2023"){
 
      this.service.PartnerSchoolListPIF("where pgid in('P1','P18','P9')").subscribe((data:any)=>{
        this.PartnerSchoolList = data;
        this.PartnerSchoolListcount= data.length;
        this.loading = false;
      })
    }
    else if(this.selectfiyear == "2021-2022"){
      this.service.OLDPartnerSchoolListPIF(this.selectfiyear ," and pgid in('P1','P18','P9')").subscribe((data:any)=>{
        this.PartnerSchoolList = data;
        this.PartnerSchoolListcount= data.length;
        this.loading = false;
      })
    }

      


  }

  ngOnInit(): void {

    if (this.selectfiyear == "2022-2023" || this.selectfiyear == "2021-2022" || this.selectfiyear == null) {
      this.showMainContent = true;
    }
  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";
    
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("school.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("school.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("school.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      if(this.selectfiyear == "2022-2023"){
      this.service.PartnerSchoolListPIF(this.where).subscribe((data: any) => {
        this.PartnerSchoolList = data;
        this.PartnerSchoolListcount = data.length;
        this.loading = false;

      });
    }
    else if (this.selectfiyear == "2021-2022"){
      this.service.OLDPartnerSchoolListPIF(this.selectfiyear ," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.PartnerSchoolList = data;
        this.PartnerSchoolListcount = data.length;
        this.loading = false;

      });
    }

    
  
  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }


  ExportReport(searchstate, searchcity, searchstatus) {
    this.loading = true;
    if(this.selectfiyear == "2022-2023"){
      this.service.PartnerSchoolListPIF(this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'School Name': obj.schoolname == null ? '' : obj.schoolname,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Address': obj.address == null ? '' : obj.address,
            'Principal Name': obj.prname == null ? '' : obj.prname,
            'Contact': obj.contactno == null ? '' : obj.contactno,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'PIF School List');
      });
    }
    else if(this.selectfiyear == "2021-2022"){
      this.service.OLDPartnerSchoolListPIF(this.selectfiyear ," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'School Name': obj.schoolname == null ? '' : obj.schoolname,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Address': obj.address == null ? '' : obj.address,
            'Principal Name': obj.prname == null ? '' : obj.prname,
            'Contact': obj.contactno == null ? '' : obj.contactno,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'PIF School List');
      });
    }
      
  }


  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  onSelectState(stname) {
    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
      (data: any) => {
        this.city = data;
      });

  }



}
