export class Eventlist {

    constructor(
        public eid: string = '',
        public partnerid: string = '',
        public pgid: string = '',
        public eventplace:string = '',
        public eventdate: string = '',
        public eventname: string = '',
        public description: string = '',
        public imageName: string = '',
        public status: number = 0,
        public filename: string = '',
        public createby: string = '',
        public createon: Date = new Date,
        public updateby: string = '',
        public updateon: Date = new Date,
        public seqno: number = 0,
    ) {}
}
