import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digitalcomputerlist',
  templateUrl: './digitalcomputerlist.component.html',
  styleUrls: ['./digitalcomputerlist.component.css']
})
export class DigitalcomputerlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
