import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-centerlist',
  templateUrl: './centerlist.component.html',
  styleUrls: ['./centerlist.component.css']
})
export class CenterlistComponent implements OnInit {
  UserId;
  pgid;
  PartnerCenterList;
  centercount;
  //Salaryapprove:Salaryapprove[];
displayedColumns: string[] = [ 'SrNo', 'approve', 'remark','depname', 'employeename','grosssalary','absent','Absentgrosssalary','totaldeduct','netamt',
'ctcamt','basicamt','hraamt','taamt','oaamt','otamt','absamt','epfamt','esic1','tds','pt','telephone','advance','loan',
'cpfamt','esic2'];
//dataSource: MatTableDataSource<Salaryapprove>;

  constructor(private service: MasterService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
   
  }

  ngOnInit(): void {
  }

}
