import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ExcelService } from 'app/services/excel.service';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-pifcenterlist',
  templateUrl: './pifcenterlist.component.html',
  styleUrls: ['./pifcenterlist.component.css']
})
export class PifcenterlistComponent implements OnInit {
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  // showMainContent: Boolean = true;
  showMainContent;
  UserId;
  pgid;
  PartnerSchoolList;
  centercount;    
  pifcenterlistcount;
  state;
  PartnerCenterList;
  city;
  loading = true;
  array = [];
  StrWhere = "";
  pifcenterlist;
  where = "";
  statelist = new FormControl();
  citylist = new FormControl();
  searchstate; selectfiyear
  searchcity; academicyear; pgname;
  searchstatus; PartnerSchoolListcount;
  p: number = 1;
  constructor(private service: MasterService, private exService: ExcelService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');


    this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid").subscribe(
      (data: any) => {
        this.state = data;
      });

    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid").subscribe(
      (data: any) => {
        this.city = data;
      });

      if(this.selectfiyear == "2022-2023"){
    this.service.PIFCenterList("where pgid in('P1','P18','P9')").subscribe((data:any)=>{
      this.PartnerCenterList = data;
      this.centercount = data.length;
      this.loading = false;
      })
    }
    else if(this.selectfiyear == "2021-2022"){
      this.service.OLDPartnerCenterListPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data:any)=>{
        this.PartnerCenterList = data;
        console.log(data);
        this.centercount = data.length;
        this.loading = false;
      })
    }
  }

  ngOnInit(): void {

    if (this.selectfiyear == "2022-2023" || this.selectfiyear == "2021-2022" || this.selectfiyear == null) {
      this.showMainContent = true;
    }

  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";

    if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
      //this.array.push("Center.state='" + searchstate + "'");
      this.array.push("center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

    }
    if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
      //this.array.push("Center.city='" + searchcity + "'");
      this.array.push("center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

    }
    if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
      //this.array.push("Center.status='" + searchstatus + "'");
      this.array.push("center.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

    }

    if (this.array.length == 0) {
      this.where = this.StrWhere;
    }
    else if (this.array.length == 1) {
      this.StrWhere = " where " + this.array[0].toString();
    }
    else {
      this.StrWhere = " where " + this.array[0].toString();
      for (let i = 1; i < this.array.length; i++) {
        this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
      }

    }
    this.where = this.StrWhere;

    if(this.selectfiyear == "2022-2023"){
    this.service.PIFCenterList (this.where).subscribe((data:any)=>{
      this.PartnerCenterList = data;
      this.centercount = data.length;
      this.loading = false;
      })
    }
    else if(this.selectfiyear == "2021-2022"){
      this.service.OLDPartnerCenterListPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data:any)=>{
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
        })
      }
    
  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }

  ExportReport(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.service.PIFCenterList (this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'Center/School Name': obj.centname == null ? '' : obj.centname,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Address': obj.address == null ? '' : obj.address,
            'Principal Name': obj.principalname == null ? '' : obj.principalname,
            'Contact': obj.principalcontact == null ? '' : obj.principalcontact,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Pif center List');
      });
  }

  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  onSelectState(stname) {
    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
      (data: any) => {
        this.city = data;
      });
  }

  // ShowHideButton() {
  //   this.showMainContent = this.showMainContent ? false : true;
  // }
}
