import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-baselinestd3',
  templateUrl: './baselinestd3.component.html',
  styleUrls: ['./baselinestd3.component.css']
})
export class Baselinestd3Component implements OnChanges {
  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  
  Partnerid;
  pgid;
  selected;
  loading = true;
  Baselinestd3;
  Baselinestd3count;

  BaselineStandard:any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  compentencies;
  constructor(private service: MasterService,private router: Router) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  
  }

  ngOnChanges() {
    this.service.SahyogiFcEvalCompetency(this.Partnerid,this.selected,this.pgid,this.Examtype,this.Standard).subscribe((data:any) => {
      this.Baselinestd3 = data;
      this.Baselinestd3count = data.length;
      this.compentencies = new Array();
     data.forEach(y => {
        this.compentencies.push(y.c1);
        this.compentencies.push(y.c2);
        this.compentencies.push(y.c3);
      })

      var graph = document.getElementById("graph");
      if(data.length != 0){
        graph.style.display = "block";
      this.BaselineStandard = new Chart('Standard3', {
        type: 'bar',
        data: {
          labels: ['Reading and Writing','Number recognisation','Mathamtical operator'],
          datasets: [
            {
              label: "Compentencies",
              backgroundColor: '#f4c20d',
              borderColor: '#f4c20d',
              data: this.compentencies
            },
          ]
        },
        options: {
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
          title: {
            display: true,
            text: 'Baseline Standard 3'
          },
          plugins: {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1;
              },
              align: 'center',
              anchor: 'center',
              color: 'black',
              font: {
                size: 14,
              }
            }
          }
        }
      })
    }
      else {
        graph.style.display = "none";
  
      }
      this.loading = false;
    })
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = this.Standard+"rd Standard Baseline Summary.png";
  }

}
