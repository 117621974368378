import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-compentency2021',
  templateUrl: './compentency2021.component.html',
  styleUrls: ['./compentency2021.component.css']
})
export class Compentency2021Component implements OnInit {
  Examtype;
  competancysummeryform: FormGroup;
  language = 'Gujarati'
  selected;
  Partnerid;
  pgid;
  FCStudentCount;
  FCStudentgujarati;
  gujarati;
  loading = true;
  Baselinedata = new Array;
  Endlinedata = new Array();
  Midlinedata = new Array();
  constructor(private service: MasterService, private fb: FormBuilder,) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

    // if (this.Partnerid =='PT51') {
    //   this.gujarati;
    // } else {

    // }

    this.service.GetPartnerFCStudCount("where partnerid='" + this.Partnerid + "' and examtype='" + this.Examtype + "' and academicyear='" + this.selected + "'").subscribe((data: any) => {

      this.FCStudentCount = data;
      this.Baselinedata = new Array();
      data.forEach(y => {
        this.Baselinedata.push(y.First);
        this.Baselinedata.push(y.Second);
        this.Baselinedata.push(y.Third);
        this.Baselinedata.push(y.Fourth);
        this.Baselinedata.push(y.Fifth);
        this.Baselinedata.push(y.Sixth);
        this.Baselinedata.push(y.Seventh);
        this.Baselinedata.push(y.Eighth);
        this.Baselinedata.push(y.Ninth);
        this.Baselinedata.push(y.Tenth);
        this.Baselinedata.push(y.Eleventh);
        this.Baselinedata.push(y.Twelth);
      });
      this.loading = false;
    });

  }

  OnSelectExamtype(Etype) {
    
    this.Baselinedata = [];
    this.Endlinedata = [];
    this.Midlinedata = [];
    this.service.GetPartnerFCStudCount("where partnerid='" + this.Partnerid + "' and examtype='" + Etype + "' and academicyear='" + this.selected + "'").subscribe((data: any) => {
      this.FCStudentCount = data;
      this.Baselinedata = new Array();
      this.Endlinedata = new Array();
      this.Midlinedata = new Array();
      data.forEach(y => {
        this.Baselinedata.push(y.First);
        this.Baselinedata.push(y.Second);
        this.Baselinedata.push(y.Third);
        this.Baselinedata.push(y.Fourth);
        this.Baselinedata.push(y.Fifth);
        this.Baselinedata.push(y.Sixth);
        this.Baselinedata.push(y.Seventh);
        this.Baselinedata.push(y.Eighth);
        this.Baselinedata.push(y.Ninth);
        this.Baselinedata.push(y.Tenth);
        this.Baselinedata.push(y.Eleventh);
        this.Baselinedata.push(y.Twelth);
      })
      data.forEach(x => {
        this.Endlinedata.push(x.First);
        this.Endlinedata.push(x.Second);
        this.Endlinedata.push(x.Third);
        this.Endlinedata.push(x.Fourth);
        this.Endlinedata.push(x.Fifth);
        this.Endlinedata.push(x.Sixth);
        this.Endlinedata.push(x.Seventh);
        this.Endlinedata.push(x.Eighth);
        this.Endlinedata.push(x.Ninth);
        this.Endlinedata.push(x.Tenth);
        this.Endlinedata.push(x.Eleventh);
        this.Endlinedata.push(x.Twelth);
      })
      data.forEach(x => {
        this.Midlinedata.push(x.First);
        this.Midlinedata.push(x.Second);
        this.Midlinedata.push(x.Third);
        this.Midlinedata.push(x.Fourth);
        this.Midlinedata.push(x.Fifth);
        this.Midlinedata.push(x.Sixth);
        this.Midlinedata.push(x.Seventh);
        this.Midlinedata.push(x.Eighth);
        this.Midlinedata.push(x.Ninth);
        this.Midlinedata.push(x.Tenth);
        this.Midlinedata.push(x.Eleventh);
        this.Midlinedata.push(x.Twelth);
      })
    })
    this.loading = false;
  }

  ngOnInit(): void {

  }


}
