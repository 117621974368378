import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-practicalassissment',
  templateUrl: './practicalassissment.component.html',
  styleUrls: ['./practicalassissment.component.css']
})
export class PracticalassissmentComponent implements OnChanges {
  @Input('ExamType') ExamType;

  selected 
  constructor() { 
  this.selected = localStorage.getItem('key');    
   }

  ngOnChanges(): void {
  }

}
