import { Injectable } from '@angular/core';
import { User } from 'app/ClassFiles/user';
import { environment } from 'environments/environment';
import { Http } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { env } from 'process';
//import { HttpClient, HttpHeaders, HttpProgressEvent } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private http: HttpClient) {

  }

  getyear() {
    var year = localStorage.getItem('key');
    return year;
  }

  getpgid() {
    var programid = localStorage.getItem('pgid');
    return programid;
  }

  login(user: User) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    return this.http.post(environment.apiUrl + 'Partner/' + 'Login', JSON.stringify(user), { headers: headers })
  }

  Menu(userId, menuparentid, type) {
    return this.http.get(environment.apiUrl + "Partner/" + "Menu/" + "/" + userId + "/" + menuparentid + "/" + type)
  }

  PartnerSchoolList(pgid: string, partnerid: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'PartnerSchoolList/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }

  PartnerCenterList(pgid: string, partnerid: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'PartnerCenterList/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }

  GetPartnerComputer(pgid: string, partnerid: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerComputer/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }

  

  PartnerEventList(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'PartnerEventList' + '?whr=' + btoa(where));
  }

  PartnerPDFList(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'PartnerPDFList' + '?whr=' + btoa(where));
  }

  InsertPartnerEvent(data: any) {
    return this.http.post(environment.apiUrl + 'Partner/' + 'InsertPartnerEvent', data);
  }

  OLDPartnerCenterList(pgid: string, partnerid: string, acyear: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerCenterList/' + pgid + '/' + partnerid + '/' + acyear + '?whr=' + btoa(where));
  }

  OLDPartnerSchoolList(pgid: string, partnerid: string, acyear: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerSchoolList/' + pgid + '/' + partnerid + '/' + acyear + '?whr=' + btoa(where));
  }
  GetPartnerDashStudStatusCount(pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerDashStudStatusCount/' + pgid + '?whr=' + btoa(where));
  }

  GetDSEmployeeCoursewiseBeniCountAnalysis(state: string, batname: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeCoursewiseBeniCountAnalysis/' + state + '/' + batname)
  }
  GetRptDSAssessmentPieGraphonepageAnalysis(state: string, batname: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSAssessmentPieGraphonepageAnalysis/' + state + '/' + batname)
  }
  GetPartnerStateCentWise(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStateCentWise?whr=' + btoa(where))
  }
  GetPartnerStateCentWiseMyEschool(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStateCentWiseMyEschool?whr=' + btoa(where))
  }
  GetPartnerGradewiseCenterAttendance(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGradewiseCenterAttendance?whr=' + btoa(where))
  }
  GetPartnerStdCentWise(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStdCentWise?whr=' + btoa(where))
  }
  GetPartnerSchoolLanguageEnrolmenttable(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerSchoolLanguageEnrolmenttable?whr=' + btoa(where))
  }
  GetPartnerLanguagewiseEnrolmenttable(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLanguagewiseEnrolmenttable?whr=' + btoa(where))
  }

  GetDSAnalysisCount(where) {
    return this.http.get(environment.apiUrl + "Employee/" + "GetDSAnalysisCount?whr=" + where)
  }

  GetDSEmployeeAgeGroupBeneficiarygraphAnalysis(state: string, batname: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeAgeGroupBeneficiarygraphAnalysis/' + state + '/' + batname)
  }
  AcademicyearPartner(partnerid: string): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'AcademicyearPartner/' + partnerid);
  }
  SahyogiOLDCALCenterData(partnerid: string, acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiOLDCALCenterData/' + partnerid + '/' + acyear + '/' + centid)
  }
  OLDPartnerStdCentWise(partnerid: string, acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerStdCentWise/' + partnerid + '/' + acyear + '/' + centid)
  }
  OLDPartnerAttendaceCentWise(partnerid: string, acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerAttendaceCentWise/' + partnerid + '/' + acyear + '/' + centid)
  }
  SahyogiStateWiseData(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiStateWiseData/' + partnerid + '/' + acyear + '/' + pgid)
  }
  SahyogiCityWiseData(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiCityWiseData/' + partnerid + '/' + acyear + '/' + pgid)
  }
  SahyogiCALData(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiCALData/' + partnerid + '/' + acyear + '/' + pgid)
  }

  OLDPartnerAttendaceEnroll(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerAttendaceEnroll/' + partnerid + '/' + acyear + '/' + pgid)
  }
  GetOLDPartnerEnrollStdWise(where) {
    return this.http.get(environment.apiUrl + "Partner/" + "GetOLDPartnerEnrollStdWise?whr=" + btoa(where))
  }

  OLDPartnerLangCentWise(partnerid: string, acyear: string, centid: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerLangCentWise/' + partnerid + '/' + acyear + '/' + centid + '/' + pgid)
  }
  SahyogiCALLangGraph(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiCALLangGraph/' + partnerid + '/' + acyear + '/' + pgid)
  }

  OLDPartnerSchoolLangDatatable(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerSchoolLangDatatable/' + partnerid + '/' + acyear + '/' + pgid)
  }
  SahyogiCALLangtable(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiCALLangtable/' + partnerid + '/' + acyear + '/' + pgid)
  }

  OLDPartnerLangCentWiseGraph(partnerid: string, acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerLangCentWiseGraph/' + partnerid + '/' + acyear + '/' + centid)
  }

  OLDPartnerLangCentWisetable(partnerid: string, acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerLangCentWisetable/' + partnerid + '/' + acyear + '/' + centid)
  }
  OLDPartnerSchoolLangDataGraph(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerSchoolLangDataGraph/' + partnerid + '/' + acyear + '/' + pgid)
  }
  OLDPartnerIDIBatchList(partnerid: string, acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIBatchList/' + partnerid + '/' + acyear)
  }
  SahyogiIDIData(partnerid: string, acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiIDIData/' + partnerid + '/' + acyear)
  }
  OLDPartnerIDIAgeEnrolmentSummary(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIAgeEnrolmentSummary/' + partnerid + '/' + acyear + '/' + pgid)
  }
  OLDPartnerIDIOverallGenderSummary(userId: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIOverallGenderSummary/' + userId + '/' + acyear + '/' + pgid)
  }

  OLDPartnerIDIAgeIntJobSummary(userId: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIAgeIntJobSummary/' + userId + '/' + acyear + '/' + pgid)
  }
  OLDPartnerIDIQualificationwiseSummary(userId: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIQualificationwiseSummary/' + userId + '/' + acyear + '/' + pgid)
  }
  OLDPartnerIDIStatusWiseEnrolmentSummary(userId: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIStatusWiseEnrolmentSummary/' + userId + '/' + acyear + '/' + pgid)
  }

  OLDPartnerIDIMaleFemaleRatio(userId: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIMaleFemaleRatio/' + userId + '/' + acyear + '/' + pgid)
  }
  GetPartnerIDIEnrollAgeIntJobNew(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIEnrollAgeIntJobNew?whr=' + btoa(where))
  }
  GetPartnerIDIEnrollAgeNew(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIEnrollAgeNew?whr=' + btoa(where))
  }
  GetPartnerIDIEnrollStatusNew(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIEnrollStatusNew?whr=' + btoa(where))
  }
  OLDPartnerIDICenterMaleFemaleRatio(userId: string, acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDICenterMaleFemaleRatio/' + userId + '/' + acyear + '/' + centid)
  }
  OLDPartnerIDICenterGender(userId: string, acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDICenterGender/' + userId + '/' + acyear + '/' + centid)
  }

  // state wise enrollment wns
  GetPartnerStatewiseEnrollmentSummary(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStatewiseEnrollmentSummary?whr=' + btoa(where))
  }
  GetPartnerStatewiseEnrollmentSummaryGraph(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStatewiseEnrollmentSummaryGraph?whr=' + btoa(where))
  }
  // city wise enrollment wns
  GetPartnerCitywiseEnrollmentSummary(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerCitywiseEnrollmentSummary?whr=' + btoa(where))
  }
  GetPartnerCitywiseEnrollmentSummaryGraph(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerCitywiseEnrollmentSummaryGraph?whr=' + btoa(where))
  }
  // Enrolment Summary Enrollment CAL New Data
  GetPartnerLanguagewiseEnrolment(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLanguagewiseEnrolment?whr=' + btoa(where))
  }
  // Languagewise School Summary Enrollment CAL New Data
  GetPartnerSchoolLanguageEnrolment(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerSchoolLanguageEnrolment?whr=' + btoa(where))
  }
  //  Standard Summary Enrollment CAL New Data
  GetPartnerStdEnrollmentSummary(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStdEnrollmentSummary?whr=' + btoa(where))
  }
  //  gender Summary Enrollment CAL New Data
  GetPartnerGenderEnrollmentSummary(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGenderEnrollmentSummary?whr=' + btoa(where))
  }
  //  GradewiseAttendanceSummary CAL New Data
  GetPartnerGradewiseAttendanceSummary(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGradewiseAttendanceSummary?whr=' + btoa(where))
  }
  //  centerwise langauge count for center graph Current data
  GetPartnerLangCentWiseGraph(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLangCentWiseGraph?whr=' + btoa(where))
  }
  //  centerwise langauge count for center table Current data  
  GetPartnerLangCentWise(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLangCentWise?whr=' + btoa(where))
  }

  GetCALAssessmentGraphCount(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetCALAssessmentGraphCount?whr=' + btoa(where))
  }

  addUpdateDocument(data: any) {
    return this.http.post(environment.apiUrl + 'Partner/' + 'addUpdateDocument', data);
  }


  InsertPartnerEventPhoto1(ptid: string, pgid: string, evname, evplace, evdate, evdesription, picture: File) {
    const formData: FormData = new FormData();
    formData.append('Image', picture);

    return this.http.post(environment.apiUrl + 'Partner/' + 'InsertPartnerEventPhoto/' + ptid + '/' + pgid + '/' + evname + '/' + evplace + '/' + evdate + '/' + evdesription, formData);
  }

  PartnerPDFReport(partnerid: string, pgid: string, academicyear: string, createdby: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Partner/' + 'PartnerPDFReport/' + partnerid + '/' + pgid + '/' + academicyear + '/' + createdby, formData);
  }

  GetPartnerEvent(eid: string): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerEvent/' + eid);
  }

  GetEventPic(id: string): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetEventPic/' + id);
  }

  UpdateEventDetail(eid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Partner/' + 'UpdateEventDetail/' + eid, data);
  }

  UpdateEventPicture(eid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Partner/' + 'UpdateEventPhoto/' + eid, formData);
  }
  GetCALAssessmentGraphLevel(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetCALAssessmentGraphLevel?whr=' + btoa(where))
  }

  GetReportPDF(id: number): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetReportPDF/' + id);
  }


  RemoveEventPhoto(id: number) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'RemoveEventPhoto/' + id);
  }

  RemoveReportPDF(id: number) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'RemoveReportPDF/' + id);
  }

  GetPartnerIDIOverallGenderNew(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIOverallGenderNew?whr=' + btoa(where));
  }

  OLDPartnerIDIAttendanceSummary(userId: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIAttendanceSummary/' + userId + '/' + acyear + '/' + pgid)
  }

  GetCALAssessmentGraphCountFinal(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetCALAssessmentGraphCountFinal?whr=' + btoa(where))
  }


  GetPartnerIDIBatchlistNew(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIBatchlistNew?whr=' + btoa(where));
  }

  GetPartnerIDIDashStudStatusCount(pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIDashStudStatusCount/' + pgid + '?whr=' + btoa(where));
  }

  // GetPartnerMaleFemaleRatioNewIDI(where) {
  //   return this.http.get(environment.apiUrl+'Partner/' + 'GetPartnerMaleFemaleRatioNewIDI?whr=' + where  )
  // }

  GetPartnerComputerIDI(pgid: string, partnerid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerComputerIDI/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }

  GetPartnerGenderEnrollmentSummaryIDI(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGenderEnrollmentSummaryIDI?whr=' + btoa(where));
  }

  OLDPartnerCenterComputer(pgid: string, partnerid: string, acyear: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerCenterComputer/' + pgid + '/' + partnerid + '/' + acyear + '?whr=' + btoa(where));
  }

  GetPartnerMaleFemaleRatioNewIDI(partnerid: string, acyear: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerMaleFemaleRatioNewIDI/' + partnerid + '/' + acyear + '/' + pgid + '?whr=' + btoa(where));
  }

  GetPartnerIDIMaleFemaleRatioCent(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIMaleFemaleRatioCent?whr=' + where)
  }

  GetPartnerCenterwiseMaleFemaleRatioNewIDI(partnerid: string, acyear: string, centid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerCenterwiseMaleFemaleRatioNewIDI/' + partnerid + '/' + acyear + '/' + centid + '?whr=' + btoa(where));
  }

  GetPartnerIDIFellowlist(pgid: string, partnerid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIFellowlist/' + pgid + '/' + partnerid + '?whr=' + btoa(where))
  }

  GetIDIAssessmentGraphCount(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetIDIAssessmentGraphCount?whr=' + btoa(where))
  }

  GetPartnerIDIAttendanceGraph(pgid: string, partnerid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIAttendanceGraph/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }

  GetPartnerIDICenterwiseAttendanceGraph(pgid: string, partnerid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDICenterwiseAttendanceGraph/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }

  GetPartnerIDICenterwiseAttendanceGraphPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDICenterwiseAttendanceGraphPIF?whr=' + btoa(where));
  }

  GetPartnerOLDIDIBatchlist(pgid: string, partnerid: string, academicyear: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerOLDIDIBatchlist/' + pgid + '/' + partnerid + '/' + academicyear + '?whr=' + btoa(where));
  }

  GetPartnerOLDIDIAttendanceCentwiseGraph(pgid: String, partnerid: string, academicyear: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerOLDIDIAttendanceCentwiseGraph/' + pgid + '/' + partnerid + '/' + academicyear + '?whr=' + btoa(where));
  }

  OldPartnerIDIAssessmentCount(partnerid: string, acyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OldPartnerIDIAssessmentCount/' + partnerid + '/' + acyear + '/' + pgid)
  }

  GetPartnerMaleFemaleRatioSummaryNewIDI(partnerid: string, acyear: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerMaleFemaleRatioSummaryNewIDI/' + partnerid + '/' + acyear + '/' + pgid + '?whr=' + btoa(where));
  }

  PartnerCenterIDIList(pgid: string, partnerid: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'PartnerCenterIDIList/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }

  GetCALFinalAssessmentLevelStandardPartnerwise(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetCALFinalAssessmentLevelStandardPartnerwise?whr=' + btoa(where));
  }

  SahyogiFcEvalCompetency(partnerid: string, acyear: string, pgid: string, examtype: string, standard) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiFcEvalCompetency/' + partnerid + '/' + acyear + '/' + pgid + '/' + examtype + '/' + standard);
  }

  GetPartnerFCStudCount(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerFCStudCount?whr=' + btoa(where));
  }

  GetPartnerFCStudCountComparision(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerFCStudCountComparision?whr=' + btoa(where))
  }

  GetPartnerFCEvalComparision(standard: string, partnerid: string, academicyear:string,whr): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerFCEvalComparision/' + standard + '/' + partnerid + '/' + academicyear + '?whr=' + btoa(whr));
  }
  
  ModuleWiseATALSummary(examtype: string, pgid: string, year: string): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'ModuleWiseATALSummary/' + examtype + '/' + pgid + '/' + year);
  }

  GetCALAssessmentLevelStandardGraphBaseline1to4(Userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStandardGraphBaseline1to4/' + Userid + '/' + role + '/' + pgid);
  }

  GetCALAssessmentLevelStandardGraphBaseline5to12(Userid: string, role: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStandardGraphBaseline5to12/' + Userid + '/' + role + '/' + pgid);
  }


  GetCALAssessmentLevelStdGraphBaselinesayhogi1to4(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphBaselinesayhogi1to4?whr=' + btoa(where));
  }

  GetCALAssessmentLevelStdGraphBaselinesayhogi5to12(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphBaselinesayhogi5to12?whr=' + btoa(where));
  }

  AddPartnerPhotogallery(ptid: string, filename: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Partner/' + 'AddPartnerPhotogallery/' + ptid + '/' + filename, formData);
  }

  GetPartnerPhotogalleryList(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerPhotogalleryList?whr=' + btoa(where));
  }

  RemovePhotogallery(id: number) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'RemovePhotogallery/' + id);
  }

  GetPartnerplanvsactualSummary(whr) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetPartnerplanvsactualSummary?whr=' + btoa(whr));
  }

  GetPartnerAlbumList(whr) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerAlbumList?whr=' + btoa(whr));
  }

  GetPartnerAlbum(id: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerAlbum/' + id);
  }

  RemovePartnerAlbum(id: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'RemovePartnerAlbum/' + id);
  }

  SahyogiEvalCompetency(partnerid: string, acyear: string, pgid: string, examtype: string, standard: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiEvalCompetency/' + partnerid + '/' + acyear + '/' + pgid + '/' + examtype + '/' + standard)
  }

  //statewise enrollment summmary atal
  GetPartnerStatewiseEnrollmentSummaryATAL(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStatewiseEnrollmentSummaryATAL?whr=' + btoa(where))
  }

  // city wise enrollment atal
  GetPartnerCitywiseEnrollmentSummaryATAL(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerCitywiseEnrollmentSummaryATAL?whr=' + btoa(where))
  }

  // city wise enrollment dpworld
  GetPartnerCitywiseEnrollmentSummaryDP(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerCitywiseEnrollmentSummaryDP?whr=' + btoa(where))
  }

  //state wise enrollment dpworld
  GetPartnerStatewiseEnrollmentSummaryDP(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStatewiseEnrollmentSummaryDP?whr=' + btoa(where))
  }

  PIFCenterList(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'PIFCenterList?whr=' + btoa(where))
  }
  GetPartnerDashStudStatusCountPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerDashStudStatusCountPIF?whr=' + btoa(where))
  }
  PartnerSchoolListPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'PartnerSchoolListPIF?whr=' + btoa(where))
  }
  GetPartnerComputerPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerComputerPIF?whr=' + btoa(where))
  }
  GetPartnerCenterIDIlistPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerCenterIDIlistPIF?whr=' + btoa(where))
  }
  GetPartnerIDIDashStudStatusCountPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIDashStudStatusCountPIF?whr=' + btoa(where))
  }


  GetPartnerStatewiseEnrollmentSummaryPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStatewiseEnrollmentSummaryPIF?whr=' + btoa(where))
  }
  GetPartnerCitywiseEnrollmentSummaryPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerCitywiseEnrollmentSummaryPIF?whr=' + btoa(where))
  }

  GetPartnerMaleFemaleRatioSummaryNewIDIPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerMaleFemaleRatioSummaryNewIDIPIF?whr=' + btoa(where))
  }
  GetPartnerGenderEnrollmentSummaryIDIPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGenderEnrollmentSummaryIDIPIF?whr=' + btoa(where))
  }
  GetPartnerIDIEnrollStatusNewPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIEnrollStatusNewPIF?whr=' + btoa(where))
  }
  GetPartnerIDIEnrollAgeIntJobNewPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIEnrollAgeIntJobNewPIF?whr=' + btoa(where))
  }
  GetPartnerIDIAttendanceGraphPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIAttendanceGraphPIF?whr=' + btoa(where))
  }
  GetPartnerIDIEnrollAgeNewPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDIEnrollAgeNewPIF?whr=' + btoa(where))
  }
  GetPartnerSchoolLanguageEnrolmentPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerSchoolLanguageEnrolmentPIF?whr=' + btoa(where))
  }
  GetPartnerLanguagewiseEnrolmenttablePIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLanguagewiseEnrolmenttablePIF?whr=' + btoa(where))
  }
  GetPartnerSchoolLanguageEnrolmenttablePIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerSchoolLanguageEnrolmenttablePIF?whr=' + btoa(where))
  }
  GetPartnerLanguagewiseEnrolmentPIF(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLanguagewiseEnrolmentPIF?whr=' + btoa(where))
  }
  GetSahyogiEvalCompetencyCitywise(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetSahyogiEvalCompetencyCitywise?whr=' + btoa(where))
  }
  OLDPartnerCenterListPIF(acyear: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerCenterListPIF/' + acyear + '?whr=' + btoa(where));
  }
  SahyogiOLDCALCenterDataPIF(acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiOLDCALCenterDataPIF/' + acyear + '/' + centid)
  }
  OLDPartnerStdCentWisePIF(acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerStdCentWisePIF/' + acyear + '/' + centid)
  }
  OLDPartnerLangCentWisetablePIF(acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerLangCentWisetablePIF/' + acyear + '/' + centid)
  }
  OLDPartnerLangCentWiseGraphPIF(acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerLangCentWiseGraphPIF/' + acyear + '/' + centid)
  }
  OLDPartnerAttendaceCentWisePIF(acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerAttendaceCentWisePIF/'  + acyear + '/' + centid)
  }
  OLDPartnerSchoolListPIF(acyear: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerSchoolListPIF/'  + acyear + '?whr=' + btoa(where));
  }
  OLDPartnerCenterComputerPIF(acyear: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerCenterComputerPIF/'  + acyear + '?whr=' + btoa(where));
  }
  SahyogiIDIDataPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiIDIDataPIF/' + acyear )
  }
  OLDPartnerIDIAgeEnrolmentSummaryPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIAgeEnrolmentSummaryPIF/' + acyear)
  }
  OLDPartnerIDIMaleFemaleRatioPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIMaleFemaleRatioPIF/' + acyear)
  }
  OLDPartnerIDIOverallGenderSummaryPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIOverallGenderSummaryPIF/' + acyear)
  }
  OLDPartnerIDIBatchListPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIBatchListPIF/' + acyear)
  }
  OLDPartnerIDIStatusWiseEnrolmentSummaryPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIStatusWiseEnrolmentSummaryPIF/' + acyear)
  }
  OLDPartnerIDIAttendanceSummaryPIF(acyear: string,month: string,year: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIAttendanceSummaryPIF/'+ acyear + '/' + month + '/' + year)
  }
  OLDPartnerIDIAgeIntJobSummaryPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDIAgeIntJobSummaryPIF/' + acyear)
  }
  GetCALAssessmentLevelStdGraphBaselinesayhogi5to122022(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraphBaselinesayhogi5to122022?whr=' + btoa(where));
  }
  SahyogiStateWiseDataOLDPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiStateWiseDataOLDPIF/' + acyear)
  }
  FillCenterCityIDI(us, ur, ogid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillCenterCityIDI?us=' + us + '&ur=' + ur + '&ogid=' + ogid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }
  GetPartnerIDICourseWiseGraph(pgid: string, partnerid: string, where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerIDICourseWiseGraph/' + pgid + '/' + partnerid + '?whr=' + btoa(where));
  }
  GetPartnerMaleFemaleRatioCourseWiseSummaryIDI(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerMaleFemaleRatioCourseWiseSummaryIDI?whr=' + btoa(where))
  }
  OLDPartnerIDICenterMaleFemaleRatioPIF(acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDICenterMaleFemaleRatioPIF/' + acyear + '/' + centid)
  }
  OLDPartnerIDICenterGenderPIF(acyear: string, centid: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerIDICenterGenderPIF/' + acyear + '/' + centid)
  }
  GetPartnerOLDIDIAttendanceCentwiseGraphPIF(pgid: string,acyear: string,where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerOLDIDIAttendanceCentwiseGraphPIF/' + pgid + '/' + acyear + '?whr=' + btoa(where));
  }
  SahyogiCALLangGraphPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiCALLangGraphPIF/'  + acyear)
  }
  SahyogiCALLangtablePIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiCALLangtablePIF/' + acyear)
  }
  OLDPartnerSchoolLangDataGraphPIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerSchoolLangDataGraphPIF/' + acyear)
  }
  OLDPartnerSchoolLangDatatablePIF(acyear: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'OLDPartnerSchoolLangDatatablePIF/' + acyear)
  }
  GetOLDPartnerEnrollStdWisePIF(acyear:string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetOLDPartnerEnrollStdWisePIF/' + acyear)
  }
  SahyogiCALDataPIF(acyear:string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiCALDataPIF/' + acyear)
  }
  GetPartnerSancharakFeedback(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerSancharakFeedback?whr=' + btoa(where))
  }
  GetCALLevelwiseComparison(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALLevelwiseComparison?whr=' + btoa(where))
  }
  GetCALAssessmentLevelStdGraph2022sayhogi1to4(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetCALAssessmentLevelStdGraph2022sayhogi1to4?whr=' + btoa(where));
  }
  GetCALPartnerwiseCenterSchoolCountSahyogi(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetCALPartnerwiseCenterSchoolCountSahyogi?whr=' + btoa(where));
  }
  GetPartnerStudentlist(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStudentlist?whr=' + btoa(where));
  }
  GetPartnerStudentAttendancelist(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStudentAttendancelist?whr=' + btoa(where));
  }
  GetPartnerStudentAttendancelist2023(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStudentAttendancelist2023?whr=' + btoa(where));
  }
  GetPartnerStudentAttendanceEnglishlist(where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStudentAttendanceEnglishlist?whr=' + btoa(where));
  }
  GetPartnerGradewiseAttendanceEnglishSummary(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGradewiseAttendanceEnglishSummary?whr=' + btoa(where))
  }

  GetMyEschoolPartnerwisecenterinfra( where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetMyEschoolPartnerwisecenterinfra?whr=' + btoa(where));
  }

  GetMyEschoolAssessmentLevelStdGraph2022sayhogi1to4(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetMyEschoolAssessmentLevelStdGraph2022sayhogi1to4?whr=' + btoa(where));
  }
  GetMyESchoolAserPartnerGraph( where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetMyESchoolAserPartnerGraph?whr=' + btoa(where));
  }

  
  GetPartnerGenderEnrollmentSummaryEnglish(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerGenderEnrollmentSummaryEnglish?whr=' + btoa(where))
  }
  GetPartnerStdEnrollmentSummaryEnglish(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerStdEnrollmentSummaryEnglish?whr=' + btoa(where))
  }
  GetPartnerLanguagewiseEnrolmentEnglishtable(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLanguagewiseEnrolmentEnglishtable?whr=' + btoa(where))
  }
  GetPartnerLanguagewiseEnrolmentEnglish(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerLanguagewiseEnrolmentEnglish?whr=' + btoa(where))
  }
  GetEnglishAserAssessmentLevelStdGraphsayhogi1to12(where) {
    return this.http.get(environment.apiUrl + 'Student/' + 'GetEnglishAserAssessmentLevelStdGraphsayhogi1to12?whr=' + btoa(where))
  }
  GetPartnerDashStudStatusCountMyEschool(pgid: string, where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerDashStudStatusCountMyEschool/' + pgid + '?whr=' + btoa(where));
  }

  GetRptMyEschoolTeacherTrainingPartner( where): any {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetRptMyEschoolTeacherTrainingPartner' + '?whr=' + btoa(where));
  }

  GetName(table: string, col: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetName?table=' + table + '&col=' + btoa(col) + '&whr=' + btoa(where));
  }

  FillDropDownUserwise(us, ur, pgid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwise?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }

  FillDropDownUserwiseLite(us, ur, pgid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwiseLite?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }

  FillDropDown(tablename, column1, column2, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillDropDown?tablename=' + tablename + '&column1=' + btoa(column1) + '&column2=' + btoa(column2) + '&whr=' + btoa(where));
  }
 
}
