import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { data } from 'jquery'

@Component({
  selector: 'app-ataldashboard',
  templateUrl: './ataldashboard.component.html',
  styleUrls: ['./ataldashboard.component.css']
})
export class AtaldashboardComponent implements OnInit {

  panelColor = new FormControl('red'); countryForm: FormGroup;
  countries = ['USA', 'Canada', 'Uk']
  selectedDeviceObj;
  UserId; pgid; centercount; Schoolcentercount; StatusCount;
  totenrolment; schcompl; studstatusdrop; studstatusAct; acyear;
  fineyear; objective;
  para1: string; para11: string; para2: string; para21: string;
  para3: string; para31: string; para4: string; para41: string;
  para5: string; para6: string; para7: string; para8: string; paraa: string;
  boldpara1: string; boldpara2: string; boldpara3: string; boldpara4: string;
  boldpara5: string; boldpara6: string; boldpara7: string; boldpara: string;
  para: string; objective1: string; programname;
  fy; CenterComputercount; col1; Academicyear; academicyear;
  cities2: any[];
  selectfiyear: any;
  demo;
  value;
  pifcenterlist;
  noofcomputer = 0;
  Eventpicture;
  photolist;
  photocount;
  partnerinfo;
  Menus;
  pifcenterlistcount;
  imgdata = [
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img1.jpg", title: "Slide 1" },
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img2.jpg", title: "Slide 2" },
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img3.jpg", title: "Slide 3" }
  ];
  constructor(private service: MasterService, public fb: FormBuilder, private router: Router) {
    this.selectfiyear = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');
    this.UserId = localStorage.getItem('UserId');  

    this.service.GetPartnerPhotogalleryList("where partner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
      this.photolist = data;
      this.photocount = data.length;
    });

    this.service.GetPartnerplanvsactualSummary("where #plpart.partnerid ='" + this.UserId + "' and academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
      this.objective = data[0].objectives;
      this.partnerinfo = data[0].partnerinfo;
    })

    this.service.GetEventPic('EVM141').subscribe((data: any) => {
      this.Eventpicture = data;
    });

    if (this.selectfiyear == null) {
      this.service.PartnerCenterList(this.pgid, this.UserId, "where  academicyear='" + "2021-2022" + "'").subscribe((data: any) => {
        this.centercount = data.length;
      });
      this.service.GetPartnerComputer(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });

      this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + "2021-2022" + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
        console.log("count" + this.Schoolcentercount);
      });
      this.service.GetPartnerDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.StatusCount = data;
        var a = data[0];
        this.studstatusAct = a.studstatusAct;
        this.studstatusdrop = a.studstatusdrop;
        this.schcompl = a.schcompl;
        this.totenrolment = a.studstatusAct;
      });

    }
    else if (this.selectfiyear == "2022-2023") {
      this.service.PartnerCenterList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
        
        this.centercount = data.length;
      });

      this.service.GetPartnerComputer(this.pgid, this.UserId, "and c.Pgid='" + this.pgid + "' and c.Status=0 ").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
      this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
        console.log("count" + this.Schoolcentercount);
      });
      if (this.pgid == 'P1' && this.UserId == 'PT40') {
        console.log('cal');

        this.service.GetPartnerCitywiseEnrollmentSummary("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "' and #stud.city not in ('Bangalore')").subscribe((data: any) => {
          console.log(data);

          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.studstatusAct;
        });
      }
      else {
        this.service.GetPartnerDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.studstatusAct;
        });
      }

    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022") {
      this.service.OLDPartnerCenterList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.centercount = data.length;
        console.log("center" + this.centercount)
      });
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {

        this.Schoolcentercount = data.length;
        console.log("schcenter" + this.Schoolcentercount)
      });

      this.service.SahyogiCALData(this.UserId, this.selectfiyear, this.pgid).subscribe((data: any) => {
        var a = data[0];
        this.totenrolment = a.student;
        console.log("enrolmentr" + this.totenrolment)
      });
      this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, "").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
        console.log("comp" + this.noofcomputer)
      });

    }

    this.service.FillDropDown("AcademicyearPartner", " academicyear", "academicyear", "where partnerid='" + this.UserId + "' order by academicyear desc").subscribe(
      (data: any) => {
        this.acyear = data;
      });

    this.service.AcademicyearPartner(this.UserId).subscribe((data: any) => {
      this.Academicyear = data;
      var a = data[0];
      this.academicyear = a.academicyear;

    });


    this.service.FillDropDown("partnerprogram", "distinct partnerprogram.pgid", "Program.pgname", "inner join program on partnerprogram.pgid=program.pgid where partnerprogram.userid='" + this.UserId + "' ").subscribe(
      (data: any) => {
        this.programname = data;
      });


  }

  ngOnInit() { }

  onSelectfineyear(selectfiyear) {
    this.noofcomputer = 0;
    localStorage.setItem('key', this.selectfiyear);
    this.selectfiyear = selectfiyear;

    if (this.selectfiyear == "2022-2023") {
      console.log(this.selectfiyear);
      
     
      if (this.pgid == 'P1' && this.UserId == 'PT40') {
        console.log('cal');

        this.service.GetPartnerCitywiseEnrollmentSummary("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "' and #stud.city not in ('Bangalore')").subscribe((data: any) => {
          console.log(data);

          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.studstatusAct;
        });
      }
      else {
        this.service.GetPartnerDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.studstatusAct;
        });
      }
      this.service.GetPartnerComputer(this.pgid, this.UserId, "and c.Pgid='" + this.pgid + "' and c.Status=0 ").subscribe((data: any) => {
        this.CenterComputercount = data
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
    
      this.service.PartnerCenterList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {
        this.centercount = data.length;
      });

      this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
        console.log("count" + this.Schoolcentercount);
      });
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022") {

      this.service.OLDPartnerCenterList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.centercount = data.length;
        console.log("center" + this.centercount)
      });
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
        console.log("school" + this.Schoolcentercount)
      });
      this.service.SahyogiCALData(this.UserId, this.selectfiyear, this.pgid).subscribe((data: any) => {
        var a = data[0];
        this.totenrolment = a.student;
        console.log("enrol" + data);
      });
      this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
        console.log("comp" + this.noofcomputer)
      });
    }

  }
  display() {
    var rowdata = document.getElementById("div1");
    if (this.UserId == "PT1" || this.UserId == "PT13" || this.UserId == "PT51" || this.UserId == "PT14" || this.UserId == "PT49" || this.UserId == "PT15" || this.UserId == "PT16" || this.UserId == "PT22" || this.UserId == "PT23" || this.UserId == "PT3" || this.UserId == "PT30" || this.UserId == "PT33" || this.UserId == "PT34" || this.UserId == "PT45" || this.UserId == "PT3" || this.UserId == "PT47" || this.UserId == "PT40") {
      rowdata.style.display = "block";
    }
    else {
      rowdata.style.display = "none";
    }
  }
  onChangeObj(newObj) {
    this.selectedDeviceObj = newObj;
  }

  onSelectprogram(val) {
    if (val == 'P1') {
      localStorage.setItem('pgname', 'CAL-PIF');
      localStorage.setItem('pgid', 'P1');
      this.router.navigate(['/Dashboard']);
      window.location.reload();
    }
    else if (val == 'P6') {
      localStorage.setItem('pgname', 'IDI');
      localStorage.setItem('pgid', 'P6');
      this.router.navigate(['/IDIDashboard']);
      // window.location.reload();
    }
    else if (val == 'P18') {
      
      localStorage.setItem('pgname', 'ATAL');
      localStorage.setItem('pgid', 'P18');
      this.router.navigate(['/ATALDashboard']);
      window.location.reload();
    }
  }
}
