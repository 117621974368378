import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-photogalleryalbum',
  templateUrl: './photogalleryalbum.component.html',
  styleUrls: ['./photogalleryalbum.component.css']
})
export class PhotogalleryalbumComponent implements OnInit {
  photolist;
  loading = true;

  UserId;
  photos;
  paid;photoscount;
  constructor(private service: MasterService,private router:Router) {
    this.UserId = localStorage.getItem('UserId');
    this.service.GetPartnerAlbumList("where partner.partnerid='"+this.UserId+"'").subscribe((data:any) => {
      this.photolist = data;
      this.photoscount = data.length;
      for (let i = 0; i < data.length; i++) {
      this.service.GetPartnerAlbum(data[i].paid).subscribe((data1: any) => {
        this.photos = data1;
        console.log(data[i].paid)
        console.log(this.photos)
      });
        
      }

      this.loading = false;
    });

  }

  ngOnInit(): void {
  }

  albumphoto(paid){
    console.log('paid'+ paid)
    this.router.navigate(['/AlbumPhotos',paid])
  }

}

