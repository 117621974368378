import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-baseline2021-std3',
  templateUrl: './baseline2021-std3.component.html',
  styleUrls: ['./baseline2021-std3.component.css']
})
export class Baseline2021Std3Component implements OnChanges {

  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  
  Partnerid;
  pgid;
  selected;
  loading = true;
  Baselinestd3;
  Baselinestd3count;

  BaselineStandard:any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  c5 = new Array();
  c6 = new Array();
  c7 = new Array();
  c8 = new Array();
  c9 = new Array();
  c10 = new Array();
  c11 = new Array();
  compentencies;
  constructor(private service: MasterService,private router: Router) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  
  }

  ngOnChanges() {
    this.service.SahyogiEvalCompetency(this.Partnerid,this.selected,this.pgid,this.Examtype,this.Standard).subscribe((data:any) => {
      this.Baselinestd3 = data;
      this.Baselinestd3count = data.length;
      this.compentencies = new Array();
     data.forEach(y => {
        this.compentencies.push(y.c1);
        this.compentencies.push(y.c2);
        this.compentencies.push(y.c3);
        this.compentencies.push(y.c4);
        this.compentencies.push(y.c5);
        this.compentencies.push(y.c6);
        this.compentencies.push(y.c7);
        this.compentencies.push(y.c8);
        this.compentencies.push(y.c9);
        this.compentencies.push(y.c10);
        this.compentencies.push(y.c11);
      })

      var graph = document.getElementById("graph");
      if(data.length != 0){
        graph.style.display = "block";
      this.BaselineStandard = new Chart('Standard3', {
        type: 'bar',
        data: {
          labels: ['Word Reading(Beginner)','Sentence Formation(Beginner)','Word Reading(Basic)','Sentence Formation(Basic)','Write the before or after number of given number(Beginner)','Arrange number in ascending and decending order(Beginner)','Addition with carry over(Beginner)','Subtraction with borrows(Beginner)','Place value of two digit number( 1 to 20)(Beginner)','Word problem - Addion and subtraction with borrow and carry over(Beginner)','Mathematical Table (2 to 10)(Beginner)'],
          datasets: [
            {
              label: "Compentencies",
              backgroundColor: '#f4c20d',
              borderColor: '#f4c20d',
              data: this.compentencies
            },
          ]
        },
        options: {
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
              scaleLabel: {
                display: true,
                labelString: "Compentencies",
              }
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
          title: {
            display: true,
            text: this.Examtype+'Standard 3'
          },
          plugins: {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1;
              },
              align: 'center',
              anchor: 'center',
              color: 'black',
              font: {
                size: 14,
              }
            }
          }
        }
      })
    }
      else {
        graph.style.display = "none";
  
      }
      this.loading = false;
    })
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard+"rd Standard Baseline Summary.png";
  }


}
