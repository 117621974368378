import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fcevaluationcompentencygraphs',
  templateUrl: './fcevaluationcompentencygraphs.component.html',
  styleUrls: ['./fcevaluationcompentencygraphs.component.css']
})
export class FcevaluationcompentencygraphsComponent implements OnInit {
  selected
  constructor() { 
  this.selected = localStorage.getItem('key');
   }
    ngOnInit(): void {
  }

}
