import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-assissment',
  templateUrl: './assissment.component.html',
  styleUrls: ['./assissment.component.css']
})
export class AssissmentComponent implements OnInit {
  selected 
  pgid;
  constructor() { 
  this.selected = localStorage.getItem('key');    
    this.pgid = localStorage.getItem('pgid');
    console.log(this.pgid);
    
   }
    ngOnInit(): void {
  }
}