import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/ClassFiles/user';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  message: string;
  loading = false;
  // public showPassword: boolean = false;

  constructor(private router: Router, private service: MasterService) { }

  ngOnInit() { }

  Loginuser(values) {
    this.loading = true;
    let user = new User();
    user.UserName = values.UserName;
    user.Password = values.Password;
    this.service.login(user).subscribe(resp => {
      console.log(resp);
      
      if (resp[0].Result === "Faliure") {
        this.loading = false;
        this.message = "Please check UserName and password";
      }
      else if (resp[0].Result === "User Inactive") {
        this.message = "User Inactive";
      }
      else {
          localStorage.setItem('UserId', resp[0].UserId);
          localStorage.setItem('UserName', JSON.stringify(resp[0].UserName));
          localStorage.setItem('Userrole', resp[0].Userrole);
          localStorage.setItem('FullName', resp[0].FullName);
          localStorage.setItem('pgid', resp[0].pgid);
          localStorage.setItem('pgname', resp[0].pgname);
          localStorage.setItem('key', resp[0].academicyear);
          console.log('UserId', resp[0].UserId);
          
          if (resp[0].UserName == 'sapyouth') {
            this.router.navigate(["/IDIDashboard"]);
          }
          else  if (resp[0].UserName == 'saparibayouth') {
            this.router.navigate(["/IDIDashboard"]);
          }
          else  if (resp[0].UserName == 'flipkartfoundation') {
            this.router.navigate(["/IDIDashboard"]);
          }
          else  if (resp[0].UserName == 'p&g') {
            this.router.navigate(["/IDIDashboard"]);
          }
          else  if (resp[0].UserName == 'ltipune') {
            this.router.navigate(["/IDIDashboard"]);
          }
          else if (resp[0].UserName == 'superadmin'){
            this.router.navigate(["/AdminDashboard"]);
          }
          else if (resp[0].UserName == 'pif'){
            this.router.navigate(["/PIFDashboard"]);
          }
          else if (resp[0].UserName == 'ibmskillbuild'){
            this.router.navigate(["/IDIDashboard"]);
          }
          // else if (resp[0].UserName == 'lti'){
          //   this.router.navigate(["/MyeschoolDashboard"])
          // }
          else if (resp[0].pgid == 'P9'){
            this.router.navigate(["/MyeschoolDashboard"])
          }
          else {
            this.router.navigate(["/Dashboard"]);
          }
      }
    });

  }

  // openDialog() {
  //   this.dialog.open(ForgotpasswordpopupComponent);
  // }

  // public togglePasswordVisibility(): void {
  //   this.showPassword = !this.showPassword;
  // }

}
