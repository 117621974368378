import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { MasterService } from 'app/services/master.service';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-ltidashboard',
  templateUrl: './ltidashboard.component.html',
  styleUrls: ['./ltidashboard.component.css']
})
export class LtidashboardComponent implements OnInit {

  UserId; pgid; centercount;  Schoolcentercount; StatusCount;
  totenrolment; schcompl; studstatusdrop; studstatusAct; acyear;
  fineyear; objective: string;
  para1: string;  para11: string; para2: string;  para21: string;
  para3: string;  para31: string; para4: string;  para41: string;
  para5: string; para6: string; para7: string; para8: string; paraa: string;
  boldpara1: string; boldpara2: string; boldpara3: string; boldpara4: string;
  boldpara5: string; boldpara6: string; boldpara7: string;boldpara: string;
  para: string;objective1 : string; programname;
  fy;CenterComputercount; col1; Academicyear; academicyear;
  countryForm: FormGroup;
  constructor(private service: MasterService, public fb: FormBuilder) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.academicyear = localStorage.getItem('academicyear');
    if(this.UserId=="PT3"){
      this.objective= `PIF – WNS Computer Program:`
      this.objective1= `Objectives:-`
      this.para1= `CAL-WNS Relationship ----- In 2016, Pratham InfoTech Foundation in partnership with WNS started CAL & DLLS program in 2 states i.e Maharashtra (@Mumbai, Navi Mumbai, Pune, Nashik) and Haryana(@ Gurgoan)`
      this.para2= `CAL Program is operational in Mumbai, NaviMumbai, Pune, Nashik and Gurgaon benefitting 60,000 students`
      this.boldpara1= `WNS Cares Foundation mission is to Educate, Empower and Enrich the underprivileged Child/ Youth.`
      this.boldpara2= `WNS (Holdings) Limited (NYSE: WNS) is a global Business Process Management (BPM) leader. They offer business value to 200+ clients around the world by combining operational excellence with deep domain expertise in key industry verticals, including banking and financial services, healthcare, insurance, manufacturing, media and entertainment, consulting and professional services, retail and consumer packaged goods, telecom, shipping and logistics, travel and leisure, and utilities.`
      this.boldpara3= `The program aims at reaching approx.60,000 school children.`
     
    }
    else if(this.UserId=="IDI"){
      this.objective= `IDI Program:Objectives of Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.`

    }
    else if(this.UserId=="PT1"){
      this.objective= `PIF – BPCL Computer Program: Objectives of Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara1= `BPCL is one of the leading Public Sector Enterprises of the Government of India under the Ministry of Petroleum & Natural Gas.`
      this.boldpara2= `A Fortune Global 500 company enjoying Navratna status, BPCL is one of the premier integrated refining & marketing companies in India. BPCL's vision is to be the most admired global energy company leveraging talent & technology.`
      this.boldpara3= `BPCL firmly believes in sustainable growth & has over the years committed itself to undertake projects that benefit the community in which it resides. The CSR thrust arears are Education & Water Conservation.`
      this.boldpara4= `CSR Vision - Be a model corporate entity with social responsibility committed to energizing lives through sustainable development.`
      

    }
    else if(this.UserId=="PT13"){
      this.objective= `PIF – BPCL Computer Program (CAL Program):`
      this.objective1= `Objectives of Computer Assisted Learning (CAL) Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the CAL program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara1= `BPCL is one of the leading Public Sector Enterprises of the Government of India under the Ministry of Petroleum & Natural Gas.`
      this.boldpara2= `A Fortune Global 500 company enjoying Navratna status, BPCL is one of the premier integrated refining & marketing companies in India. BPCL's vision is to be the most admired global energy company leveraging talent & technology.`
      this.boldpara3= `BPCL firmly believes in sustainable growth & has over the years committed itself to undertake projects that benefit the community in which it resides. The CSR thrust arears are Education & Water Conservation.`
      this.boldpara4= `CSR Vision - Be a model corporate entity with social responsibility committed to energizing lives through sustainable development.`
      

    }
    else if(this.UserId=="PT14"){
      this.objective= `Computer Program (CAL Program):`
      this.objective1= `Objectives of Computer Assisted Learning (CAL) Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the CAL program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `About SAP`
      this.boldpara1= `Our purpose is to help the world run better and improve people’s lives. We engineer solutions to fuel innovation, foster equality, and spread opportunity across borders and cultures. Together, with our customers and partners, we can transform industries, grow economies, lift up societies, and sustain our environment.`
      this.boldpara2= `SAP’s sustainability and corporate social responsibility (CSR) focus is an outgrowth of our vision to help the world run better and improve people's lives. We believe social, environmental, and economic activities and performance are interrelated – each creating tangible impacts on the others.Our efforts focus on creating a sustainable future for SAP, our customers, and society.`
     
    }
    else if(this.UserId=="PT15"){
      this.objective= `Computer Aided Learning (CAL) & Digital Literacy as Life Skill (DLLS) Program :-`
      this.objective1= `Objectives of the Program :-`
      this.para1= `Bridge the digital divide and facilitate children to experience and use IT`
      this.para2= `Ensure children with economically disadvantaged backgrounds access to most up-to-date information technologies.`
      this.para3= `Mobilize resources, provide advising and transfer know-how to assist school managements in implementing computer-aided learning effectively and efficiently.`
      this.para4= `Increase Community / Parental Participation in the program`
      this.para= `The objective of the CAL and DLLS program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. The program aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `DMart is a one-stop supermarket chain that aims to offer customers a wide range of basic home and personal products under one roof.`
      this.boldpara1= `Their core objective is to offer customers good products at great value.`
      this.boldpara2= `As a part of Corporate Social Responsibility, D-mart believes in contributing to the communities by improving the quality of education and provide children with better education and development opportunities.`
      this.boldpara3= `They believe in enriching the lives of future generation- the children of our country by creating better environments and infrastructure in public schools in selected wards of Mumbai city.`

    }
    else if(this.UserId=="PT16"){
      this.objective= `Computer Program (CAL Program):`
      this.objective1= `Objectives of Computer Assisted Learning (CAL) Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the CAL program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `About Partner`
      this.boldpara1= `Chambal Fertilisers and Chemicals Limited is one of the largest private sector fertilizer producers in India. It was promoted by Zuari Industries Limited in the year 1985. Its two hi-tech nitrogenous fertiliser (urea) plants are located at Gadepan in Kota district of Rajasthan.`
      this.boldpara2= `Vision To be the leader in the fertilizer industry by providing high quality fertilisers , create value for all our stakeholders and play a catalitic role in dilivering food security for India.`
      this.boldpara3= `Mission - Our growth and success will be achieved by:`
      this.para11= `Creating sustainable rural development in India`
      this.para21= `Enriching and protecting arable land`
      this.para31= `Boosting crop productivity by providing quality plant nutrients`
      this.para41= `Promoting sustainable agricultural practices`
     
    }
    else if(this.UserId=="PT22"){
      this.objective= `PEF – L&T Program:`
      this.objective1= `Objectives of Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `Larsen & Toubro Infotech (NSE: LTI) is a global technology consulting and digital solutions company, helping more than 250 clients succeed in a converging world. With operations in 27 countries, we go the extra mile for our clients and accelerate their digital transformation with LTI's Mosaic platform, enabling their mobile, social, analytics, IoT and cloud journeys.`
      this.boldpara1= `Founded 20 years ago as the information technology division of the Larsen & Toubro Limited, our unique heritage gives us unrivaled real-world expertise to solve the most complex challenges of enterprises across all industries.`
      this.boldpara2= `Each day, more than 20,000 LTItes enable our clients to improve the effectiveness of their business and technology operations, and deliver value to their customers, employees and shareholders.`

    }
    else if(this.UserId=="PT23"){
      this.objective= `Computer Aided Learning (CAL) & Digital Literacy as Life Skill (DLLS) Program :-`
      this.objective1= `Objectives of the Program :-`
      this.para1= `Bridge the digital divide and facilitate children to experience and use IT`
      this.para2= `Ensure children with economically disadvantaged backgrounds access to most up-to-date information technologies.`
      this.para3= `Promote use of innovative information technologies in learning and teaching`
      this.para4= `Mobilize resources, provide advising and transfer know-how to assist school managements in implementing computer-aided learning effectively and efficiently.`
      this.para5= `Increase Community / Parental Participation in the program`
      this.para6= `Train Community Youth in computer technology (after school hours)`

      this.para= `The objective of the CAL and DLLS program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other children. The program aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `CLP India is a wholly owned subsidiary of CLP Holdings Limited, which is listed on the Hong Stock Exchange and is one of the largest investor-owned power businesses in Asia.`
      this.boldpara1= `CLP India is the largest wind power developers in India with over 1000 MW of committed wind projects – approximately 920MW of this capacity is already commissioned and the rest is in various stages of development.`
      this.boldpara2= `As a part of Corporate Social Responsibility, CLP believes to be an active participant in the social and economic development of the communities in which it operates, while meeting the interests of all its stakeholders. CLP India’s focus areas include: Education and Training, Sustainable communities, health care and sanitation.`

    }
    else if(this.UserId=="PT30"){
      this.objective1= `PIF – DP WORLD Program:`
      this.para= `In 2017, Pratham InfoTech Foundation in partnership with DP World started Computer Aided Learning (CAL)& Digital Literacy as Life Skill (DLLS) program in 4 states i.e Maharashtra (Nava Sheva), Gujarat(Mundra), Tamil Nadu (Chennai) and Kerala (Cochin).
      `
      this.paraa= `The main objective of this program is to Bridge the digital divide in the government schools and enable the school children to experience adoption of technology through computers. The program aims at reaching approx. 8000 students (@ average 200 students per school) covering 40 schools in 4 locations (10 school in each location).
      `
      this.boldpara= `About Partner :-`
      this.boldpara1= `DP World is a leading enabler of global trade and an integral part of the supply chain.`
      this.boldpara2= `They has a portfolio of 78 operating marine and inland terminals supported by over 50 related businesses in 40 countries across six continents with a significant presence in both high-growth and mature markets. They aim to be essential to the bright future of global trade, ensuring everything we do has a long-lasting positive impact on economies and society. Their vision is to lead the future of World Trade.`

    }
    else if(this.UserId=="PT33"){
      this.objective= `PIF – BPCL-MCGM Computer Program:`
      this.objective1= `Objectives of Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `BPCL-MCGM is one of the leading Public Sector Enterprises of the Government of India under the Ministry of Petroleum & Natural Gas.`
      this.boldpara1= `A Fortune Global 500 company enjoying Navratna status, BPCL is one of the premier integrated refining & marketing companies in India. BPCL's vision is to be the most admired global energy company leveraging talent & technology.`
      this.boldpara2= `BPCL-MCGM firmly believes in sustainable growth & has over the years committed itself to undertake projects that benefit the community in which it resides. The CSR thrust arears are Education & Water Conservation.`
      this.boldpara3= `CSR Vision - Be a model corporate entity with social responsibility committed to energizing lives through sustainable development.`
    }
    else if(this.UserId=="PT34"){
      this.objective= `Computer Program (CAL Program):`
      this.objective1= `Objectives of Computer Assisted Learning (CAL) Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the CAL program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `About SAP`
      this.boldpara1= `Our purpose is to help the world run better and improve people’s lives. We engineer solutions to fuel innovation, foster equality, and spread opportunity across borders and cultures. Together, with our customers and partners, we can transform industries, grow economies, lift up societies, and sustain our environment.`
      this.boldpara2= `SAP’s sustainability and corporate social responsibility (CSR) focus is an outgrowth of our vision to help the world run better and improve people's lives. We believe social, environmental, and economic activities and performance are interrelated – each creating tangible impacts on the others.Our efforts focus on creating a sustainable future for SAP, our customers, and society.`
    }
    else if(this.UserId=="PT45"){
      this.objective= `PEF – L&T Program:`
      this.objective1= `Objectives of Program :-`
      this.para1= `Reach out to economically underprivileged communities`
      this.para2= `Use technology to assist in the learning process of young children`
      this.para3= `Involve the community in the program`
      this.para4= `Enhance the professional skills of teachers in schools`
      this.para5= `Increase the reading skills of children`
      this.para= `The objective of the program is to reach out to all the children from disadvantaged backgrounds, not having opportunities for exposure of IT like other chidren. IT training in professional institutes is unaffordable for them. Pratham aims to spread the vast knowledge of computers among these children according to their age.
      `
      this.boldpara= `Larsen & Toubro Infotech (NSE: LTI) is a global technology consulting and digital solutions company, helping more than 250 clients succeed in a converging world. With operations in 27 countries, we go the extra mile for our clients and accelerate their digital transformation with LTI's Mosaic platform, enabling their mobile, social, analytics, IoT and cloud journeys.`
      this.boldpara1= `Founded 20 years ago as the information technology division of the Larsen & Toubro Limited, our unique heritage gives us unrivaled real-world expertise to solve the most complex challenges of enterprises across all industries.`
      this.boldpara2= `Each day, more than 20,000 LTItes enable our clients to improve the effectiveness of their business and technology operations, and deliver value to their customers, employees and shareholders.`
    }

    this.service.FillDropDown("AcademicyearPartner", " academicyear", "academicyear", "where partnerid='" + this.UserId + "' order by academicyear desc").subscribe(
      (data: any) => {
        this.acyear = data;
      });
    this.service.AcademicyearPartner(this.UserId).subscribe((data: any) => {

      this.Academicyear = data;
    
      var a = data[0];
      this.academicyear = a.academicyear;
      localStorage.setItem('academicyear', this.academicyear);
      if (this.academicyear == "2022-2023") {

        // this.service.PartnerCenterList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "'").subscribe((data: any) => {

        //   this.centercount = data.length;

        // });


        // this.service.PartnerSchoolList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

        //   this.Schoolcentercount = data.length;
        // });
        this.service.GetPartnerDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='"+this.UserId+"' and financeyear='" + this.academicyear + "' ").subscribe((data: any) => {
      
          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.totenrolment;
        });

      }

      else  if (this.academicyear == "2020-2021" || this.academicyear == "2021-2022") {

        // this.service.PartnerCenterList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "'").subscribe((data: any) => {

        //   this.centercount = data.length;

        // });


        // this.service.PartnerSchoolList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

        //   this.Schoolcentercount = data.length;
        // });
        this.service.GetPartnerDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='"+this.UserId+"' and financeyear='" + this.academicyear + "' ").subscribe((data: any) => {
      
          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.totenrolment;
        });

      }
  //     else if (this.academicyear=="2017-2018" || this.academicyear=="2018-2019" || this.academicyear=="2019-2020") {

  //       this.service.OLDPartnerCenterList(this.pgid, "where partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

  //         this.centercount = data.length;

  //       });

  //       this.service.OLDPartnerSchoolList(this.pgid, "where partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

  //         this.Schoolcentercount = data.length;
  //       });

  //       this.service.SahyogiCALData(this.UserId ,this.academicyear,this.pgid).subscribe((data: any) => {
  //         var a = data[0];
   
  //   this.totenrolment = a.student;

  // });
  // this.service.OLDPartnerCenterList(this.pgid,this.academicyear).subscribe((data: any) => {
  
  //   this.CenterComputercount = data.length;
   
  
  // });

  //     }

    
    });
    
    this.service.FillDropDown("partnerprogram", "distinct partnerprogram.pgid", "Program.pgname", "inner join program on partnerprogram.pgid=program.pgid where partnerprogram.userid='" + this.UserId + "' ").subscribe(
      (data: any) => {
        this.programname = data;
      });

    this.service.GetPartnerDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='" + this.UserId + "'").subscribe((data: any) => {

      this.StatusCount = data.length;
      var a = data[0];
      this.studstatusAct = a.studstatusAct;
      this.studstatusdrop = a.studstatusdrop;
      this.schcompl = a.schcompl;
      this.totenrolment = a.totenrolment;
    });
   


  }
  startAnimationForLineChart(chart) {
    let seq: any, delays: any, durations: any;
    seq = 0;
    delays = 80;
    durations = 500;

    chart.on('draw', function (data) {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === 'point') {
        seq++;
        data.element.animate({
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq = 0;
  };
  startAnimationForBarChart(chart) {
    let seq2: any, delays2: any, durations2: any;

    seq2 = 0;
    delays2 = 80;
    durations2 = 500;
    chart.on('draw', function (data) {
      if (data.type === 'bar') {
        seq2++;
        data.element.animate({
          opacity: {
            begin: seq2 * delays2,
            dur: durations2,
            from: 0,
            to: 1,
            easing: 'ease'
          }
        });
      }
    });

    seq2 = 0;
  };
  ngOnInit() {

    this.display();
    /* ----------==========     Daily Sales Chart initialization For Documentation    ==========---------- */

    const dataDailySalesChart: any = {
      labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      series: [
        [12, 17, 7, 17, 23, 18, 38]
      ]
    };

    const optionsDailySalesChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      low: 0,
      high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: { top: 0, right: 0, bottom: 0, left: 0 },
    }

    var dailySalesChart = new Chartist.Line('#dailySalesChart', dataDailySalesChart, optionsDailySalesChart);

    this.startAnimationForLineChart(dailySalesChart);


    /* ----------==========     Completed Tasks Chart initialization    ==========---------- */

    const dataCompletedTasksChart: any = {
      labels: ['12p', '3p', '6p', '9p', '12p', '3a', '6a', '9a'],
      series: [
        [230, 750, 450, 300, 280, 240, 200, 190]
      ]
    };

    const optionsCompletedTasksChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0
      }),
      low: 0,
      high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: { top: 0, right: 0, bottom: 0, left: 0 }
    }

    var completedTasksChart = new Chartist.Line('#completedTasksChart', dataCompletedTasksChart, optionsCompletedTasksChart);

    // start animation for the Completed Tasks Chart - Line Chart
    this.startAnimationForLineChart(completedTasksChart);



    /* ----------==========     Emails Subscription Chart initialization    ==========---------- */

    var datawebsiteViewsChart = {
      labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
      series: [
        [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

      ]
    };
    var optionswebsiteViewsChart = {
      axisX: {
        showGrid: false
      },
      low: 0,
      high: 1000,
      chartPadding: { top: 0, right: 5, bottom: 0, left: 0 }
    };
    var responsiveOptions: any[] = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];
    var websiteViewsChart = new Chartist.Bar('#websiteViewsChart', datawebsiteViewsChart, optionswebsiteViewsChart, responsiveOptions);

    //start animation for the Emails Subscription Chart
    this.startAnimationForBarChart(websiteViewsChart);
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o2 && o1 ? o2.label === o1.label : o1 === o1;
  }
  onSelectfineyear(academicyear) {
    this.academicyear = academicyear;
    localStorage.setItem('academicyear', this.academicyear);

    if (this.academicyear == "2022-2023") {
      // this.service.PartnerCenterList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

      //   this.centercount = data.length;

      // });

      // this.service.PartnerSchoolList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

      //   this.Schoolcentercount = data.length;
      // });


    }

    else if (this.academicyear == "2020-2021" || this.academicyear == "2021-2022") {
      // this.service.PartnerCenterList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

      //   this.centercount = data.length;

      // });

      // this.service.PartnerSchoolList(this.pgid, "where centerpartner.partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

      //   this.Schoolcentercount = data.length;
      // });


    }
//     else if (this.academicyear=="2017-2018" || this.academicyear=="2018-2019" || this.academicyear=="2019-2020") {
//       this.service.OLDPartnerCenterList(this.pgid, "where partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

//         this.centercount = data.length;

//       });


//       this.service.OLDPartnerSchoolList(this.pgid, "where partnerid='" + this.UserId + "' and academicyear='" + this.academicyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {

//         this.Schoolcentercount = data.length;
//       });
//       this.service.SahyogiCALData(this.UserId ,this.academicyear,this.pgid).subscribe((data: any) => {
//         var a = data[0];
 
//   this.totenrolment = a.student;

// });

// this.service.OLDPartnerCenterComputer(this.UserId,this.academicyear,this.pgid).subscribe((data: any) => {
  
//   this.CenterComputercount = data.length;
 

// });
//     }
    
  }
  display() {
    var rowdata = document.getElementById("div1");
  
    if (this.UserId == "PT1" || this.UserId == "PT13" || this.UserId == "PT14" || this.UserId == "PT15" || this.UserId == "PT16" || this.UserId == "PT22" || this.UserId == "PT23" || this.UserId == "PT3" || this.UserId == "PT30" || this.UserId == "PT33" || this.UserId == "PT34" || this.UserId == "PT45" || this.UserId == "PT3" || this.UserId == "PT3" ) {

      rowdata.style.display = "block";
    }
    else {
      rowdata.style.display = "none";
      
    
      
    }


  }
}
