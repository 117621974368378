import { Component, Input, OnChanges, } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-baseline2022std7',
  templateUrl: './baseline2022std7.component.html',
  styleUrls: ['./baseline2022std7.component.css']
})
export class Baseline2022std7Component implements OnChanges {
  @Input('Examtype') Examtype;
  @Input('Standard') Standard;

  Partnerid;
  pgid;
  selected;
  loading = true;

  BaselineStandard: any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  c5 = new Array();
  c6 = new Array();
  c7 = new Array();
  c8 = new Array();
  c9 = new Array();
  c10 = new Array();
  c11 = new Array();
  c12 = new Array();
  c13 = new Array();
  c14 = new Array();
  c15 = new Array();
  c16 = new Array();
  compentencies;
  Baselinestd7;
  Baselinestd7count;
  totapp;
  constructor(private service: MasterService, private router: Router) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }

  ngOnChanges() {
    this.service.SahyogiEvalCompetency(this.Partnerid, this.selected, this.pgid, this.Examtype, this.Standard).subscribe((data: any) => {
      this.totapp = data[0].totalappeared;
      data.map((obj) => {
        obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
        obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
        obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
        obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
        obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
        obj.C6 = ((data[0].c6 / this.totapp) * 100).toFixed(2);
        obj.C7 = ((data[0].c7 / this.totapp) * 100).toFixed(2);
        obj.C8 = ((data[0].c8 / this.totapp) * 100).toFixed(2);
        obj.C9 = ((data[0].c9 / this.totapp) * 100).toFixed(2);
        obj.C10 = ((data[0].c10 / this.totapp) * 100).toFixed(2);
        obj.C11 = ((data[0].c11 / this.totapp) * 100).toFixed(2);
        obj.C12 = ((data[0].c12 / this.totapp) * 100).toFixed(2);
        obj.C13 = ((data[0].c13 / this.totapp) * 100).toFixed(2);
        obj.C14 = ((data[0].c14 / this.totapp) * 100).toFixed(2);
        obj.C15 = ((data[0].c15 / this.totapp) * 100).toFixed(2);
        obj.C16 = ((data[0].c16 / this.totapp) * 100).toFixed(2);
      })
      this.Baselinestd7 = data;
      this.Baselinestd7count = data.length;
      this.compentencies = new Array();
      data.forEach(y => {
        this.compentencies.push(y.C1);
        this.compentencies.push(y.C2);
        this.compentencies.push(y.C3);
        this.compentencies.push(y.C4);
        this.compentencies.push(y.C5);
        this.compentencies.push(y.C6);
        this.compentencies.push(y.C7);
        this.compentencies.push(y.C8);
        this.compentencies.push(y.C9);
        this.compentencies.push(y.C10);
        this.compentencies.push(y.C11);
        this.compentencies.push(y.C12);
        this.compentencies.push(y.C13);
        this.compentencies.push(y.C14);
        this.compentencies.push(y.C15);
        this.compentencies.push(y.C16);

      })

      var graph = document.getElementById("graph");
      if (data.length != 0) {
        graph.style.display = "block";
        this.BaselineStandard = new Chart('Standard7', {
          type: 'bar',
          data: {
            labels: ['Overview of Computer', 'Document Construction', 'Slide Developing', 'Using Spreadsheet', 'Cyber Securities', 'Overview of Computer', 'Using Degital Devices', 'Document Construction', 'Slide Developing', 'Data Entry and Processing', 'Cyber Securities', 'Troubleshooting Application Software', 'Document Construction', 'Slide Developing', 'Data Entry and Processing', 'Email'],
            datasets: [
              {
                label: "Compentencies",
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                data: this.compentencies
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            title: {
              display: true,
              text: this.Examtype + 'Standard 7'
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }
        })
      }
      else {
        graph.style.display = "none";

      }
      this.loading = false;
    })
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard + "th Standard Baseline Summary.png";
  }


}

