
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';
import 'chart.piecelabel.js';

@Component({
  selector: 'app-enrollmentgraph',
  templateUrl: './enrollmentgraph.component.html',
  styleUrls: ['./enrollmentgraph.component.css']
})
export class EnrollmentgraphComponent implements OnInit {
  
  UserId; 
  centid; 
  selected; 
  pgid; 
  LangDataGraph; 
  dataobj;
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  state;
  loading = true;
  labels = [];
  
  chartOptions = {
    plugins: {
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        anchor: "center",
        align: "center",
        font: {
          size: 9,
        }
      }
    }
  }

  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  chartData = [
    {
      label: [],
      data: [],
    }
  ];

  // CHART COLOR.
  colors = [
    {
      backgroundColor: '#C39BD3'
    },
    {
      backgroundColor: 'rgba(21, 230, 69, 0.9)'
    }
    ,
    {
      backgroundColor: 'rgba(204, 201, 43,1.0)'
    }
    ,
    {
      backgroundColor: 'rgba(245, 157, 42,1.0)'
    }

  ]

  private value;
  totalcount = 0; 
  othercount = 0; 
  Oriyacount = 0; 
  Bengalicount = 0; 
  Urducount = 0; 
  Telgucount = 0; 
  Marathicount = 0; 
  Kananndcount = 0; 
  Hindicount = 0; 
  Englishcount = 0; 
  Gujaraticount = 0; 
  Malayalamcount=0;
  selectfiyear;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    //this.selected = localStorage.getItem('academicyear');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    if (this.selectfiyear == "2024-2025") {

      if(this.pgid == 'P22'){

        this.service.GetPartnerLanguagewiseEnrolmentEnglish("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: any) => {
          this.chartData = [];
          this.dataobj = res.data;
          this.labels = res.state;
          this.loading = false;
          for (let i = 0; i < this.dataobj.length; i++) {
            this.chartData.push({ label: this.dataobj[i].LanguageName, data: this.dataobj[i].LanguageCount });
          }
  
        });
  
        this.service.GetPartnerLanguagewiseEnrolmentEnglishtable("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((data: any) => {
          this.LangDataGraph = data;
  
          this.value = this.LangDataGraph
  
          for (let j = 0; j < this.LangDataGraph.length; j++) {
            this.Malayalamcount += this.value[j].Malayalam;
            this.Englishcount += this.value[j].English;
            this.Gujaraticount += this.value[j].Gujarati;
            this.Hindicount += this.value[j].Hindi;
            this.Kananndcount += this.value[j].Kanannd;
            this.Marathicount += this.value[j].Marathi;
            this.Telgucount += this.value[j].Telgu;
            this.Urducount += this.value[j].Urdu;
            this.Bengalicount += this.value[j].Bengali;
            this.Oriyacount += this.value[j].Oriya;
            this.othercount += this.value[j].other;
            this.totalcount += this.value[j].totallang;
          }
          this.loading = false;
  
        });

      }
      else{
        // language enrollment  LangDataGraph
      this.service.GetPartnerLanguagewiseEnrolment("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: any) => {
        this.chartData = [];
        this.dataobj = res.data;
        this.labels = res.state;
        this.loading = false;
        for (let i = 0; i < this.dataobj.length; i++) {
          this.chartData.push({ label: this.dataobj[i].LanguageName, data: this.dataobj[i].LanguageCount });
        }

      });

      this.service.GetPartnerLanguagewiseEnrolmenttable("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((data: any) => {
        this.LangDataGraph = data;

        this.value = this.LangDataGraph

        for (let j = 0; j < this.LangDataGraph.length; j++) {
          this.Malayalamcount += this.value[j].Malayalam;
          this.Englishcount += this.value[j].English;
          this.Gujaraticount += this.value[j].Gujarati;
          this.Hindicount += this.value[j].Hindi;
          this.Kananndcount += this.value[j].Kanannd;
          this.Marathicount += this.value[j].Marathi;
          this.Telgucount += this.value[j].Telgu;
          this.Urducount += this.value[j].Urdu;
          this.Bengalicount += this.value[j].Bengali;
          this.Oriyacount += this.value[j].Oriya;
          this.othercount += this.value[j].other;
          this.totalcount += this.value[j].totallang;
        }
        this.loading = false;

      });

      }
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022"|| this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      // language enrollment
      this.service.SahyogiCALLangGraph(this.UserId, this.selectfiyear, this.pgid).subscribe((res: any) => {
        this.chartData = [];
        this.dataobj = res.data;
        this.labels = res.state;

        for (let i = 0; i < this.dataobj.length; i++) {
          this.chartData.push({ label: this.dataobj[i].LanguageName, data: this.dataobj[i].LanguageCount });
        }
        this.loading = false;
      });

      this.service.SahyogiCALLangtable(this.UserId, this.selectfiyear, this.pgid).subscribe((res: any) => {
        this.LangDataGraph = res;
        this.value = this.LangDataGraph
        for (let j = 0; j < this.LangDataGraph.length; j++) {
          this.Englishcount += this.value[j].English;
          this.Gujaraticount += this.value[j].Gujarati;
          this.Hindicount += this.value[j].Hindi;
          this.Kananndcount += this.value[j].Kanannd;
          this.Marathicount += this.value[j].Marathi;
          this.Telgucount += this.value[j].Telgu;
          this.Urducount += this.value[j].Urdu;
          this.Bengalicount += this.value[j].Bengali;
          this.Oriyacount += this.value[j].Oriya;
          this.othercount += this.value[j].other;
          this.totalcount += this.value[j].totallang;
        }
        this.loading = false;

      })

    }
    else if (this.selectfiyear == null) {
      this.service.GetPartnerLanguagewiseEnrolment("where CenterPartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: any) => {
        this.chartData = [];
        this.dataobj = res.data;
        this.labels = res.state;
        for (let i = 0; i < this.dataobj.length; i++) {
          this.chartData.push({ label: this.dataobj[i].LanguageName, data: this.dataobj[i].LanguageCount });
        }
        this.loading = false;
      });

      this.service.GetPartnerLanguagewiseEnrolmenttable("where CenterPartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((data: any) => {
        this.LangDataGraph = data;
        this.value = this.LangDataGraph

        for (let j = 0; j < this.LangDataGraph.length; j++) {
          this.Englishcount += this.value[j].English;
          this.Gujaraticount += this.value[j].Gujarati;
          this.Hindicount += this.value[j].Hindi;
          this.Kananndcount += this.value[j].Kanannd;
          this.Marathicount += this.value[j].Marathi;
          this.Telgucount += this.value[j].Telgu;
          this.Urducount += this.value[j].Urdu;
          this.Bengalicount += this.value[j].Bengali;
          this.Oriyacount += this.value[j].Oriya;
          this.othercount += this.value[j].other;
          this.totalcount += this.value[j].totallang;


        }
        this.loading = false;

      });

    }

  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[4].toDataURL();
    anchor.download = "EnrollmentGraph.png";
  }

}