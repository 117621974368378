import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Stateenroll } from 'app/ClassFiles/stateenroll';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-pifstategraph',
  templateUrl: './pifstategraph.component.html',
  styleUrls: ['./pifstategraph.component.css']
})
export class PifstategraphComponent implements OnInit {
  UserId;
  centid;
  selected;
  pgid;
  public chart: Chart;
  statecount;
  Boyscount;
  Girlscount;
  private value;
  StateEnroll;
  centercount = 0;
  schoolcount = 0;
  studentcount = 0;
  sancharakcount = 0;
  computerscount = 0;
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  public barChartPlugins1 = [];

  barChartPlugins = [pluginDataLabels];
  dept = [];
  centercountt = 0;
  schoolcountt = 0;
  studentcountt = 0;
  sancharakcountt = 0;
  computerscountt = 0;
  selectfiyear;
  loading = true;
  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    // this.selected = localStorage.getItem('academicyear');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');


    if (this.selectfiyear == "2022-2023") {
      this.service.GetPartnerStatewiseEnrollmentSummaryPIF("").subscribe((res: Stateenroll[]) => {

        this.StateEnroll = res;
        this.value = this.StateEnroll
        this.loading = false;

        for (let j = 0; j < this.StateEnroll.length; j++) {
          this.centercount += this.value[j].center;
          
          this.studentcount += this.value[j].student;
          this.schoolcount += this.value[j].school;

          this.sancharakcount += this.value[j].sancharak;
          this.computerscount += this.value[j].computers;
        }

        this.statecount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.statecount.push(y.state);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));


        });
        if (this.chart) { this.chart.destroy(); }
        this.chart = new Chart('canvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.statecount,

            datasets: [
              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              }


            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }

          }

        });

      });

    }

    else if (this.selectfiyear == "2021-2022") {
      this.service.SahyogiStateWiseDataOLDPIF(this.selectfiyear).subscribe((res: Stateenroll[]) => {

        this.StateEnroll = res;
        this.value = this.StateEnroll
        this.loading = false;

        for (let j = 0; j < this.StateEnroll.length; j++) {
          this.centercount += this.value[j].center;
          
          this.studentcount += this.value[j].student;
          this.schoolcount += this.value[j].school;

          this.sancharakcount += this.value[j].sancharak;
          this.computerscount += this.value[j].computers;
        }

        this.statecount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.statecount.push(y.state);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));


        });
        if (this.chart) { this.chart.destroy(); }
        this.chart = new Chart('canvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.statecount,

            datasets: [
              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              }


            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }


          }

        });

      });

    }

    else if (this.selectfiyear == null) {
      // Statewise Enrolment Summary
      this.service.GetPartnerStatewiseEnrollmentSummaryPIF("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Stateenroll[]) => {
        this.StateEnroll = res;
        this.value = this.StateEnroll
        this.loading = false;

        for (let j = 0; j < this.StateEnroll.length; j++) {
          this.centercount += this.value[j].center;
          this.studentcount += this.value[j].student;
          this.schoolcount += this.value[j].school;

          this.sancharakcount += this.value[j].sancharak;
          this.computerscount += this.value[j].computers;
        }

        this.statecount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.statecount.push(y.state);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));


        });
        if (this.chart) { this.chart.destroy(); }
        this.chart = new Chart('canvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.statecount,

            datasets: [
              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              }


            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }

        });

      });
    }
  }
  ngOnInit(): void {

    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }
  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "StateGraph.png";
  }
}