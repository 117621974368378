import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import 'chart.piecelabel.js';

@Component({
  selector: 'app-laguagewiseschoolgraph',
  templateUrl: './laguagewiseschoolgraph.component.html',
  styleUrls: ['./laguagewiseschoolgraph.component.css']
})
export class LaguagewiseschoolgraphComponent implements OnInit {

  UserId; centid; selected; pgid; dataobj1; SchoolLangDataGraph; private value;
  labels1 = [];
  chartOptions1 = {
    plugins: {

      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        anchor: "end",

        font: {
          size: 10,
        }
      }
    }
  }
  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  chartData1 = [
    {
      label: [],
      data: [],

    }
  ];

  // CHART COLOR.
  colors1 = [
    {
      backgroundColor: '#F9F75A'
    },
    {
      backgroundColor: 'rgba(21, 230, 69, 0.9)'
    }
    ,
    {
      backgroundColor: 'rgba(204, 201, 43,1.0)'
    }
    ,
    {
      backgroundColor: 'rgba(245, 157, 42,1.0)'
    }

  ]
  totalcountt = 0; othercountt = 0; Oriyacountt = 0; Bengalicountt = 0; Urducountt = 0; Telgucountt = 0;
  Marathicountt = 0; Kananndcountt = 0; Hindicountt = 0; Englishcountt = 0; Gujaraticountt = 0; Malayalamcount = 0;
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  loading = true;

  selectfiyear;
  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');


    if (this.selectfiyear == "2022-2023") {
      // schoolLanguage   SchoolLangDataGraph

      this.service.GetPartnerSchoolLanguageEnrolmentPIF("where center.pgid in('P18','P1','P9')")
        .subscribe((res: any) => {
          this.chartData1 = [];
          this.dataobj1 = res.data;
          this.labels1 = res.state;

          for (let i = 0; i < this.dataobj1.length; i++) {
            this.chartData1.push({ label: this.dataobj1[i].LanguageName, data: this.dataobj1[i].LanguageCount });
          }
          this.loading = false;
        });
      ``
      this.service.GetPartnerSchoolLanguageEnrolmenttablePIF("where center.pgid in('P18','P1','P9')").subscribe((data: any) => {
        this.SchoolLangDataGraph = data;
        this.value = this.SchoolLangDataGraph

        for (let j = 0; j < this.SchoolLangDataGraph.length; j++) {
          this.Malayalamcount += this.value[j].Malayalam;
          this.Englishcountt += this.value[j].English;
          this.Gujaraticountt += this.value[j].Gujarati;
          this.Hindicountt += this.value[j].Hindi;
          this.Kananndcountt += this.value[j].Kanannd;
          this.Marathicountt += this.value[j].Marathi;
          this.Telgucountt += this.value[j].Telgu;
          this.Urducountt += this.value[j].Urdu;
          this.Bengalicountt += this.value[j].Bengali;
          this.Oriyacountt += this.value[j].Oriya;
          this.othercountt += this.value[j].other;
          this.totalcountt += this.value[j].total;


        }
        this.loading = false;
      });

    }

    else if (this.selectfiyear == "2021-2022") {
      // schoolLanguage   SchoolLangDataGraph

      this.service.OLDPartnerSchoolLangDataGraphPIF(this.selectfiyear).subscribe((res: any) => {

        this.chartData1 = [];
        this.dataobj1 = res.data;
        this.labels1 = res.state;

        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].LanguageName, data: this.dataobj1[i].LanguageCount });
        }
        this.loading = false;
      });
      
      this.service.OLDPartnerSchoolLangDatatablePIF(this.selectfiyear).subscribe((data: any) => {

        this.SchoolLangDataGraph = data;
        this.value = this.SchoolLangDataGraph

        for (let j = 0; j < this.SchoolLangDataGraph.length; j++) {
          this.Malayalamcount += this.value[j].Malayalam;
          this.Englishcountt += this.value[j].English;
          this.Gujaraticountt += this.value[j].Gujarati;
          this.Hindicountt += this.value[j].Hindi;
          this.Kananndcountt += this.value[j].Kanannd;
          this.Marathicountt += this.value[j].Marathi;
          this.Telgucountt += this.value[j].Telgu;
          this.Urducountt += this.value[j].Urdu;
          this.Bengalicountt += this.value[j].Bengali;
          this.Oriyacountt += this.value[j].Oriya;
          this.othercountt += this.value[j].other;
          this.totalcountt += this.value[j].total;


        }
        this.loading = false;
      });

    }


    else if (this.selectfiyear == null) {
      // schoolLanguage   SchoolLangDataGraph

      this.service.GetPartnerSchoolLanguageEnrolmentPIF("where center.pgid in('P18','P1','P9')")
        .subscribe((res: any) => {

          this.chartData1 = [];
          this.dataobj1 = res.data;
          this.labels1 = res.state;
          this.loading = false;

          for (let i = 0; i < this.dataobj1.length; i++) {
            this.chartData1.push({ label: this.dataobj1[i].LanguageName, data: this.dataobj1[i].LanguageCount });
          }
        });

      this.service.GetPartnerSchoolLanguageEnrolmenttablePIF("where center.pgid in('P18','P1','P9')").subscribe((data: any) => {
        this.SchoolLangDataGraph = data;
        this.value = this.SchoolLangDataGraph

        for (let j = 0; j < this.SchoolLangDataGraph.length; j++) {
          this.Englishcountt += this.value[j].English;
          this.Gujaraticountt += this.value[j].Gujarati;

          this.Hindicountt += this.value[j].Hindi;
          this.Kananndcountt += this.value[j].Kanannd;
          this.Marathicountt += this.value[j].Marathi;
          this.Telgucountt += this.value[j].Telgu;
          this.Urducountt += this.value[j].Urdu;
          this.Bengalicountt += this.value[j].Bengali;
          this.Oriyacountt += this.value[j].Oriya;
          this.othercountt += this.value[j].other;
          this.totalcountt += this.value[j].total;


        }
        this.loading = false;
        console.log("enrollmentdtaa" + JSON.stringify(this.SchoolLangDataGraph));
      });



    }
  }
  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';


  }
  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[5].toDataURL();
    anchor.download = "SchoolGraph.png";
  }
}