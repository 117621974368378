import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { ExcelService } from 'app/services/excel.service';

@Component({
  selector: 'app-partner-school-list',
  templateUrl: './partner-school-list.component.html',
  styleUrls: ['./partner-school-list.component.css']
})
export class PartnerSchoolListComponent implements OnInit {
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  UserId;
  pgid; showMainContent;
  PartnerSchoolList;
  centercount;
  state;
  city;
  loaded;
  loading = true;
  array = [];
  StrWhere = "";
  statelist = new FormControl();
  citylist = new FormControl();
  searchstate; selectfiyear
  searchcity; academicyear; pgname;
  searchstatus; PartnerSchoolListcount;
  p: number = 1;
  where = "";

  constructor(private exService: ExcelService, private service: MasterService) {
    
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');
    
    if (this.selectfiyear == "2024-2025" || this.selectfiyear == "2023-2024") {
      this.service.PartnerSchoolList(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.PartnerSchoolList = data;
      });
      this.service.PartnerSchoolList(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.PartnerSchoolListcount = data.length;
        this.loading = false;

      });
      if (this.pgid == 'P9') {
        this.service.FillDropDown("Center", "distinct state", "state", "where pgid = 'P9' union select distinct state,state from CenterLite where pgid = 'P9'").subscribe(
          (data: any) => {
            this.state = data;
          });
        this.service.FillDropDown("Center", "distinct city", "city", "where pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
      else {
        this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "' and Centerpartner.status=0").subscribe(
          (data: any) => {
            this.state = data;
          });

        this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "' and Centerpartner.status=0").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023") {
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {

        this.PartnerSchoolList = data;
      });
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {

        this.PartnerSchoolListcount = data.length;
        this.loading = false;
      });
      if (this.pgid == 'P9') {
        this.service.FillDropDown("Center", "distinct state", "state", "where pgid = 'P9' union select distinct state,state from CenterLite where pgid = 'P9'").subscribe(
          (data: any) => {
            this.state = data;
          });
        this.service.FillDropDown("Center", "distinct city", "city", "where pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
      else {
        this.service.FillDropDown("OLDPartnerSchoolList", "distinct state", "state", " where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "' and academicyear = '"+this.selectfiyear+"'").subscribe(
          (data: any) => {
            this.state = data;
          });

        this.service.FillDropDown("OLDPartnerSchoolList", "distinct city", "city", "where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "' and academicyear = '"+this.selectfiyear+"'").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
    }
    else if (this.selectfiyear == null) {
      this.selectfiyear = "2024-2025";
      this.service.PartnerSchoolList(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.PartnerSchoolList = data;
      });
      this.service.PartnerSchoolList(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.PartnerSchoolListcount = data.length;
        this.loading = false;

      });
      if (this.pgid == 'P9') {
        this.service.FillDropDown("Center", "distinct state", "state", "where pgid = 'P9' union select distinct state,state from CenterLite where pgid = 'P9'").subscribe(
          (data: any) => {
            this.state = data;
          });
        this.service.FillDropDown("Center", "distinct city", "city", "where pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
      else {
        this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "' and Centerpartner.status=0  ").subscribe(
          (data: any) => {
            this.state = data;
          });

        this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "' and Centerpartner.status=0  ").subscribe(
          (data: any) => {
            this.city = data;
          });
      }
    }

  }

  ngOnInit(): void {

    if (this.selectfiyear == "2024-2025" || this.selectfiyear == null) {
      this.showMainContent = true;
    }
  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";

    if (this.selectfiyear == null) {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ") and partnerid='" + this.UserId + "'");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ") and partnerid='" + this.UserId + "'");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ") and partnerid='" + this.UserId + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;


      this.service.PartnerSchoolList(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.PartnerSchoolList = data;
        this.PartnerSchoolListcount = data.length;
        this.loading = false;

      });

    }
    else if (this.selectfiyear == "2024-2025") {

      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ") and partnerid='" + this.UserId + "'");

      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ") and partnerid='" + this.UserId + "'");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ") and partnerid='" + this.UserId + "'");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      this.service.PartnerSchoolList(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.PartnerSchoolList = data;
        this.PartnerSchoolListcount = data.length;
        this.loading = false;
      });

    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {

      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ") and partnerid='" + this.UserId + "'");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ") and partnerid='" + this.UserId + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " and " + this.array[0].toString();
      }
      else {
        this.StrWhere = " and " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;
      
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, this.where).subscribe((data: any) => {
        this.PartnerSchoolList = data;
        this.PartnerSchoolListcount = data.length;
        this.loading = false;
      });

    }
  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }


  ExportReport(searchstate, searchcity, searchstatus) {
    this.loading = true;
    if (this.selectfiyear == "2024-2025") {
      this.service.PartnerSchoolList(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'School Name': obj.schoolname == null ? '' : obj.schoolname,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Address': obj.address == null ? '' : obj.address,
            'Principal Name': obj.prname == null ? '' : obj.prname,
            'Contact': obj.contactno == null ? '' : obj.contactno,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Partner School List');
      });
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'School Name': obj.schoolname == null ? '' : obj.schoolname,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Address': obj.address == null ? '' : obj.address,
            'Principal Name': obj.prname == null ? '' : obj.prname,
            'Contact': obj.contactno == null ? '' : obj.contactno,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Partner School List');
      });
    }

  }


  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  onSelectState(stname) {
    this.searchcity ='';
    
    if (this.pgid == 'P9') {
      // this.service.FillDropDown("Center", "distinct city", "city", "where state = '" + stname + "' and pgid = 'P9' union select distinct city,city from CenterLite where pgid='P9'").subscribe(
      //   (data: any) => {
      //     this.city = data;
      //   });
      this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where centerpartner.partnerid='" + this.UserId + "' and center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ") union select distinct city,city from CenterLite inner join CenterPartnerlite on CenterLite.centid=CenterPartnerlite.centid where pgid='P9' and centerpartnerlite.partnerid='" + this.UserId + "' and state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
        (data: any) => {
          this.city = data;
        });
    }
    else {
      
      this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where centerpartner.partnerid='" + this.UserId + "' and center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
        (data: any) => {
          this.city = data;
        });
    }
  }



}
