import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-eventlist',
  templateUrl: './eventlist.component.html',
  styleUrls: ['./eventlist.component.css']
})
export class EventlistComponent implements OnInit {
  searchprogram;
  searchpartner;
  programlist = new FormControl();
  partnerlist = new FormControl();
  EventList;
  partner;
  program;
  where = '';
  StrWhere = '';
  array = [];
  loading = true;
  eventcount;
  p: number = 1;
  constructor(private service: MasterService) {
    this.service.PartnerEventList('').subscribe((data: any) => {
      this.EventList = data;
      this.eventcount = data.length;
      this.loading = false;
    });
    this.pageload();
  }

  pageload(){
    this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear in ('2023-2024','2024-2025')").subscribe((data: any) => {
      this.partner = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct Program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid").subscribe((data: any) => {
      this.program = data;
  });

  }

  onselectpartner(partnerdpd) {
    if (partnerdpd == '' || partnerdpd.length == 0 || partnerdpd[0] == undefined)  {
      this.service.FillDropDown("PartnerProgram","distinct Program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid").subscribe((data: any) => {
        this.program = data;
    });
      } else {
        this.service.FillDropDown("PartnerProgram","distinct Program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid where PartnerProgram.userid in (" + this.getMultiSelectionWhere(partnerdpd.toString()) + ")")
        .subscribe((data: any) => {
          this.program = data;
        })
        }
  }

  getMultiSelectionWhere(where) {
    let arr = where.split(',');
    let currentWhere = '';
    for (let i = 0; i < arr.length; i++) {
      currentWhere += '\'' + arr[i] + '\'';
      if (i + 1 != arr.length) {
        currentWhere += ',';
      }
    }
    return currentWhere;
  }

  filterrecord(searchpartner, searchprogram) {
    this.loading = true;
    this.array = [];
    this.StrWhere = '';
    if(searchpartner!= null  &&  searchpartner!='' &&  searchpartner!='undefined' && searchpartner!='select' ){
      this.array.push('PartnerEventMaster.partnerid in(' + this.getMultiSelectionWhere(searchpartner.toString()) + ')');
    }
    if(searchprogram!= null  &&  searchprogram!='' &&  searchprogram!='undefined' && searchprogram!='select' ){
     this.array.push('PartnerEventMaster.pgid in(' + this.getMultiSelectionWhere(searchprogram.toString()) + ')');
   }


   if (this.array.length == 0) {
     this.where = this.StrWhere;
   } else if (this.array.length == 1) {
     this.StrWhere = ' where ' + this.array[0].toString();
   } else {
     this.StrWhere = ' where ' + this.array[0].toString();
     for (let i = 1; i < this.array.length; i++) {
       this.StrWhere = this.StrWhere + ' and ' + this.array[i].toString();
     }

   }
   this.where = this.StrWhere;

   this.service.PartnerEventList(this.where).subscribe((data: any) => {
    this.EventList = data;
    this.eventcount = data.length;
    this.loading = false;
  });


  }

  ngOnInit(): void {
  }

  selectpartner(pt){
    if (pt._selected) {
      this.partnerlist.setValue([]);
    }
  }

  selectprogram(pro){
    if (pro._selected) {
      this.programlist.setValue([]);
    }
  }
}
