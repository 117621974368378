import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Eventlist } from 'app/ClassFiles/eventlist';
import { MasterService } from 'app/services/master.service';
import { data } from 'jquery';

@Component({
  selector: 'app-eventdetails',
  templateUrl: './eventdetails.component.html',
  styleUrls: ['./eventdetails.component.css']
})
export class EventdetailsComponent implements OnInit {
  public Updateeventdetails: FormGroup;
  public Updateeventgallery: FormGroup;
  partner;
  program;
  fileToUpload: File = null;
  imageUrl: string;
  submited = new EventEmitter<string>();
  eid;
  Partnereventdetails;
  Eventpicture;
  constructor(private service: MasterService,private route: ActivatedRoute) { 
    this.eid = route.snapshot.params["id"];
    this.service.GetPartnerEvent(this.eid).subscribe((data:any) => {
      this.Partnereventdetails = data;
    });

    this.service.GetEventPic(this.eid).subscribe((data: any) => {
      this.Eventpicture = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear='2020-2021'").subscribe((data: any) => {
      this.partner = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid").subscribe((data: any) => {
      this.program = data;
    });
  }

  Delete(epid) {
    this.service.RemoveEventPhoto(epid).subscribe((res) => {
      this.submited.emit(res.toString());
      alert("Deleted Succesfully")
      this.service.GetEventPic(this.eid).subscribe((data: any) => {
        this.Eventpicture = data;
      });
    });
  }


  onselectpartner(partnerdpd) {
    if (partnerdpd == '' || partnerdpd.length == 0 || partnerdpd[0] == undefined)  {
      this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear='2020-2021'").subscribe((data: any) => {
      this.program = data;
    });
      } else {
        this.service.FillDropDown("PartnerProgram","distinct Program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid where PartnerProgram.userid='" + partnerdpd + "'")
        .subscribe((data: any) => {
          this.program = data;
       
        })
        }
  }

  ngOnInit(){
    this.Updateeventdetails = new FormGroup({
      eventname : new FormControl('', Validators.required),
      eventplace :  new FormControl('', Validators.required),
      eventdate :  new FormControl('', Validators.required),
      description :  new FormControl('', Validators.required),
      progname :  new FormControl('', Validators.required),
      partname :  new FormControl('', Validators.required),
     
  
    });

    this.Updateeventgallery = new FormGroup ({
      choosefile: new FormControl('', Validators.required)
    })
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.Updateeventdetails.controls[controlName].hasError(errorName);
  }

  public GalleryError = (controlName: string, errorName: string) => {
    return this.Updateeventgallery.controls[controlName].hasError(errorName);
  }


  UpdateEventdetails(){
    const newevent: Eventlist = new Eventlist();
    newevent.partnerid = this.Updateeventdetails.value.partname;
    newevent.pgid = this.Updateeventdetails.value.progname;
    newevent.eventname = this.Updateeventdetails.value.eventname;
    newevent.eventplace = this.Updateeventdetails.value.eventplace;
    newevent.eventdate = this.Updateeventdetails.value.eventdate;
    newevent.description = this.Updateeventdetails.value.description;
    
    this.service.UpdateEventDetail(this.eid,newevent).subscribe((res) => {
      if (res == "Success") {        
        this.submited.emit(res.toString());
        alert("Updated Succesfully");
      }
      else{
        this.Updateeventdetails.reset();
      }
    });    
  }

  
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  UpdateEventPicture(){    
    this.service.UpdateEventPicture(this.eid,this.fileToUpload).subscribe((res) => {
    
      if (res == "Success") {        
        this.submited.emit(res.toString());
        alert("Picture Added Succesfully")
        this.Updateeventgallery.reset();
        this.service.GetEventPic(this.eid).subscribe((data: any) => {
          this.Eventpicture = data;
        });
      }
      else{
        this.Updateeventgallery.reset();
      }
    });    
  }

}
