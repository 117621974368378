import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Stateenroll } from 'app/ClassFiles/stateenroll';
import { Chart } from 'chart.js';
import { Attendancegraph } from 'app/ClassFiles/attendancegraph';
import { Enrollcity } from 'app/ClassFiles/enrollcity';
declare var pdfMake: any;
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import * as jspdf from 'jspdf';

@Component({
  selector: 'app-enrollment-graph',
  templateUrl: './enrollment-graph.component.html',
  styleUrls: ['./enrollment-graph.component.css']
})

export class EnrollmentGraphComponent implements OnInit {

  docDefinition: any;
  strwhere = '';
  where = "";
  array = [];
  centercountt = 0;
  schoolcountt = 0;
  studentcountt = 0;
  sancharakcountt = 0;
  computerscountt = 0;
  loading = false;
  public pieChartLabels: Array<string> = ['Boys', 'Girls'];
  loaded;
  public pieChartData: any[];
  pieChartColor: any = [
    {
      backgroundColor: ['rgba(76, 168, 50, 1)',
        'rgba(245, 169, 17,1)',
        'rgba(240, 233, 41,1)'
      ]
    }
  ]
  public piechartOptions: any = {
    responsive: true,

  };

  UserId;
  centid;
  Userrole;
  selected;
  public chartt: Chart;
  dept = []; private value;
  cityEnroll;
  public citychart: Chart;
  citycount;
  cenroll = []; att = [];
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  ongoing;
  dropout;
  complete;
  enrollment;
  enrollmentcount;
  boyscount;
  girlscount;
  abc;
  pgid; 
  pgname; 
  selectfiyear;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    
    this.UserId = localStorage.getItem('UserId');
    this.Userrole = localStorage.getItem("role");
    this.centid = route.snapshot.params["id"];
    this.selectfiyear = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');
    this.pgname = localStorage.getItem('pgname');

    var d = new Date();
    var curr_month = d.getMonth();


    var year = d.getFullYear();
    var monthname

    if (curr_month == 1) {
      monthname = 'January';
    }
    else if (curr_month == 2) {
      monthname = 'February';
    }
    else if (curr_month == 3) {
      monthname = 'March';
    }
    else if (curr_month == 4) {
      monthname = 'April';
    }
    else if (curr_month == 5) {
      monthname = 'May';
    }
    else if (curr_month == 6) {
      monthname = 'June';
    }
    else if (curr_month == 7) {
      monthname = 'July';
    }
    else if (curr_month == 8) {
      monthname = 'August';
    }
    else if (curr_month == 9) {
      monthname = 'September';
    }
    else if (curr_month == 10) {
      monthname = 'October';
    }
    else if (curr_month == 11) {
      monthname = 'November';
    }
    else if (curr_month == 12) {
      monthname = 'December';
    }
    // this.monthyear = "(" + monthname + "-" + year + ")"
    // this.dummymonth = monthname;
    // this.dummyyear = year;
    if (this.selectfiyear == "2024-2025") {
      this.service.GetCALPartnerwiseCenterSchoolCountSahyogi("where partnerid='" + this.UserId + "' and month='" + monthname + "' and year='2023' and pgid = '" + this.pgid + "'").subscribe((data: any) => {
        // this.centercount = data[0].centcount;
        // this.schoolcount = data[0].schcount;
        this.enrollmentcount = data[0].totstud;
        this.boyscount = data[0].boys;
        this.girlscount = data[0].girls;
        // this.computercount = data[0].totcomp - 1;
      })
    }

    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {

      this.service.SahyogiIDIData(this.UserId, this.selectfiyear).subscribe((data: any) => {
        this.ongoing = data[0].ongoing;
        this.dropout = data[0].dropout;
        this.complete = data[0].completed;
        this.enrollment = data[0].totstud;
      });
    }
  }


  filterrecord(month, year) {

    this.loading = true;
    this.array = [];
    this.strwhere = '';
    if (month != null && month != '' && month != 'undefined' && month != 'Select') {
      this.array.push("month = '" + month + "'");
    }
    if (year != null && year != '' && year != 'undefined' && year != 'Select') {
      this.array.push("year = '" + year + "'");
    }
    if (this.array.length == 0) {
      this.where = this.strwhere;
    }
    else if (this.array.length == 1) {
      this.strwhere = this.array[0].toString();
    }
    // else {
    //   this.strwhere = " and " + this.array[0].toString();
    //   for (let i = 1; i < this.array.length; i++) {
    //     this.strwhere = this.strwhere + " and " + this.array[i].toString();
    //   }
    // }
    // this.where = " Where center.teamleaderid = '" + this.UserId + "' and SchoolTeacherTraining.schteachtranid='" + this.schoolteacherID + "'";

    this.where = "where partnerid='" + this.UserId + "' and month='" + month + "' and year='" + year + "' and pgid = '" + this.pgid + "'"
    this.service.GetCALPartnerwiseCenterSchoolCountSahyogi(this.where).subscribe((data: any) => {
      this.abc = data.length;
      if (this.abc == 0) {
        this.enrollmentcount = 0;
        this.boyscount = 0;
        this.girlscount = 0;
      } else {
        this.enrollmentcount = data[0].totstud;
        this.boyscount = data[0].boys;
        this.girlscount = data[0].girls;
      }
    });
    this.loading = false;
  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "StateGraph.png";
  }

  generarPDF() {
    const div = document.getElementById('content');
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(div, options).then((canvas) => {
      var img = canvas.toDataURL("image/PNG");
      var doc = new jsPDF('l', 'mm', 'a4');

      // Add image Canvas to PDF
      const bufferX = 5;
      const bufferY = 5;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      return doc;
    }).then((doc) => {
      doc.save('postres.pdf');
    });
    
  }

}