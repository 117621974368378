import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-endlinekerala2022',
  templateUrl: './endlinekerala2022.component.html',
  styleUrls: ['./endlinekerala2022.component.css']
})
export class Endlinekerala2022Component implements OnInit {
  Examtype = 'PracticalBaseline';
  selected;
  Partnerid;
  pgid;
  FCStudentCount;
  FCStudentCountt;
  loading = true;
  Baselinedata = new Array;
  Endlinedata = new Array();
  Semester = 'sem 1';
  City = 'Cochin';

  constructor(private service: MasterService, private fb: FormBuilder,) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

    this.service.GetPartnerFCStudCount("where partnerid='" + this.Partnerid + "' and examtype='PracticalBaseline' and academicyear='" + this.selected + "'").subscribe((data: any) => {
      this.FCStudentCount = data;
      this.FCStudentCountt = data.length;
      this.Endlinedata = new Array();
      data.forEach(y => {
        this.Endlinedata.push(y.First);
        this.Endlinedata.push(y.Second);
        this.Endlinedata.push(y.Third);
        this.Endlinedata.push(y.Fourth);
        this.Endlinedata.push(y.Fifth);
        this.Endlinedata.push(y.Sixth);
        this.Endlinedata.push(y.Seventh);
        this.Endlinedata.push(y.Eighth);
        this.Endlinedata.push(y.Ninth);
        this.Endlinedata.push(y.Tenth);
        this.Endlinedata.push(y.Eleventh);
        this.Endlinedata.push(y.Twelth);
      });
      this.loading = false;
    });

  }

  OnSelectExamtype(Etype) {
    this.Endlinedata = [];
    this.service.GetPartnerFCStudCount("where partnerid='" + this.Partnerid + "' and examtype='" + Etype + "' and academicyear='" + this.selected + "'").subscribe((data: any) => {
      this.FCStudentCount = data;
      this.Endlinedata = new Array();
      data.forEach(x => {
        this.Endlinedata.push(x.First);
        this.Endlinedata.push(x.Second);
        this.Endlinedata.push(x.Third);
        this.Endlinedata.push(x.Fourth);
        this.Endlinedata.push(x.Fifth);
        this.Endlinedata.push(x.Sixth);
        this.Endlinedata.push(x.Seventh);
        this.Endlinedata.push(x.Eighth);
        this.Endlinedata.push(x.Ninth);
        this.Endlinedata.push(x.Tenth);
        this.Endlinedata.push(x.Eleventh);
        this.Endlinedata.push(x.Twelth);
      })
    })
    this.loading = false;
  }

  ngOnInit(): void {
  }


}
