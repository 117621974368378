import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-kerala5',
  templateUrl: './kerala5.component.html',
  styleUrls: ['./kerala5.component.css']
})
export class Kerala5Component implements OnChanges {

  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  @Input('City') City;
  loading = true;
  Partnerid;
  pgid;
  selected;

  EndlineStandard: any;
  c1 = new Array();
  c2 = new Array();
  compentencies;
  Endlinestd5;
  Endlinestd5count;
  totapp;

  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }
  
  ngOnChanges() {
    if (this.City == 'Cochin') {
      this.service.GetSahyogiEvalCompetencyCitywise("where city = 'Cochin' and examtype = 'PracticalEndline' and standard = '5'").subscribe((data: any) => {
        this.totapp = data[0].totalappeared;
        data.map((obj) => {
          obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
          obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
        })
        this.Endlinestd5 = data;
        this.Endlinestd5count = data.length;
        this.loading = false;
        this.compentencies = new Array();
        data.forEach(y => {
          this.compentencies.push(y.C1);
          this.compentencies.push(y.C2);
        })

        var graph = document.getElementById("graph");
        if (data.length != 0) {
          graph.style.display = "block";
          this.EndlineStandard = new Chart('Standard5', {
            type: 'bar',
            data: {
              labels: ['Geogebra', 'Unicode'],

              datasets: [
                {
                  label: "Compentencies",
                  backgroundColor: '#f4c20d',
                  borderColor: '#f4c20d',
                  data: this.compentencies
                },
              ]
            },
            options: {
              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  }
                }],
              },
              title: {
                display: true,
                text: this.Examtype + 'Standard' + this.Standard
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1;
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 14,
                  }
                }
              }
            }
          })
        }
        else {
          graph.style.display = "none";

        }
        this.loading = false;
      })
    }
    else {
      this.service.GetSahyogiEvalCompetencyCitywise("where city = 'Mundra' and examtype = 'PracticalEndline' and standard = '5'").subscribe((data: any) => {
        this.totapp = data[0].totalappeared;
        data.map((obj) => {
          obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
          obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
          obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
          obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
          obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
        })
        this.Endlinestd5 = data;
        this.Endlinestd5count = data.length;
        this.loading = false;
        this.compentencies = new Array();
        data.forEach(y => {
          this.compentencies.push(y.C1);
          this.compentencies.push(y.C2);
          this.compentencies.push(y.C3);
          this.compentencies.push(y.C4);
          this.compentencies.push(y.C5);
        })

        var graph = document.getElementById("graph");
        if (data.length != 0) {
          graph.style.display = "block";
          this.EndlineStandard = new Chart('Standard5', {
            type: 'bar',
            data: {
              labels: ['Exploring Desktop(Beginner)', 'Window Media Player(Beginner)', 'Basic Word Processing(Basic)', 'Slide Making(Basic)', 'Sound Recorder(Basic)'],

              datasets: [
                {
                  label: "Compentencies",
                  backgroundColor: '#f4c20d',
                  borderColor: '#f4c20d',
                  data: this.compentencies
                },
              ]
            },
            options: {
              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  }
                }],
              },
              title: {
                display: true,
                text: this.Examtype + 'Standard' + this.Standard
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1;
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 14,
                  }
                }
              }
            }
          })
        }
        else {
          graph.style.display = "none";

        }
        this.loading = false;
      })
    }
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard + "th Standard Endline Summary.png";
  }

}


