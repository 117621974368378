import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MasterService } from 'app/services/master.service';
import { MatDialog } from '@angular/material/dialog';
import { PendamiceventmessageComponent } from 'app/layouts/pendamiceventmessage/pendamiceventmessage.component';

@Component({
  selector: 'app-partnereventlist',
  templateUrl: './partnereventlist.component.html', 
  styleUrls: ['./partnereventlist.component.css']
})
export class PartnereventlistComponent implements OnInit {
  searchprogram;
  searchpartner;
  programlist = new FormControl(); 
  partnerlist = new FormControl();
  EventList;
  partner;
  program;
  where = '';
  StrWhere = '';
  array = [];
  loading = true; 
  eventcount;
  p: number = 1;
  UserId;
  pgname;
  selectfiyear;
  pgid;
  constructor(private service: MasterService,public dialog: MatDialog) {
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');
    this.pgid = localStorage.getItem('pgid');
    this.UserId = localStorage.getItem('UserId');
    this.service.PartnerEventList("where Partner.partnerid ='"+this.UserId+"' and PartnerEventMaster.pgid='"+this.pgid+"'").subscribe((data: any) => {
      this.EventList = data;
      this.eventcount = data.length;
      this.loading=false;
    });

    // if (this.UserId == 'PT30') {
    //   this.openDialog();
    // }
  }

  // openDialog() {
  //   this.dialog.open(PendamiceventmessageComponent);
  // }

  

  ngOnInit(): void {
  }
}
