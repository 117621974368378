import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-pifenrollment',
  templateUrl: './pifenrollment.component.html',
  styleUrls: ['./pifenrollment.component.css']
})
export class PifenrollmentComponent implements OnInit {
  docDefinition: any;
  
  centercountt =0;
  schoolcountt =0;
  studentcountt =0;
  sancharakcountt =0;
  computerscountt =0;
  loading = false;
  public pieChartLabels: Array<string> = ['Boys', 'Girls'];
  
  public pieChartData: any[];
  pieChartColor: any = [
    {
      backgroundColor: ['rgba(76, 168, 50, 1)',
        'rgba(245, 169, 17,1)',
        'rgba(240, 233, 41,1)'
      ]
    }
  ]
  public piechartOptions: any = {
    responsive: true,

  };

  UserId;
  centid;
  selected;
  public chartt: Chart;
  dept = []; private value;
  cityEnroll;
  public citychart: Chart;
  citycount;
  cenroll = [];att= [];
  public pieChartType: string;
 public barChartType: string;
 public barChartType1: string;
  ongoing;
  dropout;
  complete;
  enrollment;

pgid;pgname;selectfiyear;
  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    this.selectfiyear = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');
    this.pgname = localStorage.getItem('pgname');

    this.service.GetPartnerDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='"+this.UserId+"'").subscribe((data: any) => {
      this.ongoing= data[0].studstatusAct;
      this.dropout= data[0].studstatusdrop;
      this.complete= data[0].schcompl;
      this.enrollment= data[0].totenrolment;
    });

  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType='bar';
    this.barChartType1='bar';
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "StateGraph.png";
  }

  generarPDF() {
    const div = document.getElementById('content');
    const options = {
      background: 'white',
      scale: 3
    };

  html2canvas(div, options).then((canvas) => {
      var img = canvas.toDataURL("image/PNG");
      var doc = new jsPDF('l', 'mm', 'a4');

      // Add image Canvas to PDF
      const bufferX = 5;
      const bufferY = 5;
      const imgProps = (<any>doc).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');

      return doc;
    }).then((doc) => {
      doc.save('postres.pdf');  
    });
  }

}
 