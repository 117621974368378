import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { ExcelService } from 'app/services/excel.service';

@Component({
  selector: 'app-myeschoolteachertraining',
  templateUrl: './myeschoolteachertraining.component.html',
  styleUrls: ['./myeschoolteachertraining.component.css']
})
export class MyeschoolteachertrainingComponent implements OnInit {

  UserId;
  role;
  pgid;
  pgname;
  finyear;
  state;
  city;
  center;
  school;
  clusterlist;
  where = '';
  StrWhere = '';
  array = [];
  loading = true;
  p = 1;
  reportdata;
  datacount;
  searchmode: any;
  selectfiyear: string;

  constructor(private service: MasterService, private exService: ExcelService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');

    this.service.GetRptMyEschoolTeacherTrainingPartner(" " + this.where + " and cp.partnerid='" + this.UserId + "' ").subscribe((data) => {
      this.reportdata = data;
      this.datacount = data.length;
      this.loading = false;
    })
  }

  ngOnInit() {
  }

  onSelectMode(mode) {
    this.searchmode = mode;
    if (mode == 'Impact') {
      this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where  center.status='0' and Centerpartner.status=0  and  Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.state = data;
        });
      this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where  center.status='0' and Centerpartner.status=0 and Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.city = data;
        });
      this.service.FillDropDown("Center", "distinct centname", "center.centid", "inner join CenterPartner on center.centid=CenterPartner.centid where  center.status='0' and Centerpartner.status=0  and Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.center = data;
        });
      this.service.FillDropDown("Center", "distinct schoolname", "school.schoolid", "inner join CenterPartner on center.centid=CenterPartner.centid inner join school on school.centid=center.centid where center.status='0' and Centerpartner.status=0 and school.status=0 and Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.school = data;
        });
      this.service.FillDropDown("cluster", "distinct clustername", "clustername", "").subscribe(
        (data: any) => {
          this.clusterlist = data;
        });
    }
    if (mode == 'Lite') {
      this.service.FillDropDown("Centerlite", "distinct state", "state", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.state = data;
        });
      this.service.FillDropDown("Centerlite", "distinct centerlite.city", "centerlite.city", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.city = data;
        });
      this.service.FillDropDown("Centerlite", "distinct centname", "centerlite.centid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.center = data;
        });
      this.service.FillDropDown("Centerlite", "distinct schoolname", "schoollite.schoolid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid  inner join schoollite on schoollite.centid=centerlite.centid  where Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.school = data;
        });
      this.service.FillDropDown("cluster", "distinct clustername", "clustername", "").subscribe(
        (data: any) => {
          this.clusterlist = data;
        });
    }
  }

  onSelectState(stname) {
    if (this.searchmode == 'Impact') {
      if (stname == '' || stname.length == 0 || stname[0] == undefined) {
        this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where  center.status='0' and Centerpartner.status=0 and Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => { this.city = data; });
      } else {
        this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.status=0 and center.state ='" + stname + "' order by Center.city").subscribe(
          (data: any) => {
            this.city = data;
          });
        this.service.FillDropDown("Center", "distinct centname", "center.centid", "inner join CenterPartner on center.centid=CenterPartner.centid where  Center.status=0 and Center.state = '" + stname + "' order by Center.centname").subscribe(
          (data: any) => {
            this.center = data;
          });
        this.service.FillDropDown("Center", "distinct schoolname", "school.schoolid", "inner join CenterPartner on center.centid=CenterPartner.centid inner join school on school.centid=center.centid   and Center.status=0 and Center.state = '" + stname + "' order by School.schoolname").subscribe(
          (data: any) => {
            this.school = data;
          });
      }
    }
    if (this.searchmode == 'Lite') {
      if (stname == '' || stname.length == 0 || stname[0] == undefined) {
        this.service.FillDropDown("Centerlite", "distinct centerlite.city", "centerlite.city", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => { this.city = data; });
      } else {
        this.service.FillDropDown("Centerlite", "distinct centerlite.city", "centerlite.city", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where centerlite.state= '" + stname + "'  and Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.city = data;
          });
        this.service.FillDropDown("Centerlite", "distinct centname", "centerlite.centid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where centerlite.state= '" + stname + "'  and Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.center = data;
          });
        this.service.FillDropDown("Centerlite", "distinct schoolname", "schoollite.schoolid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid inner join schoollite on schoollite.centid=centerlite.centid      where centerlite.state= '" + stname + "'  and Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.school = data;
          });
      }
    }
  }

  onSelectCity(ctname) {
    if (this.searchmode == 'Impact') {
      if (ctname == '' || ctname.length == 0 || ctname[0] == undefined) {
        this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where  center.status='0' and Centerpartner.status=0 and Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.center = data;
          });
      } else {
        this.service.FillDropDown("Center", "distinct centname", "center.centid", "inner join CenterPartner on center.centid=CenterPartner.centid where  Center.status=0 and Center.city = '" + ctname + "' order by Center.centname").subscribe(
          (data: any) => {
            this.center = data;
          });
        this.service.FillDropDown("Center", "distinct schoolname", "school.schoolid", "inner join CenterPartner on center.centid=CenterPartner.centid inner join school on school.centid=center.centid   and Center.status=0 and Center.city = '" + ctname + "' order by School.schoolname").subscribe(
          (data: any) => {
            this.school = data;
          });
        this.service.FillDropDown("cluster", "distinct clustername", "clustername", "where city ='" + ctname + "'").subscribe(
          (data: any) => {
            this.clusterlist = data;
          });
      }
    }
    if (this.searchmode == 'Lite') {
      if (ctname == '' || ctname.length == 0 || ctname[0] == undefined) {
        this.service.FillDropDown("Centerlite", "distinct centerlite.city", "centerlite.city", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.center = data;
          });
      } else {
        this.service.FillDropDown("Centerlite", "distinct centname", "centerlite.centid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where centerlite.city=  '" + ctname + "' and Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.center = data;
          });
        this.service.FillDropDown("Centerlite", "distinct schoolname", "schoollite.schoolid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid inner join schoollite on schoollite.centid=centerlite.centid where centerlite.city=  '" + ctname + "'  and Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.school = data;
          });
        this.service.FillDropDown("cluster", "distinct clustername", "clustername", " where city ='" + ctname + "'").subscribe(
          (data: any) => {
            this.clusterlist = data;
          });
      }
    }
  }

  onSelectCenter(centname) {
    if (this.searchmode == 'Impact') {
      if (centname == '' || centname.length == 0 || centname[0] == undefined) {
        this.service.FillDropDown("Center", "distinct centname", "centname", "inner join CenterPartner on center.centid=CenterPartner.centid where  center.status='0' and Centerpartner.status=0 and Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.school = data;
          });
      } else {
        this.service.FillDropDown("Center", "distinct schoolname", "school.schoolid", "inner join CenterPartner on center.centid=CenterPartner.centid inner join school on school.centid=center.centid   and Center.status=0 and Center.centid = '" + centname + "' order by School.schoolname").subscribe(
          (data: any) => {
            this.school = data;
          });
      }
    }
    if (this.searchmode == 'Lite') {
      if (centname == '' || centname.length == 0 || centname[0] == undefined) {
        this.service.FillDropDown("Centerlite", "distinct centname", "centerlite.centid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid where Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.school = data;
          });
      } else {
        this.service.FillDropDown("Centerlite", "distinct schoolname", "schoollite.schoolid", "inner join CenterPartnerlite on centerlite.centid=CenterPartnerlite.centid inner join schoollite on schoollite.centid=centerlite.centid where centerlite.centid=  '" + centname + "'  and Centerpartnerlite.partnerid='" + this.UserId + "' and Centerlite.pgid='" + this.pgid + "'  ").subscribe(
          (data: any) => {
            this.school = data;
          });
      }
    }
  }

  filterrecord(srchmode, searchstate, searchcity, searchcluster, searchcenter, searchschool) {

    this.loading = true;
    this.array = [];
    this.StrWhere = '';

    if (searchstate != null && searchstate !== '' && searchstate !== 'undefined' && searchstate !== 'Select' && searchstate.length !== 0) {
      this.array.push("MyEschoolTeacher.state = '" + searchstate + "'");
    }
    if (searchcity != null && searchcity !== '' && searchcity !== 'undefined' && searchcity !== 'Select' && searchcity.length !== 0) {
      this.array.push("MyEschoolTeacher.city = '" + searchcity + "'");
    }
    if (searchcluster != null && searchcluster !== '' && searchcluster !== 'undefined' && searchcluster !== 'Select' && searchcluster.length !== 0) {
      this.array.push("cluster = '" + searchcluster + "'");
    }
    if (searchcenter != null && searchcenter !== '' && searchcenter !== 'undefined' && searchcenter !== 'Select' && searchcenter.length !== 0) {
      this.array.push("c.centid = '" + searchcenter + "'");
    }
    if (searchschool != null && searchschool !== '' && searchschool !== 'undefined' && searchschool !== 'Select' && searchschool.length !== 0) {
      this.array.push("MyEschoolTeacher.schoolid = '" + searchschool + "'");
    }
    if (srchmode != null && srchmode !== '' && srchmode !== undefined && srchmode !== 'Select') {
      this.array.push("MyEschoolTeacher.centermode = '" + srchmode + "'");
    }

    if (this.array.length == 0) {
      this.where = this.StrWhere;
    } else if (this.array.length == 1) {
      this.StrWhere = ' where ' + this.array[0].toString();
    } else {
      this.StrWhere = ' where ' + this.array[0].toString();
      for (let i = 1; i < this.array.length; i++) {
        this.StrWhere = this.StrWhere + ' and ' + this.array[i].toString();
      }

    }

    this.where = this.StrWhere;

    this.service.GetRptMyEschoolTeacherTrainingPartner("" + this.where + " and cp.partnerid='" + this.UserId + "'").subscribe((data) => {
      this.reportdata = data;
      this.datacount = this.reportdata.length;
      this.loading = false;
    })
  }

  Export(searchstate, searchcity, searchcluster, searchcenter, searchschool) {
    this.loading = true;
    this.array = [];
    this.StrWhere = '';
    this.loading = true;
    this.service.GetRptMyEschoolTeacherTrainingPartner(" " + this.where + " and cp.partnerid='" + this.UserId + "'").subscribe((data: any) => {
      this.loading = false;
      const new_list = data.map(function (obj) {
        return {
          "Sr No": 0,
          "Mytechid": obj.mytechid,
          "State": obj.state,
          "City": obj.city,
          "Cluster": obj.cluster,
          "Center ID": obj.centid,
          "Center Name": obj.centname,
          "Parter Name": obj.partnername,
          "School ID": obj.schoolid,
          "School Name": obj.schoolname,
          "UDISE No": obj.udiseno,
          "Teacher Name": obj.teachername,
          "Gender": obj.gender,
          "Contact No": obj.contactno,
          "Designation": obj.designation,
          "Qualification": obj.education,
          "Standard": obj.standard,
          "Subject": obj.subject,
          "School Joining Date": obj.schooljoiningdate,
          "Training Date": obj.trainingdate,
          "Training Venue": obj.venue,
          "Training Time": obj.trainingtime,
          "कार्यशाळेच्या माध्यमातून डिजिटल साक्षर उपकरण हाताळण्याची पद्धत तुम्हाला अवगत झाली आहे का?": obj.q1,
          "डिजिटल साक्षर अभ्यासक्रमाच्या नियमित वापराने मुलांच्या शैक्षणिक प्रगतीमध्ये किती मदत होईल?": obj.q2,
          "शिक्षकांच्या अनुपस्थितीत विद्यार्थ्यांच्या स्वयं अध्यायानाकरिता डिजिटल साक्षर अभ्यासक्रमाचा कितपत वापर होईल?": obj.q3,
          "प्रशिक्षकाच्या एकूण कौश्यल्यांचे मूल्यमापन करा.": obj.q4,
          "संपूर्ण प्रशिक्षणाचे मुल्यांकन करा.": obj.q5,
          "तुमची प्रतिक्रिया व सुचना:": obj.q6,
        };
      });
      for (let j = 0; j < new_list.length; j++) {
        new_list[j]['Sr No'] = j + 1;
      }
      this.exService.exportAsExcelFile(new_list, " My eschool Teacher's Training ");
    });
  }
}