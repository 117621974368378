import { Component, Input, OnChanges } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-gujarati6th',
  templateUrl: './gujarati6th.component.html',
  styleUrls: ['./gujarati6th.component.css']
})
export class Gujarati6thComponent implements OnChanges {

  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  loading = true;
  Partnerid;
  pgid;
  selected;

  EndlineStandard: any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  c5 = new Array();
  c6 = new Array();
  c7 = new Array();
  compentencies;
  Endlinestd6;
  Endlinestd6count;
  totapp;

  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }

  ngOnChanges() {
    this.service.SahyogiEvalCompetency(this.Partnerid, this.selected, this.pgid, this.Examtype, this.Standard).subscribe((data: any) => {
      this.totapp = data[0].totalappeared;
      data.map((obj) => {
        obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
        obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
        obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
        obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
        obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
        obj.C6 = ((data[0].c6 / this.totapp) * 100).toFixed(2);
        obj.C7 = ((data[0].c7 / this.totapp) * 100).toFixed(2);
      })
      this.Endlinestd6 = data;
      this.Endlinestd6count = data.length;
      this.loading = false;
      this.compentencies = new Array();
      data.forEach(y => {
        this.compentencies.push(y.C1);
        this.compentencies.push(y.C2);
        this.compentencies.push(y.C3);
        this.compentencies.push(y.C4);
        this.compentencies.push(y.C5);
        this.compentencies.push(y.C6);
        this.compentencies.push(y.C7);
      })

      var graph = document.getElementById("graph");
      if (data.length != 0) {
        graph.style.display = "block";
        this.EndlineStandard = new Chart('gujaratiStandard6', {
          type: 'bar',
          data: {
            labels: ['Overview of Computer(Beginner)', 'Exploring Desktop(Beginner)', 'Using Media Player(Beginner)', 'Overview of Computer(Basic)', 'Basic Word Processing(Basic)', 'Slide Making(Basic)', 'Sound Recording(Basic)'],

            datasets: [
              {
                label: "Compentencies",
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                data: this.compentencies
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            title: {
              display: true,
              text: this.Examtype + 'Standard' + this.Standard
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }
        })
      }
      else {
        graph.style.display = "none";

      }
      this.loading = false;
    })
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard + "th Standard Endline Summary.png";
  }

}

