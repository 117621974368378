import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { FormControl } from '@angular/forms';
import { ExcelService } from 'app/services/excel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-infralist',
  templateUrl: './infralist.component.html',
  styleUrls: ['./infralist.component.css']
})
export class InfralistComponent implements OnInit {

  @ViewChild('TABLE', { static: true }) table: ElementRef;
  UserId;
  pgid;
  PartnerSchoolList;
  centercount;
  state; PartnerCenterList;
  city;
  loading = true;
  showMainContent;
  array = [];
  StrWhere = "";
  statelist = new FormControl();
  citylist = new FormControl();
  searchstate; selectfiyear
  searchcity; academicyear; pgname;
  p: number = 1;
  where = "";
  searchstatus; PartnerSchoolListcount; CenterComputercount;
  schoolid;

  constructor(private service: MasterService, private exService: ExcelService, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');
    this.schoolid = route.snapshot.params['id'];

    this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
      (data: any) => {
        this.state = data;

      });

    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
      (data: any) => {
        this.city = data;

      });
    this.service.GetMyEschoolPartnerwisecenterinfra("where CenterPartner.partnerid='" + this.UserId + "' and schoolid= '" + this.schoolid + "' ").subscribe((data: any) => {
      this.PartnerCenterList = data;
      this.CenterComputercount = data.length;
      console.log(this.CenterComputercount);

      this.loading = false;
    });
  }

  ngOnInit(): void {

  }

  ExportReport(searchstate, searchcity, searchstatus) {
    this.loading = true;

    if (this.selectfiyear == "2023-2024" || this.selectfiyear == "2024-2025") {
    if (this.where == '') {
      this.service.GetMyEschoolPartnerwisecenterinfra("where CenterPartner.partnerid='" + this.UserId + "' and schoolid= '" + this.schoolid + "' ").subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            Srno: 0,
            State: obj.state == null ? '' : obj.state,
            City: obj.city == null ? '' : obj.city,
            CenterID: obj.tlname == null ? '' : obj.tlname,
            CenterName: obj.centname == null ? '' : obj.centname,
            ClusterName: obj.Clustername == null ? '' : obj.Clustername,
            DigitalClassroomNo: obj.DigitalClassroomNo == null ? '' : obj.DigitalClassroomNo,
            AvailableDevice: obj.AvailableDevice == null ? '' : obj.AvailableDevice,
            TotalUnits: obj.TotalUnits == null ? '' : obj.TotalUnits,
            Brand: obj.Brand == null ? '' : obj.Brand,
            Model: obj.Model == null ? '' : obj.Model,
            OperatingSystem: obj.OperatingSystem == null ? '' : obj.OperatingSystem,
            Version: obj.Version == null ? '' : obj.Version,
            ScreenSize: obj.ScreenSize == null ? '' : obj.ScreenSize,
            RAM: obj.ram == null ? '' : obj.ram,
            InternetConnectivity: obj.InternetConnectivity == null ? '' : obj.InternetConnectivity,
            InternetBandwidth: obj.InternetBandwidth == null ? '' : obj.InternetBandwidth,
            DigitalSaksharUnitprovided: obj.DigitalSaksharUnitprovided == null ? '' : obj.DigitalSaksharUnitprovided,
            DigitalSaksharInstalled: obj.DigitalSaksharInstalled == null ? '' : obj.DigitalSaksharInstalled,
            Media: obj.Media == null ? '' : obj.Media,
            EarthingDone: obj.EarthingDone == null ? '' : obj.EarthingDone,
            Tubelights: obj.Tubelights == null ? '' : obj.Tubelights,
            Fan: obj.fan == null ? '' : obj.fan,
            InverterConnection: obj.InverterConnection == null ? '' : obj.InverterConnection,

          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'My e-school Infra Details List');
      });
    }
  
    else {
      this.service.GetMyEschoolPartnerwisecenterinfra("where CenterPartner.partnerid='" + this.UserId + "' and schoolid= '" + this.schoolid + "' ").subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            Srno: 0,
            State: obj.state == null ? '' : obj.state,
            City: obj.city == null ? '' : obj.city,
            CenterID: obj.tlname == null ? '' : obj.tlname,
            CenterName: obj.centname == null ? '' : obj.centname,
            ClusterName: obj.Clustername == null ? '' : obj.Clustername,
            DigitalClassroomNo: obj.DigitalClassroomNo == null ? '' : obj.DigitalClassroomNo,
            AvailableDevice: obj.AvailableDevice == null ? '' : obj.AvailableDevice,
            TotalUnits: obj.TotalUnits == null ? '' : obj.TotalUnits,
            Brand: obj.Brand == null ? '' : obj.Brand,
            Model: obj.Model == null ? '' : obj.Model,
            OperatingSystem: obj.OperatingSystem == null ? '' : obj.OperatingSystem,
            Version: obj.Version == null ? '' : obj.Version,
            ScreenSize: obj.ScreenSize == null ? '' : obj.ScreenSize,
            RAM: obj.ram == null ? '' : obj.ram,
            InternetConnectivity: obj.InternetConnectivity == null ? '' : obj.InternetConnectivity,
            InternetBandwidth: obj.InternetBandwidth == null ? '' : obj.InternetBandwidth,
            DigitalSaksharUnitprovided: obj.DigitalSaksharUnitprovided == null ? '' : obj.DigitalSaksharUnitprovided,
            DigitalSaksharInstalled: obj.DigitalSaksharInstalled == null ? '' : obj.DigitalSaksharInstalled,
            Media: obj.Media == null ? '' : obj.Media,
            EarthingDone: obj.EarthingDone == null ? '' : obj.EarthingDone,
            Tubelights: obj.Tubelights == null ? '' : obj.Tubelights,
            Fan: obj.fan == null ? '' : obj.fan,
            InverterConnection: obj.InverterConnection == null ? '' : obj.InverterConnection,

          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'My e-school Infra Details List');
      });
    }
  }
    // else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023") {
    //   this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, this.where).subscribe((data: any) => {
    //     this.loading = false;
    //     const new_list = data.map(function (obj) {
    //       return {
    //         Srno: 0,
    //         State: obj.state == null ? '' : obj.state,
    //         City: obj.city == null ? '' : obj.city,
    //         CenterID: obj.tlname == null ? '' : obj.tlname,
    //         CenterName: obj.centname == null ? '' : obj.centname,
    //         ClusterName: obj.Clustername == null ? '' : obj.Clustername,
    //         DigitalClassroomNo: obj.DigitalClassroomNo == null ? '' : obj.DigitalClassroomNo,
    //         AvailableDevice: obj.AvailableDevice == null ? '' : obj.AvailableDevice,
    //         TotalUnits: obj.TotalUnits == null ? '' : obj.TotalUnits,
    //         Brand: obj.Brand == null ? '' : obj.Brand,
    //         Model: obj.Model == null ? '' : obj.Model,
    //         OperatingSystem: obj.OperatingSystem == null ? '' : obj.OperatingSystem,
    //         Version: obj.Version == null ? '' : obj.Version,
    //         ScreenSize: obj.ScreenSize == null ? '' : obj.ScreenSize,
    //         RAM: obj.ram == null ? '' : obj.ram,
    //         InternetConnectivity: obj.InternetConnectivity == null ? '' : obj.InternetConnectivity,
    //         InternetBandwidth: obj.InternetBandwidth == null ? '' : obj.InternetBandwidth,
    //         DigitalSaksharUnitprovided: obj.DigitalSaksharUnitprovided == null ? '' : obj.DigitalSaksharUnitprovided,
    //         DigitalSaksharInstalled: obj.DigitalSaksharInstalled == null ? '' : obj.DigitalSaksharInstalled,
    //         Media: obj.Media == null ? '' : obj.Media,
    //         EarthingDone: obj.EarthingDone == null ? '' : obj.EarthingDone,
    //         Tubelights: obj.Tubelights == null ? '' : obj.Tubelights,
    //         Fan: obj.fan == null ? '' : obj.fan,
    //         InverterConnection: obj.InverterConnection == null ? '' : obj.InverterConnection,
    //       };
    //     });
    //     for (let i = 0; i < new_list.length; i++) {
    //       new_list[i].Srno = i + 1;
    //     }
    //     this.exService.exportAsExcelFile(new_list, 'Partner Infra Details List');
    //   });
    // }
  }
}
