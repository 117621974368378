import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ExcelService } from 'app/services/excel.service';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-youthschoollist',
  templateUrl: './youthschoollist.component.html',
  styleUrls: ['./youthschoollist.component.css']
})
export class YouthschoollistComponent implements OnInit {
  UserId;
  pgid;
  PartnerCenterList; IDIBatchList; IDIBatchcount;
  centercount;
  state;
  city;
  loading = true;
  array = [];
  StrWhere = "";
  where = "";
  selected;
  selectedd;
  statelist = new FormControl();
  citylist = new FormControl();
  statuslist = new FormControl();
  pgname;
  searchstatus = 0;
  p=1;
  constructor(private service: MasterService, private router: Router,private exService: ExcelService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');


    if (this.selected == "null") {

      this.service.GetPartnerIDIBatchlistNew('').subscribe((data: any) => {
        this.IDIBatchList = data;
        this.IDIBatchcount = data.length;
        this.loading = false;
      });
      
    }
    else if (this.selected == "2022-2023") {
      this.service.GetPartnerIDIBatchlistNew('').subscribe((data: any) => {
        this.IDIBatchList = data;
       this.IDIBatchcount = data.length;
        this.loading = false;
      });
      
    }

    else if (this.selected == "2021-2022") {
      this.service.OLDPartnerIDIBatchListPIF(this.selected).subscribe((data: any) => {
        this.IDIBatchList = data;
       this.IDIBatchcount = data.length;
        this.loading = false;
      });
      
    }
    this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where center.pgid = 'P6'").subscribe(
      (data: any) => {
        this.state = data;
      });

      this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where center.pgid = 'P6'").subscribe(
        (data: any) => {
        this.city = data;
      });
  }

   
  onSelectState(stname) {
    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
      (data: any) => {
        this.city = data;
      });

  }
  

  ngOnInit(): void {
  }

  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";


    if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
      //this.array.push("Center.state='" + searchstate + "'");
      this.array.push("center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

    }
    if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
      //this.array.push("Center.city='" + searchcity + "'");
      this.array.push("center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

    }
    if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
      //this.array.push("Center.status='" + searchstatus + "'");
      this.array.push("center.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

    }

    if (this.array.length == 0) {
      this.where = this.StrWhere;
    }
    else if (this.array.length == 1) {
      this.StrWhere = " where " + this.array[0].toString();
    }
    else {
      this.StrWhere = " where " + this.array[0].toString();
      for (let i = 1; i < this.array.length; i++) {
        this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
      }

    }
    this.where = this.StrWhere;

      this.service.GetPartnerIDIBatchlistNew(this.where).subscribe((data: any) => {
        this.IDIBatchList = data;
        this.IDIBatchcount = data.length;
        this.loading = false;
      });
    

   
  }


  Export(searchstate, searchcity, searchstatus) {
    this.loading = true;

      this.service.GetPartnerIDIBatchlistNew(this.where).subscribe((data: any) => {
      this.loading = false;
      const new_list = data.map(function (obj) {
        return {
          'Srno':0,
          'State': obj.state==null?'':obj.state,
          'City': obj.city==null?'':obj.city,
          'Program Fellow': obj.fellow==null?'':obj.fellow,
          'Center Name': obj.centname==null?'':obj.centname,
          'Batch Name': obj.batchname==null?'':obj.batchname,
          'Total Boys': obj.totboys==null?'':obj.totboys,
          'Total Girls':obj.totgirls==null?'':obj.totgirls,
          'Total Enrollment':obj.totstud==null?'':obj.totstud,
        };
      });
      for (let i = 0; i < new_list.length; i++) {
        new_list[i].Srno=i+1;
      }
      this.exService.exportAsExcelFile(new_list, 'Batch Summary');
    });
  
 

  }


  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }
  selectstatus(sts) {
    if (sts._selected) {
      this.statuslist.setValue([]);
    }
  }

}


