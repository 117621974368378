import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Attendancegraph } from 'app/ClassFiles/attendancegraph';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-gradewisegraph',
  templateUrl: './gradewisegraph.component.html',
  styleUrls: ['./gradewisegraph.component.css']
})

export class GradewisegraphComponent implements OnInit {
  
  UserId; 
  centid; 
  selected; 
  pgid;
  public stdchart: Chart;
  monthname; 
  a1gradecount; 
  agradecount; 
  bgradecount; 
  cgradecount; 
  dgradecount; 
  d1gradecount; 
  egradecount;
  e1gradecount;
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  att = [];
  loading = true;
  selectfiyear;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    if (this.selectfiyear == "2024-2025") {
      
      if (this.pgid == 'P1') {
        
        this.service.GetPartnerGradewiseAttendanceSummary("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: Attendancegraph[]) => {     
          this.loading = false;
          this.monthname = new Array();
          this.a1gradecount = new Array();
          this.agradecount = new Array();
          this.bgradecount = new Array();
          this.cgradecount = new Array();
          this.dgradecount = new Array();
          // this.d1gradecount = new Array();
          this.egradecount = new Array();
          this.e1gradecount = new Array();

          res.forEach(y => {

            this.monthname.push(y.month);
            this.a1gradecount.push((y.a1grade));
            this.agradecount.push((y.agrade));
            this.bgradecount.push((y.bgrade));
            this.cgradecount.push((y.cgrade));
            this.dgradecount.push((y.dgrade));
            // this.d1gradecount.push((y.d1grade));
            this.egradecount.push((y.egrade));
            this.e1gradecount.push((y.e1grade));



          });
          if (this.stdchart) { this.stdchart.destroy(); }
          this.stdchart = new Chart('attcanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.monthname,

              datasets: [

                {
                  label: 'Grade A1 (100)',
                  data: this.a1gradecount,
                  backgroundColor: "#84A59D",
                  borderColor: '#84A59D',
                  fill: true
                },
                {
                  label: 'Grade A (99>=76)',
                  data: this.agradecount,
                  backgroundColor: "#3cba54",
                  borderColor: '#3cba54',
                  fill: true
                },

                {
                  label: 'Grade B (75>= 51)',
                  data: this.bgradecount,
                  backgroundColor: "#f4c20d",
                  borderColor: '#f4c20d',
                  fill: true
                },

                {
                  label: 'Grade C (50 >= 35)',
                  data: this.cgradecount,
                  backgroundColor: "#4885ed",
                  borderColor: '#4885ed',
                  fill: true
                },

                {
                  label: 'Grade D (34 >= 1)',
                  data: this.dgradecount,
                  backgroundColor: "#8a2be2",
                  borderColor: '#8a2be2',
                  fill: true
                },

                // {
                //   label:'Grade D1 (x=0)',
                //   data: this.d1gradecount,
                //   backgroundColor: "#EAEF54",
                //   borderColor: '#EAEF54',
                //   fill: true
                // },

                {
                  label: 'Grade E (20 >= 0)',
                  data: this.egradecount,
                  backgroundColor: "#DB4437",
                  borderColor: '#DB4437',
                  fill: true
                },
                {
                  label: 'Grade E1 (Planned Working Days=0)',
                  data: this.e1gradecount,
                  backgroundColor: "#fd7e14",
                  borderColor: '#fd7e14',
                  fill: true
                }

              ],

            },
            options: {
              legend: {
                display: true,
                position: 'bottom',
              },

              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  }


                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1;
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });

      }
      else if (this.pgid == 'P22') {

        this.service.GetPartnerGradewiseAttendanceEnglishSummary("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: Attendancegraph[]) => {     
          this.loading = false;
          this.monthname = new Array();
          this.a1gradecount = new Array();
          this.agradecount = new Array();
          this.bgradecount = new Array();
          this.cgradecount = new Array();
          this.dgradecount = new Array();
          // this.d1gradecount = new Array();
          this.egradecount = new Array();
          this.e1gradecount = new Array();

          res.forEach(y => {

            this.monthname.push(y.month);
            this.a1gradecount.push((y.a1grade));
            this.agradecount.push((y.agrade));
            this.bgradecount.push((y.bgrade));
            this.cgradecount.push((y.cgrade));
            this.dgradecount.push((y.dgrade));
            // this.d1gradecount.push((y.d1grade));
            this.egradecount.push((y.egrade));
            this.e1gradecount.push((y.e1grade));

          });
          if (this.stdchart) { this.stdchart.destroy(); }
          this.stdchart = new Chart('attcanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.monthname,

              datasets: [

                {
                  label: 'Grade A1 (100)',
                  data: this.a1gradecount,
                  backgroundColor: "#84A59D",
                  borderColor: '#84A59D',
                  fill: true
                },
                {
                  label: 'Grade A (99>=76)',
                  data: this.agradecount,
                  backgroundColor: "#3cba54",
                  borderColor: '#3cba54',
                  fill: true
                },

                {
                  label: 'Grade B (75>= 51)',
                  data: this.bgradecount,
                  backgroundColor: "#f4c20d",
                  borderColor: '#f4c20d',
                  fill: true
                },

                {
                  label: 'Grade C (50 >= 35)',
                  data: this.cgradecount,
                  backgroundColor: "#4885ed",
                  borderColor: '#4885ed',
                  fill: true
                },

                {
                  label: 'Grade D (34 >= 1)',
                  data: this.dgradecount,
                  backgroundColor: "#8a2be2",
                  borderColor: '#8a2be2',
                  fill: true
                },

                // {
                //   label:'Grade D1 (x=0)',
                //   data: this.d1gradecount,
                //   backgroundColor: "#EAEF54",
                //   borderColor: '#EAEF54',
                //   fill: true
                // },

                {
                  label: 'Grade E (20 >= 0)',
                  data: this.egradecount,
                  backgroundColor: "#DB4437",
                  borderColor: '#DB4437',
                  fill: true
                },
                {
                  label: 'Grade E1 (Planned Working Days=0)',
                  data: this.e1gradecount,
                  backgroundColor: "#fd7e14",
                  borderColor: '#fd7e14',
                  fill: true
                }

              ],

            },
            options: {
              legend: {
                display: true,
                position: 'bottom',
              },

              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  }

                }],

              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1;
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      }

    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022"|| this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      // Gradewise Attendance Summary
      this.service.OLDPartnerAttendaceEnroll(this.UserId, this.selectfiyear, this.pgid).subscribe((res: Attendancegraph[]) => {
        this.loading = false;
        this.monthname = new Array();
        this.a1gradecount = new Array();
        this.agradecount = new Array();
        this.bgradecount = new Array();
        this.cgradecount = new Array();
        this.dgradecount = new Array();
        this.d1gradecount = new Array();
        this.egradecount = new Array();
        this.e1gradecount = new Array();
        



        res.forEach(y => {

          this.monthname.push(y.month);
          this.a1gradecount.push((y.a1grade));
          this.agradecount.push((y.agrade));
          this.bgradecount.push((y.bgrade));
          this.cgradecount.push((y.cgrade));
          this.dgradecount.push((y.dgrade));
          this.d1gradecount.push((y.d1grade));
          this.egradecount.push((y.egrade));
          this.e1gradecount.push((y.e1grade));



        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.monthname,

            datasets: [

              {
                label: 'Grade A1(x=100)',
                data: this.a1gradecount,
                backgroundColor: "#85C1E9",
                borderColor: '#85C1E9',
                fill: true,

              },

              {
                label: 'Grade A (99<=x<85)',
                data: this.agradecount,
                backgroundColor: "#48C9B0",
                borderColor: '#48C9B0',
                fill: true
              },

              {
                label: 'Grade B (84<= x <60)',
                data: this.bgradecount,
                backgroundColor: "#9CE7B8",
                borderColor: '#9CE7B8',
                fill: true
              },

              {
                label: 'Grade C (59<= x <= 35)',
                data: this.cgradecount,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true
              },

              {
                label: 'Grade D (34<= x <= 1)',
                data: this.dgradecount,
                backgroundColor: "#909DE2",
                borderColor: '#909DE2',
                fill: true
              },

              {
                label: 'Grade D1 (x=0)',
                data: this.d1gradecount,
                backgroundColor: "#EAEF54",
                borderColor: '#EAEF54',
                fill: true
              },

              {
                label: 'Grade E (Comp Class ActualWorkingDays=0)',
                data: this.egradecount,
                backgroundColor: "#E6C0EA",
                borderColor: '#E6C0EA',
                fill: true
              },
              {
                label: 'Grade E1 (Planned Working Days=0)',
                data: this.e1gradecount,
                backgroundColor: "#fd7e14",
                borderColor: '#fd7e14',
                fill: true
              }




            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'afsf',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
      
    }
   
  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[6].toDataURL();
    anchor.download = "GradeWiseAttendanceGraph.png";
  }

}