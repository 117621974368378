import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-atalassesment',
  templateUrl: './atalassesment.component.html',
  styleUrls: ['./atalassesment.component.css']
})
export class AtalassesmentComponent implements OnInit {
  UserId;
  pgid;
  atalsummary;
  atalsummarycount: number;
  standardlabel = new Array();
  digitallit = new Array();
  Ideation = new Array();
  designthinking = new Array();
  computationalthinking = new Array();
  physicalcomputing = new Array();
  stembasededucation = new Array();
  stackchart: any;
  value;
  totdiglit = 0;
  totideation = 0;
  totdesignthinking = 0;
  totsembased =0;
  totcomputationalthinking = 0;
  totphysicalcomputing = 0;
  searchexamtype;
  loading;
  selected;
  constructor(private service: MasterService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

    if (this.selected == '2020-2021') {
      this.searchexamtype = 'Final Exam'
    } else if(this.selected == '2021-2022'){
      this.searchexamtype = 'Endline'
    }else if(this.selected == '2022-2023'){
      this.searchexamtype = 'Baseline'
    }
    this.service.ModuleWiseATALSummary(this.searchexamtype, this.pgid, this.selected).subscribe((data: any) => {
      this.atalsummarycount = data.length;
      this.value = data;

      var totalphy = 0, totaldigi = 0, totalideation = 0,totalsembased =0 ,totaldesign = 0, totalcomp = 0;
      var lenphy = this.atalsummarycount;
      var lensemb = this.atalsummarycount;
      var lendigi = this.atalsummarycount;
      var lenidea = this.atalsummarycount;
      var lendesign = this.atalsummarycount;
      var lencomp = this.atalsummarycount;
      var valphy, valdigi, validea, valdesign, valcomp ,valsem;

      for (let i = 0; i <= this.atalsummarycount; i++) {
        if (this.value[i].physicalcomputing == 0) {
          lenphy--;
        } else {
          valphy = this.value[i].physicalcomputing;
          totalphy = totalphy + valphy;
        }
        if (this.value[i].stembasededucation == 0) {
          lensemb--;
        } else {
          valsem = this.value[i].stembasededucation;
          totalsembased = totalsembased + valsem;
        }
        if (this.value[i].digitalliteracy == 0) {
          lendigi--;
        } else {
          valdigi = this.value[i].digitalliteracy;
          totaldigi = totaldigi + valdigi;
        }
        if (this.value[i].Ideation == 0) {
          lenidea--;
        } else {
          validea = this.value[i].Ideation;
          totalideation = totalideation + validea;
        }
        
        if (this.value[i].designthinking == 0) {
          lendesign--;
        } else {
          valdesign = this.value[i].designthinking;
          totaldesign = totaldesign + valdesign;
        }
        if (this.value[i].computationalthinking == 0) {
          lencomp--;
        } else {
          valcomp = this.value[i].computationalthinking;
          totalcomp = totalcomp + valcomp;
        }
        this.totdiglit = totaldigi / lendigi;
        this.totideation = totalideation / lenidea;
        this.totsembased = totalsembased/lensemb;
        this.totdesignthinking = totaldesign / lendesign;
        this.totcomputationalthinking = totalcomp / lencomp;
        this.totphysicalcomputing = totalphy / lenphy;
      }
    });


    this.service.ModuleWiseATALSummary(this.searchexamtype, this.pgid, this.selected).subscribe((data: any) => {
      this.atalsummary = data;
      if (this.selected == '2020-2021' || this.selected == '2021-2022'){
      data.forEach(y => {
        if (y.standard != 0 || y.digitalliteracy != 0 || y.Ideation != 0 || y.designthinking != 0 || y.computationalthinking != 0 || y.physicalcomputing != 0 ||y.stembasededucation !=0) {
          this.standardlabel.push('STD ' + y.standard);
          this.digitallit.push(((y.digitalliteracy)).toFixed(2));
          this.Ideation.push(((y.Ideation)).toFixed(2));
          this.designthinking.push(((y.designthinking)).toFixed(2));
          this.computationalthinking.push(((y.computationalthinking)).toFixed(2));
          this.physicalcomputing.push(((y.physicalcomputing)).toFixed(2));
          this.stembasededucation.push(((y.stembasededucation)).toFixed(2));
          
        }


      })

      if (this.stackchart) {
        this.stackchart.destroy();
      }
      this.stackchart = new Chart('stackcanvas', {
        type: 'horizontalBar',
        data: {
          labels: this.standardlabel,
          datasets: [
            {
              label: 'Digital Literacy',
              data: this.digitallit,
              backgroundColor: '#4285F4',
              borderColor: '#4285F4',
              fill: true,

            },
            {
              label: 'STEM Based Education',
              data: this.stembasededucation,
              backgroundColor: '#3cba54',
              borderColor: '#3cba54',
              fill: true
            },

            {
              label: 'Ideation',
              data: this.Ideation,
              backgroundColor: '#F4B400',
              borderColor: '#F4B400',
              fill: true
            },

            {
              label: 'Design Thinking',
              data: this.designthinking,
              backgroundColor: '#51B8CD',
              borderColor: '#51B8CD',
              fill: true
            },

            {
              label: 'Computational Thinking',
              data: this.computationalthinking,
              backgroundColor: '#CB4335',
              borderColor: '#CB4335',
              fill: true
            },


            {
              label: 'Physical Computing',
              data: this.physicalcomputing,
              backgroundColor: '#1ABC9C',
              borderColor: '#1ABC9C',
              fill: true
            },

           

          ],

        },
        options: {
          legend: {
            display: true,
            position: 'bottom',
          },

          scales: {
            xAxes: [{ 
              display: true,
              stacked: true,
              distribution: 'series',
              scaleLabel: {
                display: true,

              }

            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Standard',
              }
            }],

          },
          plugins: {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'white'
            }
          }
        }
      });}

     else if (this.selected == '2022-2023' || this.selected == '2022-2023'){
        data.forEach(y => {
          if (y.standard != 0 || y.digitalliteracy != 0 || y.Ideation != 0 || y.designthinking != 0 || y.computationalthinking != 0 || y.physicalcomputing != 0 ||y.stembasededucation !=0) {
            this.standardlabel.push('STD ' + y.standard);
            this.digitallit.push(((y.digitalliteracy)).toFixed(2));
            this.Ideation.push(((y.Ideation)).toFixed(2));
            this.designthinking.push(((y.designthinking)).toFixed(2));
            this.computationalthinking.push(((y.computationalthinking)).toFixed(2));
            this.physicalcomputing.push(((y.physicalcomputing)).toFixed(2));
            // this.stembasededucation.push(((y.stembasededucation)).toFixed(2));
            
          }
  
  
        })
  
        if (this.stackchart) {
          this.stackchart.destroy();
        }
        this.stackchart = new Chart('stackcanvas', {
          type: 'horizontalBar',
          data: {
            labels: this.standardlabel,
            datasets: [
              {
                label: 'Digital Literacy',
                data: this.digitallit,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true,
  
              },
              // {
              //   label: 'STEM Based Education',
              //   data: this.stembasededucation,
              //   backgroundColor: '#3cba54',
              //   borderColor: '#3cba54',
              //   fill: true
              // },
  
              {
                label: 'Ideation',
                data: this.Ideation,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },
  
              {
                label: 'Design Thinking',
                data: this.designthinking,
                backgroundColor: '#51B8CD',
                borderColor: '#51B8CD',
                fill: true
              },
  
              {
                label: 'Computational Thinking',
                data: this.computationalthinking,
                backgroundColor: '#CB4335',
                borderColor: '#CB4335',
                fill: true
              },
  
  
              {
                label: 'Physical Computing',
                data: this.physicalcomputing,
                backgroundColor: '#1ABC9C',
                borderColor: '#1ABC9C',
                fill: true
              },
  
             
  
            ],
  
          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
  
            scales: {
              xAxes: [{ 
                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
  
                }
  
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',
                }
              }],
  
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });}

    });

  }


  filterrecord(searchexamtype) {
    this.totdiglit = 0;
    this.totideation = 0;
    this.totsembased = 0;

    this.totdesignthinking = 0;
    this.totcomputationalthinking = 0;
    this.totphysicalcomputing = 0;

    this.standardlabel = [];
    this.digitallit = [];
    this.Ideation = [];
    this.designthinking = [];
    this.computationalthinking = [];
    this.physicalcomputing = [];
    this.stembasededucation=[];

    this.service.ModuleWiseATALSummary(searchexamtype, this.pgid, this.selected).subscribe((data: any) => {
      this.atalsummarycount = data.length;
      this.value = data;

      var totalphy = 0, totaldigi = 0, totalideation = 0, totalsembased =0,totaldesign = 0, totalcomp = 0;
      var lenphy = this.atalsummarycount;
      var lensemb = this.atalsummarycount;
      var lendigi = this.atalsummarycount;
      var lenidea = this.atalsummarycount;
      var lendesign = this.atalsummarycount;
      var lencomp = this.atalsummarycount;
      var valphy, valdigi,valsem ,validea, valdesign, valcomp;

      for (let i = 0; i <= this.atalsummarycount; i++) {
        if (this.value[i].physicalcomputing == 0) {
          lenphy--;
        } else {
          valphy = this.value[i].physicalcomputing;
          totalphy = totalphy + valphy;
        }

        if (this.value[i].digitalliteracy == 0) {
          lendigi--;
        } else {
          valdigi = this.value[i].digitalliteracy;
          totaldigi = totaldigi + valdigi;
        }
        if (this.value[i].stembasededucation == 0) {
          lensemb--;
        } else {
          valsem = this.value[i].stembasededucation;
          totalsembased = totalsembased + valsem;
        }
        if (this.value[i].Ideation == 0) {
          lenidea--;
        } else {
          validea = this.value[i].Ideation;
          totalideation = totalideation + validea;
        }
      
        if (this.value[i].designthinking == 0) {
          lendesign--;
        } else {
          valdesign = this.value[i].designthinking;
          totaldesign = totaldesign + valdesign;
        }
        if (this.value[i].computationalthinking == 0) {
          lencomp--;
        } else {
          valcomp = this.value[i].computationalthinking;
          totalcomp = totalcomp + valcomp;
        }
        this.totdiglit = totaldigi / lendigi;
        this.totideation = totalideation / lenidea;
        this.totsembased = totalsembased/lensemb;
        this.totdesignthinking = totaldesign / lendesign;
        this.totcomputationalthinking = totalcomp / lencomp;
        this.totphysicalcomputing = totalphy / lenphy;
      }
      this.loading = false;
    });


    this.service.ModuleWiseATALSummary(searchexamtype, this.pgid, this.selected).subscribe((data1: any) => {
      this.atalsummary = data1;
      if (this.selected == '2020-2021' || this.selected == '2021-2022'){
      data1.forEach(y => {
        if (y.standard != 0 || y.digitalliteracy != 0 || y.Ideation != 0 || y.designthinking != 0 || y.computationalthinking != 0 || y.physicalcomputing != 0 || y.stembasededucation !=0) {
          this.standardlabel.push('STD ' + y.standard);
          this.digitallit.push(((y.digitalliteracy)).toFixed(2));
          this.stembasededucation.push(((y.stembasededucation)).toFixed(2));
          this.Ideation.push(((y.Ideation)).toFixed(2));
          this.designthinking.push(((y.designthinking)).toFixed(2));
          this.computationalthinking.push(((y.computationalthinking)).toFixed(2));
          this.physicalcomputing.push(((y.physicalcomputing)).toFixed(2));
         
        }


      })
      this.loading = false;

      this.stackchart = new Chart('stackcanvas', {
        type: 'horizontalBar',
        data: {
          labels: this.standardlabel,
          datasets: [
            {
              label: 'Digital Literacy',
              data: this.digitallit,
              backgroundColor: '#4285F4',
              borderColor: '#4285F4',
              fill: true,

            },
            {
              label: 'STEM Based Education',
              data: this.stembasededucation,
              backgroundColor: '#3cba54',
              borderColor: '#3cba54',
              fill: true
            },

            {
              label: 'Ideation',
              data: this.Ideation,
              backgroundColor: '#F4B400',
              borderColor: '#F4B400',
              fill: true
            },

            {
              label: 'Design Thinking',
              data: this.designthinking,
              backgroundColor: '#51B8CD',
              borderColor: '#51B8CD',
              fill: true
            },

            {
              label: 'Computational Thinking',
              data: this.computationalthinking,
              backgroundColor: '#CB4335',
              borderColor: '#CB4335',
              fill: true
            },


            {
              label: 'Physical Computing',
              data: this.physicalcomputing,
              backgroundColor: '#1ABC9C',
              borderColor: '#1ABC9C',
              fill: true
            },

           

          ],

        },
        options: {
          legend: {
            display: true,
            position: 'bottom',
          },

          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
              scaleLabel: {
                display: true,

              }

            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Standard',
              }


            }],

          },
          plugins: {
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'white'
            }
          }
        }
      });}

      else if (this.selected == '2022-2023'){
        data1.forEach(y => {
          if (y.standard != 0 || y.digitalliteracy != 0 || y.Ideation != 0 || y.designthinking != 0 || y.computationalthinking != 0 || y.physicalcomputing != 0 || y.stembasededucation !=0) {
            this.standardlabel.push('STD ' + y.standard);
            this.digitallit.push(((y.digitalliteracy)).toFixed(2));
            // this.stembasededucation.push(((y.stembasededucation)).toFixed(2));
            this.Ideation.push(((y.Ideation)).toFixed(2));
            this.designthinking.push(((y.designthinking)).toFixed(2));
            this.computationalthinking.push(((y.computationalthinking)).toFixed(2));
            this.physicalcomputing.push(((y.physicalcomputing)).toFixed(2));
           
          }
  
  
        })
        this.loading = false;
  
        this.stackchart = new Chart('stackcanvas', {
          type: 'horizontalBar',
          data: {
            labels: this.standardlabel,
            datasets: [
              {
                label: 'Digital Literacy',
                data: this.digitallit,
                backgroundColor: '#4285F4',
                borderColor: '#4285F4',
                fill: true,
  
              },
              // {
              //   label: 'STEM Based Education',
              //   data: this.stembasededucation,
              //   backgroundColor: '#3cba54',
              //   borderColor: '#3cba54',
              //   fill: true
              // },
  
              {
                label: 'Ideation',
                data: this.Ideation,
                backgroundColor: '#F4B400',
                borderColor: '#F4B400',
                fill: true
              },
  
              {
                label: 'Design Thinking',
                data: this.designthinking,
                backgroundColor: '#51B8CD',
                borderColor: '#51B8CD',
                fill: true
              },
  
              {
                label: 'Computational Thinking',
                data: this.computationalthinking,
                backgroundColor: '#CB4335',
                borderColor: '#CB4335',
                fill: true
              },
  
  
              {
                label: 'Physical Computing',
                data: this.physicalcomputing,
                backgroundColor: '#1ABC9C',
                borderColor: '#1ABC9C',
                fill: true
              },
  
             
  
            ],
  
          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
  
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
  
                }
  
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Standard',
                }
  
  
              }],
  
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'white'
              }
            }
          }
        });}
    });
  }
  ngOnInit(): void {
  }

}
