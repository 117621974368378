
import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Stateenroll } from 'app/ClassFiles/stateenroll';
import { Chart } from 'chart.js';
import { Attendancegraph } from 'app/ClassFiles/attendancegraph';
import { Enrollcity } from 'app/ClassFiles/enrollcity';
declare var pdfMake: any;

@Component({
  selector: 'app-citygraph',
  templateUrl: './citygraph.component.html',
  styleUrls: ['./citygraph.component.css']
})

export class CitygraphComponent implements OnInit {
  
  loading = true;
  cityEnrollgraph;
  docDefinition: any;
  centercount = 0;
  schoolcount = 0;
  studentcount = 0;
  sancharakcount = 0;
  computerscount = 0;
  totalcount = 0; 
  othercount = 0; 
  Oriyacount = 0; Bengalicount = 0; 
  Urducount = 0; 
  Telgucount = 0; 
  Marathicount = 0; 
  Kananndcount = 0; 
  Hindicount = 0; 
  Englishcount = 0; 
  Gujaraticount = 0;
  centercountt = 0;
  schoolcountt = 0;
  studentcountt = 0;
  sancharakcountt = 0;
  computerscountt = 0;
  totalcountt = 0; 
  othercountt = 0; 
  Oriyacountt = 0; 
  Bengalicountt = 0; 
  Urducountt = 0; 
  Telgucountt = 0; 
  Marathicountt = 0; 
  Kananndcountt = 0; 
  Hindicountt = 0; 
  Englishcountt = 0; 
  Gujaraticountt = 0;

  public pieChartLabels: Array<string> = ['Boys', 'Girls'];
  public pieChartType: string;
  public pieChartData: any[];
  pieChartColor: any = [
    {
      backgroundColor: ['#8DC1EE',
        '#F95ADA',]
    }
  ]
  public piechartOptions: any = {
    responsive: true,

  };
  StdCentWise;
  public myChart: Chart;
  stdname;
  stdCount;
  gendersummary;
  UserId;
  centid;
  selected;
  statecount;
  Boyscount;
  Girlscount;
  StateEnroll;
  public chart: Chart; public chartt: Chart;
  dept = []; private value; private valuee;
  cityEnroll;
  public citychart: Chart;
  citycount;
  cenroll = []; att = [];
  public stdchart: Chart;
  monthname;
  a1gradecount;
  agradecount;
  bgradecount;
  cgradecount;
  dgradecount;
  d1gradecount;
  egradecount;
  public barChartType: string;
  public barChartType1: string;
  labels1 = [];
  chartOptions1 = {
    responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
  }
  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  chartData1 = [
    {
      label: [],
      data: [],

    }
  ];

  // CHART COLOR.
  colors1 = [
    {
      backgroundColor: '#8DC1EE'
    },
    {
      backgroundColor: '#F95ADA'
    }

  ]
  dataobj1;
  LangDataGraph;
  labels = [];
  chartOptions = {
    responsive: true    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
  }
  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  chartData = [
    {
      label: [],
      data: [],
    }
  ];
  SchoolLangDataGraph;
  // CHART COLOR.
  colors = [
    {
      backgroundColor: '#8DC1EE'
    },
    {
      backgroundColor: '#F95ADA'
    }

  ]
  dataobj; 
  pgid; 
  selectfiyear;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    //this.selected = localStorage.getItem('academicyear');
    this.selectfiyear = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');

    if (this.selectfiyear == "2024-2025") {
      if (this.UserId == 'PT47') {
        //Citywise Enrolment Summary ATAL
        this.service.GetPartnerCitywiseEnrollmentSummaryATAL("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
          this.cityEnroll = res;
          this.value = this.cityEnroll
          this.loading = false;
          for (let j = 0; j < this.cityEnroll.length; j++) {
            this.centercountt += this.value[j].center;
            this.studentcountt += this.value[j].student;
            this.schoolcountt += this.value[j].school;

            this.sancharakcountt += this.value[j].sancharak;
            this.computerscountt += this.value[j].computers;


          }
          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();

          res.forEach(y => {

            this.statecount.push(y.city);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));


          });
          if (this.chartt) { this.chartt.destroy(); }
          this.chartt = new Chart('citycanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.statecount,

              datasets: [


                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#8DC1EE",
                  borderColor: '#8DC1EE',
                  fill: true,

                },

                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#F95ADA",
                  borderColor: '#F95ADA',
                  fill: true
                }

              ],

            },
            options: {

              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',


                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  scaleLabel: {
                    display: true,
                    labelString: "Count",

                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      } 
      else if (this.UserId == 'PT30') {
        this.service.GetPartnerCitywiseEnrollmentSummaryDP("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
          this.cityEnroll = res;
          this.value = this.cityEnroll
          this.loading = false;
          for (let j = 0; j < this.cityEnroll.length; j++) {
            this.centercountt += this.value[j].center;
            this.studentcountt += this.value[j].student;
            this.schoolcountt += this.value[j].school;

            this.sancharakcountt += this.value[j].sancharak;
            this.computerscountt += this.value[j].computers;


          }
          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();

          res.forEach(y => {

            this.statecount.push(y.city);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));


          });
          if (this.chartt) { this.chartt.destroy(); }
          this.chartt = new Chart('citycanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.statecount,

              datasets: [


                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#8DC1EE",
                  borderColor: '#8DC1EE',
                  fill: true,

                },

                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#F95ADA",
                  borderColor: '#F95ADA',
                  fill: true
                }

              ],

            },
            options: {

              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',


                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  scaleLabel: {
                    display: true,
                    labelString: "Count",

                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      }
      else if (this.UserId == 'PT40') {
        
        if (this.pgid == 'P1') {
          this.service.GetPartnerCitywiseEnrollmentSummary("where PartnerWiseEnrollmentSummary.partnerid='" + this.UserId + "' and PartnerWiseEnrollmentSummary.pgid='" + this.pgid + "' and #stud.city not in ('Bangalore')").subscribe((res: Enrollcity[]) => {
            this.value = this.cityEnroll
            this.loading = false;
            for (let j = 0; j < this.cityEnroll.length; j++) {
              this.centercountt += this.value[j].center;
              this.studentcountt += this.value[j].student;
              this.schoolcountt += this.value[j].school;

              this.sancharakcountt += this.value[j].sancharak;
              this.computerscountt += this.value[j].computers;


            }
            this.statecount = new Array();
            this.Boyscount = new Array();
            this.Girlscount = new Array();

            res.forEach(y => {

              this.statecount.push(y.city);
              this.Boyscount.push((y.boys));
              this.Girlscount.push((y.girls));


            });
            if (this.chartt) { this.chartt.destroy(); }
            this.chartt = new Chart('citycanvas', {
              type: 'bar',
              //responsive: true,
              data: {
                labels: this.statecount,

                datasets: [


                  {
                    label: 'Total Boys',
                    data: this.Boyscount,
                    backgroundColor: "#8DC1EE",
                    borderColor: '#8DC1EE',
                    fill: true,

                  },

                  {
                    label: 'Total Girls',
                    data: this.Girlscount,
                    backgroundColor: "#F95ADA",
                    borderColor: '#F95ADA',
                    fill: true
                  }

                ],

              },
              options: {

                legend: {
                  display: true,
                  position: 'bottom',

                },

                scales: {
                  xAxes: [{

                    display: true,
                    stacked: true,
                    distribution: 'series',


                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,

                    scaleLabel: {
                      display: true,
                      labelString: "Count",

                    }
                  }],
                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'black',
                    font: {
                      size: 8,
                    }
                  }
                }
              }
            });
          });
        }
        else {
          this.service.GetPartnerCitywiseEnrollmentSummaryATAL("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
            this.cityEnroll = res;
            this.value = this.cityEnroll
            this.loading = false;
            for (let j = 0; j < this.cityEnroll.length; j++) {
              this.centercountt += this.value[j].center;
              this.studentcountt += this.value[j].student;
              this.schoolcountt += this.value[j].school;

              this.sancharakcountt += this.value[j].sancharak;
              this.computerscountt += this.value[j].computers;


            }
            this.statecount = new Array();
            this.Boyscount = new Array();
            this.Girlscount = new Array();

            res.forEach(y => {

              this.statecount.push(y.city);
              this.Boyscount.push((y.boys));
              this.Girlscount.push((y.girls));


            });
            if (this.chartt) { this.chartt.destroy(); }
            this.chartt = new Chart('citycanvas', {
              type: 'bar',
              //responsive: true,
              data: {
                labels: this.statecount,

                datasets: [


                  {
                    label: 'Total Boys',
                    data: this.Boyscount,
                    backgroundColor: "#8DC1EE",
                    borderColor: '#8DC1EE',
                    fill: true,

                  },

                  {
                    label: 'Total Girls',
                    data: this.Girlscount,
                    backgroundColor: "#F95ADA",
                    borderColor: '#F95ADA',
                    fill: true
                  }

                ],

              },
              options: {

                legend: {
                  display: true,
                  position: 'bottom',

                },

                scales: {
                  xAxes: [{

                    display: true,
                    stacked: true,
                    distribution: 'series',


                  }],
                  yAxes: [{
                    display: true,
                    stacked: true,

                    scaleLabel: {
                      display: true,
                      labelString: "Count",

                    }
                  }],
                },
                plugins: {
                  datalabels: {
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                    },
                    align: 'center',
                    anchor: 'center',
                    color: 'black',
                    font: {
                      size: 8,
                    }
                  }
                }
              }
            });
          });
        }
      }
      else {
        // Citywise Enrolment Summary
        //
        this.service.GetPartnerCitywiseEnrollmentSummary("where PartnerWiseEnrollmentSummary.partnerid='" + this.UserId + "' and PartnerWiseEnrollmentSummary.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
          // this.service.SahyogiCityWiseData(this.UserId, this.selectfiyear, this.pgid).subscribe((res: Enrollcity[]) => {
          this.cityEnroll = res;
          this.value = this.cityEnroll
          this.loading = false;
          for (let j = 0; j < this.cityEnroll.length; j++) {
            this.centercountt += this.value[j].center;
            this.studentcountt += this.value[j].student;
            this.schoolcountt += this.value[j].school;
            this.sancharakcountt += this.value[j].sancharak;
            this.computerscountt += this.value[j].computers;
          }
        });
        this.service.GetPartnerCitywiseEnrollmentSummaryGraph("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
          
          this.cityEnrollgraph = res;
          this.value = this.cityEnroll
          this.loading = false;
          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();
          res.forEach(y => {
            this.statecount.push(y.city);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));
          });
          if (this.chartt) { this.chartt.destroy(); }
          this.chartt = new Chart('citycanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.statecount,
              datasets: [
                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#8DC1EE",
                  borderColor: '#8DC1EE',
                  fill: true,
                },
                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#F95ADA",
                  borderColor: '#F95ADA',
                  fill: true
                }
              ],
            },
            options: {
              legend: {
                display: true,
                position: 'bottom',
              },
              scales: {
                xAxes: [{
                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      }
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022"|| this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      // Citywise Enrolment Summary
      this.service.SahyogiCityWiseData(this.UserId, this.selectfiyear, this.pgid).subscribe((res: Enrollcity[]) => {
        this.cityEnroll = res;
        this.value = this.cityEnroll
        this.loading = false;
        for (let j = 0; j < this.cityEnroll.length; j++) {
          this.centercountt += parseInt(this.value[j].center);
          this.studentcountt += parseInt(this.value[j].student);
          this.schoolcountt += parseInt(this.value[j].school);

          this.sancharakcountt += parseInt(this.value[j].sancharak);
          this.computerscountt += parseInt(this.value[j].computers);
        }
        this.statecount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.statecount.push(y.city);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));


        });
        if (this.chartt) { this.chartt.destroy(); }
        this.chartt = new Chart('citycanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.statecount,

            datasets: [

              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#8DC1EE",
                borderColor: '#8DC1EE',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#F95ADA",
                borderColor: '#F95ADA',
                fill: true
              }


            ],


          },
          options: {

            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });

    }
    else if (this.selectfiyear == null) {

      if (this.UserId == 'PT47') {
        //Citywise Enrolment Summary ATAL
        this.service.GetPartnerCitywiseEnrollmentSummaryATAL("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
          this.cityEnroll = res;
          this.value = this.cityEnroll
          this.loading = false;
          for (let j = 0; j < this.cityEnroll.length; j++) {
            this.centercountt += this.value[j].center;
            this.studentcountt += this.value[j].student;
            this.schoolcountt += this.value[j].school;

            this.sancharakcountt += this.value[j].sancharak;
            this.computerscountt += this.value[j].computers;


          }
          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();

          res.forEach(y => {

            this.statecount.push(y.city);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));


          });
          if (this.chartt) { this.chartt.destroy(); }
          this.chartt = new Chart('citycanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.statecount,

              datasets: [


                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#8DC1EE",
                  borderColor: '#8DC1EE',
                  fill: true,

                },

                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#F95ADA",
                  borderColor: '#F95ADA',
                  fill: true
                }

              ],

            },
            options: {

              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',


                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  scaleLabel: {
                    display: true,
                    labelString: "Count",

                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      } 
      else if (this.UserId == 'PT30') {
        this.service.GetPartnerCitywiseEnrollmentSummaryDP("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
          this.cityEnroll = res;
          this.value = this.cityEnroll
          this.loading = false;
          for (let j = 0; j < this.cityEnroll.length; j++) {
            this.centercountt += this.value[j].center;
            this.studentcountt += this.value[j].student;
            this.schoolcountt += this.value[j].school;

            this.sancharakcountt += this.value[j].sancharak;
            this.computerscountt += this.value[j].computers;


          }
          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();

          res.forEach(y => {

            this.statecount.push(y.city);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));


          });
          if (this.chartt) { this.chartt.destroy(); }
          this.chartt = new Chart('citycanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.statecount,

              datasets: [


                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#8DC1EE",
                  borderColor: '#8DC1EE',
                  fill: true,

                },

                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#F95ADA",
                  borderColor: '#F95ADA',
                  fill: true
                }

              ],

            },
            options: {

              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',


                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  scaleLabel: {
                    display: true,
                    labelString: "Count",

                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      } 
      else {
        //Citywise Enrolment Summary
        this.service.GetPartnerCitywiseEnrollmentSummary("where PartnerWiseEnrollmentSummary.partnerid='" + this.UserId + "' and PartnerWiseEnrollmentSummary.pgid='" + this.pgid + "'").subscribe((res: Enrollcity[]) => {
          // this.service.SahyogiCityWiseData(this.UserId, this.selectfiyear, this.pgid).subscribe((res: Enrollcity[]) => {
          this.cityEnroll = res;
          this.value = this.cityEnroll
          this.loading = false;
          for (let j = 0; j < this.cityEnroll.length; j++) {
            this.centercountt += this.value[j].center;
            this.studentcountt += this.value[j].student;
            this.schoolcountt += this.value[j].school;

            this.sancharakcountt += this.value[j].sancharak;
            this.computerscountt += this.value[j].computers;


          }
          this.statecount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();

          res.forEach(y => {

            this.statecount.push(y.city);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));


          });
          if (this.chartt) { this.chartt.destroy(); }
          this.chartt = new Chart('citycanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.statecount,

              datasets: [


                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#8DC1EE",
                  borderColor: '#8DC1EE',
                  fill: true,

                },

                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#F95ADA",
                  borderColor: '#F95ADA',
                  fill: true
                }

              ],

            },
            options: {

              legend: {
                display: true,
                position: 'bottom',

              },

              scales: {
                xAxes: [{

                  display: true,
                  stacked: true,
                  distribution: 'series',


                }],
                yAxes: [{
                  display: true,
                  stacked: true,

                  scaleLabel: {
                    display: true,
                    labelString: "Count",

                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
      }

    }

  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }

  downloadcitycanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[1].toDataURL();
    anchor.download = "CityGraph.png";
  }

}