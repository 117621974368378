import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import 'chart.piecelabel.js';
@Component({
  selector: 'app-pifoverallgendergraph',
  templateUrl: './pifoverallgendergraph.component.html',
  styleUrls: ['./pifoverallgendergraph.component.css']
})
export class PifoverallgendergraphComponent implements OnInit {

  UserId;
  centid;
  selected;
  pgid;
  loading = true;
  gendersummary;
  totgirls = 0;
  totboys = 0;
  totstudent = 0;
  public pieChartLabels: Array<string> = ['Boys', 'Girls'];

  public pieChartData: any[];
  pieChartColor: any = [
    {
      backgroundColor: ['#A7E8F1', '#F1A7E3']
    }
  ]
  public piechartOptions: any = {
    pieceLabel: {
      render: function (args) {
        const label = args.label,
          value = args.value;
        return value;
      }
    }

  };
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;


  selectfiyear;
  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    //this.selected = localStorage.getItem('academicyear');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');


    if (this.selectfiyear == "2022-2023") {
      // gender wise summary new data
      service.GetPartnerGenderEnrollmentSummary("where center.pgid in('P1','P18','P9') and centerpartner.status=0").subscribe((res: any) => {
        this.gendersummary = res;

        for (let j = 0; j < this.gendersummary.length; j++) {
          this.totgirls += this.gendersummary[j].girls;
          this.totboys += this.gendersummary[j].boys;
          this.totstudent += this.gendersummary[j].student;

        }


        this.pieChartData = [
          ((this.totboys * 100) / (this.totstudent)).toFixed(2)
          , ((this.totgirls * 100) / (this.totstudent)).toFixed(2)];
        this.loading = false;


      })
    }

    if (this.selectfiyear == "2021-2022") {
      
      // gender wise summary new data
      this.service.SahyogiCALDataPIF(this.selectfiyear).subscribe((res: any) => {
        this.gendersummary = res;

        for (let j = 0; j < this.gendersummary.length; j++) {
          this.totgirls += this.gendersummary[j].girls;
          this.totboys += this.gendersummary[j].boys;
          this.totstudent += this.gendersummary[j].student;

        }


        this.pieChartData = [
          ((this.totboys * 100) / (this.totstudent)).toFixed(2)
          , ((this.totgirls * 100) / (this.totstudent)).toFixed(2)];
        this.loading = false;


      })
    }

    else if (this.selectfiyear == null) {

      this.selectfiyear = "2021-2022";
      // gender wise summary new data
      service.GetPartnerGenderEnrollmentSummary("where center.pgid in('P1','P18','P9') and centerpartner.status=0").subscribe((res: any) => {
        console.log(res);

        this.gendersummary = res;
        console.log('Gendersummary' + this.gendersummary);

        for (let j = 0; j < this.gendersummary.length; j++) {
          this.totgirls += this.gendersummary[j].girls;
          this.totboys += this.gendersummary[j].boys;
          this.totstudent += this.gendersummary[j].student;

        }

        this.pieChartData = [
          ((this.totboys * 100) / (this.totstudent)).toFixed(2)
          , ((this.totgirls * 100) / (this.totstudent)).toFixed(2)];
        this.loading = false;

      })
    }
  }
  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }
  downloadCanvasss(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[2].toDataURL();
    anchor.download = "GenderGraph.png";
  }
}