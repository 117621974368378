import { Component, Input, OnChanges } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-gujarati10',
  templateUrl: './gujarati10.component.html',
  styleUrls: ['./gujarati10.component.css']
})
export class Gujarati10Component implements OnChanges {
  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  // @Input('language')Language; 
   loading = true;
   Partnerid;
  pgid;
  selected;
 
  BaselineStandard:any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  compentencies;
  Baselinestd6to12;
  Baselinestd6to12count;
  totapp;

   constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }

  ngOnChanges() {
    this.service.SahyogiEvalCompetency(this.Partnerid,this.selected,this.pgid,this.Examtype,this.Standard).subscribe((data:any) => {
      this.totapp = data[0].totalappeared;
      data.map((obj) => {
        obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
        obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
        obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
        obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
      })
      this.Baselinestd6to12 = data;
      
      // console.log( 'gujarati10'+JSON.stringify(this.Baselinestd6to12));
      this.Baselinestd6to12count = data.length;
      this.loading = false;      
      this.compentencies = new Array();
      data.forEach(y => {
         this.compentencies.push(y.C1);
         this.compentencies.push(y.C2);
         this.compentencies.push(y.C3);
         this.compentencies.push(y.C4);
        
       })
 
       var graph = document.getElementById("graph");
       if(data.length != 0){
         graph.style.display = "block";
       this.BaselineStandard = new Chart('gujaratiStandard10', {
         type: 'bar',
         data: {
          labels: ['Coding(Beginner)','Coding(Basic)' ,'Coding(Intermediate)','Coding(Advance)'],

           datasets: [
             {
               label: "Compentencies",
               backgroundColor: '#f4c20d',
               borderColor: '#f4c20d',
               data: this.compentencies
             },
           ]
         },
         options: {
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
           title: {
             display: true,
             text: this.Examtype +'Standard'+ this.Standard
           },
           plugins: {
             datalabels: {
               display: function (context) {
                 return context.dataset.data[context.dataIndex] >= 1;
               },
               align: 'center',
               anchor: 'center',
               color: 'black',
               font: {
                 size: 14,
               }
             }
           }
         } 
       })
      }
      else {
        graph.style.display = "none";
  
      }
      this.loading = false;
     })
   }

   downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard+"th Standard Endline Summary.png";
  }

}

