import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Attendancegraph } from 'app/ClassFiles/attendancegraph';
import { Boygirlsstackgraph } from 'app/ClassFiles/boygirlsstackgraph';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-pifcentergraph',
  templateUrl: './pifcentergraph.component.html',
  styleUrls: ['./pifcentergraph.component.css']
})
export class PifcentergraphComponent implements OnInit {
  public pieChartLabels2: Array<string> = ['Boys', 'Girls'];
  public pieChartType2: string;
  public pieChartData2: any[];
  pieChartColor2: any = [
    {
      backgroundColor: ['#F18AE0',
        '#8DEEDB',
      ]
    }
  ]
  public piechartOptions2: any = {
    responsive: true,
    pieceLabel: {
      render: function (args) {
        const label = args.label,
          value = args.value;
        return value;
      }
    }
  };
  GetPartnerLangCentWiseGraph;
  dept = []; att = [];
  public chart: Chart;
  schoolcount;
  Boyscount;
  loading = true; 
  Girlscount;
  stdname; name;
  stdCount; Count;
  centid;
  StateCentWise;
  StdCentWise;
  langname; LangCentWise;
  langCount; state;
  egradecount; d1gradecount; dgradecount; cgradecount; bgradecount; agradecount; a1gradecount; monthname;

  public stdchart: Chart;
  public barChartType: string;
  public barChartType1: string;
  labels = [];
  chartOptions = {
    plugins: {
      responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        align: 'center',
        anchor: 'center',
        color: 'black',
        font: {
          size: 8,
        }
      }
    }
  }
  // STATIC DATA FOR THE CHART IN JSON FORMAT.
  chartData = [
    {
      data: [],
      label: [],
    }
  ];

  // CHART COLOR.
  colors = [
    {
      backgroundColor: '#5690F5'
    },
    {
      backgroundColor: '#F3D73A'
    }
    ,
    {
      backgroundColor: '#F05EC6'
    }
    ,
    {
      backgroundColor: '#F34346'
    }

  ]
  dataobj;
  public myChart: Chart;
  public myCharttt: Chart;
  attgraph
  selected; UserId; pgid; selectfiyear; pgname;
  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    this.selected = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');
    if (this.selected == "2022-2023") {
      // Center Wise Summary graph with table center
      this.service.GetPartnerStateCentWise("where center.centid='" + this.centid + "'").subscribe((res: Boygirlsstackgraph[]) => {


        if (res.length == 0) {
          this.StateCentWise = 0;

        }
        else {
          this.StateCentWise = res;

        }


        this.schoolcount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.schoolcount.push(y.centname);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));

        });
        if (this.chart) { this.chart.destroy(); }
        this.chart = new Chart('canvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.schoolcount,

            datasets: [

              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              }


            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true, 
                distribution: 'series',
              }],

              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        }); 
      });

      // standard summary
      this.service.GetPartnerStdCentWise("where center.centid='" + this.centid + "'").subscribe((res: any) => {
        this.StdCentWise = res;
        this.stdname = new Array();
        this.stdCount = new Array();

        let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
          totFifth = 0, totSixth = 0, totSeventh = 0
          , totEighth = 0, totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;

        res.forEach(
          y => {
            [

              totFirst += (y.First),
              totSecond += (y.Second),
              totThird += (y.Third),
              totFourth += (y.Fourth),
              totFifth += (y.Fifth),
              totSixth += (y.Sixth),
              totSeventh += (y.Seventh),
              totEighth += (y.Eighth),
              totNinth += (y.Ninth),
              totTenth += (y.Tenth),
              totEleventh += (y.Eleventh),
              totTwelth += (y.Twelth),


            ];
          });

        this.stdCount.push(totFirst);
        this.stdname.push('1st');
        this.stdCount.push(totSecond);
        this.stdname.push('2nd');
        this.stdCount.push(totThird);
        this.stdname.push('3rd');
        this.stdCount.push(totFourth);
        this.stdname.push('4th');
        this.stdCount.push(totFifth);
        this.stdname.push('5th');
        this.stdCount.push(totSixth);
        this.stdname.push('6th');
        this.stdCount.push(totSeventh);
        this.stdname.push('7th');
        this.stdCount.push(totEighth);
        this.stdname.push('8th');
        this.stdCount.push(totNinth);
        this.stdname.push('9th');
        this.stdCount.push(totTenth);
        this.stdname.push('10th');
        this.stdCount.push(totEleventh);
        this.stdname.push('11th');
        this.stdCount.push(totTwelth);
        this.stdname.push('12th');


        if (this.myChart) { this.myChart.destroy(); }
        this.myChart = new Chart('canvass', {

          type: 'bar',
          data: {
            labels: this.stdname,
            datasets: [
              {
                label: 'Standard',
                data: this.stdCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,

              }
            ],

          },

          options: {
            legend: {
              display: true,
              position: 'bottom',

            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                anchor: "center",
                align: "center",
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
      // gender summary
      this.service.GetPartnerStateCentWise("where center.centid='" + this.centid + "'").subscribe((res: any) => {
        this.pieChartData2 = [
          ((res[0].boys * 100) / (res[0].boys + res[0].girls)).toFixed(2)
          , ((res[0].girls * 100) / (res[0].boys + res[0].girls)).toFixed(2)];
      });

      // Gradewise Attendance Summary
      this.service.GetPartnerGradewiseCenterAttendance("where centid='" + this.centid + "'").subscribe((res: Attendancegraph[]) => {
        this.monthname = new Array();
        this.a1gradecount = new Array();
        this.agradecount = new Array();
        this.bgradecount = new Array();
        this.cgradecount = new Array();
        this.dgradecount = new Array();
        this.d1gradecount = new Array();
        this.egradecount = new Array();

        res.forEach(y => {
          this.monthname.push(y.month);
          this.a1gradecount.push((y.a1grade));
          this.agradecount.push((y.agrade));
          this.bgradecount.push((y.bgrade));
          this.cgradecount.push((y.cgrade));
          this.dgradecount.push((y.dgrade));
          this.d1gradecount.push((y.d1grade));
          this.egradecount.push((y.egrade));
        });

        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.monthname,
            datasets: [
              // {
              //   label: 'Grade A1(x=100)',
              //   data: this.a1gradecount,
              //   backgroundColor: "#85C1E9",
              //   borderColor: '#85C1E9',
              //   fill: true,
              // },
              {
                label: 'Grade A (100>=76)',
                data: this.agradecount,
                backgroundColor: "#3cba54",
                borderColor: '#3cba54',
                fill: true
              },
              {
                label: 'Grade B (75>= 51)',
                data: this.bgradecount,
                backgroundColor: "#f4c20d",
                borderColor: '#f4c20d',
                fill: true
              },
              {
                label: 'Grade C (50 >= 35)',
                data: this.cgradecount,
                backgroundColor: "#4885ed",
                borderColor: '#4885ed',
                fill: true
              },
              {
                label: 'Grade D (34 >= 1)',
                data: this.dgradecount,
                backgroundColor: "#8a2be2",
                borderColor: '#8a2be2',
                fill: true
              },
              // {
              //   label: 'Grade D1 (x=0)',
              //   data: this.d1gradecount,
              //   backgroundColor: "#EAEF54",
              //   borderColor: '#EAEF54',
              //   fill: true
              // },
              {
                label: 'Grade E (20 >= 0)',
                data: this.egradecount,
                backgroundColor: "#DB4437",
                borderColor: '#DB4437',
                fill: true
              }
            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
      
      // language
      this.service.GetPartnerLangCentWiseGraph("where Center.centid='" + this.centid + "'").subscribe((res: any) => {
          this.GetPartnerLangCentWiseGraph = res;
          this.loading = false;
          this.chartData = [];
          this.dataobj = res.data;

          for (let i = 0; i < this.dataobj.length; i++) {
            this.chartData.push({ data: this.dataobj[i].LanguageCount, label: this.dataobj[i].LanguageName });
          }
          this.labels = res.state;
        });

      service.GetPartnerLangCentWise("where Center.centid='" + this.centid + "'").subscribe((data: any) => {
        this.LangCentWise = data;

      })

    }
    else if (this.selected == "2017-2018" || this.selected == "2018-2019" || this.selected == "2019-2020" || this.selected == "2020-2021" || this.selected == "2021-2022") {
      // language
      this.service.OLDPartnerLangCentWiseGraphPIF(this.selected, this.centid).subscribe((res: any) => {
        this.chartData = [];
        this.dataobj = res.data;

        for (let i = 0; i < this.dataobj.length; i++) {
          this.chartData.push({ data: this.dataobj[i].LanguageCount, label: this.dataobj[i].LanguageName });
        }
        this.labels = res.state;
      });

      service.OLDPartnerLangCentWisetablePIF(this.selected, this.centid).subscribe((data: any) => {
        this.LangCentWise = data;
        this.loading = false;
      })

      // center detail graph with table
      this.service.SahyogiOLDCALCenterDataPIF(this.selected, this.centid).subscribe((res: Boygirlsstackgraph[]) => {
        this.StateCentWise = res;
        this.schoolcount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.schoolcount.push(y.centname);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));


        });
        if (this.chart) { this.chart.destroy(); }
        this.chart = new Chart('canvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.schoolcount,

            datasets: [

              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              }


            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'afsf',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
    
      // gender wise graph with table
      this.service.SahyogiOLDCALCenterDataPIF(this.selected, this.centid).subscribe((res: any) => {
        this.pieChartData2 = [
          ((res[0].boys * 100) / (res[0].boys + res[0].girls)).toFixed(2)
          , ((res[0].girls * 100) / (res[0].boys + res[0].girls)).toFixed(2)];
      })

  
      // standard summary
      this.service.OLDPartnerStdCentWisePIF(this.selected, this.centid).subscribe((res: any) => {
        this.StdCentWise = res;
        this.stdname = new Array();
        this.stdCount = new Array();

        let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
          totFifth = 0, totSixth = 0, totSeventh = 0
          , totEighth = 0, totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;

        res.forEach(
          y => {
            [

              totFirst += (y.First),
              totSecond += (y.Second),
              totThird += (y.Third),
              totFourth += (y.Fourth),
              totFifth += (y.Fifth),
              totSixth += (y.Sixth),
              totSeventh += (y.Seventh),
              totEighth += (y.Eighth),
              totNinth += (y.Ninth),
              totTenth += (y.Tenth),
              totEleventh += (y.Eleventh),
              totTwelth += (y.Twelth),


            ];
          });

        this.stdCount.push(totFirst);
        this.stdname.push('1st');
        this.stdCount.push(totSecond);
        this.stdname.push('2nd');
        this.stdCount.push(totThird);
        this.stdname.push('3rd');
        this.stdCount.push(totFourth);
        this.stdname.push('4th');
        this.stdCount.push(totFifth);
        this.stdname.push('5th');
        this.stdCount.push(totSixth);
        this.stdname.push('6th');
        this.stdCount.push(totSeventh);
        this.stdname.push('7th');
        this.stdCount.push(totEighth);
        this.stdname.push('8th');
        this.stdCount.push(totNinth);
        this.stdname.push('9th');
        this.stdCount.push(totTenth);
        this.stdname.push('10th');
        this.stdCount.push(totEleventh);
        this.stdname.push('11th');
        this.stdCount.push(totTwelth);
        this.stdname.push('12th');


        if (this.myChart) { this.myChart.destroy(); }
        this.myChart = new Chart('canvass', {
          type: 'bar',
          data: {
            labels: this.stdname,
            datasets: [
              {
                label: 'Standard',
                data: this.stdCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,

              }
            ],

          },

          options: {
            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: "Count",


                }
              }],
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
      // Gradewise Attendance Summary

      this.service.OLDPartnerAttendaceCentWisePIF(this.selected, this.centid).subscribe((res: Attendancegraph[]) => {
        this.monthname = new Array();
        this.a1gradecount = new Array();
        this.agradecount = new Array();
        this.bgradecount = new Array();
        this.cgradecount = new Array();
        this.dgradecount = new Array();
        this.d1gradecount = new Array();
        this.egradecount = new Array();


        res.forEach(y => {

          this.monthname.push(y.month);
          this.a1gradecount.push((y.a1grade));
          this.agradecount.push((y.agrade));
          this.bgradecount.push((y.bgrade));
          this.cgradecount.push((y.cgrade));
          this.dgradecount.push((y.dgrade));
          this.d1gradecount.push((y.d1grade));
          this.egradecount.push((y.egrade));
        });

        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.monthname,
            datasets: [
              {
                label: 'Grade A1(x=100)',
                data: this.a1gradecount,
                backgroundColor: "#85C1E9",
                borderColor: '#85C1E9',
                fill: true,
              },
              {
                label: 'Grade A (99<=x<85)',
                data: this.agradecount,
                backgroundColor: "#48C9B0",
                borderColor: '#48C9B0',
                fill: true
              },
              {
                label: 'Grade B (84<= x <60)',
                data: this.bgradecount,
                backgroundColor: "#9CE7B8",
                borderColor: '#9CE7B8',
                fill: true
              },
              {
                label: 'Grade C (59<= x <= 35)',
                data: this.cgradecount,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true
              },
              {
                label: 'Grade D (34<= x <= 1)',
                data: this.dgradecount,
                backgroundColor: "#909DE2",
                borderColor: '#909DE2',
                fill: true
              },
              {
                label: 'Grade D1 (x=0)',
                data: this.d1gradecount,
                backgroundColor: "#EAEF54",
                borderColor: '#EAEF54',
                fill: true
              },
              {
                label: 'Grade E (Comp Class ActualWorkingDays=0)',
                data: this.egradecount,
                backgroundColor: "#E6C0EA",
                borderColor: '#E6C0EA',
                fill: true
              }
            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'afsf',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
    }
    else if(this.selected == "2020-2021" || this.selected == "2021-2022"){
        // language
        this.service.OLDPartnerLangCentWiseGraphPIF(this.selected, this.centid).subscribe((res: any) => {
          this.chartData = [];
          this.dataobj = res.data;
  
          for (let i = 0; i < this.dataobj.length; i++) {
            this.chartData.push({ data: this.dataobj[i].LanguageCount, label: this.dataobj[i].LanguageName });
          }
          this.labels = res.state;
        });
  
        service.OLDPartnerLangCentWisetablePIF(this.selected, this.centid).subscribe((data: any) => {
          this.LangCentWise = data;
          this.loading = false;
        })
  
        // center detail graph with table
        this.service.SahyogiOLDCALCenterDataPIF(this.selected, this.centid).subscribe((res: Boygirlsstackgraph[]) => {
          this.StateCentWise = res;
          this.schoolcount = new Array();
          this.Boyscount = new Array();
          this.Girlscount = new Array();
  
          res.forEach(y => {
  
            this.schoolcount.push(y.centname);
            this.Boyscount.push((y.boys));
            this.Girlscount.push((y.girls));
  
  
          });
          if (this.chart) { this.chart.destroy(); }
          this.chart = new Chart('canvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.schoolcount,
  
              datasets: [
  
                {
                  label: 'Total Boys',
                  data: this.Boyscount,
                  backgroundColor: "#4F87E2",
                  borderColor: '#4F87E2',
                  fill: true,
  
                },
  
                {
                  label: 'Total Girls',
                  data: this.Girlscount,
                  backgroundColor: "#9BE24F",
                  borderColor: '#9BE24F',
                  fill: true
                }
  
  
              ],
  
            },
            options: {
  
              legend: {
                display: true,
                position: 'bottom',
                // legendname: 'afsf',
  
              },
  
              scales: {
                xAxes: [{
  
                  display: true,
                  stacked: true,
                  distribution: 'series',
  
  
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
  
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
  
                  }
                }],
  
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });

        // gender wise graph with table
        this.service.SahyogiOLDCALCenterDataPIF(this.selected, this.centid).subscribe((res: any) => {
          this.pieChartData2 = [
            ((res[0].boys * 100) / (res[0].boys + res[0].girls)).toFixed(2)
            , ((res[0].girls * 100) / (res[0].boys + res[0].girls)).toFixed(2)];
        })
  
    
        // standard summary
        this.service.OLDPartnerStdCentWisePIF(this.selected, this.centid).subscribe((res: any) => {
          this.StdCentWise = res;
          this.stdname = new Array();
          this.stdCount = new Array();
  
          let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
            totFifth = 0, totSixth = 0, totSeventh = 0
            , totEighth = 0, totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;
  
          res.forEach(
            y => {
              [
                totFirst += (y.First),
                totSecond += (y.Second),
                totThird += (y.Third),
                totFourth += (y.Fourth),
                totFifth += (y.Fifth),
                totSixth += (y.Sixth),
                totSeventh += (y.Seventh),
                totEighth += (y.Eighth),
                totNinth += (y.Ninth),
                totTenth += (y.Tenth),
                totEleventh += (y.Eleventh),
                totTwelth += (y.Twelth),
              ];
            });
  
          this.stdCount.push(totFirst);
          this.stdname.push('1st');
          this.stdCount.push(totSecond);
          this.stdname.push('2nd');
          this.stdCount.push(totThird);
          this.stdname.push('3rd');
          this.stdCount.push(totFourth);
          this.stdname.push('4th');
          this.stdCount.push(totFifth);
          this.stdname.push('5th');
          this.stdCount.push(totSixth);
          this.stdname.push('6th');
          this.stdCount.push(totSeventh);
          this.stdname.push('7th');
          this.stdCount.push(totEighth);
          this.stdname.push('8th');
          this.stdCount.push(totNinth);
          this.stdname.push('9th');
          this.stdCount.push(totTenth);
          this.stdname.push('10th');
          this.stdCount.push(totEleventh);
          this.stdname.push('11th');
          this.stdCount.push(totTwelth);
          this.stdname.push('12th');
  
  
          if (this.myChart) { this.myChart.destroy(); }
          this.myChart = new Chart('canvass', {
            type: 'bar',
            data: {
              labels: this.stdname,
              datasets: [
                {
                  label: 'Standard',
                  data: this.stdCount,
                  backgroundColor: "#ffc107",
                  borderColor: '#ffc107',
                  fill: true,
  
                }
              ],
  
            },
  
            options: {
              legend: {
                display: true,
                position: 'bottom',
              },
  
              scales: {
                xAxes: [{
  
                  display: true,
                  stacked: true,
                  distribution: 'series',
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
  
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
  
  
                  }
                }],
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
        // Gradewise Attendance Summary
  
        this.service.OLDPartnerAttendaceCentWisePIF(this.selected, this.centid).subscribe((res: Attendancegraph[]) => {
          this.monthname = new Array();
          this.agradecount = new Array();
          this.bgradecount = new Array();
          this.cgradecount = new Array();
          this.dgradecount = new Array();
          this.egradecount = new Array();
  
  
          res.forEach(y => {
            this.monthname.push(y.month);
            this.agradecount.push((y.agrade));
            this.bgradecount.push((y.bgrade));
            this.cgradecount.push((y.cgrade));
            this.dgradecount.push((y.dgrade));
            this.egradecount.push((y.egrade));
          });
  
          if (this.stdchart) { this.stdchart.destroy(); }
          this.stdchart = new Chart('attcanvas', {
            type: 'bar',
            //responsive: true,
            data: {
              labels: this.monthname,
              datasets: [
                {
                  label: 'Grade A (99<=x<85)',
                  data: this.agradecount,
                  backgroundColor: "#48C9B0",
                  borderColor: '#48C9B0',
                  fill: true
                },
                {
                  label: 'Grade B (84<= x <60)',
                  data: this.bgradecount,
                  backgroundColor: "#9CE7B8",
                  borderColor: '#9CE7B8',
                  fill: true
                },
                {
                  label: 'Grade C (59<= x <= 35)',
                  data: this.cgradecount,
                  backgroundColor: "#D4DC9B",
                  borderColor: '#D4DC9B',
                  fill: true
                },
                {
                  label: 'Grade D (34<= x <= 1)',
                  data: this.dgradecount,
                  backgroundColor: "#909DE2",
                  borderColor: '#909DE2',
                  fill: true
                },
                {
                  label: 'Grade E (Comp Class ActualWorkingDays=0)',
                  data: this.egradecount,
                  backgroundColor: "#E6C0EA",
                  borderColor: '#E6C0EA',
                  fill: true
                }
              ],
  
            },
            options: {
              legend: {
                display: true,
                position: 'bottom',
                // legendname: 'afsf',
  
              },
  
              scales: {
                xAxes: [{
  
                  display: true,
                  stacked: true,
                  distribution: 'series',
  
  
                }],
                yAxes: [{
                  display: true,
                  stacked: true,
  
                  scaleLabel: {
                    display: true,
                    labelString: "Count",
  
                  }
  
  
                }],
  
              },
              plugins: {
                datalabels: {
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                  },
                  align: 'center',
                  anchor: 'center',
                  color: 'black',
                  font: {
                    size: 8,
                  }
                }
              }
            }
          });
        });
  
  
    }

    else if (this.selected == null) {

      this.selectfiyear = "2022-2023";
      // Center Wise Summary graph with table center
      this.service.GetPartnerStateCentWise("where center.centid='" + this.centid + "'").subscribe((res: Boygirlsstackgraph[]) => {
        this.StateCentWise = res;
        this.schoolcount = new Array();
        this.Boyscount = new Array();
        this.Girlscount = new Array();

        res.forEach(y => {

          this.schoolcount.push(y.centname);
          this.Boyscount.push((y.boys));
          this.Girlscount.push((y.girls));

        });
        if (this.chart) { this.chart.destroy(); }
        this.chart = new Chart('canvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.schoolcount,

            datasets: [

              {
                label: 'Total Boys',
                data: this.Boyscount,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true,

              },

              {
                label: 'Total Girls',
                data: this.Girlscount,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              }


            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],

              yAxes: [{
                display: true,
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }


              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });

      // standard summary
      this.service.GetPartnerStdCentWise("where center.centid='" + this.centid + "'").subscribe((res: any) => {
        this.StdCentWise = res;
        this.stdname = new Array();
        this.stdCount = new Array();

        let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
            totFifth = 0, totSixth = 0, totSeventh = 0, totEighth = 0,
            totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;

        res.forEach(
          y => {
            [

              totFirst += (y.First),
              totSecond += (y.Second),
              totThird += (y.Third),
              totFourth += (y.Fourth),
              totFifth += (y.Fifth),
              totSixth += (y.Sixth),
              totSeventh += (y.Seventh),
              totEighth += (y.Eighth),
              totNinth += (y.Ninth),
              totTenth += (y.Tenth),
              totEleventh += (y.Eleventh),
              totTwelth += (y.Twelth),


            ];
          });

        this.stdCount.push(totFirst);
        this.stdname.push('1st');
        this.stdCount.push(totSecond);
        this.stdname.push('2nd');
        this.stdCount.push(totThird);
        this.stdname.push('3rd');
        this.stdCount.push(totFourth);
        this.stdname.push('4th');
        this.stdCount.push(totFifth);
        this.stdname.push('5th');
        this.stdCount.push(totSixth);
        this.stdname.push('6th');
        this.stdCount.push(totSeventh);
        this.stdname.push('7th');
        this.stdCount.push(totEighth);
        this.stdname.push('8th');
        this.stdCount.push(totNinth);
        this.stdname.push('9th');
        this.stdCount.push(totTenth);
        this.stdname.push('10th');
        this.stdCount.push(totEleventh);
        this.stdname.push('11th');
        this.stdCount.push(totTwelth);
        this.stdname.push('12th');


        if (this.myChart) { this.myChart.destroy(); }
        this.myChart = new Chart('canvass', {

          type: 'bar',
          data: {
            labels: this.stdname,
            datasets: [
              {
                label: 'Standard',
                data: this.stdCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,
              }
            ],

          },

          options: {
            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'Course Name',
            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });
      
      // gender summary
      this.service.GetPartnerStateCentWise("where center.centid='" + this.centid + "'")
        .subscribe((res: any) => {
          this.pieChartData2 = [
            ((res[0].boys * 100) / (res[0].boys + res[0].girls)).toFixed(2)
            , ((res[0].girls * 100) / (res[0].boys + res[0].girls)).toFixed(2)];
        })

      // Gradewise Attendance Summary
      this.service.GetPartnerGradewiseCenterAttendance("where centid='" + this.centid + "'").subscribe((res: Attendancegraph[]) => {
        this.monthname = new Array();
        this.a1gradecount = new Array();
        this.agradecount = new Array();
        this.bgradecount = new Array();
        
        res.forEach(y => {

          this.monthname.push(y.month);
          this.a1gradecount.push((y.Day0));
          this.agradecount.push((y.Day1to4));
          this.bgradecount.push((y.Day5to8));

        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.monthname,

            datasets: [

              {
                label: 'Day0',
                data: this.a1gradecount,
                backgroundColor: "#48C9B0",
                borderColor: '#48C9B0',
                fill: true,

              },

              {
                label: 'Day1to4',
                data: this.agradecount,
                backgroundColor: "rgba(204, 204, 0, 0.9)",
                borderColor: 'rgba(21, 230, 69, 0.9)',
                fill: true
              },

              {
                label: 'Day5to8',
                data: this.bgradecount,
                backgroundColor: "#85C1E9",
                borderColor: '#85C1E9',
                fill: true
              },
            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'afsf',
            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',


              }],
              yAxes: [{
                display: true,
                stacked: true,

                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],

            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });

      // language
      this.service.GetPartnerLangCentWiseGraph("where Center.centid='" + this.centid + "'")
        .subscribe((res: any) => {
          this.loading = false;

          this.chartData = [];
          this.dataobj = res.data;

          for (let i = 0; i < this.dataobj.length; i++) {
            this.chartData.push({ data: this.dataobj[i].LanguageCount, label: this.dataobj[i].LanguageName });
          }
          this.labels = res.state;
        });

      service.GetPartnerLangCentWise("where Center.centid='" + this.centid + "'").subscribe((data: any) => {
        this.LangCentWise = data;

      })
    } 
  }

  ngOnInit(): void {
    this.pieChartType2 = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }
}
