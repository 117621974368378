import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-endlinestd4',
  templateUrl: './endlinestd4.component.html',
  styleUrls: ['./endlinestd4.component.css']
})
export class Endlinestd4Component implements OnChanges {
  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  
  Partnerid;
  pgid;
  selected;
  loading = true;
  Endlinecount;
  Endline;
  compentencies;
  EndlineStandard:any;
  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');
  }

  ngOnChanges() {
    this.service.SahyogiFcEvalCompetency(this.Partnerid,this.selected,this.pgid,this.Examtype,this.Standard).subscribe((data:any) => {
      this.Endline = data;
      this.Endlinecount = data.length;
      this.compentencies = new Array();
      data.forEach(y => {
         this.compentencies.push(y.c1);
         this.compentencies.push(y.c2);
         this.compentencies.push(y.c3);
         this.compentencies.push(y.c4);
         this.compentencies.push(y.c5);
       })
 
       var graph = document.getElementById("graph");
       if(data.length != 0){
         graph.style.display = "block";
       this.EndlineStandard = new Chart('EndlineGraph', {
         type: 'bar',
         data: {
           labels: ["Operation on Number", "Knowledge of Number", "Knowledge of word", "Para Reading","Vocabulary"],
           datasets: [
             {
               label: "Compentencies",
               backgroundColor: '#3cba54',
               borderColor: '#3cba54',
               data: this.compentencies
             },
           ]
         },
         options: {
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
           title: {
             display: true,
             text: 'Endline Standard  ' + this.Standard,
           },
           plugins: {
             datalabels: {
               display: function (context) {
                 return context.dataset.data[context.dataIndex] >= 1;
               },
               align: 'center',
               anchor: 'center',
               color: 'black',
               font: {
                 size: 14,
               }
             }
           }
         }
       })
      }
      else {
        graph.style.display = "none";
      }
      this.loading = false;
     })
   }

   downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = this.Standard+"th Standard Endline Summary.png";
  }
}