import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Statusenroll } from 'app/ClassFiles/Statusenroll';
import { Chart } from 'chart.js';
import { Idiageenrollment } from 'app/ClassFiles/idiageenrollment';
import { Qualificationgraph } from 'app/ClassFiles/qualificationgraph';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-youthenrollment',
  templateUrl: './youthenrollment.component.html',
  styleUrls: ['./youthenrollment.component.css']
})
export class YouthenrollmentComponent implements OnInit {

  attsumamary: FormGroup;
  loading = false;
  labels1 = [];
  gendersummarycount;
  countdata;
  dataobj1;
  chartOptions1 = {
    legend: {
      display: true,
      position: 'bottom',
    },
    plugins: {
      responsive: true,
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        anchor: 'end',
        color: 'black',
        font: {
          size: 9,
        }
      }
    }
  }

  chartData1 = [
    {
      label: [],
      data: [],

    }
  ];

  // CHART COLOR.
  colors1 = [
    {
      backgroundColor: '#5690F5'
    },
    {
      backgroundColor: '#F05EC6'
    },
    {
      backgroundColor: '#F3D73A'
    }
    ,
    {
      backgroundColor: '#F34346'
    }

  ]

  groupbatchcount;
  ongoingcount;
  completedcount;
  dropoutcount;
  groupbatch; girls14count; girls18count; girls21count;
  total14count; boys14count; boys18count; boys21count;
  total18count;
  total21count;
  gendersummary;
  BelowSSCcount;
  SSCClass11count;
  HSCcount;
  PersuingGraduationcount;
  Gradudatecount;
  public stdchart: Chart; att = [];
  public intrestedchart: Chart; intrested = [];
  public statuschart: Chart; stacan = [];
  public qualichart: Chart;
  quali = [];
  public notintrestedchart: Chart;
  notintrested = [];
  public pieChartData: any[];
  public pieChartLabels: Array<string> = ['Boys', 'Girls'];
  public pieChartType: string;
  pieChartColor: any = [
    {
      backgroundColor: ['#F18AE0',
        '#8DEEDB',
      ]
    }
  ]
  public piechartOptions: any = {
    plugins: {
      responsive: true,
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        align: 'center',
        anchor: 'center',
        color: 'black',
        font: {
          size: 8,
        }
      }
    }
  };

  //line
  public lineChartType: string;
  public lineChartLegend: boolean = true;
  public lineChartData;
  public linechartLabels: Array<string> = ['AGrade', 'BGrade', 'CGrade', 'DGrade'];
  public linechartOptions: any = {
    responsive: true,
    legend: {
      possition: 'bottom',
    },
    plugins: {
      responsive: true,
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        align: 'right',
        anchor: 'right',
        color: 'black',
        font: {
          size: 10,
        }
      }
    },
   
  };
  lineChartColor: any = [
    {
      fill: false,
      borderColor: 'rgba(129, 159, 247, 1)',
      pointBackgroundColor: 'rgba(129, 159, 247, 1)',
      pointBorderColor: 'rgba(129, 159, 247, 1)',
      lineTension: 0,
      pointRadius: 4,
    }
  ]

  UserId; centid; financialyear; pgid
  public chart: Chart;
  public barChartType: string;
  barChartPlugins = [pluginDataLabels];
  public barChartType1: string;
  pgname;
  ongoing;
  dropout;
  complete;
  enrollment;
  currmonthval;
  curryear;
  totgirls = 0;
  totboys = 0;
  totstudent = 0;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute,private fb: FormBuilder) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    this.financialyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');
    this.pgid = localStorage.getItem('pgid');


    var sysdate = new Date();
    
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this.currmonthval=monthNames[sysdate.getMonth()];
    console.log(this.currmonthval);
     
    this.curryear = sysdate.getFullYear();
    // console.log(this.curryear);
    
    if(this.currmonthval == 'January'){
      this.currmonthval = 'December';
      this.curryear = sysdate.getFullYear()-1;
      
    } 
    if(this.currmonthval == 'July' && this.financialyear == '2021-2022'){
      this.currmonthval = 'July';
      this.curryear = sysdate.getFullYear()-1;
      
    }
    if(this.currmonthval == 'July' && this.financialyear == '2022-2023'){
      this.currmonthval = 'July';
      this.curryear = sysdate.getFullYear();
      
    }
    // if(this.currmonthval == 'August'){
    //   this.currmonthval = 'August';
    //   this.curryear = sysdate.getFullYear()-1;
      
    // } 
   

    if (this.financialyear == "2022-2023") {
      this.service.GetPartnerIDIDashStudStatusCount('P6', "").subscribe((data: any) => {
        this.ongoing = data[0].studstatusact;
        this.dropout = data[0].studstatusdrop;
        this.complete = data[0].studstatusComp;
        this.enrollment = data[0].totenrolment;
      });
      
      //  Age Enrollment Summary
      this.service.GetPartnerIDIEnrollAgeNewPIF("where pgid='P6'").subscribe((res: Idiageenrollment[]) => {
     
        this.groupbatch = new Array();
        this.total14count = new Array();
        this.total18count = new Array();
        this.total21count = new Array();

        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.total14count.push((y.total14));
          this.total18count.push((y.total18));
          this.total21count.push((y.total21));



        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.total14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.total18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.total21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });
      
      //  GetPartnerIDIMaleFemaleRatioNew  
      this.service.GetPartnerMaleFemaleRatioSummaryNewIDIPIF("").subscribe((res: any) => { 
        this.chartData1 = [];
        this.dataobj1 = res.data;
        this.labels1 = res.Batch;
        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
        }
      });


      //  StatusWise Enrolment Summary IDI 
      this.service.GetPartnerIDIEnrollStatusNewPIF("").subscribe((res: Statusenroll[]) => {
        this.groupbatch = new Array();
        this.ongoingcount = new Array();
        this.completedcount = new Array();
        this.dropoutcount = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.ongoingcount.push((y.boys14));
          this.completedcount.push((y.boys18));
          this.dropoutcount.push((y.boys21));
        });
        if (this.statuschart) { this.statuschart.destroy(); }
        this.statuschart = new Chart('statuscanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [
              {
                label: 'Ongoing',
                data: this.ongoingcount,
                backgroundColor: "#F7DC6F",
                borderColor: '#F7DC6F',
                fill: true,

              },

              {
                label: 'Completed',
                data: this.completedcount,
                backgroundColor: "#58D68D",
                borderColor: '#58D68D',
                fill: true
              },

              {
                label: 'Dropout',
                data: this.dropoutcount,
                backgroundColor: "#EC7063",
                borderColor: '#EC7063',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],

              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });


      // Agewise Interested For Job Summary IDI 
      this.service.GetPartnerIDIEnrollAgeIntJobNewPIF("").subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.boys14count = new Array();
        this.boys18count = new Array();
        this.boys21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.boys14count.push((y.boys14));
          this.boys18count.push((y.boys18));
          this.boys21count.push((y.boys21));

        });
        if (this.intrestedchart) { this.intrestedchart.destroy(); }
        this.intrestedchart = new Chart('intrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.boys14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,
              },
              {
                label: '18-21',
                data: this.boys18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.boys21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      //  Agewise not Interested For Job Summary IDI 
      this.service.GetPartnerIDIEnrollAgeIntJobNewPIF("").subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.girls14count = new Array();
        this.girls18count = new Array();
        this.girls21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.girls14count.push((y.girls14));
          this.girls18count.push((y.girls18));
          this.girls21count.push((y.girls21));

        });
        if (this.notintrestedchart) { this.notintrestedchart.destroy(); }
        this.notintrestedchart = new Chart('notintrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.girls14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.girls18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.girls21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });
  //  overall gender Summary IDI 
      this.service.GetPartnerGenderEnrollmentSummaryIDIPIF("where Center.pgid='P6' and acyear='2022-2023'").subscribe((res: any) => {
        this.gendersummary = res;
        this.gendersummarycount = res.length;

        for (let j = 0; j < this.gendersummary.length; j++) {
          this.totgirls += this.gendersummary[j].girls;
          this.totboys += this.gendersummary[j].boys;
          this.totstudent += this.gendersummary[j].totstud;

        }


        this.pieChartData = [
          ((this.totboys * 100) / (this.totstudent)).toFixed(2)
          , ((this.totgirls * 100) / (this.totstudent)).toFixed(2)];
      })

      //Attendance Summary
      this.service.GetPartnerIDIAttendanceGraphPIF("").subscribe((res: any) => {
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
      })

    }


   else if (this.financialyear == "2021-2022") {
    this.service.SahyogiIDIDataPIF(this.financialyear).subscribe((data: any) => {
        this.ongoing = data.ongoing;
        this.dropout = data.dropout;
        this.complete = data.completed;
        this.enrollment = data.totstud;

        
      });
      
      //  Age Enrollment Summary
      this.service.OLDPartnerIDIAgeEnrolmentSummaryPIF(this.financialyear).subscribe((res: Idiageenrollment[]) => {
          
        this.groupbatch = new Array();
        this.total14count = new Array();
        this.total18count = new Array();
        this.total21count = new Array();

        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.total14count.push((y.total14));
          this.total18count.push((y.total18));
          this.total21count.push((y.total21));



        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.total14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.total18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.total21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });
      
      //  GetPartnerIDIMaleFemaleRatioNew  
      this.service.OLDPartnerIDIMaleFemaleRatioPIF(this.financialyear).subscribe((res: any) => {    
         
        this.chartData1 = [];
        this.dataobj1 = res.data;
        
        this.labels1 = res.Batch;
        
        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
        }
      });


      //  StatusWise Enrolment Summary IDI 
      this.service.OLDPartnerIDIStatusWiseEnrolmentSummaryPIF(this.financialyear).subscribe((res: Statusenroll[]) => {
        
        this.groupbatch = new Array();
        this.ongoingcount = new Array();
        this.completedcount = new Array();
        this.dropoutcount = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.ongoingcount.push((y.ongoing));
          this.completedcount.push((y.completed));
          this.dropoutcount.push((y.dropout));
        });
        if (this.statuschart) { this.statuschart.destroy(); }
        this.statuschart = new Chart('statuscanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [
              {
                label: 'Ongoing',
                data: this.ongoingcount,
                backgroundColor: "#F7DC6F",
                borderColor: '#F7DC6F',
                fill: true,

              },

              {
                label: 'Completed',
                data: this.completedcount,
                backgroundColor: "#58D68D",
                borderColor: '#58D68D',
                fill: true
              },

              {
                label: 'Dropout',
                data: this.dropoutcount,
                backgroundColor: "#EC7063",
                borderColor: '#EC7063',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],

              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });


      // Agewise Interested For Job Summary IDI 
      this.service.OLDPartnerIDIAgeIntJobSummaryPIF(this.financialyear).subscribe((res: Idiageenrollment[]) => {
        console.log(res);
        
        this.groupbatch = new Array();
        this.boys14count = new Array();
        this.boys18count = new Array();
        this.boys21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.boys14count.push((y.boys14));
          this.boys18count.push((y.boys18));
          this.boys21count.push((y.boys21));

        });
        if (this.intrestedchart) { this.intrestedchart.destroy(); }
        this.intrestedchart = new Chart('intrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.boys14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,
              },
              {
                label: '18-21',
                data: this.boys18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.boys21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      //  Agewise not Interested For Job Summary IDI 
      this.service.OLDPartnerIDIAgeIntJobSummaryPIF(this.financialyear).subscribe((res: Idiageenrollment[]) => {
        console.log(res);
        
        this.groupbatch = new Array();
        this.girls14count = new Array();
        this.girls18count = new Array();
        this.girls21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.girls14count.push((y.girls14));
          this.girls18count.push((y.girls18));
          this.girls21count.push((y.girls21));

        });
        if (this.notintrestedchart) { this.notintrestedchart.destroy(); }
        this.notintrestedchart = new Chart('notintrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.girls14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.girls18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.girls21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });
  //  overall gender Summary IDI 
      this.service.OLDPartnerIDIOverallGenderSummaryPIF(this.financialyear).subscribe((res: any) => { 
        console.log(res);
        
        this.gendersummary = res;
        // this.gendersummarycount = res.length;

        // for (let j = 0; j < this.gendersummary.length; j++) {
          this.totgirls = this.gendersummary[0].girls;
          this.totboys = this.gendersummary[0].boys;
          this.totstudent = this.gendersummary[0].totstud;

        // }


        this.pieChartData = [
          ((this.totboys * 100) / (this.totstudent)).toFixed(2)
          , ((this.totgirls * 100) / (this.totstudent)).toFixed(2)];
      })

      //Attendance Summary
      this.service.OLDPartnerIDIAttendanceSummaryPIF(this.financialyear,this.currmonthval,this.curryear).subscribe((res: any) => {
        console.log(res);
        
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
      })

    }
    
    
    else if (this.financialyear == null) {
     
      this.service.GetPartnerIDIDashStudStatusCount('P6', "").subscribe((data: any) => {
        this.ongoing = data[0].studstatusact;
        this.dropout = data[0].studstatusdrop;
        this.complete = data[0].studstatusComp;
        this.enrollment = data[0].totenrolment;
      });
      
      //  Age Enrollment Summary
      this.service.GetPartnerIDIEnrollAgeNewPIF("where pgid='P6'").subscribe((res: Idiageenrollment[]) => {
     
        this.groupbatch = new Array();
        this.total14count = new Array();
        this.total18count = new Array();
        this.total21count = new Array();

        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.total14count.push((y.total14));
          this.total18count.push((y.total18));
          this.total21count.push((y.total21));



        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.total14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.total18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.total21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });
      
      //  GetPartnerIDIMaleFemaleRatioNew  
      this.service.GetPartnerMaleFemaleRatioSummaryNewIDIPIF("").subscribe((res: any) => {      
        this.chartData1 = [];
        this.dataobj1 = res.data;
        this.labels1 = res.Batch;
        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
        }
      });


      //  StatusWise Enrolment Summary IDI 
      this.service.GetPartnerIDIEnrollStatusNewPIF("").subscribe((res: Statusenroll[]) => {
        console.log(res)
        this.groupbatch = new Array();
        this.ongoingcount = new Array();
        this.completedcount = new Array();
        this.dropoutcount = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.ongoingcount.push((y.boys14));
          this.completedcount.push((y.boys18));
          this.dropoutcount.push((y.boys21));
        });
        if (this.statuschart) { this.statuschart.destroy(); }
        this.statuschart = new Chart('statuscanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [
              {
                label: 'Ongoing',
                data: this.ongoingcount,
                backgroundColor: "#F7DC6F",
                borderColor: '#F7DC6F',
                fill: true,

              },

              {
                label: 'Completed',
                data: this.completedcount,
                backgroundColor: "#58D68D",
                borderColor: '#58D68D',
                fill: true
              },

              {
                label: 'Dropout',
                data: this.dropoutcount,
                backgroundColor: "#EC7063",
                borderColor: '#EC7063',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],

              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });


      // Agewise Interested For Job Summary IDI 
      this.service.GetPartnerIDIEnrollAgeIntJobNewPIF("").subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.boys14count = new Array();
        this.boys18count = new Array();
        this.boys21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.boys14count.push((y.boys14));
          this.boys18count.push((y.boys18));
          this.boys21count.push((y.boys21));

        });
        if (this.intrestedchart) { this.intrestedchart.destroy(); }
        this.intrestedchart = new Chart('intrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.boys14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,
              },
              {
                label: '18-21',
                data: this.boys18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.boys21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      //  Agewise not Interested For Job Summary IDI 
      this.service.GetPartnerIDIEnrollAgeIntJobNewPIF("").subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.girls14count = new Array();
        this.girls18count = new Array();
        this.girls21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.girls14count.push((y.girls14));
          this.girls18count.push((y.girls18));
          this.girls21count.push((y.girls21));

        });
        if (this.notintrestedchart) { this.notintrestedchart.destroy(); }
        this.notintrestedchart = new Chart('notintrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.girls14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.girls18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.girls21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });
  //  overall gender Summary IDI 
      this.service.GetPartnerGenderEnrollmentSummaryIDIPIF("where Center.pgid='P6' and acyear='2022-2023'").subscribe((res: any) => {
        this.gendersummary = res;
        this.gendersummarycount = res.length;

        for (let j = 0; j < this.gendersummary.length; j++) {
          this.totgirls += this.gendersummary[j].girls;
          this.totboys += this.gendersummary[j].boys;
          this.totstudent += this.gendersummary[j].totstud;

        }


        this.pieChartData = [
          ((this.totboys * 100) / (this.totstudent)).toFixed(2)
          , ((this.totgirls * 100) / (this.totstudent)).toFixed(2)];
      })

      //Attendance Summary
      this.service.GetPartnerIDIAttendanceGraphPIF("").subscribe((res: any) => {
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
      })


    }
  }

  show(currmonthval,curryear){
    if(this.financialyear == "2022-2023"){
    this.service.GetPartnerIDIAttendanceGraph(this.pgid, this.UserId,"where month ='"+currmonthval+"' and year = '"+curryear+"'").subscribe((res: any) => {
      if(res.length == 1){
        this.countdata = 1;
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }];
      }else if(res == ''){
        this.lineChartData = '';
      }
    })
  }
  if(this.financialyear == "2021-2022"){
    this.service.OLDPartnerIDIAttendanceSummaryPIF(this.financialyear,this.currmonthval,this.curryear).subscribe((res: any) => {
      
      if(res.length == 1){
        this.countdata = 1;
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }];
      }else if(res == ''){
        this.lineChartData = '';
      }
    })
  }
  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
    this.lineChartType = 'line';

    this.attsumamary = this.fb.group({
      monthcontrol: ['', Validators.required],
      yearcontrol: ['', Validators.required]
    });
  }

  downloadagewiseenrolmentgraph(event) {
    console.log("work");
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "AgeWise Enrolment Summary Graph.png";
  }

  
  downloadmalefemalegraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[1].toDataURL();
    anchor.download = "Male-Female Summary Graph.png";
  }
  
  downloadoverallgendergraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[2].toDataURL();
    anchor.download = "Overall Gender Summary Graph.png";
  }

  downloadstatuswiseenrolmentgraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = "StatusWise Enrolment Summary Graph.png";
  }

  downloadagewiseintrestedforjobgraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[4].toDataURL();
    anchor.download = "AgeWise Interested For Job Summary Graph.png";
  }

  downloadagewisenotintrestedforjobgraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[5].toDataURL();
    anchor.download = "AgeWise Not Interested For Job Summary Graph.png";
  }

  downloadqualificationsummamrygraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[6].toDataURL();
    anchor.download = "Qualification Summary Graph.png";
  }

  downloadattendancesummamrygraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[7].toDataURL();
    anchor.download = "Attendance Summary Graph.png";
  }

  downloadattendancesummamrygraph2020(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[6].toDataURL();
    anchor.download = "Attendance Summary Graph.png";
  }

}
