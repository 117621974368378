import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
  { path: '/user-profile', title: 'User Profile', icon: 'person', class: '' },
  { path: '/table-list', title: 'Table List', icon: 'content_paste', class: '' },
  { path: '/typography', title: 'Typography', icon: 'library_books', class: '' },
  { path: '/icons', title: 'Icons', icon: 'bubble_chart', class: '' },
  { path: '/maps', title: 'Maps', icon: 'location_on', class: '' },
  { path: '/notifications', title: 'Notifications', icon: 'notifications', class: '' },
  { path: '/upgrade', title: 'Upgrade to PRO', icon: 'unarchive', class: 'active-pro' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  UserId;
  partnerid;
  Menus;
  Userrole;
  selectedItem: string;
  FullName;
  pgid;
  constructor(private service: MasterService, private router: Router) {
    this.FullName = localStorage.getItem("FullName");
    this.UserId = localStorage.getItem("UserId");
    this.pgid = localStorage.getItem('pgid');
    this.service.Menu(this.UserId, null, this.pgid).subscribe(
      (data: any) => {

        this.Menus = data;
        localStorage.setItem('menuid', data[0].menuid);
        localStorage.setItem('UserId', data[0].UserId);

      }
    );
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };

  listClick(event, newValue) {
    this.selectedItem = newValue;
    var year = this.service.getyear();
    var programid = this.service.getpgid();


    if (newValue.menuname == "Evaluation") {
      if (year == "2020-2021" && programid == 'P1') {
        this.router.navigate(['/Assessment'])
      }
      else if (year == "2021-2022" && programid == 'P1') {
        this.router.navigate(['/Assessment'])
      }
      else if ((year == "2022-2023" || year == "2023-2024" || year == "2024-2025") && (programid == 'P1' || programid == 'P9' || programid == 'P22')) {
        this.router.navigate(['/Assessment'])
      }
      else if (year == '2020-2021' && programid == 'P18') {
        this.router.navigate(['/ATALAssesment'])
      }
      else if (year == '2021-2022' && programid == 'P18') {
        this.router.navigate(['/ATALAssesment'])
      }
      else if (year == '2022-2023' && programid == 'P18') {
        this.router.navigate(['/ATALAssesment'])
      }
      else if (year == '2020-2021' && programid == 'P6') {
        this.router.navigate(['/IDIEvalution'])
      }
      else if (year == '2021-2022' && programid == 'P6') {
        this.router.navigate(['/IDIEvalution'])
      }
      else if (year == '2022-2023' && programid == 'P6') {
        this.router.navigate(['/IDIEvalution'])
      }
    }

    else if (newValue.menuname == "Dashboard") {
      if (year == "2022-2023" && programid == 'P18') {
        this.router.navigate(['/ATALDashboard'])
      }

    }
    else if (newValue.menuname == "Training") {
      if (year == "2022-2023" && programid == 'P1') {
        this.router.navigate(['/Training'])
      }

    }
    // else if (newValue.menuname == "ATALDashboard") {
    //   if (year == "2022-2023" && programid == 'P18') {
    //     this.router.navigate(['/ATALDashboard'])
    //   }

    // }
  }


  submenu(menuid, menuurl) {
    var pgid = this.service.getpgid();
    var UserId = localStorage.getItem("UserId");
    localStorage.setItem('menuid', menuid);
    localStorage.setItem('UserId', UserId);
    this.router.navigate([menuurl]);

    if (menuid == "M1") {
      if (pgid == 'P1') {
        this.router.navigate(['/Dashboard']);
      }
      else if (pgid == 'P6') {
        this.router.navigate(['/IDIDashboard']);
      }
      else if (pgid == 'P18') {
        this.router.navigate(['/ATALDashboard']);
      }
    }

    if (menuid == "M18") {
      if (pgid == 'P1') {
        this.router.navigate(['/Dashboard']);
      }
      else if (pgid == 'P6') {
        this.router.navigate(['/IDIDashboard']);
      }
      else if (pgid == 'P18') {
        this.router.navigate(['/ATALDashboard']);
      }
    }

  }

  logout() {
    var userId = localStorage.removeItem("UserId");
    var selectfiyear = localStorage.removeItem("key");
    this.router.navigate(["/Login"]);
  }
}
