import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {
  // abc='2020-2019';
  acyear;UserId; selected = '2020-2019';
//abc='2020-2019';abc
abcd;abc;abcdef;localvalue;
  constructor(private service: MasterService, public fb: FormBuilder) { 
    this.UserId = localStorage.getItem('UserId');
    this.service.FillDropDown("AcademicyearPartner", " academicyear", "academicyear", "where partnerid='" + this.UserId + "' order by academicyear desc").subscribe(
      (data: any) => {
        this.acyear = data;
     
       this.abcdef = data[0].column1;
      });

  }

  ngOnInit(): void {
 // get localStorage value

 this.localvalue = JSON.parse(localStorage.getItem('key'));
    
  }
  saveInLocalStorage(localvalue) {
    // update localStorage value
    localStorage.setItem('key', JSON.stringify(this.localvalue));

   
  }


}
