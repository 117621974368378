import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { ExcelService } from 'app/services/excel.service';
@Component({
  selector: 'app-partner-computer-list',
  templateUrl: './partner-computer-list.component.html',
  styleUrls: ['./partner-computer-list.component.css']
})
export class PartnerComputerListComponent implements OnInit {
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  UserId;
  pgid;
  PartnerSchoolList;
  centercount;
  state; PartnerCenterList;
  city;
  loading = true;
  showMainContent;
  array = [];
  StrWhere = "";
  statelist = new FormControl();
  citylist = new FormControl();
  searchstate; selectfiyear
  searchcity; academicyear; pgname;
  p: number = 1;
  where = "";
  searchstatus; PartnerSchoolListcount; CenterComputercount;
  constructor(private service: MasterService, private exService: ExcelService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    //this.academicyear = localStorage.getItem('academicyear');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');

    if (this.selectfiyear == null) {
      this.selectfiyear = "2024-2025";
      this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.state = data;

        });

      this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'  ").subscribe(
        (data: any) => {
          this.city = data;

        });
      this.service.GetPartnerComputer(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;
      });

    }
    else if (this.selectfiyear == "2024-2025") {

      this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'").subscribe(
        (data: any) => {
          this.state = data;

        });

      this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'").subscribe(
        (data: any) => {
          this.city = data;

        });

      this.service.GetPartnerComputer(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;

      });

    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, "").subscribe((data: any) => {
        this.PartnerCenterList = data;

      });
      this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, "").subscribe((data: any) => {

        this.CenterComputercount = data.length;
        this.loading = false;

      });
      this.service.FillDropDown("OLDPartnerCenterComputer", "distinct state", "state", " where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "' and academicyear='" + this.selectfiyear + "'").subscribe(
        (data: any) => {
          console.log(data);

          this.state = data;
        });

      this.service.FillDropDown("OLDPartnerCenterComputer", "distinct city", "city", "where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "' and academicyear='" + this.selectfiyear + "'").subscribe(
        (data: any) => {
          this.city = data;
        });
    }

  }

  ngOnInit(): void {

    if (this.selectfiyear == "2022-2023" || this.selectfiyear == null) {
      this.showMainContent = true;
    }
  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;

    this.array = [];
    this.where = "";
    this.StrWhere = "";
    if (this.selectfiyear == null) {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("c.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("c.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("c.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " and " + this.array[0].toString();
      }
      else {
        this.StrWhere = " and " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      this.service.GetPartnerComputer(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;

      });
    }

    else if (this.selectfiyear == "2024-2025") {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("c.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("c.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("c.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " and " + this.array[0].toString();
      }
      else {
        this.StrWhere = " and " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      this.service.GetPartnerComputer(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;

      });
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " and " + this.array[0].toString();
      }
      else {
        this.StrWhere = " and " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;

      });

    }

  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }

  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  onSelectState(stname) {
    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
      (data: any) => {
        this.city = data;
      });

  }

  ExportReport(searchstate, searchcity, searchstatus) {
    this.loading = true;
    if (this.UserId == 'PT30' && this.selectfiyear == "2024-2025") {
      this.service.GetPartnerComputer(this.pgid, this.UserId, this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Team Leader': obj.tlname == null ? '' : obj.tlname,
            'Center Name  ': obj.centname == null ? '' : obj.centname,
            'No.of School': obj.noofschool == null ? '' : obj.noofschool,
            'No of Computers': obj.noofcomputers == null ? '' : obj.noofcomputers,
            'Total Boys': obj.totboys == null ? '' : obj.totboys,
            'Total Girls': obj.totgirls == null ? '' : obj.totgirls,
            'Total Enrollment  ': obj.totstud == null ? '' : obj.totstud,
            'Internet Availability': obj.internetavailable == null ? '' : obj.internetavailable,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Partner Computer List');
      });
    } else
      if (this.selectfiyear == "2024-2025") {
        this.service.GetPartnerComputer(this.pgid, this.UserId, this.where).subscribe((data: any) => {
          this.loading = false;
          const new_list = data.map(function (obj) {
            return {
              'Srno': 0,
              'State': obj.state == null ? '' : obj.state,
              'City': obj.city == null ? '' : obj.city,
              'Team Leader': obj.tlname == null ? '' : obj.tlname,
              'Center Name  ': obj.centname == null ? '' : obj.centname,
              'No.of School': obj.noofschool == null ? '' : obj.noofschool,
              'No of Sancharaks': obj.noofsancharaks == null ? '' : obj.noofsancharaks,
              'No of Computers': obj.noofcomputers == null ? '' : obj.noofcomputers,
              'Total Boys': obj.totboys == null ? '' : obj.totboys,
              'Total Girls': obj.totgirls == null ? '' : obj.totgirls,
              'Total Enrollment  ': obj.totstud == null ? '' : obj.totstud,
              'Internet Availability': obj.internetavailable == null ? '' : obj.internetavailable,

            };
          });
          for (let i = 0; i < new_list.length; i++) {
            new_list[i].Srno = i + 1;
          }
          this.exService.exportAsExcelFile(new_list, 'Partner Computer List');
        });
      } else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
        this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, this.where).subscribe((data: any) => {
          this.loading = false;
          const new_list = data.map(function (obj) {
            return {
              'Srno': 0,
              'State': obj.state == null ? '' : obj.state,
              'City': obj.city == null ? '' : obj.city,
              'Team Leader': obj.tlname == null ? '' : obj.tlname,
              'Center Name  ': obj.centname == null ? '' : obj.centname,
              'No.of School': obj.noofschool == null ? '' : obj.noofschool,
              'No of Sancharaks': obj.noofsancharaks == null ? '' : obj.noofsancharaks,
              'No of Computers': obj.noofcomputers == null ? '' : obj.noofcomputers,
              'Total Boys': obj.totboys == null ? '' : obj.totboys,
              'Total Girls': obj.totgirls == null ? '' : obj.totgirls,
              'Total Enrollment  ': obj.totstud == null ? '' : obj.totstud,
              'Internet Availability': obj.internetavailable == null ? '' : obj.internetavailable,
            };
          });
          for (let i = 0; i < new_list.length; i++) {
            new_list[i].Srno = i + 1;
          }
          this.exService.exportAsExcelFile(new_list, 'Partner Computer List');
        });
      }

  }


}
