import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginRoutingModule } from './login/login-routing.module';
import { CenterlistRoutingModule } from './layouts/centerlist/centerlist-routing.module';
import { PartnerCenterListComponent } from './layouts/partner-center-list/partner-center-list.component';
import { PartnerSchoolListComponent } from './layouts/partner-school-list/partner-school-list.component';
import { AdmindashboardComponent } from './layouts/Adminpanel/admindashboard/admindashboard.component';
import { EventlistComponent } from './layouts/Adminpanel/eventlist/eventlist.component';
import { ReportlistComponent } from './layouts/Adminpanel/reportlist/reportlist.component';
import { AddreportComponent } from './layouts/Adminpanel/addreport/addreport.component';
import { AddeventComponent } from './layouts/Adminpanel/addevent/addevent.component';
import { CenterGraphComponent } from './layouts/center-graph/center-graph.component';
import { EnrollmentGraphComponent } from './layouts/enrollment-graph/enrollment-graph.component';
import { ChartsModule } from 'ng2-charts';
import { PartnerComputerListComponent } from './layouts/partner-computer-list/partner-computer-list.component';
import { IdidashboardComponent } from './ididashboard/ididashboard.component';
import { LtidashboardComponent } from './ltidashboard/ltidashboard.component';
import { IdicenterlistComponent } from './layouts/idicenterlist/idicenterlist.component';
import { IdibatchlistComponent } from './layouts/idibatchlist/idibatchlist.component';
import { IdienrollmentlistComponent } from './layouts/idienrollmentlist/idienrollmentlist.component';
import { IdicomputerlistComponent } from './layouts/idicomputerlist/idicomputerlist.component';
import { IdiEnrollmentGraphComponent } from './layouts/idi-enrollment-graph/idi-enrollment-graph.component';
import { IdiCenterGraphComponent } from './layouts/idi-center-graph/idi-center-graph.component';
import { CitygraphComponent } from './layouts/enrollment-graph/citygraph/citygraph.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StategraphComponent } from './layouts/enrollment-graph/stategraph/stategraph.component';
import { GendergraphComponent } from './layouts/enrollment-graph/gendergraph/gendergraph.component';
import { StandardgraphComponent } from './layouts/enrollment-graph/standardgraph/standardgraph.component';
import { EnrollmentgraphComponent } from './layouts/enrollment-graph/enrollmentgraph/enrollmentgraph.component';
import { SchoolgraphComponent } from './layouts/enrollment-graph/schoolgraph/schoolgraph.component';
import { GradewisegraphComponent } from './layouts/enrollment-graph/gradewisegraph/gradewisegraph.component';
import { DemoComponent } from './layouts/demo/demo.component';
import { MatIconModule } from '@angular/material/icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { AssissmentComponent } from './layouts/assissment/assissment.component';
import { EventdetailsComponent } from './layouts/Adminpanel/eventdetails/eventdetails.component';
import { PartnerreportlistComponent } from './layouts/partnerreportlist/partnerreportlist.component';
import { PartnereventlistComponent } from './layouts/partnereventlist/partnereventlist.component';
import { PartnereventdeatilsComponent } from './layouts/partnereventdeatils/partnereventdeatils.component';
import { AtalassesmentComponent } from './layouts/atalassesment/atalassesment.component';
import { IdievalutionComponent } from './layouts/idievalution/idievalution.component';
import { ProgramteamComponent } from './layouts/programteam/programteam.component';
import { FcevaluationcompentencygraphsComponent } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevaluationcompentencygraphs.component';
import { FcevalutionCompentecyComponent } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/fcevalution-compentecy.component';
import { Baselinestd1Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Baseline/baselinestd1/baselinestd1.component';
import { Baselinestd2Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Baseline/baselinestd2/baselinestd2.component';
import { Baselinestd3Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Baseline/baselinestd3/baselinestd3.component';
import { Baselinestd4Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Baseline/baselinestd4/baselinestd4.component';
import { Baselinestd5Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Baseline/baselinestd5/baselinestd5.component';
import { Endlinestd6Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Endline/endlinestd6/endlinestd6.component';
import { Baselinestd6to12Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Baseline/baselinestd6to12/baselinestd6to12.component';
import { ComparisongraphsComponent } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/comparisongraphs/comparisongraphs.component';
import { Comparisonstandard6to12Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/comparisongraphs/comparisonstandard6to12/comparisonstandard6to12.component';
import { Comparisonstandard1Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/comparisongraphs/comparisonstandard1/comparisonstandard1.component';
import { Comparisonstandard2Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/comparisongraphs/comparisonstandard2/comparisonstandard2.component';
import { Comparisonstandard3Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/comparisongraphs/comparisonstandard3/comparisonstandard3.component';
import { Comparisonstandard4Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/comparisongraphs/comparisonstandard4/comparisonstandard4.component';
import { Comparisonstandard5Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/comparisongraphs/comparisonstandard5/comparisonstandard5.component';
import { Endlinestd1Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Endline/endlinestd1/endlinestd1.component';
import { Endlinestd2Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Endline/endlinestd2/endlinestd2.component';
import { Endlinestd3Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Endline/endlinestd3/endlinestd3.component';
import { Endlinestd4Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Endline/endlinestd4/endlinestd4.component';
import { Endlinestd5Component } from './layouts/FC Evaluation/fcevaluationcompentencygraphs/fcevalution-compentecy/Endline/endlinestd5/endlinestd5.component';
import { PartnerwisephotolistComponent } from './layouts/Adminpanel/partnerwisephotolist/partnerwisephotolist.component';
import { AddphotosComponent } from './layouts/Adminpanel/addphotos/addphotos.component';
import { CreatealbumsComponent } from './layouts/Adminpanel/createalbums/createalbums.component';
import { PhotoalbumlistComponent } from './layouts/Adminpanel/photoalbumlist/photoalbumlist.component';
import { PhotoalbumdetailsComponent } from './layouts/Adminpanel/photoalbumdetails/photoalbumdetails.component';
import { PhotogalleryalbumComponent } from './layouts/photogalleryalbum/photogalleryalbum.component';
import { AlbumphotosComponent } from './layouts/albumphotos/albumphotos.component';
import { PhotosliderviewpopupComponent } from './layouts/Adminpanel/photosliderviewpopup/photosliderviewpopup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AssessmentlevelwiseComponent } from './layouts/assissment/theoryassissment/assessmentlevelwise/assessmentlevelwise.component';
import { Compentency2021Component } from './layouts/assissment/theoryassissment/compentency2021/compentency2021.component';
import { Baseline2021Std1Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std1/baseline2021-std1.component';
import { Baseline2021Std2Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std2/baseline2021-std2.component';
import { Baseline2021Std3Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std3/baseline2021-std3.component';
import { Baseline2021Std4Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std4/baseline2021-std4.component';
import { Baseline2021Std5Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std5/baseline2021-std5.component';
import { Baseline2021Std6to12Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std6to12/baseline2021-std6to12.component';
import { Baseline2021Std7Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std7/baseline2021-std7.component';
import { Baseline2021Std8Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std8/baseline2021-std8.component';
import { Baseline2021Std9Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std9/baseline2021-std9.component';
import { Baseline2021Std10Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std10/baseline2021-std10.component';
import { Baseline2021Std11Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std11/baseline2021-std11.component';
import { Baseline2021Std12Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2021-std12/baseline2021-std12.component';
import { PendamiceventmessageComponent } from './layouts/pendamiceventmessage/pendamiceventmessage.component';
import { English10thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english10th/english10th.component';
import { English12thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english12th/english12th.component';
import { Gujarati10Component } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati10/gujarati10.component';
import { Gujarati12thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati12th/gujarati12th.component';
import { PifdashboardComponent } from './layouts/pifdashboard/pifdashboard.component';
import { PifcenterlistComponent } from './layouts/pifmodule/pifcenterlist/pifcenterlist.component';
import { PifschoollistComponent } from './layouts/pifmodule/pifschoollist/pifschoollist.component';
import { PifenrollmentComponent } from './layouts/pifmodule/pifenrollment/pifenrollment.component';
import { PifcomputerlistComponent } from './layouts/pifmodule/pifcomputerlist/pifcomputerlist.component';
import { PifcentergraphComponent } from './layouts/pifmodule/pifcentergraph/pifcentergraph.component';
import { PifyouthbaseddashboardComponent } from './layouts/pifyouthbaseddashboard/pifyouthbaseddashboard.component';
import { PifdigitalsakshardashboardComponent } from './layouts/pifdigitalsakshardashboard/pifdigitalsakshardashboard.component';
import { YouthcenterlistComponent } from './layouts/pifyouthbaseddashboard/youthcenterlist/youthcenterlist.component';
import { YouthschoollistComponent } from './layouts/pifyouthbaseddashboard/youthschoollist/youthschoollist.component';
import { YouthenrollmentComponent } from './layouts/pifyouthbaseddashboard/youthenrollment/youthenrollment.component';
import { YouthcomputerlistComponent } from './layouts/pifyouthbaseddashboard/youthcomputerlist/youthcomputerlist.component';
import { DigitalcenterlistComponent } from './layouts/pifdigitalsakshardashboard/digitalcenterlist/digitalcenterlist.component';
import { DigitalschoollistComponent } from './layouts/pifdigitalsakshardashboard/digitalschoollist/digitalschoollist.component';
import { DigitalenrolmentComponent } from './layouts/pifdigitalsakshardashboard/digitalenrolment/digitalenrolment.component';
import { DigitalcomputerlistComponent } from './layouts/pifdigitalsakshardashboard/digitalcomputerlist/digitalcomputerlist.component';
import { PifyouthcentergraphComponent } from './layouts/pifyouthbaseddashboard/pifyouthcentergraph/pifyouthcentergraph.component';
import { PifstategraphComponent } from './layouts/pifmodule/pifenrollment/pifstategraph/pifstategraph.component';
import { PifcitygraphComponent } from './layouts/pifmodule/pifenrollment/pifcitygraph/pifcitygraph.component';
import { PifoverallgendergraphComponent } from './layouts/pifmodule/pifenrollment/pifoverallgendergraph/pifoverallgendergraph.component';
import { PifstandardgraphComponent } from './layouts/pifmodule/pifenrollment/pifstandardgraph/pifstandardgraph.component';
import { LanguagewiseenrollmentgraphComponent } from './layouts/pifmodule/pifenrollment/languagewiseenrollmentgraph/languagewiseenrollmentgraph.component';
import { LaguagewiseschoolgraphComponent } from './layouts/pifmodule/pifenrollment/laguagewiseschoolgraph/laguagewiseschoolgraph.component';
import { English1stComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english1st/english1st.component';
import { English2ndComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english2nd/english2nd.component';
import { English3rdComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english3rd/english3rd.component';
import { English4thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english4th/english4th.component';
import { English5thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english5th/english5th.component';
import { English6thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english6th/english6th.component';
import { English7thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english7th/english7th.component';
import { English8thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english8th/english8th.component';
import { English9thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english9th/english9th.component';
import { English11thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/english11th/english11th.component';
import { Gujarati5thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati5th/gujarati5th.component';
import { Gujarati6thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati6th/gujarati6th.component';
import { Gujarati7thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati7th/gujarati7th.component';
import { Gujarati8thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati8th/gujarati8th.component';
import { Gujarati9thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati9th/gujarati9th.component';
import { Gujarati11thComponent } from './layouts/assissment/theoryassissment/compentency2021/endline/gujarati11th/gujarati11th.component';
import { TheoryassissmentComponent } from './layouts/assissment/theoryassissment/theoryassissment.component';
import { PracticalassissmentComponent } from './layouts/assissment/practicalassissment/practicalassissment.component';
import { PracassessmentlevelwiseComponent } from './layouts/assissment/practicalassissment/pracassessmentlevelwise/pracassessmentlevelwise.component';
import { Practcompetency2022Component } from './layouts/assissment/practicalassissment/practcompetency2022/practcompetency2022.component';
import { Standard5Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard5/standard5.component';
import { Standard6Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard6/standard6.component';
import { Standard7Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard7/standard7.component';
import { Standard8Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard8/standard8.component';
import { Standard9Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard9/standard9.component';
import { Standard10Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard10/standard10.component';
import { Standard11Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard11/standard11.component';
import { Standard12Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/standard12/standard12.component';
import { Kerala5Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/kerala5/kerala5.component';
import { Kerala6Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/kerala6/kerala6.component';
import { Kerala7Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/kerala7/kerala7.component';
import { Allstate5Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/allstate5/allstate5.component';
import { Allstate6Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/allstate6/allstate6.component';
import { Allstate7Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/allstate7/allstate7.component';
import { Allstate8Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/allstate8/allstate8.component';
import { Allstate9Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/allstate9/allstate9.component';
import { Allstate11Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/allstate11/allstate11.component';
import { Kerala8Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/kerala8/kerala8.component';
import { Kerala9Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/kerala9/kerala9.component';
import { Kerala11Component } from './layouts/assissment/practicalassissment/practcompetency2022/Endline/kerala11/kerala11.component';
import { Baselinestandard5Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestandard5/baselinestandard5.component';
import { Baselinestd6Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestd6/baselinestd6.component';
import { Baselinestd7Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestd7/baselinestd7.component';
import { Baselinestd8Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestd8/baselinestd8.component';
import { Baselinestd9Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestd9/baselinestd9.component';
import { Baselinestd10Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestd10/baselinestd10.component';
import { Baselinestd11Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestd11/baselinestd11.component';
import { Baselinestd12Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline/baselinestd12/baselinestd12.component';
import { Baseline2022std5Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std5/baseline2022std5.component';
import { Baseline2022std6Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std6/baseline2022std6.component';
import { Baseline2022std7Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std7/baseline2022std7.component';
import { Baseline2022std8Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std8/baseline2022std8.component';
import { Baseline2022std9Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std9/baseline2022std9.component';
import { Baseline2022std10Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std10/baseline2022std10.component';
import { Baseline2022std11Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std11/baseline2022std11.component';
import { Baseline2022std12Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022std12/baseline2022std12.component';
import { Baseline2022gujstd9Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022gujstd9/baseline2022gujstd9.component';
import { Baseline2022gujstd10Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022gujstd10/baseline2022gujstd10.component';
import { Baseline2022gujstd11Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022gujstd11/baseline2022gujstd11.component';
import { Baseline2022gujstd12Component } from './layouts/assissment/theoryassissment/compentency2021/Baseline2021/baseline2022gujstd12/baseline2022gujstd12.component';
import { Baselinestd62022Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline2022/baselinestd62022/baselinestd62022.component';
import { Baselinestd72022Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline2022/baselinestd72022/baselinestd72022.component';
import { Baselinestd82022Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline2022/baselinestd82022/baselinestd82022.component';
import { Baselinestd92022Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline2022/baselinestd92022/baselinestd92022.component';
import { Baselinestd102022Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline2022/baselinestd102022/baselinestd102022.component';
import { Baselinestd112022Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline2022/baselinestd112022/baselinestd112022.component';
import { Baselinestd122022Component } from './layouts/assissment/practicalassissment/practcompetency2022/baseline2022/baselinestd122022/baselinestd122022.component';
import { AtaldashboardComponent } from './ATALDashboard/ataldashboard/ataldashboard.component';
import { TrainingComponent } from './layouts/training/training.component';
import { GeneralimpactofwholetrainingComponent } from './layouts/training/generalimpactofwholetraining/generalimpactofwholetraining.component';
import { LearningatmosphereComponent } from './layouts/training/learningatmosphere/learningatmosphere.component';
import { PracticalsessionscoveredintrainingComponent } from './layouts/training/practicalsessionscoveredintraining/practicalsessionscoveredintraining.component';
import { ComparisonwiseComponent } from './layouts/assissment/theoryassissment/comparisonwise/comparisonwise.component';
import { Std5Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std5/std5.component';
import { Std6Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std6/std6.component';
import { Std7Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std7/std7.component';
import { Std8Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std8/std8.component';
import { Std9Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std9/std9.component';
import { Std10Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std10/std10.component';
import { Std11Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std11/std11.component';
import { Std12Component } from './layouts/assissment/theoryassissment/comparisonwise/comparison/std12/std12.component';
import { ComparisonpracComponent } from './layouts/assissment/practicalassissment/comparisonprac/comparisonprac.component';
import { Midlinestd5Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd5/midlinestd5.component';
import { Midlinestd6Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd6/midlinestd6.component';
import { Midlinestd7Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd7/midlinestd7.component';
import { Midlinestd8Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd8/midlinestd8.component';
import { Midlinestd9Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd9/midlinestd9.component';
import { Midlinestd10Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd10/midlinestd10.component';
import { Midlinestd11Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd11/midlinestd11.component';
import { Midlinestd12Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinestd12/midlinestd12.component';
import { Midlinegujstd9Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinegujstd9/midlinegujstd9.component';
import { Midlinegujstd10Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinegujstd10/midlinegujstd10.component';
import { Midlinegujstd11Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinegujstd11/midlinegujstd11.component';
import { Midlinegujstd12Component } from './layouts/assissment/theoryassissment/compentency2021/Midline2022/midlinegujstd12/midlinegujstd12.component';
import { Pracmidlinestd5Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd5/pracmidlinestd5.component';
import { Pracmidlinestd6Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd6/pracmidlinestd6.component';
import { Pracmidlinestd7Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd7/pracmidlinestd7.component';
import { Pracmidlinestd8Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd8/pracmidlinestd8.component';
import { Pracmidlinestd9Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd9/pracmidlinestd9.component';
import { Pracmidlinestd10Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd10/pracmidlinestd10.component';
import { Pracmidlinestd11Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd11/pracmidlinestd11.component';
import { Pracmidlinestd12Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinestd12/pracmidlinestd12.component';
import { Pracmidlinegujstd9Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinegujstd9/pracmidlinegujstd9.component';
import { Pracmidlinegujstd10Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinegujstd10/pracmidlinegujstd10.component';
import { Pracmidlinegujstd11Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinegujstd11/pracmidlinegujstd11.component';
import { Pracmidlinegujstd12Component } from './layouts/assissment/practicalassissment/practcompetency2022/Midline2022/pracmidlinegujstd12/pracmidlinegujstd12.component';
import { KeralaevaluationComponent } from './layouts/keralaevaluation/keralaevaluation.component';
import { Endlinekerala2022Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekerala2022.component';
import { Comparisonkerala2022Component } from './layouts/keralaevaluation/comparisonkerala2022/comparisonkerala2022.component';
import { Keralacomparisonstd1Component } from './layouts/keralaevaluation/comparisonkerala2022/keralacomparisonstd1/keralacomparisonstd1.component';
import { Keralacomparisonstd2Component } from './layouts/keralaevaluation/comparisonkerala2022/keralacomparisonstd2/keralacomparisonstd2.component';
import { Keralacomparisonstd3Component } from './layouts/keralaevaluation/comparisonkerala2022/keralacomparisonstd3/keralacomparisonstd3.component';
import { Keralacomparisonstd4Component } from './layouts/keralaevaluation/comparisonkerala2022/keralacomparisonstd4/keralacomparisonstd4.component';
import { Keralacomparisonstd5Component } from './layouts/keralaevaluation/comparisonkerala2022/keralacomparisonstd5/keralacomparisonstd5.component';
import { Keralacomparisonstd6Component } from './layouts/keralaevaluation/comparisonkerala2022/keralacomparisonstd6/keralacomparisonstd6.component';
import { Keralacomparisonstd7Component } from './layouts/keralaevaluation/comparisonkerala2022/keralacomparisonstd7/keralacomparisonstd7.component';
import { Endlinekeralastd1Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekeralastd1/endlinekeralastd1.component';
import { Endlinekeralastd2Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekeralastd2/endlinekeralastd2.component';
import { Endlinekeralastd3Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekeralastd3/endlinekeralastd3.component';
import { Endlinekeralastd4Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekeralastd4/endlinekeralastd4.component';
import { Endlinekeralastd5Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekeralastd5/endlinekeralastd5.component';
import { Endlinekeralastd6Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekeralastd6/endlinekeralastd6.component';
import { Endlinekeralastd7Component } from './layouts/keralaevaluation/endlinekerala2022/endlinekeralastd7/endlinekeralastd7.component';
import { UndermaintenanceRoutingModule } from './undermaintenance/undermaintenance-routing.module';
import { EnrollmentListComponent } from './layouts/partner-school-list/enrollment-list/enrollment-list.component';
import { AttendanceListComponent } from './layouts/partner-school-list/attendance-list/attendance-list.component';
import { PartnerinfralistComponent } from './layouts/partnerinfralist/partnerinfralist.component';
import { MyeSchoolDashboardComponent } from './Mye-school/mye-school-dashboard/mye-school-dashboard.component';
import { MyeSchoolSchoollistComponent } from './Mye-school/mye-school-schoollist/mye-school-schoollist.component';
import { EnrollmentlistComponent } from './Mye-school/mye-school-schoollist/enrollmentlist/enrollmentlist.component';
import { MyeSchoolDigiclassroomComponent } from './Mye-school/mye-school-digiclassroom/mye-school-digiclassroom.component';
import { MyeSchoolEnrollmentComponent } from './Mye-school/mye-school-enrollment/mye-school-enrollment.component';
import { GraphofcityComponent } from './Mye-school/mye-school-enrollment/graphofcity/graphofcity.component';
import { GraphofstateComponent } from './Mye-school/mye-school-enrollment/graphofstate/graphofstate.component';
import { GraphofgenderComponent } from './Mye-school/mye-school-enrollment/graphofgender/graphofgender.component';
import { GraphofstandardComponent } from './Mye-school/mye-school-enrollment/graphofstandard/graphofstandard.component';
import { InfralistComponent } from './Mye-school/mye-school-digiclassroom/infralist/infralist.component';
import { MyeschoolteachertrainingComponent } from './Mye-school/myeschoolteachertraining/myeschoolteachertraining.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    MatProgressSpinnerModule,
    LoginRoutingModule,
    CenterlistRoutingModule,
    ChartsModule, MatIconModule, NgxPaginationModule,
    MatDialogModule,
    UndermaintenanceRoutingModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PartnerCenterListComponent,
    PartnerSchoolListComponent,
    AdmindashboardComponent,
    EventlistComponent,
    ReportlistComponent,
    AddreportComponent,
    AddeventComponent,
    CenterGraphComponent,
    EnrollmentGraphComponent,
    PartnerComputerListComponent,
    IdidashboardComponent,
    AssissmentComponent,
    LtidashboardComponent,
    IdicenterlistComponent,
    IdibatchlistComponent,
    IdienrollmentlistComponent,
    IdicomputerlistComponent, IdiEnrollmentGraphComponent, IdiCenterGraphComponent,
    CitygraphComponent, StategraphComponent, GendergraphComponent, StandardgraphComponent,
    EnrollmentgraphComponent, SchoolgraphComponent, GradewisegraphComponent, DemoComponent,
    EventdetailsComponent,
    PartnerreportlistComponent,
    PartnereventlistComponent,
    PartnereventdeatilsComponent,
    AtalassesmentComponent,
    IdievalutionComponent,
    ProgramteamComponent,
    FcevalutionCompentecyComponent,
    Baselinestd1Component,
    Baselinestd2Component,
    Baselinestd3Component,
    Baselinestd4Component,
    Baselinestd5Component,
    Endlinestd6Component,
    Baselinestd6to12Component,
    FcevaluationcompentencygraphsComponent,
    ComparisongraphsComponent,
    Comparisonstandard6to12Component,
    Comparisonstandard1Component,
    Comparisonstandard2Component,
    Comparisonstandard3Component,
    Comparisonstandard4Component,
    Comparisonstandard5Component,
    Endlinestd1Component,
    Endlinestd2Component,
    Endlinestd3Component,
    Endlinestd4Component,
    Endlinestd5Component,
    PartnerwisephotolistComponent,
    AddphotosComponent,
    CreatealbumsComponent,
    PhotoalbumlistComponent,
    PhotoalbumdetailsComponent,
    PhotogalleryalbumComponent,
    AlbumphotosComponent,
    PhotosliderviewpopupComponent,
    AssessmentlevelwiseComponent,
    Compentency2021Component,
    Baseline2021Std1Component,
    Baseline2021Std2Component,
    Baseline2021Std3Component,
    Baseline2021Std4Component,
    Baseline2021Std5Component,
    Baseline2021Std6to12Component,
    Baseline2021Std7Component,
    Baseline2021Std8Component,
    Baseline2021Std9Component,
    Baseline2021Std10Component,
    Baseline2021Std11Component,
    Baseline2021Std12Component,
    PendamiceventmessageComponent,
    English10thComponent,
    English12thComponent,
    Gujarati10Component,
    Gujarati12thComponent,
    PifdashboardComponent,
    PifcenterlistComponent,
    PifschoollistComponent,
    PifenrollmentComponent,
    PifcomputerlistComponent,
    PifcentergraphComponent,
    PifyouthbaseddashboardComponent,
    PifdigitalsakshardashboardComponent,
    YouthcenterlistComponent,
    YouthschoollistComponent,
    YouthenrollmentComponent,
    YouthcomputerlistComponent,
    DigitalcenterlistComponent,
    DigitalschoollistComponent,
    DigitalenrolmentComponent,
    DigitalcomputerlistComponent,
    PifyouthcentergraphComponent,
    PifstategraphComponent,
    PifcitygraphComponent,
    PifoverallgendergraphComponent,
    PifstandardgraphComponent,
    LanguagewiseenrollmentgraphComponent,
    LaguagewiseschoolgraphComponent,
    English1stComponent,
    English2ndComponent,
    English3rdComponent,
    English4thComponent,
    English5thComponent,
    English6thComponent,
    English7thComponent,
    English8thComponent,
    English9thComponent,
    English11thComponent,
    Gujarati5thComponent,
    Gujarati6thComponent,
    Gujarati7thComponent,
    Gujarati8thComponent,
    Gujarati9thComponent,
    Gujarati11thComponent,
    TheoryassissmentComponent,
    PracticalassissmentComponent,
    PracassessmentlevelwiseComponent,
    Practcompetency2022Component,
    Standard5Component,
    Standard6Component,
    Standard7Component,
    Standard8Component,
    Standard9Component,
    Standard10Component,
    Standard11Component,
    Standard12Component,
    Kerala5Component,
    Kerala6Component,
    Kerala7Component,
    Allstate5Component,
    Allstate6Component,
    Allstate7Component,
    Allstate8Component,
    Allstate9Component,
    Allstate11Component,
    Kerala8Component,
    Kerala9Component,
    Kerala11Component,
    Baselinestandard5Component,
    Baselinestd6Component,
    Baselinestd7Component,
    Baselinestd8Component,
    Baselinestd9Component,
    Baselinestd10Component,
    Baselinestd11Component,
    Baselinestd12Component,
    Baseline2022std5Component,
    Baseline2022std6Component,
    Baseline2022std7Component,
    Baseline2022std8Component,
    Baseline2022std9Component,
    Baseline2022std10Component,
    Baseline2022std11Component,
    Baseline2022std12Component,
    Baseline2022gujstd9Component,
    Baseline2022gujstd10Component,
    Baseline2022gujstd11Component,
    Baseline2022gujstd12Component,
    Baselinestd62022Component,
    Baselinestd72022Component,
    Baselinestd82022Component,
    Baselinestd92022Component,
    Baselinestd102022Component,
    Baselinestd112022Component,
    Baselinestd122022Component,
    AtaldashboardComponent,
    TrainingComponent,
    GeneralimpactofwholetrainingComponent,
    LearningatmosphereComponent,
    PracticalsessionscoveredintrainingComponent,
    ComparisonwiseComponent,
    Std5Component,
    Std6Component,
    Std7Component,
    Std8Component,
    Std9Component,
    Std10Component,
    Std11Component,
    Std12Component,
    ComparisonpracComponent,
    Midlinestd5Component,
    Midlinestd6Component,
    Midlinestd7Component,
    Midlinestd8Component,
    Midlinestd9Component,
    Midlinestd10Component,
    Midlinestd11Component,
    Midlinestd12Component,
    Midlinegujstd9Component,
    Midlinegujstd10Component,
    Midlinegujstd11Component,
    Midlinegujstd12Component,
    Pracmidlinestd5Component,
    Pracmidlinestd6Component,
    Pracmidlinestd7Component,
    Pracmidlinestd8Component,
    Pracmidlinestd9Component,
    Pracmidlinestd10Component,
    Pracmidlinestd11Component,
    Pracmidlinestd12Component,
    Pracmidlinegujstd9Component,
    Pracmidlinegujstd10Component,
    Pracmidlinegujstd11Component,
    Pracmidlinegujstd12Component,
    KeralaevaluationComponent,
    Endlinekerala2022Component,
    Comparisonkerala2022Component,
    Keralacomparisonstd1Component,
    Keralacomparisonstd2Component,
    Keralacomparisonstd3Component,
    Keralacomparisonstd4Component,
    Keralacomparisonstd5Component,
    Keralacomparisonstd6Component,
    Keralacomparisonstd7Component,
    Endlinekeralastd1Component,
    Endlinekeralastd2Component,
    Endlinekeralastd3Component,
    Endlinekeralastd4Component,
    Endlinekeralastd5Component,
    Endlinekeralastd6Component,
    Endlinekeralastd7Component,
    EnrollmentListComponent,
    AttendanceListComponent,
    PartnerinfralistComponent,
    MyeSchoolDashboardComponent,
    MyeSchoolSchoollistComponent,
    EnrollmentlistComponent,
    MyeSchoolDigiclassroomComponent,
    MyeSchoolEnrollmentComponent,
    GraphofcityComponent,
    GraphofstateComponent,
    GraphofgenderComponent,
    GraphofstandardComponent,
    InfralistComponent,
    MyeschoolteachertrainingComponent,
    
  ],
  entryComponents: [PhotosliderviewpopupComponent, PendamiceventmessageComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
