import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Statusenroll } from 'app/ClassFiles/Statusenroll';
import { Chart } from 'chart.js';
import { Idiageenrollment } from 'app/ClassFiles/idiageenrollment';
import { Qualificationgraph } from 'app/ClassFiles/qualificationgraph';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-idi-enrollment-graph',
  templateUrl: './idi-enrollment-graph.component.html',
  styleUrls: ['./idi-enrollment-graph.component.css']
})
export class IdiEnrollmentGraphComponent implements OnInit {
  attsumamary: FormGroup;
  courseattsummary: FormGroup;
  loading = false;
  labels1 = [];
  strwhere = '';
  array = [];
  where = "";
  gendersummarycount;
  countdata;
  cdata
  dataobj1;
  citydpd;
  role;
  srccity;

  chartOptions1 = {
    legend: {
      display: true,
      position: 'bottom',
    },
    plugins: {
      responsive: true,
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        anchor: 'end',
        color: 'black',
        font: {
          size: 9,
        }
      }
    }
  }

  chartData1 = [
    {
      label: [],
      data: [],

    }
  ];

  // CHART COLOR.
  colors1 = [
    {
      backgroundColor: '#5690F5'
    },
    {
      backgroundColor: '#F05EC6'
    },
    {
      backgroundColor: '#F3D73A'
    }
    ,
    {
      backgroundColor: '#F34346'
    }

  ]

  groupbatchcount;
  ongoingcount;
  completedcount;
  dropoutcount;
  groupbatch; girls14count; girls18count; girls21count;
  course;
  coursename; Boys; Girls;
  total14count; boys14count; boys18count; boys21count;
  total18count;
  total21count;
  gendersummary;
  BelowSSCcount;
  SSCClass11count;
  HSCcount;
  PersuingGraduationcount;
  Gradudatecount;
  public stdchart: Chart; att = [];
  public stdchart1: Chart; attt = [];
  public intrestedchart: Chart; intrested = [];
  public statuschart: Chart; stacan = [];
  public qualichart: Chart;
  quali = [];
  public notintrestedchart: Chart;
  notintrested = [];
  public pieChartData: any[];
  public pieChartLabels: Array<string> = ['Boys', 'Girls'];
  public pieChartType: string;
  pieChartColor: any = [
    {
      backgroundColor: ['#F18AE0',
        '#8DEEDB',
      ]
    }
  ]
  public piechartOptions: any = {
    plugins: {
      responsive: true,
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        align: 'center',
        anchor: 'center',
        color: 'black',
        font: {
          size: 8,
        }
      }
    }
  };

  //line
  // public lineChartType: string;
  // public lineChartLegend: boolean = true;
  public lineChartData;
  public lineChartLabels: Array<string> = ['AGrade', 'BGrade', 'CGrade', 'DGrade'];
  public lineChartType = 'line';
  public lineChartLegend = true;
  public linechartOptions: any = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      responsive: true,
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        align: 'right',
        anchor: 'right',
        color: 'black',
        font: {
          size: 10,
        }
      }
    },

  };
  lineChartColors: any = [
    {
      fill: false,
      borderColor: 'rgba(129, 159, 247, 1)',
      pointBackgroundColor: 'rgba(129, 159, 247, 1)',
      pointBorderColor: 'rgba(129, 159, 247, 1)',
      lineTension: 0,
      pointRadius: 4,
    }
  ]

  public linecoursedata;
  public linecourselabels: Array<string> = ['AGrade', 'BGrade', 'CGrade', 'DGrade'];
  public linecoursetype = 'line';
  public linecourselegend = true;
  public linecourseoptions: any = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      responsive: true,
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
        },
        align: 'right',
        anchor: 'right',
        color: 'black',
        font: {
          size: 10,
        }
      }
    },

  };
  linecoursecolors: any = [
    {
      fill: false,
      borderColor: 'rgba(129, 159, 247, 1)',
      pointBackgroundColor: 'rgba(129, 159, 247, 1)',
      pointBorderColor: 'rgba(129, 159, 247, 1)',
      lineTension: 0,
      pointRadius: 4,
    }
  ]

  gradedata = new Array();
  Gradelabel = new Array();
  public linechart2: Chart;
  Chart = [];

  UserId; centid; financialyear; pgid
  public chart: Chart;
  public barChartType: string;
  barChartPlugins = [pluginDataLabels];
  public barChartType1: string;
  pgname;
  ongoing;
  dropout;
  complete;
  enrollment;
  currmonthval;
  secourse = 'CRS3';
  coursemonth;
  curryear;
  courseyear;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) {
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    this.financialyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');
    this.pgid = localStorage.getItem('pgid');
    this.role = localStorage.getItem("role");
   

    var sysdate = new Date();

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    this.currmonthval = monthNames[sysdate.getMonth()];
    this.coursemonth = monthNames[sysdate.getMonth()];
    this.curryear = sysdate.getFullYear();
    this.courseyear = sysdate.getFullYear();
   
    if (this.currmonthval == 'January') {
      this.currmonthval = 'December';
      
      this.curryear = sysdate.getFullYear() - 1;
    }
    if (this.coursemonth == 'August') {
      this.coursemonth = 'July';
   
      this.courseyear = sysdate.getFullYear() - 1;
    }

    this.service.FillDropDown("center", "distinct city", "city", "inner join CenterPartner on Center.centid=CenterPartner.centid and center.status=0 and CenterPartner.status=0 and pgid='P6' and CenterPartner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
      this.citydpd = data;
    });
    this.service.FillDropDown("Center", "distinct Course.coursename", "Course.courseid", "inner join CenterPartner on CenterPartner.centid = Center.centid inner join Partner on Partner.partnerid = CenterPartner.partnerid inner join Student on Student.centid = Center.centid inner join StudentCourse on StudentCourse.studentid = Student.studentid inner join Course on Course.courseid = StudentCourse.courseid where CenterPartner.partnerid ='" + this.UserId + "' and CenterPartner.status=0").subscribe((data: any) => {
      this.courseattsummary.controls['courseatt'].setValue(data[0].column1)
      this.course = data;
    });

    if (this.financialyear == "2024-2025") {
      this.service.GetPartnerIDIDashStudStatusCount(this.pgid, "where CenterPartner.partnerid='" + this.UserId + "' ").subscribe((data: any) => {
        this.ongoing = data[0].studstatusact;
        this.dropout = data[0].studstatusdrop;
        this.complete = data[0].studstatusComp;
        this.enrollment = data[0].totenrolment;
      });

      //  Age Enrollment Summary
      this.service.GetPartnerIDIEnrollAgeNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: Idiageenrollment[]) => {

        this.groupbatch = new Array();
        this.total14count = new Array();
        this.total18count = new Array();
        this.total21count = new Array();

        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.total14count.push((y.total14));
          this.total18count.push((y.total18));
          this.total21count.push((y.total21));



        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.total14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.total18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.total21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      //  GetPartnerIDIMaleFemaleRatioNew  
      this.service.GetPartnerMaleFemaleRatioSummaryNewIDI(this.UserId, this.financialyear, this.pgid, "where Centerpartner.partnerid='" + this.UserId + "'").subscribe((res: any) => {
        this.chartData1 = [];
        this.dataobj1 = res.data;
        this.labels1 = res.Batch;
        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
        }
      });


      //  StatusWise Enrolment Summary IDI 
      this.service.GetPartnerIDIEnrollStatusNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: Statusenroll[]) => {
        this.groupbatch = new Array();
        this.ongoingcount = new Array();
        this.completedcount = new Array();
        this.dropoutcount = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.ongoingcount.push((y.boys14));
          this.completedcount.push((y.boys18));
          this.dropoutcount.push((y.boys21));
        });
        if (this.statuschart) { this.statuschart.destroy(); }
        this.statuschart = new Chart('statuscanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [
              {
                label: 'Ongoing',
                data: this.ongoingcount,
                backgroundColor: "#F7DC6F",
                borderColor: '#F7DC6F',
                fill: true,

              },

              {
                label: 'Completed',
                data: this.completedcount,
                backgroundColor: "#58D68D",
                borderColor: '#58D68D',
                fill: true
              },

              {
                label: 'Dropout',
                data: this.dropoutcount,
                backgroundColor: "#EC7063",
                borderColor: '#EC7063',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },

            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],

              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });

      // if(this.UserId != 'PT58'){
      // Agewise Interested For Job Summary IDI 
      this.service.GetPartnerIDIEnrollAgeIntJobNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.boys14count = new Array();
        this.boys18count = new Array();
        this.boys21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.boys14count.push((y.boys14));
          this.boys18count.push((y.boys18));
          this.boys21count.push((y.boys21));

        });
        if (this.intrestedchart) { this.intrestedchart.destroy(); }
        this.intrestedchart = new Chart('intrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.boys14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,
              },
              {
                label: '18-21',
                data: this.boys18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.boys21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      //  Agewise not Interested For Job Summary IDI 
      this.service.GetPartnerIDIEnrollAgeIntJobNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.girls14count = new Array();
        this.girls18count = new Array();
        this.girls21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.girls14count.push((y.girls14));
          this.girls18count.push((y.girls18));
          this.girls21count.push((y.girls21));

        });
        if (this.notintrestedchart) { this.notintrestedchart.destroy(); }
        this.notintrestedchart = new Chart('notintrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.girls14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.girls18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.girls21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });
      // }
      //  overall gender Summary IDI 
      this.service.GetPartnerGenderEnrollmentSummaryIDI("where Centerpartner.partnerid='" + this.UserId + "' and center.pgid='" + this.pgid + "'").subscribe((res: any) => {
        this.gendersummary = res;
        this.gendersummarycount = res.length;
        this.pieChartData = [
          ((res[0].boys * 100) / (res[0].totstud)).toFixed(2)
          , ((res[0].girls * 100) / (res[0].totstud)).toFixed(2)];
      });

      //Attendance Summary
      // this.service.GetPartnerIDIAttendanceGraph(this.pgid, this.UserId, "where month ='" + this.currmonthval + "' and year = '" + this.curryear + "'").subscribe((res: any) => {
      //   this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
      // });

      //course Attendance Summary      
      // this.service.GetPartnerIDICourseWiseGraph(this.pgid, this.UserId, "where month ='" + this.coursemonth + "' and year = '" + this.courseyear + "' and course.courseid ='"+ this.course+"'").subscribe((res: any) => {
      // this.service.GetPartnerIDICourseWiseGraph(this.pgid, this.UserId, "where month ='" + this.coursemonth + "' and year = '" + this.courseyear + "' and course.courseid ='CRS3'").subscribe((res: any) => {
      this.service.GetPartnerIDICourseWiseGraph(this.pgid, this.UserId, "where month ='" + this.currmonthval + "' and year = '" + this.curryear + "' and course.courseid ='CRS3'").subscribe((res: any) => {
        // this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
        res.forEach(y => {
          // this.gradedata.push((y.agrade).toFixed(2));
          this.Gradelabel.push('A Grade (100 >= 76)')
          // this.gradedata.push(((y.bgrade * 100) / y.totstudent).toFixed(2));
          this.Gradelabel.push('B Grade (75  >= 51)')
          // this.gradedata.push(((y.cgrade * 100) / y.totstudent).toFixed(2));
          this.Gradelabel.push('C Grade (50  >= 35)')
          // this.gradedata.push(((y.dgrade * 100) / y.totstudent).toFixed(2));
          this.Gradelabel.push('D Grade (BELOW 35)')
          // this.gradedata.push(((y.egrade * 100) / y.totstudent).toFixed(2));
          // this.Gradelabel.push('E Grade (0 ATTENDANCE)')
        });
        

        // console.log(this.lineChartData);
        this.lineChartData = [
          { 
            data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade],
            label:"Grades"
           }];

        
      });


      // course wise enrollment summary
      this.service.GetPartnerMaleFemaleRatioCourseWiseSummaryIDI("where Centerpartner.partnerid='" + this.UserId + "' and center.pgid='" + this.pgid + "'").subscribe((res: Idiageenrollment[]) => {


        this.coursename = new Array();
        this.groupbatch = new Array();
        this.Boys = new Array();
        this.Girls = new Array();

        res.forEach(y => {

          this.coursename.push(y.coursename);
          this.Boys.push((y.Boys));
          this.Girls.push((y.Girls));
          this.groupbatch.push((y.groupbatch));



        });
        if (this.stdchart1) { this.stdchart1.destroy(); }
        this.stdchart1 = new Chart('attcanvass', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.coursename,

            datasets: [

              {
                label: 'Boys',
                data: this.Boys,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: 'Girls',
                data: this.Girls,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

    }

    else if (this.financialyear == "2017-2018" || this.financialyear == "2018-2019" || this.financialyear == "2019-2020" || this.financialyear == "2020-2021" || this.financialyear == "2021-2022" || this.financialyear == "2022-2023" || this.financialyear == "2023-2024") {

      this.service.SahyogiIDIData(this.UserId, this.financialyear).subscribe((data: any) => {
        this.ongoing = data[0].ongoing;
        this.dropout = data[0].dropout;
        this.complete = data[0].completed;
        this.enrollment = data[0].totstud;
      });

      // gender summary
      this.service.OLDPartnerIDIOverallGenderSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: any) => {
        this.gendersummary = res;
        this.gendersummarycount = res.length;
        this.pieChartData = [
          ((res[0].boys * 100) / (res[0].totstud)).toFixed(2)
          , ((res[0].girls * 100) / (res[0].totstud)).toFixed(2)];
      });

      // Age Enrollment Summary
      this.service.OLDPartnerIDIAgeEnrolmentSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.total14count = new Array();
        this.total18count = new Array();
        this.total21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.total14count.push((y.total14));
          this.total18count.push((y.total18));
          this.total21count.push((y.total21));



        });
        if (this.stdchart) { this.stdchart.destroy(); }
        this.stdchart = new Chart('attcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [
              {
                label: 'Below 18',
                data: this.total14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,
              },
              {
                label: '18-21',
                data: this.total18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },
              {
                label: 'Above 21',
                data: this.total21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],
          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      // Agewise Interested For Job Summary IDI 
      this.service.OLDPartnerIDIAgeIntJobSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.boys14count = new Array();
        this.boys18count = new Array();
        this.boys21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.boys14count.push((y.boys14));
          this.boys18count.push((y.boys18));
          this.boys21count.push((y.boys21));

        });
        if (this.intrestedchart) { this.intrestedchart.destroy(); }
        this.intrestedchart = new Chart('intrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.boys14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.boys18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.boys21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      // Agewise not Interested For Job Summary IDI 
      this.service.OLDPartnerIDIAgeIntJobSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: Idiageenrollment[]) => {
        this.groupbatch = new Array();
        this.girls14count = new Array();
        this.girls18count = new Array();
        this.girls21count = new Array();


        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.girls14count.push((y.girls14));
          this.girls18count.push((y.girls18));
          this.girls21count.push((y.girls21));

        });
        if (this.notintrestedchart) { this.notintrestedchart.destroy(); }
        this.notintrestedchart = new Chart('notintrestedcanvas', {
          type: 'bar',

          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Below 18',
                data: this.girls14count,
                backgroundColor: "#D4DC9B",
                borderColor: '#D4DC9B',
                fill: true,

              },

              {
                label: '18-21',
                data: this.girls18count,
                backgroundColor: "#9BE24F",
                borderColor: '#9BE24F',
                fill: true
              },

              {
                label: 'Above 21',
                data: this.girls21count,
                backgroundColor: "#4F87E2",
                borderColor: '#4F87E2',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            },
            plugins: {
              responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 10,
                }
              }
            }
          }
        });
      });

      // StatusWise Enrolment Summary IDI 
      this.service.OLDPartnerIDIStatusWiseEnrolmentSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: Statusenroll[]) => {
        this.groupbatch = new Array();
        this.ongoingcount = new Array();
        this.completedcount = new Array();
        this.dropoutcount = new Array();
        res.forEach(y => {
          this.groupbatch.push(y.groupbatch);
          this.ongoingcount.push((y.ongoing));
          this.completedcount.push((y.completed));
          this.dropoutcount.push((y.dropout));
        });

        if (this.statuschart) { this.statuschart.destroy(); }
        this.statuschart = new Chart('statuscanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,

            datasets: [

              {
                label: 'Ongoing',
                data: this.ongoingcount,
                backgroundColor: "#85C1E9",
                borderColor: '#85C1E9',
                fill: true,

              },

              {
                label: 'Completed',
                data: this.completedcount,
                backgroundColor: "#A3E4D7",
                borderColor: '#A3E4D7',
                fill: true
              },

              {
                label: 'Dropout',
                data: this.dropoutcount,
                backgroundColor: "#F1948A",
                borderColor: 'rgba(0,128,0, 0.9)',
                fill: true
              }
            ],

          },
          options: {

            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",

                }
              }],
            }
          }
        });
      });

      // Qualificationwise Summary IDI 
      this.service.OLDPartnerIDIQualificationwiseSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: Qualificationgraph[]) => {
        this.groupbatch = new Array();
        this.BelowSSCcount = new Array();
        this.SSCClass11count = new Array();
        this.HSCcount = new Array();
        this.PersuingGraduationcount = new Array();
        this.Gradudatecount = new Array();
        res.forEach(y => {

          this.groupbatch.push(y.groupbatch);
          this.BelowSSCcount.push((y.BelowSSC));
          this.SSCClass11count.push((y.SSCClass11));
          this.HSCcount.push((y.HSC));
          this.PersuingGraduationcount.push((y.PersuingGraduation));
          this.Gradudatecount.push((y.Gradudate));
        });
        if (this.qualichart) { this.qualichart.destroy(); }
        this.qualichart = new Chart('qualificationcanvas', {
          type: 'bar',
          //responsive: true,
          data: {
            labels: this.groupbatch,
            datasets: [
              {
                label: 'BelowSSC',
                data: this.BelowSSCcount,
                backgroundColor: "#85C1E9",
                borderColor: '#85C1E9',
                fill: true,

              },

              {
                label: 'SSCClass11',
                data: this.SSCClass11count,
                backgroundColor: "#F4D03F",
                borderColor: '#F4D03F',
                fill: true
              },

              {
                label: 'HSC',
                data: this.HSCcount,
                backgroundColor: "#F1948A",
                borderColor: '#F1948A',
                fill: true
              },
              {
                label: 'PersuingGraduation',
                data: this.PersuingGraduationcount,
                backgroundColor: "#ABEBC6",
                borderColor: '#ABEBC6',
                fill: true,

              },

              {
                label: 'Gradudate',
                data: this.Gradudatecount,
                backgroundColor: "rgba(204, 204, 0, 0.9)",
                borderColor: 'rgba(21, 230, 69, 0.9)',
                fill: true
              }
            ],

          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              responsive: true,
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 8,
                }
              }
            }
          }
        });
      });

      // Attendance line Summary IDI 
      this.service.OLDPartnerIDIAttendanceSummary(this.UserId, this.financialyear, this.pgid).subscribe((res: any) => {
        this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
      });

      // GetPartnerIDIMaleFemaleRatioNew  
      this.service.OLDPartnerIDIMaleFemaleRatio(this.UserId, this.financialyear, this.pgid).subscribe((res: any) => {
        // this.loading = false;        
        this.chartData1 = [];
        this.dataobj1 = res.data;
        this.labels1 = res.Batch;

        for (let i = 0; i < this.dataobj1.length; i++) {
          this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
        }
      });

    }
  }

  // show(currmonthval,curryear) {
  //   this.service.GetPartnerIDIAttendanceGraph(this.pgid, this.UserId, "where month ='" + currmonthval + "' and year = '" + curryear + "'").subscribe((res: any) => {
  //     if (res.length == 1) {
  //       this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }];
  //       console.log(this.lineChartData);

  //     } else if (res == '') {
  //       this.lineChartData = '';
  //     }
  //   })
  // }
  Oncourse(s){
    this.secourse = s;
  }
  show1(secourse) {

    this.service.GetPartnerIDICourseWiseGraph(this.pgid, this.UserId, "where month ='" + this.currmonthval + "' and year = '" + this.curryear + "' and course.courseid ='" + secourse + "'").subscribe((res: any) => {
      this.Gradelabel = [];
      res.forEach(y => {
        // this.gradedata.push((y.agrade).toFixed(2));
        this.Gradelabel.push('A Grade (100 >= 76)')
        // this.gradedata.push(((y.bgrade * 100) / y.totstudent).toFixed(2));
        this.Gradelabel.push('B Grade (75  >= 51)')
        // this.gradedata.push(((y.cgrade * 100) / y.totstudent).toFixed(2));
        this.Gradelabel.push('C Grade (50  >= 35)')
        // this.gradedata.push(((y.dgrade * 100) / y.totstudent).toFixed(2));
        this.Gradelabel.push('D Grade (BELOW 35)')
        // this.gradedata.push(((y.egrade * 100) / y.totstudent).toFixed(2));
        // this.Gradelabel.push('E Grade (0 ATTENDANCE)')
      });
      

      // console.log(this.lineChartData);
      this.lineChartData = [
        { 
          data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade],
          label:"Grades"
         }];
    })

    // this.service.GetPartnerIDICourseWiseGraph(this.pgid, this.UserId, "where month ='" + this.currmonthval + "' and year = '" + this.curryear + "' and course.courseid ='" + this.course + "'").subscribe((res: any) => {
    //   var graph3 = document.getElementById("graph3");
    //   if (res.length != 0) {
    //     graph3.style.display = "block";
    //     res.forEach(y => {

    //       this.gradedata.push((y.agrade).toFixed(2));
    //       this.Gradelabel.push('A Grade (100 >= 76)')
    //       this.gradedata.push(((y.bgrade * 100) / y.totstudent).toFixed(2));
    //       this.Gradelabel.push('B Grade (75  >= 51)')
    //       this.gradedata.push(((y.cgrade * 100) / y.totstudent).toFixed(2));
    //       this.Gradelabel.push('C Grade (50  >= 35)')
    //       this.gradedata.push(((y.dgrade * 100) / y.totstudent).toFixed(2));
    //       this.Gradelabel.push('D Grade (BELOW 35)')
    //       this.gradedata.push(((y.egrade * 100) / y.totstudent).toFixed(2));
    //       this.Gradelabel.push('E Grade (0 ATTENDANCE)')
    //     });

    //     this.linechart2 = new Chart('canvaspie1', {
    //       type: 'line',
    //       data: {
    //         labels: this.Gradelabel,
    //         datasets: [
    //           {
    //             data: this.gradedata,
    //             backgroundColor: [
    //               "#3cba54",
    //               "#f4c20d",
    //               "#4885ed",
    //               "#8a2be2",
    //               "#db3236"

    //             ],
    //             fill: false
    //           }
    //         ]
    //       },
    //       options: {
    //         legend: {
    //           display: true

    //         },
    //         plugins: {
    //           datalabels: {
    //             display: function (context) {
    //               return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
    //             },
    //             align: 'center',
    //             anchor: 'center',
    //             color: 'black'
    //           }
    //         }
    //       }
    //     });
    //   }
    //   else {
    //     graph3.style.display = "none";
    //   }
    // })
  }

  // toggleshow(course,coursemonth,courseyear) { 
  //   console.log(this.course,this.coursemonth,this.courseyear);

  //   this.service.GetPartnerIDICourseWiseGraph(this.pgid, this.UserId, "where month ='" + coursemonth + "' and year = '" + courseyear + "' and course.courseid ='"+ course+"'").subscribe((res: any) => {
  //     console.log(this.course);

  //     if (res.length == 1) {
  //       this.linecoursedata = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }];
  //       this.linecoursedata.render();
  //       console.log(this.linecoursedata);

  //     } else if (res == '') {
  //       this.linecoursedata = '';
  //     }
  //   })
  // }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';

    this.attsumamary = this.fb.group({
      monthcontrol: ['', Validators.required],
      yearcontrol: ['', Validators.required],
    });
    this.courseattsummary = this.fb.group({
      month: ['', Validators.required],
      year: ['', Validators.required],
      courseatt: ['', Validators.required],
    });

  }

  filterrecord(srccity) {
    
    this.loading = true;
    this.array = [];
    this.strwhere = '';

    if (srccity != null && srccity != '' && srccity != undefined && srccity != 'Select') {
      this.array.push("Center.city = '" + srccity + "'");
    }
    if (this.array.length == 0) {
      this.where = this.strwhere;
    }
    else if (this.array.length == 1) {
      this.strwhere = ' and ' + this.array[0].toString();
    }
    // else {
    //   this.strwhere = " and " + this.array[0].toString();
    //   for (let i = 1; i < this.array.length; i++) {
    //     this.strwhere = this.strwhere + " and " + this.array[i].toString();
    //   }
    // }

    this.where = this.strwhere;
  
    //  Age Enrollment Summary
    this.service.GetPartnerIDIEnrollAgeNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid  +"'"+ this.where).subscribe((res: Idiageenrollment[]) => {

      this.groupbatch = new Array();
      this.total14count = new Array();
      this.total18count = new Array();
      this.total21count = new Array();

      res.forEach(y => {

        this.groupbatch.push(y.groupbatch);
        this.total14count.push((y.total14));
        this.total18count.push((y.total18));
        this.total21count.push((y.total21));



      });
      if (this.stdchart) { this.stdchart.destroy(); }
      this.stdchart = new Chart('attcanvas', {
        type: 'bar',
        //responsive: true,
        data: {
          labels: this.groupbatch,

          datasets: [

            {
              label: 'Below 18',
              data: this.total14count,
              backgroundColor: "#D4DC9B",
              borderColor: '#D4DC9B',
              fill: true,

            },

            {
              label: '18-21',
              data: this.total18count,
              backgroundColor: "#9BE24F",
              borderColor: '#9BE24F',
              fill: true
            },

            {
              label: 'Above 21',
              data: this.total21count,
              backgroundColor: "#4F87E2",
              borderColor: '#4F87E2',
              fill: true
            }
          ],

        },
        options: {

          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
          plugins: {
            responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'black',
              font: {
                size: 10,
              }
            }
          }
        }
      });
    });

    //  GetPartnerIDIMaleFemaleRatioNew  
    this.service.GetPartnerMaleFemaleRatioSummaryNewIDI(this.UserId, this.financialyear, this.pgid, "where Centerpartner.partnerid='" + this.UserId +"'"+ this.where).subscribe((res: any) => {
      this.chartData1 = [];
      this.dataobj1 = res.data;
      this.labels1 = res.Batch;
      for (let i = 0; i < this.dataobj1.length; i++) {
        this.chartData1.push({ label: this.dataobj1[i].GenderName, data: this.dataobj1[i].GenderCount });
      }
    });

    //  StatusWise Enrolment Summary IDI 
    this.service.GetPartnerIDIEnrollStatusNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid +"'"+ this.where).subscribe((res: Statusenroll[]) => {
      this.groupbatch = new Array();
      this.ongoingcount = new Array();
      this.completedcount = new Array();
      this.dropoutcount = new Array();


      res.forEach(y => {

        this.groupbatch.push(y.groupbatch);
        this.ongoingcount.push((y.boys14));
        this.completedcount.push((y.boys18));
        this.dropoutcount.push((y.boys21));
      });
      if (this.statuschart) { this.statuschart.destroy(); }
      this.statuschart = new Chart('statuscanvas', {
        type: 'bar',
        //responsive: true,
        data: {
          labels: this.groupbatch,

          datasets: [
            {
              label: 'Ongoing',
              data: this.ongoingcount,
              backgroundColor: "#F7DC6F",
              borderColor: '#F7DC6F',
              fill: true,

            },

            {
              label: 'Completed',
              data: this.completedcount,
              backgroundColor: "#58D68D",
              borderColor: '#58D68D',
              fill: true
            },

            {
              label: 'Dropout',
              data: this.dropoutcount,
              backgroundColor: "#EC7063",
              borderColor: '#EC7063',
              fill: true
            }
          ],

        },
        options: {

          legend: {
            display: true,
            position: 'bottom',
          },

          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],

            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
          plugins: {
            responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'black',
              font: {
                size: 8,
              }
            }
          }
        }
      });
    });

    // if(this.UserId != 'PT58'){
    // Agewise Interested For Job Summary IDI 
    this.service.GetPartnerIDIEnrollAgeIntJobNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid +"'"+ this.where).subscribe((res: Idiageenrollment[]) => {
      this.groupbatch = new Array();
      this.boys14count = new Array();
      this.boys18count = new Array();
      this.boys21count = new Array();


      res.forEach(y => {

        this.groupbatch.push(y.groupbatch);
        this.boys14count.push((y.boys14));
        this.boys18count.push((y.boys18));
        this.boys21count.push((y.boys21));

      });
      if (this.intrestedchart) { this.intrestedchart.destroy(); }
      this.intrestedchart = new Chart('intrestedcanvas', {
        type: 'bar',

        data: {
          labels: this.groupbatch,

          datasets: [

            {
              label: 'Below 18',
              data: this.boys14count,
              backgroundColor: "#D4DC9B",
              borderColor: '#D4DC9B',
              fill: true,
            },
            {
              label: '18-21',
              data: this.boys18count,
              backgroundColor: "#9BE24F",
              borderColor: '#9BE24F',
              fill: true
            },

            {
              label: 'Above 21',
              data: this.boys21count,
              backgroundColor: "#4F87E2",
              borderColor: '#4F87E2',
              fill: true
            }
          ],

        },
        options: {

          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
          plugins: {
            responsive: true,
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'black',
              font: {
                size: 10,
              }
            }
          }
        }
      });
    });

    //  Agewise not Interested For Job Summary IDI 
    this.service.GetPartnerIDIEnrollAgeIntJobNew("where Centerpartner.partnerid='" + this.UserId + "' and pgid='" + this.pgid +"'"+ this.where).subscribe((res: Idiageenrollment[]) => {
      this.groupbatch = new Array();
      this.girls14count = new Array();
      this.girls18count = new Array();
      this.girls21count = new Array();


      res.forEach(y => {

        this.groupbatch.push(y.groupbatch);
        this.girls14count.push((y.girls14));
        this.girls18count.push((y.girls18));
        this.girls21count.push((y.girls21));

      });
      if (this.notintrestedchart) { this.notintrestedchart.destroy(); }
      this.notintrestedchart = new Chart('notintrestedcanvas', {
        type: 'bar',

        data: {
          labels: this.groupbatch,

          datasets: [

            {
              label: 'Below 18',
              data: this.girls14count,
              backgroundColor: "#D4DC9B",
              borderColor: '#D4DC9B',
              fill: true,

            },

            {
              label: '18-21',
              data: this.girls18count,
              backgroundColor: "#9BE24F",
              borderColor: '#9BE24F',
              fill: true
            },

            {
              label: 'Above 21',
              data: this.girls21count,
              backgroundColor: "#4F87E2",
              borderColor: '#4F87E2',
              fill: true
            }
          ],

        },
        options: {

          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",

              }
            }],
          },
          plugins: {
            responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'black',
              font: {
                size: 10,
              }
            }
          }
        }
      });
    });

    // }
    //  overall gender Summary IDI 
    this.service.GetPartnerGenderEnrollmentSummaryIDI("where Centerpartner.partnerid='" + this.UserId + "' and center.pgid='" + this.pgid +"'"+ this.where).subscribe((res: any) => {
      this.gendersummary = res;
      this.gendersummarycount = res.length;
      this.pieChartData = [
        ((res[0].boys * 100) / (res[0].totstud)).toFixed(2)
        , ((res[0].girls * 100) / (res[0].totstud)).toFixed(2)];
    })

    // course wise enrollment summary
    this.service.GetPartnerMaleFemaleRatioCourseWiseSummaryIDI("where Centerpartner.partnerid='" + this.UserId + "' and center.pgid='" + this.pgid +"'"+ this.where).subscribe((res: Idiageenrollment[]) => {

      this.coursename = new Array();
      this.groupbatch = new Array();
      this.Boys = new Array();
      this.Girls = new Array();

      res.forEach(y => {

        this.coursename.push(y.coursename);
        this.Boys.push((y.Boys));
        this.Girls.push((y.Girls));
        this.groupbatch.push((y.groupbatch));



      });
      if (this.stdchart1) { this.stdchart1.destroy(); }
      this.stdchart1 = new Chart('attcanvass', {
        type: 'bar',
        //responsive: true,
        data: {
          labels: this.coursename,

          datasets: [

            {
              label: 'Boys',
              data: this.Boys,
              backgroundColor: "#D4DC9B",
              borderColor: '#D4DC9B',
              fill: true,

            },

            {
              label: 'Girls',
              data: this.Girls,
              backgroundColor: "#9BE24F",
              borderColor: '#9BE24F',
              fill: true
            },
          ],

        },
        options: {

          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
          plugins: {
            responsive: true,    // THIS WILL MAKE THE CHART RESPONSIVE (VISIBLE IN ANY DEVICE).
            datalabels: {
              display: function (context) {
                return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
              align: 'center',
              anchor: 'center',
              color: 'black',
              font: {
                size: 10,
              }
            }
          }
        }
      });
    });

    //Attendance Summary
    this.service.GetPartnerIDIAttendanceGraph(this.pgid, this.UserId, "where month ='" + this.currmonthval + "' and year = '" + this.curryear +"'"+ this.where).subscribe((res: any) => {
      this.lineChartData = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
    })

    //course Attendance Summary
    this.service.GetPartnerIDICourseWiseGraph(this.pgid, this.UserId, "where month ='" + this.coursemonth + "' and year = '" + this.courseyear + "' and course.courseid ='" + this.course +"'"+ this.where).subscribe((res: any) => {
      this.linecoursedata = [{ data: [res[0].agrade, res[0].bgrade, res[0].cgrade, res[0].dgrade], label: 'Grades' }]
    });

    this.loading = false;
  }

  downloadagewiseenrolmentgraph(event) {

    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "AgeWise Enrolment Summary Graph.png";
  }

  downloadacoursewiseenrolmentgraph(event) {

    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "Coursewise Enrolment Summary Graph.png";
  }

  downloadmalefemalegraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[1].toDataURL();
    anchor.download = "Male-Female Summary Graph.png";
  }

  downloadoverallgendergraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[2].toDataURL();
    anchor.download = "Overall Gender Summary Graph.png";
  }

  downloadstatuswiseenrolmentgraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = "StatusWise Enrolment Summary Graph.png";
  }

  downloadagewiseintrestedforjobgraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[4].toDataURL();
    anchor.download = "AgeWise Interested For Job Summary Graph.png";
  }

  downloadagewisenotintrestedforjobgraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[5].toDataURL();
    anchor.download = "AgeWise Not Interested For Job Summary Graph.png";
  }

  downloadqualificationsummamrygraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[6].toDataURL();
    anchor.download = "Qualification Summary Graph.png";
  }

  downloadattendancesummamrygraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[7].toDataURL();
    anchor.download = "Attendance Summary Graph.png";
  }
  downloadcourseattendancesummamrygraph(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[7].toDataURL();
    anchor.download = "Coursewise Attendance Summary Graph.png";
  }

  downloadattendancesummamrygraph2020(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[6].toDataURL();
    anchor.download = "Attendance Summary Graph.png";
  }

}