import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-youthcomputerlist',
  templateUrl: './youthcomputerlist.component.html',
  styleUrls: ['./youthcomputerlist.component.css']
})
export class YouthcomputerlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
