import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-comparisonstandard4',
  templateUrl: './comparisonstandard4.component.html',
  styleUrls: ['./comparisonstandard4.component.css']
})
export class Comparisonstandard4Component implements OnChanges {
  @Input('Standard') Standard;

  Partnerid;
  pgid;
  selected;
  loading = true;
  Comparisonstd4count;
  Comparisonstd4;
  bc1;
  bc2;
  bc3;
  bc4;
  bc5
  ec1;
  ec2;
  ec3;
  ec4;
  ec5;
  baselinecompentency = [];
  endlinecompentency = [];
  Comparisonstd4graph: any;
  grpahdata;
  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }
  ngOnChanges() {
    this.baselinecompentency = [];
    this.endlinecompentency = [];
    this.service.GetPartnerFCEvalComparision(this.Standard, this.Partnerid, this.selected,'').subscribe((data: any) => {
      this.Comparisonstd4 = data;
      this.Comparisonstd4count = data.length;
      data.forEach(y => {
        this.bc1 = ((y.bc1 * 100) / (y.basetotappeared)).toFixed(2);
        this.bc2 = ((y.bc2 * 100) / (y.basetotappeared)).toFixed(2);
        this.bc3 = ((y.bc3 * 100) / (y.basetotappeared)).toFixed(2);
        this.bc4 = ((y.bc4 * 100) / (y.basetotappeared)).toFixed(2);
        this.bc5 = ((y.bc5 * 100) / (y.basetotappeared)).toFixed(2);
        this.ec1 = ((y.ec1 * 100) / (y.endtotappeared)).toFixed(2);
        this.ec2 = ((y.ec2 * 100) / (y.endtotappeared)).toFixed(2);
        this.ec3 = ((y.ec3 * 100) / (y.endtotappeared)).toFixed(2);
        this.ec4 = ((y.ec4 * 100) / (y.endtotappeared)).toFixed(2);
        this.ec5 = ((y.ec5 * 100) / (y.endtotappeared)).toFixed(2);
      });

      this.baselinecompentency = new Array();
      this.baselinecompentency.push(this.bc1);
      this.baselinecompentency.push(this.bc2);
      this.baselinecompentency.push(this.bc3);
      this.baselinecompentency.push(this.bc4);
      this.baselinecompentency.push(this.bc5);

      this.endlinecompentency = new Array();
      this.endlinecompentency.push(this.ec1);
      this.endlinecompentency.push(this.ec2);
      this.endlinecompentency.push(this.ec3);
      this.endlinecompentency.push(this.ec4);
      this.endlinecompentency.push(this.ec5);

      var graph = document.getElementById("graph");
      if (data.length != 0) {
        graph.style.display = "block";
        if (this.Comparisonstd4graph) {
           this.Comparisonstd4graph.destroy(); 
          }
        this.Comparisonstd4graph = new Chart('comparisongraph', {
          type: 'bar',
          data: {
            labels: ["Operation on Number", "Knowledge of Number", "Knowledge of word", "Para Reading","Vocabulary"],
            datasets: [
              {
                label: "Baseline",
                backgroundColor: "#f4c20d",
                data: this.baselinecompentency
              },
              {
                label: "Endline",
                backgroundColor: "#3cba54",
                data: this.endlinecompentency
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                scaleLabel:{
                  display:true,
                labelString:"Compentencies",
              }
              }],
              yAxes: [{
                  ticks: {
                      min: 0,
                  },
              scaleLabel:{
                display:true,
              labelString:"Count",
            }
              }]
          },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }

        })
      }
      else {
        graph.style.display = "none";
      }

      this.loading = false;
    })
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "4th Standard Comparison Summary.png";
  }

}
