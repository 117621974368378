import { Component, OnInit , EventEmitter } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MasterService } from 'app/services/master.service';
import { Eventlist } from 'app/ClassFiles/eventlist';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addevent',
  templateUrl: './addevent.component.html',
  styleUrls: ['./addevent.component.css']
})
export class AddeventComponent implements OnInit {
  public addeventform: FormGroup;
  partner;
  program;
  fileToUpload: File = null;
  imageUrl: string;
  submited = new EventEmitter<string>();
  constructor( private fb: FormBuilder, private service: MasterService, private router: Router) { 
    this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear in('2023-2024','2024-2025')").subscribe((data: any) => {
      this.partner = data;
    });

    this.service.FillDropDown("PartnerProgram","distinct program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid").subscribe((data: any) => {
      this.program = data;
    });
  }

  onselectpartner(partnerdpd) {
    if (partnerdpd == '' || partnerdpd.length == 0 || partnerdpd[0] == undefined)  {
      this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear in('2023-2024','2024-2025')").subscribe((data: any) => {
      this.program = data;
    });
      } else {
        this.service.FillDropDown("PartnerProgram","distinct Program.pgname","PartnerProgram.pgid","inner join Program on PartnerProgram.pgid=Program.pgid where PartnerProgram.userid='" + partnerdpd + "'")
        .subscribe((data: any) => {
          this.program = data;
       
        })
        }
  }

  ngOnInit(){
    this.addeventform = new FormGroup({
      eventname : new FormControl('', Validators.required),
      eventplace :  new FormControl('', Validators.required),
      eventdate  :  new FormControl('', Validators.required),
      description :  new FormControl('', Validators.required),
      progname :  new FormControl('', Validators.required),
      partname :  new FormControl('', Validators.required),
      choosefile :  new FormControl('', Validators.required),
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.addeventform.controls[controlName].hasError(errorName);
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    //Show image preview
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }


  Addevent(){
    const newevent: Eventlist = new Eventlist();
    newevent.partnerid = this.addeventform.value.partname;
    newevent.pgid = this.addeventform.value.progname;
    newevent.eventname = this.addeventform.value.eventname;
    newevent.eventplace = this.addeventform.value.eventplace;
    newevent.eventdate = this.addeventform.value.eventdate;
    newevent.description = this.addeventform.value.description;
    
    this.service.InsertPartnerEventPhoto1(newevent.partnerid,newevent.pgid,newevent.eventname,newevent.eventplace,newevent.eventdate,newevent.description,this.fileToUpload).subscribe((res) => {

      if (res == "Success") {        
        this.submited.emit(res.toString());
        localStorage.setItem('eid', res.toString());
        alert("Event Added Succesfuly");
        this.router.navigate(['/EventList']);
      }
      else{
        alert("Failed to Add Event");
        this.router.navigate(['/Addevent']);
      }
    });    
  }
}
