import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baselinestd102022',
  templateUrl: './baselinestd102022.component.html',
  styleUrls: ['./baselinestd102022.component.css']
})
export class Baselinestd102022Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
