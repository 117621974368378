import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ExcelService } from 'app/services/excel.service';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-pifcomputerlist',
  templateUrl: './pifcomputerlist.component.html',
  styleUrls: ['./pifcomputerlist.component.css']
})
export class PifcomputerlistComponent implements OnInit {
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  UserId;
  pgid;
  PartnerSchoolList;
  centercount;
  state; PartnerCenterList;
  city;
  loading = true;
  showMainContent;
  array = [];
  StrWhere = "";
  statelist = new FormControl();
  citylist = new FormControl();
  searchstate; selectfiyear
  searchcity; academicyear; pgname;
  p: number = 1;
  where = "";
  searchstatus; PartnerSchoolListcount; CenterComputercount;
  constructor(private service: MasterService, private exService: ExcelService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');

    this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid").subscribe(
      (data: any) => {
        this.state = data;

      });

    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid").subscribe(
      (data: any) => {
        this.city = data;

      });
        if(this.selectfiyear == "2022-2023"){
          this.service.GetPartnerComputerPIF("").subscribe((data: any) => {
            this.PartnerCenterList = data;
            this.CenterComputercount = data.length;
            this.loading = false;
          });
      
        }
    else if(this.selectfiyear == "2021-2022"){
      this.service.OLDPartnerCenterComputerPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;
      });
    }



  }

  ngOnInit(): void {

    if (this.selectfiyear == "2022-2023" || this.selectfiyear == "2021-2022" || this.selectfiyear == null) {
      this.showMainContent = true;
    }
  } 

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;

    this.array = [];
    this.where = "";
    this.StrWhere = "";
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("c.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");

      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("c.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");

      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("c.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
  
  
      }
      this.where = this.StrWhere;

      // if (this.array.length == 0) {
      //   this.where = this.StrWhere;
      // }
      // else if (this.array.length == 1) {
      //   this.StrWhere = " and " + this.array[0].toString();
      // }
      // else {
      //   this.StrWhere = " and " + this.array[0].toString();
      //   for (let i = 1; i < this.array.length; i++) {
      //     this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
      //   }

      // }
      // this.where = this.StrWhere;
      if (this.selectfiyear == "2022-2023"){
      this.service.GetPartnerComputerPIF(this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;

      });
    }
    else if(this.selectfiyear == "2021-2022"){
      this.service.OLDPartnerCenterComputerPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.CenterComputercount = data.length;
        this.loading = false;
      });
    } 
  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }

  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  onSelectState(stname) {
    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
      (data: any) => {
        this.city = data;
      });

  }

  ExportReport(searchstate, searchcity, searchstatus) {
    this.loading = true;

    if(this.selectfiyear == "2022-2023"){
      this.service.GetPartnerComputerPIF(this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Team Leader': obj.tlname == null ? '' : obj.tlname,
            'Center Name  ': obj.centname == null ? '' : obj.centname,
            'No.of School': obj.noofschool == null ? '' : obj.noofschool,
            'No of Computers': obj.noofcomputers == null ? '' : obj.noofcomputers,
            'Total Boys': obj.totboys == null ? '' : obj.totboys,
            'Total Girls': obj.totgirls == null ? '' : obj.totgirls,
            'Total Enrollment  ': obj.totstud == null ? '' : obj.totstud,
            'Internet Availability': obj.internetavailable == null ? '' : obj.internetavailable,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'PIF Computer List');
      });
    
      }
    
    else if (this.selectfiyear == "2021-2022") {
      this.service.OLDPartnerCenterComputerPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'Team Leader': obj.tlname == null ? '' : obj.tlname,
            'Center Name  ': obj.centname == null ? '' : obj.centname,
            'No.of School': obj.noofschool == null ? '' : obj.noofschool,
            'No of Computers': obj.noofcomputers == null ? '' : obj.noofcomputers,
            'Total Boys': obj.totboys == null ? '' : obj.totboys,
            'Total Girls': obj.totgirls == null ? '' : obj.totgirls,
            'Total Enrollment  ': obj.totstud == null ? '' : obj.totstud,
            'Internet Availability': obj.internetavailable == null ? '' : obj.internetavailable,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'PIF Computer List');
      });
  }
}
}
