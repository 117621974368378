import { Component, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { PhotosliderviewpopupComponent } from '../Adminpanel/photosliderviewpopup/photosliderviewpopup.component';

@Component({
  selector: 'app-albumphotos',
  templateUrl: './albumphotos.component.html',
  styleUrls: ['./albumphotos.component.css']
})
export class AlbumphotosComponent implements OnInit {
  paid;
  photos;
  photoscount;
  constructor(private service: MasterService,public dialog: MatDialog,
    private route:ActivatedRoute) {
    this.paid = route.snapshot.params["id"];
   
    this.service.GetPartnerAlbum(this.paid).subscribe((data: any) => {
      this.photos = data;
      this.photoscount = data.length;
    });
   }
  ngOnInit(): void {
  }

  openDialog() {
    this.dialog.open(PhotosliderviewpopupComponent,  {
      data: {
        id: this.paid,
      }
    });
  }
}
