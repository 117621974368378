import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-fcevalution-compentecy',
  templateUrl: './fcevalution-compentecy.component.html',
  styleUrls: ['./fcevalution-compentecy.component.css']
})
export class FcevalutionCompentecyComponent implements OnInit {
  Examtype = 'Baseline'
  selected;
  Partnerid;
  pgid;
  FCStudentCount;
  loading = true;
  Baselinedata = new Array;
  Endlinedata = new Array();;
  constructor(private service: MasterService) { 
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

    this.service.GetPartnerFCStudCount("where partnerid='"+this.Partnerid+"' and examtype='"+this.Examtype+"' and academicyear='"+this.selected+"'").subscribe((data:any) => {
      this.FCStudentCount = data;
      this.Baselinedata = new Array();
     data.forEach(y => {
        this.Baselinedata.push(y.First);
        this.Baselinedata.push(y.Second);
        this.Baselinedata.push(y.Third);
        this.Baselinedata.push(y.Fourth);
        this.Baselinedata.push(y.Fifth);
        this.Baselinedata.push(y.Sixth);
        this.Baselinedata.push(y.Seventh);
        this.Baselinedata.push(y.Eighth);
        this.Baselinedata.push(y.Ninth);
        this.Baselinedata.push(y.Tenth);
        this.Baselinedata.push(y.Eleventh);
        this.Baselinedata.push(y.Twelth);
      });
      this.loading = false;
    });

  }

  OnSelectExamtype(Etype)
  {
    this.Baselinedata = [];
    this.Endlinedata = [];
    this.service.GetPartnerFCStudCount("where partnerid='"+this.Partnerid+"' and examtype='"+Etype+"' and academicyear='"+this.selected+"'").subscribe((data:any) => {
      this.FCStudentCount = data;
      this.Baselinedata = new Array();
      this.Endlinedata = new Array();
      data.forEach(y => {
        this.Baselinedata.push(y.First);
        this.Baselinedata.push(y.Second);
        this.Baselinedata.push(y.Third);
        this.Baselinedata.push(y.Fourth);
        this.Baselinedata.push(y.Fifth);
        this.Baselinedata.push(y.Sixth);
        this.Baselinedata.push(y.Seventh);
        this.Baselinedata.push(y.Eighth);
        this.Baselinedata.push(y.Ninth);
        this.Baselinedata.push(y.Tenth);
        this.Baselinedata.push(y.Eleventh);
        this.Baselinedata.push(y.Twelth);
      })
      data.forEach(x => {
        this.Endlinedata.push(x.Sixth);
        this.Endlinedata.push(x.Seventh);
        this.Endlinedata.push(x.Eighth);
        this.Endlinedata.push(x.Ninth);
        this.Endlinedata.push(x.Tenth);
        this.Endlinedata.push(x.Eleventh);
        this.Endlinedata.push(x.Twelth);
      })
    })
    this.loading = false;
  }

  ngOnInit(): void {
  }

}
