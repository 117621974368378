import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-english2nd',
  templateUrl: './english2nd.component.html',
  styleUrls: ['./english2nd.component.css']
})
export class English2ndComponent implements OnChanges {

  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  loading = true;
  Partnerid;
  pgid;
  selected;

  EndlineStandard: any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  c5 = new Array();
  c6 = new Array();
  c7 = new Array();
  c8 = new Array();
  c9 = new Array();
  c10 = new Array();
  c11 = new Array();
  c12 = new Array();
  c13 = new Array();
  c14 = new Array();
  compentencies;
  Endlinestd2;
  Endlinestd2count;
  totapp;

  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }

  ngOnChanges() {
    this.service.SahyogiEvalCompetency(this.Partnerid, this.selected, this.pgid, this.Examtype, this.Standard).subscribe((data: any) => {
      this.totapp = data[0].totalappeared;
      data.map((obj)=>{
        obj.C1 = ((data[0].c1 / this.totapp) * 100).toFixed(2);
        obj.C2 = ((data[0].c2 / this.totapp) * 100).toFixed(2);
        obj.C3 = ((data[0].c3 / this.totapp) * 100).toFixed(2);
        obj.C4 = ((data[0].c4 / this.totapp) * 100).toFixed(2);
        obj.C5 = ((data[0].c5 / this.totapp) * 100).toFixed(2);
        obj.C6 = ((data[0].c6 / this.totapp) * 100).toFixed(2);
        obj.C7 = ((data[0].c7 / this.totapp) * 100).toFixed(2);
        obj.C8 = ((data[0].c8 / this.totapp) * 100).toFixed(2);
        obj.C9 = ((data[0].c9 / this.totapp) * 100).toFixed(2);
        obj.C10 = ((data[0].c10 / this.totapp) * 100).toFixed(2);
        obj.C11 = ((data[0].c11 / this.totapp) * 100).toFixed(2);
        obj.C12 = ((data[0].c12 / this.totapp) * 100).toFixed(2);
        obj.C13 = ((data[0].c13 / this.totapp) * 100).toFixed(2);
        obj.C14 = ((data[0].c14 / this.totapp) * 100).toFixed(2);
    })
      this.Endlinestd2 = data;
      this.Endlinestd2count = data.length;
      this.loading = false;
      this.compentencies = new Array();
      data.forEach(y => {
        this.compentencies.push(y.C1);
        this.compentencies.push(y.C2);
        this.compentencies.push(y.C3);
        this.compentencies.push(y.C4);
        this.compentencies.push(y.C5);
        this.compentencies.push(y.C6);
        this.compentencies.push(y.C7);
        this.compentencies.push(y.C8);
        this.compentencies.push(y.C9);
        this.compentencies.push(y.C10);
        this.compentencies.push(y.C11);
        this.compentencies.push(y.C12);
        this.compentencies.push(y.C13);
        this.compentencies.push(y.C14);
      })

      var graph = document.getElementById("graph");
      if (data.length != 0) {
        graph.style.display = "block";
        this.EndlineStandard = new Chart('gujaratiStandard2', {
          type: 'bar',
          data: {
            labels: ['Letters Recognition(Beginner)', 'Word Reading(Beginner)', 'Sentence Formation(Beginner)', 'Vowel Recognition(Basic)', 'Word Reading(Basic)', 'Sentence Formation(Basic)', 'Create Pattern and Arrange in Sequence(Beginner)', 'Count picture and write the number( 1 to 20)(Beginner)', 'Knowledge of Number 11 to 50(Beginner)', 'One digit to one digit addition(Beginner)', 'One digit to one digit Subtraction(Beginner)', 'Knowledge of Number 51 to 100(Basic)', 'Two digit to one digit addition(Basic)', 'Two digit to one digit Subtraction(Basic)'],

            datasets: [
              {
                label: "Compentencies",
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                data: this.compentencies
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            title: {
              display: true,
              text: this.Examtype + 'Standard' + this.Standard
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }
        })
      }
      else {
        graph.style.display = "none";

      }
      this.loading = false;
    })
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard + "th Standard Endline Summary.png";
  }

}