import { HttpClient } from '@angular/common/http';
import { Component, OnInit,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Photo } from 'app/ClassFiles/photo';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-createalbums',
  templateUrl: './createalbums.component.html',
  styleUrls: ['./createalbums.component.css']
})
export class CreatealbumsComponent implements OnInit {
  Addphotosform : FormGroup;
  images = [];
  files = 0;
  myFiles: string[] = []; 
  fileToUpload: File = null;
  imageUrl: string;
  partner;
  submited = new EventEmitter<string>();
  loading = false;
  constructor(private HttpClient: HttpClient,private fb: FormBuilder,private service: MasterService,private router: Router) {
    this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear in('2023-2024','2024-2025')").subscribe((data: any) => {
      this.partner = data;
    });
   }

  ngOnInit() {
    this.Addphotosform = this.fb.group({
      partnername: ['', Validators.required],
      titlename : ['', Validators.required],
      filename: ['', Validators.required]
    });
  }

  getFileDetails(e) {  
    for (var i = 0; i < e.target.files.length; i++) {  
      this.myFiles.push(e.target.files[i]);
    }  
  }  

  AddPhotos(){
    this.loading = true;
    const formData = new FormData();  
    for (var i = 0; i < this.myFiles.length; i++) {  
      formData.append("fileUpload", this.myFiles[i]);  
    }

    // this.HttpClient.post('http://localhost:24214/api//Partner/AddPartnerAlbum/'+ this.Addphotosform.value.partnername + '/' +  this.Addphotosform.value.titlename , formData).subscribe(  
    this.HttpClient.post('https://api.jankari.org.in/api/Partner/AddPartnerAlbum/'+ this.Addphotosform.value.partnername + '/' +  this.Addphotosform.value.titlename , formData).subscribe(  
      data => {    
          if(data=='Upload Success'){
            alert("Photos Added Succesfully");
            this.router.navigate(['/PhotoAlbumList']);
          }
          else{
            alert("Failed to Add");
          }
        }); 
        this.loading = false;
  }


}
