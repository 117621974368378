import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-idienrollmentlist',
  templateUrl: './idienrollmentlist.component.html',
  styleUrls: ['./idienrollmentlist.component.css']
})
export class IdienrollmentlistComponent implements OnInit {
  UserId;
  pgid;
  PartnerCenterList;
  centercount;
  state;
  city;
  loading = true;
  array = [];
   StrWhere = "";
   selected;selectedd;
   ComputerList;Computercount;
  constructor(private service: MasterService,private router: Router) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('academicyear');

if(this.selected=="2020-2021"){

  // this.service.PartnerCenterList(this.pgid, "where  centerpartner.partnerid='"+this.UserId+"' and academicyear='"+this.selected+"'").subscribe((data: any) => {
  //   this.PartnerCenterList = data;  
  // });
  // this.service.PartnerCenterList(this.pgid, "where centerpartner.partnerid='"+this.UserId+"' and academicyear='"+this.selected+"'").subscribe((data: any) => {
  
  //   this.centercount = data.length;
  // });
}
else if(this.selected=="2017-2018" || this.selected=="2018-2019" || this.selected=="2019-2020"){
//   this.service.OLDPartnerCenterComputer(this.UserId,this.selected,this.pgid).subscribe((data: any) => {
//   this.ComputerList = data;  

// });
// this.service.OLDPartnerCenterComputer(this.UserId,this.selected,this.pgid).subscribe((data: any) => {
  
//   this.Computercount = data.length;
 

// });

}


    this.service.FillDropDown("Center","distinct state", "state","inner join CenterPartner on center.centid=CenterPartner.centid where partnerid='PT47'").subscribe(
     (data: any) => {
       this.state = data;
   
     });

this.service.FillDropDown("Center","distinct city", "city","inner join CenterPartner on center.centid=CenterPartner.centid where partnerid='PT47'").subscribe(
     (data: any) => {
       this.city = data;
      
     });
  }

  ngOnInit(): void {
  }


  filterrecord(searchstate,searchcity,searchstatus){
    this.loading = true;

    this.array=[];
    var where="";
    this.StrWhere  ="";
   
if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
  this.array.push("Center.state='" + searchstate + "'");
}
if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
  this.array.push("Center.city='" + searchcity + "'");
}
if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
  this.array.push("Center.status='" + searchstatus + "'");
}

if(this.array.length==0)
{
 where= this.StrWhere;
}
else if (this.array.length == 1)
        {
         this.StrWhere  =   " where " + this.array[0].toString();
       }
       else
                {
                 this.StrWhere  = " where " + this.array[0].toString();
                 for (let i = 1; i <  this.array.length; i++)
                    {
                     this.StrWhere  =   this.StrWhere  + " and " + this.array[i].toString();
                    }

                   
                }
                where=  this.StrWhere;  

                // this.service.PartnerCenterList(this.pgid,where).subscribe((data: any) => {
                //   this.PartnerCenterList = data;
               
                
                // });
                // this.service.PartnerCenterList(this.pgid,where).subscribe((data: any) => {
                //   if (data == "") {
                //     this.centercount = 0;
                //   }
                //   else if (data == null && data[0] == null) {
                //     this.centercount = 0;
                //   }
                //   else {
                //     this.centercount = data[0].tRecordCount;
                //   }
            
                
                // });
             
  }
  Back(){
    localStorage.removeItem('selectedDevice');
    this.router.navigate(['/dashboard']);
  }


}

