import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-standardgraph',
  templateUrl: './standardgraph.component.html',
  styleUrls: ['./standardgraph.component.css']
})
export class StandardgraphComponent implements OnInit {

  UserId;centid;selected;pgid;stdname;stdCount; StdCentWise;
  public myChart: Chart;
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  loading = true;
  selectfiyear;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    //this.selected = localStorage.getItem('academicyear');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    if(this.selectfiyear=="2024-2025"){ 

    // Standard Summary

    if(this.pgid == 'P22'){
      this.service.GetPartnerStdEnrollmentSummaryEnglish("where partnerid='"+ this.UserId +"' and pgid='"+ this.pgid + "'").subscribe((res: any) => {
        this.StdCentWise= res;
        this.stdname = new Array();
        this.stdCount = new Array();
        this.loading = false;
        let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
          totFifth = 0, totSixth = 0, totSeventh = 0
          , totEighth = 0, totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;
      
        res.forEach(
          y => {
            [
      
              totFirst += (y.First),
              totSecond += (y.Second),
              totThird += (y.Third),
              totFourth += (y.Fourth),
              totFifth += (y.Fifth),
              totSixth += (y.Sixth),
              totSeventh += (y.Seventh),
              totEighth += (y.Eighth),
              totNinth += (y.Ninth),
              totTenth += (y.Tenth),
              totEleventh += (y.Eleventh),
              totTwelth += (y.Twelth),
      
      
            ];
          });
      
          this.stdCount.push(totFirst);
        this.stdname.push('1st');
        this.stdCount.push(totSecond);
        this.stdname.push('2nd');
        this.stdCount.push(totThird);
        this.stdname.push('3rd');
        this.stdCount.push(totFourth);
        this.stdname.push('4th');
        this.stdCount.push(totFifth);
        this.stdname.push('5th');
        this.stdCount.push(totSixth);
        this.stdname.push('6th');
        this.stdCount.push(totSeventh);
        this.stdname.push('7th');
        this.stdCount.push(totEighth);
        this.stdname.push('8th');
        this.stdCount.push(totNinth);
        this.stdname.push('9th');
        this.stdCount.push(totTenth);
        this.stdname.push('10th');
        this.stdCount.push(totEleventh);
        this.stdname.push('11th');
        this.stdCount.push(totTwelth);
        this.stdname.push('12th');
      
      
        if (this.myChart) { this.myChart.destroy(); }
        this.myChart = new Chart('canvass', {
      
          type: 'bar',
          data: {
            labels: this.stdname,
            datasets: [
              {
                label: 'Enrollment',
                data: this.stdCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,
              }
            ],
          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
      
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
              
                scaleLabel: {
                  display: true,
                  labelString: "Count",
      
                }
              }],
            },
            plugins: {
              datalabels: {
                display: function(context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
                anchor: "center",
                align: "center",
                font:{
                  size: 10,
                }
              }
            }
          },
  
          
        });
      });
    }
    else{
      this.service.GetPartnerStdEnrollmentSummary("where partnerid='"+ this.UserId +"' and pgid='"+ this.pgid +"'").subscribe((res: any) => {
        this.StdCentWise= res;
        this.stdname = new Array();
        this.stdCount = new Array();
        this.loading = false;
        let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
          totFifth = 0, totSixth = 0, totSeventh = 0
          , totEighth = 0, totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;
      
        res.forEach(
          y => {
            [
      
              totFirst += (y.First),
              totSecond += (y.Second),
              totThird += (y.Third),
              totFourth += (y.Fourth),
              totFifth += (y.Fifth),
              totSixth += (y.Sixth),
              totSeventh += (y.Seventh),
              totEighth += (y.Eighth),
              totNinth += (y.Ninth),
              totTenth += (y.Tenth),
              totEleventh += (y.Eleventh),
              totTwelth += (y.Twelth),
      
      
            ];
          });
      
          this.stdCount.push(totFirst);
        this.stdname.push('1st');
        this.stdCount.push(totSecond);
        this.stdname.push('2nd');
        this.stdCount.push(totThird);
        this.stdname.push('3rd');
        this.stdCount.push(totFourth);
        this.stdname.push('4th');
        this.stdCount.push(totFifth);
        this.stdname.push('5th');
        this.stdCount.push(totSixth);
        this.stdname.push('6th');
        this.stdCount.push(totSeventh);
        this.stdname.push('7th');
        this.stdCount.push(totEighth);
        this.stdname.push('8th');
        this.stdCount.push(totNinth);
        this.stdname.push('9th');
        this.stdCount.push(totTenth);
        this.stdname.push('10th');
        this.stdCount.push(totEleventh);
        this.stdname.push('11th');
        this.stdCount.push(totTwelth);
        this.stdname.push('12th');
      
      
        if (this.myChart) { this.myChart.destroy(); }
        this.myChart = new Chart('canvass', {
      
          type: 'bar',
          data: {
            labels: this.stdname,
            datasets: [
              {
                label: 'Enrollment',
                data: this.stdCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,
              }
            ],
          },
          options: {
            legend: {
              display: true,
              position: 'bottom',
            },
      
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
              
                scaleLabel: {
                  display: true,
                  labelString: "Count",
      
                }
              }],
            },
            plugins: {
              datalabels: {
                display: function(context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
                anchor: "center",
                align: "center",
                font:{
                  size: 10,
                }
              }
            }
          },
  
          
        });
      });
    }
       
    }
    else if(this.selectfiyear=="2017-2018" || this.selectfiyear=="2018-2019" || this.selectfiyear=="2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022"|| this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024")
    {
      // Standard Summary
      this.service.GetOLDPartnerEnrollStdWise("where partnerid='"+this.UserId+"' and academicyear='"+this.selectfiyear+"'").subscribe((res: any) => {
        this.StdCentWise= res;
        this.stdname = new Array();
        this.stdCount = new Array();
        this.loading = false;

        let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
          totFifth = 0, totSixth = 0, totSeventh = 0
          , totEighth = 0, totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;

        res.forEach(
          y => {
            [

              totFirst += (y.First),
              totSecond += (y.Second),
              totThird += (y.Third),
              totFourth += (y.Fourth),
              totFifth += (y.Fifth),
              totSixth += (y.Sixth),
              totSeventh += (y.Seventh),
              totEighth += (y.Eighth),
              totNinth += (y.Ninth),
              totTenth += (y.Tenth),
              totEleventh += (y.Eleventh),
              totTwelth += (y.Twelth),


            ];
          });

          this.stdCount.push(totFirst);
        this.stdname.push('1st');
        this.stdCount.push(totSecond);
        this.stdname.push('2nd');
        this.stdCount.push(totThird);
        this.stdname.push('3rd');
        this.stdCount.push(totFourth);
        this.stdname.push('4th');
        this.stdCount.push(totFifth);
        this.stdname.push('5th');
        this.stdCount.push(totSixth);
        this.stdname.push('6th');
        this.stdCount.push(totSeventh);
        this.stdname.push('7th');
        this.stdCount.push(totEighth);
        this.stdname.push('8th');
        this.stdCount.push(totNinth);
        this.stdname.push('9th');
        this.stdCount.push(totTenth);
        this.stdname.push('10th');
        this.stdCount.push(totEleventh);
        this.stdname.push('11th');
        this.stdCount.push(totTwelth);
        this.stdname.push('12th');


        if (this.myChart) { this.myChart.destroy(); }
        this.myChart = new Chart('canvass', {
        
          type: 'bar',
          data: {
            labels: this.stdname,
            datasets: [
              {
                label: 'Enrollment',
                data: this.stdCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,

              }
            ],

          },

          options: {
            
            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'Course Name',

            },

            scales: {
              xAxes: [{

                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",


                }
              }],
            },
            plugins: {
              datalabels: {
                display: function(context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
              },
                anchor: "center",
                align: "center",
                font:{
                  size: 10,
                }
              }
            }
          },

          
        });
      });

    } 
    else if(this.selectfiyear== null){ 
      // Standard Summary
      this.service.GetPartnerStdEnrollmentSummary("where partnerid='"+this.UserId+"' and pgid='"+this.pgid+"'").subscribe((res: any) => {
        this.StdCentWise= res;
        this.stdname = new Array();
        this.stdCount = new Array();
        this.loading = false;
        let totFirst = 0, totSecond = 0, totThird = 0, totFourth = 0,
          totFifth = 0, totSixth = 0, totSeventh = 0
          , totEighth = 0, totNinth = 0, totTenth = 0, totEleventh = 0, totTwelth = 0;
      
        res.forEach(
          y => {
            [
      
              totFirst += (y.First),
              totSecond += (y.Second),
              totThird += (y.Third),
              totFourth += (y.Fourth),
              totFifth += (y.Fifth),
              totSixth += (y.Sixth),
              totSeventh += (y.Seventh),
              totEighth += (y.Eighth),
              totNinth += (y.Ninth),
              totTenth += (y.Tenth),
              totEleventh += (y.Eleventh),
              totTwelth += (y.Twelth),
      
      
            ];
          });
      
          this.stdCount.push(totFirst);
        this.stdname.push('1st');
        this.stdCount.push(totSecond);
        this.stdname.push('2nd');
        this.stdCount.push(totThird);
        this.stdname.push('3rd');
        this.stdCount.push(totFourth);
        this.stdname.push('4th');
        this.stdCount.push(totFifth);
        this.stdname.push('5th');
        this.stdCount.push(totSixth);
        this.stdname.push('6th');
        this.stdCount.push(totSeventh);
        this.stdname.push('7th');
        this.stdCount.push(totEighth);
        this.stdname.push('8th');
        this.stdCount.push(totNinth);
        this.stdname.push('9th');
        this.stdCount.push(totTenth);
        this.stdname.push('10th');
        this.stdCount.push(totEleventh);
        this.stdname.push('11th');
        this.stdCount.push(totTwelth);
        this.stdname.push('12th');
      
      
        if (this.myChart) { this.myChart.destroy(); }
        this.myChart = new Chart('canvass', {
      
          type: 'bar',
          data: {
            labels: this.stdname,
            datasets: [
              {
                label: 'Enrollment',
                data: this.stdCount,
                backgroundColor: "#ffc107",
                borderColor: '#ffc107',
                fill: true,
      
              }
            ],
      
          },
      
          options: {
            legend: {
              display: true,
              position: 'bottom',
              // legendname: 'Course Name',
    
            },
      
            scales: {
              xAxes: [{
      
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            plugins: {
              
              datalabels: {
                display: function(context) {
                  return context.dataset.data[context.dataIndex] >= 1; // or >= 1 or ...
               },
                anchor: "center",
                align: "center",
                font:{
                  size: 10,
                }
              }
            }
          },
    
          
        });
      });
                
    }
      
  }
  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType='bar';
    this.barChartType1='bar';

  }
  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = "StandardGraph.png";
  }
}
