import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baselinestd92022',
  templateUrl: './baselinestd92022.component.html',
  styleUrls: ['./baselinestd92022.component.css']
})
export class Baselinestd92022Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
