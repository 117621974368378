import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-comparisongraphs',
  templateUrl: './comparisongraphs.component.html',
  styleUrls: ['./comparisongraphs.component.css']
})
export class ComparisongraphsComponent implements OnInit {
  Partnerid;
  pgid;
  selected;
  comparisonstudentcount
  totfirst  = 0;
  totsecond = 0;
  totthird  = 0;
  totfourth  = 0;
  totfifth = 0;
  totsixth = 0;
  totseventh = 0;
  toteight  = 0;
  totnineth  = 0;
  tottenth  = 0;
  toteleventh  = 0;
  tottwelth  = 0;
  value
  constructor(private service:MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');
    
    this.service.GetPartnerFCStudCountComparision("where partnerid='"+this.Partnerid+"' and acyear='"+this.selected+"'").subscribe((Data:any) => {
      this.comparisonstudentcount = Data;
      this.value = this.comparisonstudentcount;
  
      for (let j = 0; j < this.comparisonstudentcount.length; j++) {
        this.totfirst += this.value[j].First;
        this.totsecond+= this.value[j].Second;
        this.totthird += this.value[j].Third;
        this.totfourth += this.value[j].Fourth;
        this.totfifth+= this.value[j].Fifth;
        this.totsixth+= this.value[j].Sixth;
        this.totseventh += this.value[j].Seventh;
        this.toteight += this.value[j].Eighth;
        this.totnineth += this.value[j].Ninth;
        this.tottenth += this. value[j].Tenth;
        this.toteleventh += this.value[j].Eleventh
        this.tottwelth += this.value[j].Twelth
       
       }
      console.log(JSON.stringify(this.comparisonstudentcount));
    })
   }

  ngOnInit(): void {
  }

}
