import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-baselinestd1',
  templateUrl: './baselinestd1.component.html',
  styleUrls: ['./baselinestd1.component.css']
})
export class Baselinestd1Component implements OnChanges {
  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  
  Partnerid;
  pgid;
  selected;
  loading = true;
  Baselinestd1;
  Baselinestd1count;

  BaselineStandard;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  compentencies;

  groupchart: any
  constructor(private service: MasterService,private router: Router) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }

  ngOnChanges() {
    if (this.selected == '2021-2022') {
      this.service.SahyogiEvalCompetency(this.Partnerid,this.selected,this.pgid,this.Examtype,this.Standard).subscribe((data:any) => {
        this.Baselinestd1 = data;
        this.Baselinestd1count = data.length;
        this.compentencies = new Array();
       data.forEach(y => {
          this.compentencies.push(y.c1);
          this.compentencies.push(y.c2);
          this.compentencies.push(y.c3);
          this.compentencies.push(y.c4);
        })
        var graph = document.getElementById("graph");
        if(data.length != 0){
          graph.style.display = "block";
        this.BaselineStandard = new Chart('Standard1', {
          type: 'bar',
          data: {
            labels: ['Conversation','Comparision concept','Create pattern','Number recognisation & counting'],
            datasets: [
              {
                label: "Compentencies",
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                data: this.compentencies
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            title: {
              display: true,
              text: 'Baseline Standard 1'
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }
        })
      }
      else {
        graph.style.display = "none";
      }
      this.loading = false;
      })
    } else if (this.selected == '2020-2021') {
      this.service.SahyogiFcEvalCompetency(this.Partnerid,this.selected,this.pgid,this.Examtype,this.Standard).subscribe((data:any) => {
        this.Baselinestd1 = data;
        this.Baselinestd1count = data.length;
        this.compentencies = new Array();
       data.forEach(y => {
          this.compentencies.push(y.c1);
          this.compentencies.push(y.c2);
          this.compentencies.push(y.c3);
          this.compentencies.push(y.c4);
        })
        var graph = document.getElementById("graph");
        if(data.length != 0){
          graph.style.display = "block";
        this.BaselineStandard = new Chart('Standard1', {
          type: 'bar',
          data: {
            labels: ['Conversation','Comparision concept','Create pattern','Number recognisation & counting'],
            datasets: [
              {
                label: "Compentencies",
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                data: this.compentencies
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            title: {
              display: true,
              text: 'Baseline Standard 1'
            },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }
        })
      }
      else {
        graph.style.display = "none";
      }
      this.loading = false;
      })
    }

   
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = this.Standard+"st Standard Baseline Summary.png";
  }

}
