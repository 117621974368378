export class User {
    constructor(
        public UserName:string="",
    public Password:string="",
    public offemail:string="",
    public Userrole:string="",
    public FullName:string=""
    
    ){}
}

