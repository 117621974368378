import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mye-school-dashboard',
  templateUrl: './mye-school-dashboard.component.html',
  styleUrls: ['./mye-school-dashboard.component.css']
})
export class MyeSchoolDashboardComponent implements OnInit {
  panelColor = new FormControl('red'); countryForm: FormGroup;
  countries = ['USA', 'Canada', 'Uk']
  selectedDeviceObj;
  UserId; pgid; centercount; Schoolcentercount; StatusCount;
  totenrolment: any; schcompl; studstatusdrop; studstatusAct; acyear;
  fineyear; objective;
  para1: string; para11: string; para2: string; para21: string;
  para3: string; para31: string; para4: string; para41: string;
  para5: string; para6: string; para7: string; para8: string; paraa: string;
  boldpara1: string; boldpara2: string; boldpara3: string; boldpara4: string;
  boldpara5: string; boldpara6: string; boldpara7: string; boldpara: string;
  para: string; objective1: string; programname;
  fy; CenterComputercount; col1; Academicyear; academicyear;
  cities2: any[];
  selectfiyear: any;
  demo;
  value;
  pifcenterlist;
  noofcomputer = 0;
  noofinfra;
  Eventpicture;
  photolist;
  photocount;
  partnerinfo;
  Menus;
  pifcenterlistcount;
  imgdata = [
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img1.jpg", title: "Slide 1" },
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img2.jpg", title: "Slide 2" },
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img3.jpg", title: "Slide 3" }
  ];
  infraList = [];
  role: string;
  listdata: Object;
  datacount: any;
  loading: boolean;
  reportdata: any;

  constructor(private service: MasterService, public fb: FormBuilder, private router: Router) {
    this.selectfiyear = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');
    this.role = localStorage.getItem('role');
    this.UserId = localStorage.getItem('UserId');

    this.service.GetPartnerPhotogalleryList("where partner.partnerid='" + this.UserId + "'").subscribe((data: any) => {
      this.photolist = data;
      this.photocount = data.length;
    });

    this.service.GetPartnerplanvsactualSummary("where #plpart.partnerid ='" + this.UserId + "' and academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
      this.objective = data[0].objectives;
      this.partnerinfo = data[0].partnerinfo;
    })

    this.service.GetEventPic('EVM141').subscribe((data: any) => {
      this.Eventpicture = data;
    });

    if (this.selectfiyear == null) {

      this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + "2024-2025" + "'").subscribe((data: any) => {
        this.centercount = data.length;
      });
      this.service.GetPartnerComputer(this.pgid, this.UserId, "").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });

      this.service.GetMyEschoolPartnerwisecenterinfra("where partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.CenterComputercount = data;

        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofinfra += this.value[j].noofinfras;
        }
      });

      // this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + "2023-2024" + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {
      //   this.Schoolcentercount = data.length;
      //   console.log("count" + this.Schoolcentercount);
      // });

      if (this.pgid == 'P1' && this.UserId == 'PT40') {
        console.log('cal');

        this.service.GetPartnerCitywiseEnrollmentSummary("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "' and #stud.city not in ('Bangalore')").subscribe((data: any) => {
          console.log(data);

          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.studstatusAct;
        });
      }
      else {
        this.service.GetPartnerDashStudStatusCount(this.pgid, "where partnerid='" + this.UserId + "'").subscribe((data: any) => {
          this.StatusCount = data;
          console.log(data);

          var a = data[0];
          this.studstatusAct = a.studstatusAct;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.studstatusAct;
        });
      }
    }
    else if (this.selectfiyear == "2024-2025") {
      
      //School
      this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "'").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });
      
      //Digital Classroom
      this.service.GetMyEschoolPartnerwisecenterinfra("where partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.noofinfra = this.CenterComputercount.length;
      });

      this.service.GetRptMyEschoolTeacherTrainingPartner("where cp.partnerid='" + this.UserId + "' ").subscribe((data) => {
        this.reportdata = data;
        this.datacount = data.length;
      });
//Enrolment
      this.service.GetPartnerDashStudStatusCountMyEschool(this.pgid, "where partnerid='" + this.UserId + "'").subscribe((data: any) => {
        var a = data[0];
        this.totenrolment = a.studstatusAct;
      });
    }

    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022" || this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      // this.service.OLDPartnerCenterList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
      //   this.centercount = data.length;
      // });
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, "and OLDPartnerSchoolList.partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });

      this.service.SahyogiCALData(this.UserId, this.selectfiyear, this.pgid).subscribe((data: any) => {
        var a = data[0];
        this.totenrolment = a.student;
      });

      this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, "").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });

      this.service.GetMyEschoolPartnerwisecenterinfra(" where CenterPartner.partnerid='" + this.UserId + "' and c.Pgid='P9' and c.Status=0 ").subscribe((data: any) => {
        this.infraList = data
        this.noofinfra = this.infraList.length;
      });

      this.service.GetRptMyEschoolTeacherTrainingPartner("where cp.partnerid='" + this.UserId + "' ").subscribe((data) => {
        this.reportdata = data;
        this.datacount = data.length;
        
      })
    }

    this.service.FillDropDown("AcademicyearPartner", " academicyear", "academicyear", "where partnerid='" + this.UserId + "' order by academicyear desc").subscribe(
      (data: any) => {
        this.acyear = data;
      });

    this.service.AcademicyearPartner(this.UserId).subscribe((data: any) => {
      this.Academicyear = data;
      var a = data[0];
      this.academicyear = a.academicyear;

    });

    this.service.FillDropDown("partnerprogram", "distinct partnerprogram.pgid", "Program.pgname", "inner join program on partnerprogram.pgid=program.pgid where partnerprogram.userid='" + this.UserId + "' ").subscribe(
      (data: any) => {
        this.programname = data;
      });
  }

  ngOnInit() { }

  onSelectfineyear(selectfiyear) {
    this.noofcomputer = 0;
    localStorage.setItem('key', this.selectfiyear);
    this.selectfiyear = selectfiyear;

    if (this.selectfiyear == "2024-2025") {

      if (this.pgid == 'P1' && this.UserId == 'PT40') {
        this.service.GetPartnerCitywiseEnrollmentSummary("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "' and #stud.city not in ('Bangalore')").subscribe((data: any) => {

          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.student;
          this.studstatusdrop = a.student;
          this.schcompl = a.student;
          this.totenrolment = a.student;
        });
      }
      else if(this.pgid == 'P9'){
        this.service.GetPartnerDashStudStatusCountMyEschool(this.pgid, "where partnerid='" + this.UserId + "'").subscribe((data: any) => {
          this.StatusCount = data;
          var a = data[0];
          this.totenrolment = a.studstatusAct;
        });
      }
      else {
        this.service.GetPartnerDashStudStatusCount(this.pgid, "where partnerid='" + this.UserId + "'").subscribe((data: any) => {
          this.StatusCount = data;
          var a = data[0];
          this.studstatusAct = a.StatusCount;
          this.studstatusdrop = a.studstatusdrop;
          this.schcompl = a.schcompl;
          this.totenrolment = a.studstatusAct;
        });
      }
      if (this.pgid == 'P1') {
      this.service.GetPartnerComputer(this.pgid, this.UserId, "and c.Pgid='p1' and c.Status=0 ").subscribe((data: any) => {

        this.CenterComputercount = data
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
    }

    if (this.pgid == 'P9' && this.UserId == 'PT40') {
      this.service.GetMyEschoolPartnerwisecenterinfra(" where partnerid='" + this.UserId + "' and c.Pgid='P9' and c.Status=0 ").subscribe((data: any) => {

        this.CenterComputercount = data        
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofinfra += this.value[j].noofinfras;
        }
      });
    }
      this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });

      this.service.PartnerSchoolList(this.pgid, this.UserId, "where  academicyear='" + this.selectfiyear + "' and pgid='" + this.pgid + "' ").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
        console.log("count" + this.Schoolcentercount);
      });
    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022"|| this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {

      // this.service.OLDPartnerCenterList(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
      //   this.centercount = data.length;

      // });
      this.service.OLDPartnerSchoolList(this.pgid, this.UserId, this.selectfiyear, "and OLDPartnerSchoolList.partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.Schoolcentercount = data.length;

      });
      this.service.SahyogiCALData(this.UserId, this.selectfiyear, this.pgid).subscribe((data: any) => {
        var a = data[0];
        this.totenrolment = a.student;

      });
      this.service.OLDPartnerCenterComputer(this.pgid, this.UserId, this.selectfiyear, "and partnerid='" + this.UserId + "'").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }

      });
      this.service.GetRptMyEschoolTeacherTrainingPartner("where cp.partnerid='" + this.UserId + "' ").subscribe((data) => {
        this.reportdata = data;
        this.datacount = data.length;
        
      });

      if (this.pgid == 'P9') {
        
        this.service.GetMyEschoolPartnerwisecenterinfra(" where CenterPartner.partnerid='" + this.UserId + "' and c.Pgid='P9' and c.Status=0 ").subscribe((data: any) => {
          this.CenterComputercount = data    
          this.noofinfra = data.length
        });
      }
    }

  }

  onSelectprogram(val) {
    if (val == 'P1') {
      localStorage.setItem('pgname', 'CAL-PIF');
      localStorage.setItem('pgid', 'P1');
      this.router.navigate(['/Dashboard']);
      window.location.reload();
    }
    else if (val == 'P22') {
      localStorage.setItem('pgname', 'English');
      localStorage.setItem('pgid', 'P22');
      this.router.navigate(['/Dashboard']);
      window.location.reload();
    }
    else if (val == 'P9') {
      localStorage.setItem('pgname', 'My-eSchool');
      localStorage.setItem('pgid', 'P9');
      this.router.navigate(['/MyeschoolDashboard']);
      window.location.reload();
    }
    else if (val == 'P6') {
      localStorage.setItem('pgname', 'IDI');
      localStorage.setItem('pgid', 'P6');
      this.router.navigate(['/IDIDashboard']);
    }
    else if (val == 'P18') {
      localStorage.setItem('pgname', 'ATAL');
      localStorage.setItem('pgid', 'P18');
      this.router.navigate(['/ATALDashboard']);
    }
  }
}