import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-std10',
  templateUrl: './std10.component.html',
  styleUrls: ['./std10.component.css']
})
export class Std10Component implements  OnChanges {
  @Input('Standard') Standard;

  Partnerid;
  pgid;
  selected;
  loading = true;
  Comparisonstd5count;
  Comparisonstd5;
  bc0;
  bc1;
  bc2;
  bc3;
  bc4;
  mc0;
  mc1;
  mc2;
  mc3;
  mc4;
  baselinecompentency = [];
  midlinecompentency = [];
  Comparisonstd5graph: any;
  grpahdata;
  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');
  }
  ngOnChanges() {
    this.baselinecompentency = [];
    this.midlinecompentency = [];
    this.service.GetCALLevelwiseComparison("where b.partnerid='" + this.Partnerid + "' and b.standard='" + this.Standard + "'").subscribe((data: any) => {
      console.log(this.Standard);
      
      this.Comparisonstd5 = data;
      console.log(this.Comparisonstd5);
      this.Comparisonstd5count = data.length;
      data.forEach(y => {
        this.bc0 = ((y.base0 * 100) / (y.totstud)).toFixed(2);
        this.bc1 = ((y.basebeginner * 100) / (y.totstud)).toFixed(2);
        this.bc2 = ((y.basebasic * 100) / (y.totstud)).toFixed(2);
        this.bc3 = ((y.baseintermediate * 100) / (y.totstud)).toFixed(2);
        this.bc4 = ((y.baseadvance * 100) / (y.totstud)).toFixed(2);
        this.mc0 = ((y.mid0 * 100) / (y.totstud)).toFixed(2);
        this.mc1 = ((y.midbeginner * 100) / (y.totstud)).toFixed(2);
        this.mc2 = ((y.midbasic * 100) / (y.totstud)).toFixed(2);
        this.mc3 = ((y.midintermediate * 100) / (y.totstud)).toFixed(2);
        this.mc4 = ((y.midadvance * 100) / (y.totstud)).toFixed(2);
      });

      this.baselinecompentency = new Array();
      this.baselinecompentency.push(this.bc0);
      this.baselinecompentency.push(this.bc1);
      this.baselinecompentency.push(this.bc2);
      this.baselinecompentency.push(this.bc3);
      this.baselinecompentency.push(this.bc4);

      this.midlinecompentency = new Array();
      this.midlinecompentency.push(this.mc0);
      this.midlinecompentency.push(this.mc1);
      this.midlinecompentency.push(this.mc2);
      this.midlinecompentency.push(this.mc3);
      this.midlinecompentency.push(this.mc4);

      var graph = document.getElementById("graph");
      if (data.length != 0) {
        graph.style.display = "block";
        if (this.Comparisonstd5graph) {
           this.Comparisonstd5graph.destroy(); 
          }

        this.Comparisonstd5graph = new Chart('comparisongraph', {
          type: 'bar',
          data: {
            labels: ["0 Level","Beginner","Basic", "Intermediate", "Advanced"],
            datasets: [
              {
                label: "Baseline",
                backgroundColor: "#f4c20d",
                data: this.baselinecompentency
              },
              {
                label: "Midline",
                backgroundColor: "#3cba54",
                data: this.midlinecompentency
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                scaleLabel:{
                  display:true,
                labelString:"Compentencies",
              }
              }],
              yAxes: [{
                  ticks: {
                      min: 0,
                  },
              scaleLabel:{
                display:true,
              labelString:"Count",
            }
              }]
          },
            plugins: {
              datalabels: {
                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }

        })
      }
      else {
        graph.style.display = "none";
      }

      this.loading = false;
    })
  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    anchor.download = "10th Standard Comparison Summary.png";
  }
}

