import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ExcelService } from 'app/services/excel.service';

@Component({
  selector: 'app-idibatchlist',
  templateUrl: './idibatchlist.component.html',
  styleUrls: ['./idibatchlist.component.css']
})
export class IdibatchlistComponent implements OnInit {
  
  UserId;
  pgid;
  PartnerCenterList; IDIBatchList; IDIBatchcount;
  centercount;
  state;
  city;
  loading = true;
  array = [];
  StrWhere = "";
  where = "";
  selected;
  selectedd;
  statelist = new FormControl();
  citylist = new FormControl();
  statuslist = new FormControl();
  pgname;
  searchstatus = 0;
  p=1;

  constructor(private service: MasterService, private router: Router,private exService: ExcelService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');

    if (this.selected == "2024-2025") {
      this.service.GetPartnerIDIBatchlistNew("where CenterPartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'").subscribe((data: any) => {
        this.IDIBatchList = data;
        this.IDIBatchcount = data.length;
        this.loading = false; 
        
      });
    }
    else if (this.selected == "2017-2018" || this.selected == "2018-2019" || this.selected == "2019-2020" || this.selected == "2020-2021" || this.selected == "2021-2022" || this.selected == "2022-2023" || this.selected == "2023-2024") {
      this.service.GetPartnerOLDIDIBatchlist(this.pgid,this.UserId, this.selected,"").subscribe((data: any) => {
        this.IDIBatchList = data;
        this.IDIBatchcount = data.length;
        this.loading = false;
      });
    }
    this.service.FillDropDown("Center","distinct state", "state","inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='"+this.UserId+"' ").subscribe(
      (data: any) => {
        this.state = data;
      });
 
     this.service.FillDropDown("Center","distinct city", "city","inner join CenterPartner on center.centid=CenterPartner.centid where Centerpartner.partnerid='"+this.UserId+"' ").subscribe(
      (data: any) => {
        this.city = data;
      });

  }

  ngOnInit(): void {
  }

  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";

    if (this.selected == 'null') {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("Center.state='" + searchstate + "'");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("Center.city='" + searchcity + "'");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("Center.status='" + searchstatus + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere + "and CenterPartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'";

      this.service.GetPartnerIDIBatchlistNew(this.where).subscribe((data: any) => {
        this.IDIBatchList = data;
        this.IDIBatchcount = data.length;
        this.loading = false;
      });
    }
    else if (this.selected == "2024-2025") {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("Center.state='" + searchstate + "'");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("Center.city='" + searchcity + "'");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("Center.status='" + searchstatus + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
      }

      this.where = this.StrWhere + "and CenterPartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'";

      this.service.GetPartnerIDIBatchlistNew(this.where).subscribe((data: any) => {
        this.IDIBatchList = data;
        this.IDIBatchcount = data.length;
        this.loading = false;
      });

    }
    else if (this.selected == "2017-2018" || this.selected == "2018-2019" || this.selected == "2019-2020" || this.selected == "2020-2021" || this.selected == "2021-2022"|| this.selected == "2022-2023" || this.selected == "2023-2024") {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("OLDPartnerIDIBatchList.state='" + searchstate + "'");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("OLDPartnerIDIBatchList.city='" + searchcity + "'");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("status='" + searchstatus + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }

      }
      this.where = this.StrWhere;

      this.service.GetPartnerOLDIDIBatchlist(this.pgid,this.UserId, this.selected,this.where).subscribe((data: any) => {
        this.IDIBatchList = data;
        this.IDIBatchcount = data.length;
        this.loading = false;
      });

    }
  }

  Export() {
    this.loading = true;
    if (this.selected == "2024-2025"){
      this.service.GetPartnerIDIBatchlistNew("where CenterPartner.partnerid='" + this.UserId + "' and Center.pgid='" + this.pgid + "'").subscribe((data: any) => {
      this.loading = false;
      const new_list = data.map(function (obj) {
        return {
          'Srno':0,
          'State': obj.state==null?'':obj.state,
          'City': obj.city==null?'':obj.city,
          'Program Fellow': obj.fellow==null?'':obj.fellow,
          'Center Name': obj.centname==null?'':obj.centname,
          'Batch Name': obj.batchname==null?'':obj.batchname,
          'Total Boys': obj.totboys==null?'':obj.totboys,
          'Total Girls':obj.totgirls==null?'':obj.totgirls,
          'Total Enrollment':obj.totstud==null?'':obj.totstud,
        };
      });
      for (let i = 0; i < new_list.length; i++) {
        new_list[i].Srno=i+1;
      }
      this.exService.exportAsExcelFile(new_list, 'Batch Summary');
      });
    }
    else if(this.selected=="2017-2018" || this.selected=="2018-2019" || this.selected=="2019-2020" || this.selected == "2020-2021" || this.selected == "2021-2022" || this.selected == "2022-2023" || this.selected == "2023-2024") {
      this.service.GetPartnerOLDIDIBatchlist(this.pgid,this.UserId,this.selected,this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno':0,
            'State': obj.state==null?'':obj.state,
            'City': obj.city==null?'':obj.city,
            'Program Fellow': obj.fellow==null?'':obj.fellow,
            'Center Name': obj.centname==null?'':obj.centname,
            'Batch Name': obj.batchname==null?'':obj.batchname,
            'Total Boys': obj.totboys==null?'':obj.totboys,
            'Total Girls':obj.totgirls==null?'':obj.totgirls,
            'Total Enrollment':obj.totstud==null?'':obj.totstud,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno=i+1;
        }
        this.exService.exportAsExcelFile(new_list, 'Batch Summary');
      });
    }

  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }
  selectstatus(sts) {
    if (sts._selected) {
      this.statuslist.setValue([]);
    }
  }

}

