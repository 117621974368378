import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { data } from 'jquery';

@Component({
  selector: 'app-pifdashboard',
  templateUrl: './pifdashboard.component.html',
  styleUrls: ['./pifdashboard.component.css']
})
export class PifdashboardComponent implements OnInit {
  panelColor = new FormControl('red'); countryForm: FormGroup;
  countries = ['USA', 'Canada', 'Uk']
  selectedDeviceObj;
  UserId;
  pgid;
  centercount; Schoolcentercount; StatusCount;
  totenrolment; schcompl; studstatusdrop; studstatusAct; acyear;
  fineyear; objective;
  para1: string; para11: string; para2: string; para21: string;
  para3: string; para31: string; para4: string; para41: string;
  para5: string; para6: string; para7: string; para8: string; paraa: string;
  boldpara1: string; boldpara2: string; boldpara3: string; boldpara4: string;
  boldpara5: string; boldpara6: string; boldpara7: string; boldpara: string;
  para: string; objective1: string; programname;
  fy; CenterComputercount; col1; Academicyear; academicyear;
  cities2: any[];
  selectfiyear: any;

  demo;
  value;
  pifcenterlist;
  noofcomputer = 0;
  Eventpicture;
  photolist;
  photocount;
  partnerinfo;
  Menus;
  enrolmentcount;
  pifcenterlistcount;
  imgdata = [
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img1.jpg", title: "Slide 1" },
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img2.jpg", title: "Slide 2" },
    { img: "https://therichpost.com/wp-content/uploads/2021/05/bootstrap5-carousel-slider-img3.jpg", title: "Slide 3" }
  ];
  constructor(private service: MasterService, public fb: FormBuilder, private router: Router) {
    this.selectfiyear = localStorage.getItem('key');
    this.pgid = localStorage.getItem('pgid');
    this.UserId = localStorage.getItem('UserId');
    this.pgid = 0;
    localStorage.setItem('pgname', 'SchoolBased');


    if (this.selectfiyear == null) {
      this.service.PIFCenterList(" where pgid in('P1','P18','P9') ").subscribe((data: any) => {
        this.pifcenterlist = data;
        this.pifcenterlistcount = data.length;
      })

      this.service.GetPartnerComputerPIF("").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
      this.service.PartnerSchoolListPIF("where pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });
      this.service.GetPartnerDashStudStatusCountPIF("where center.pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.StatusCount = data;
        this.totenrolment =  0;        
        for (let j = 0; j < this.StatusCount.length; j++) {
          this.totenrolment += this.StatusCount[j].studstatusAct;
        }
        
      });

    }
   

    if (this.selectfiyear == '2021-2022') {
      
      // this.service.PIFCenterList("where pgid in('P1','P18','P9')").subscribe((data: any) => {
      //   this.pifcenterlist = data;
      //   this.pifcenterlistcount = data.length;
      // })
      this.service.OLDPartnerCenterListPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.pifcenterlist = data;
      this.pifcenterlistcount = data.length;
        // console.log("center" + this.centercount)
      });

      this.service.OLDPartnerCenterComputerPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
      this.service.OLDPartnerSchoolListPIF(this.selectfiyear ,"and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });
      this.service.GetPartnerDashStudStatusCountPIF("where center.pgid in ('P1','P18','P9')").subscribe((data: any) => {
        console.log(data);
        
        this.StatusCount = data;
        this.totenrolment =  0;        
        for (let j = 0; j < this.StatusCount.length; j++) {
          this.totenrolment += this.StatusCount[j].studstatusAct;
        }
      });

    }
   
    else if (this.selectfiyear == "2022-2023") {
      
      this.service.PIFCenterList(" where pgid in('P1','P18','P9') ").subscribe((data: any) => {
        this.pifcenterlist = data;
        this.pifcenterlistcount = data.length;
      })

      this.service.GetPartnerComputerPIF("").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
      this.service.PartnerSchoolListPIF("where pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });
      this.service.GetPartnerDashStudStatusCountPIF("where center.pgid in('P1','P18','P9')").subscribe((data: any) => {
        console.log(data);
        
        this.StatusCount = data;
        this.totenrolment =  0;        
        for (let j = 0; j < this.StatusCount.length; j++) {
          this.totenrolment += this.StatusCount[j].studstatusAct;
        }
        
      });

    }


    this.service.FillDropDown("AcademicyearPartner", " academicyear", "academicyear", "where partnerid='" + this.UserId + "' order by academicyear desc").subscribe(
      (data: any) => {
        this.acyear = data;
      });

    this.service.AcademicyearPartner(this.UserId).subscribe((data: any) => {
      this.Academicyear = data;
      var a = data[0];
      this.academicyear = a.academicyear;

    });

  }

  ngOnInit() { }

  onSelectfineyear(selectfiyear) {
    this.noofcomputer = 0;
    this.totenrolment = 0;
    localStorage.setItem('key', this.selectfiyear);
    this.selectfiyear = selectfiyear;

    if (this.selectfiyear == "2022-2023") {
      this.service.PIFCenterList("where pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.pifcenterlist = data;
        this.pifcenterlistcount = data.length;
      })
      this.service.PartnerSchoolListPIF("where pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });

      this.service.GetPartnerComputerPIF("").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
      this.service.GetPartnerDashStudStatusCountPIF("where center.pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.StatusCount = data;
        this.value = this.StatusCount;
        for (let j = 0; j < this.StatusCount.length; j++) {
          this.totenrolment += this.value[j].studstatusAct;
        }
      });

    }
    else if (this.selectfiyear == "2021-2022") {
      // this.service.PIFCenterList("where pgid in('P1','P18','P9')").subscribe((data: any) => {
      //   this.pifcenterlist = data;
      //   this.pifcenterlistcount = data.length;
        this.service.OLDPartnerCenterListPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data: any) => {
          this.pifcenterlist = data;
          
        this.pifcenterlistcount = data.length;
      })
      
      this.service.OLDPartnerSchoolListPIF(this.selectfiyear ,"and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.Schoolcentercount = data.length;
      });

      this.service.OLDPartnerCenterComputerPIF(this.selectfiyear," and pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.CenterComputercount = data;
        this.value = this.CenterComputercount;
        for (let j = 0; j < this.CenterComputercount.length; j++) {
          this.noofcomputer += this.value[j].noofcomputers;
        }
      });
      this.service.GetPartnerDashStudStatusCountPIF("where center.pgid in('P1','P18','P9')").subscribe((data: any) => {
        this.StatusCount = data; 
        this.value = this.StatusCount;
        for (let j = 0; j < this.StatusCount.length; j++) {
          this.totenrolment += this.value[j].studstatusAct;
        } 
        
      });

    }


  }


  onchangedashboard(){

  }

  onSelectprogram(val) {
    console.log(val);
    
    if (val == '0') {
      localStorage.setItem('pgname', 'SchoolBased');
      localStorage.setItem('pgid', '0');
      this.router.navigate(['/PIFDashboard']);
      console.log( 'school'+this.router);

    }
    else if (val == '1') {
      localStorage.setItem('pgname', 'YouthBased');
      localStorage.setItem('pgid', '1');
      this.router.navigate(['/PIFYouthBasedDashBoard']);
      console.log( 'youth'+this.router);
    }
    else if (val == '2') {
      localStorage.setItem('pgname', 'DigitalSakshar');
      localStorage.setItem('pgid', '2');
      this.router.navigate(['/PIFDigistalSaksharDashboard']);
      console.log( 'digital'+this.router);
      

    }
  }
}
