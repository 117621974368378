import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digitalschoollist',
  templateUrl: './digitalschoollist.component.html',
  styleUrls: ['./digitalschoollist.component.css']
})
export class DigitalschoollistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
