import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-keralaevaluation',
  templateUrl: './keralaevaluation.component.html',
  styleUrls: ['./keralaevaluation.component.css']
})
export class KeralaevaluationComponent implements OnInit {
  selected 
  constructor() { 
  this.selected = localStorage.getItem('key');    
   }
    ngOnInit(): void {
  }
}