import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ExcelService } from 'app/services/excel.service';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-youthcenterlist',
  templateUrl: './youthcenterlist.component.html',
  styleUrls: ['./youthcenterlist.component.css']
})
export class YouthcenterlistComponent implements OnInit {
  UserId;
  pgid = 'P6';
  PartnerCenterList;
  centercount;
  state;
  city;
  loading = true;
  array = [];
  StrWhere = ""; 
  where = "";
  selected;
  statelist = new FormControl();
  citylist = new FormControl();
  statuslist = new FormControl();
  p: number = 1;
  pgname;
  searchstatus = 0;
  constructor(private exService: ExcelService, private service: MasterService, private router: Router) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');
    this.pgname = localStorage.getItem('pgname');

    
    
    if (this.selected == "null") {

      this.service.GetPartnerCenterIDIlistPIF('').subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
      });
      
    }
    else if (this.selected == "2022-2023") {
      this.service.GetPartnerCenterIDIlistPIF('').subscribe((data: any) => {
        this.PartnerCenterList = data;
       this.centercount = data.length;
        this.loading = false;
      }); 
      
    }
    else if (this.selected == "2021-2022") {
      this.service.OLDPartnerCenterListPIF(this.selected," and pgid in('P6')").subscribe((data: any) => {        
        this.PartnerCenterList = data;
       this.centercount = data.length;
        this.loading = false;
      }); 
      
    }
    this.service.FillDropDown("Center", "distinct state", "state", "inner join CenterPartner on center.centid=CenterPartner.centid where center.pgid = 'P6'").subscribe(
      (data: any) => {
        this.state = data;
      });

    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.pgid = 'P6'").subscribe(
      (data: any) => {
        this.city = data;
      });
  }

  ngOnInit(): void {
  }

  getMultiSelectionWhere(where) {

    var arr = where.split(',');
    var currentWhere = '';

    for (var i = 0; i < arr.length; i++) {
      currentWhere += "'" + arr[i] + "'";
      if (i + 1 != arr.length)
        currentWhere += ",";
    }
    return currentWhere;
  }


  onSelectState(stname) {
    this.service.FillDropDown("Center", "distinct city", "city", "inner join CenterPartner on center.centid=CenterPartner.centid where center.state in (" + this.getMultiSelectionWhere(stname.toString()) + ")").subscribe(
      (data: any) => {
        this.city = data;
      });

  }

  filterrecord(searchstate, searchcity, searchstatus) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";

    if (this.selected == 'null') {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("Center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("Center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("c.status in (" + this.getMultiSelectionWhere(searchstatus.toString()) + ")");

      }
      // if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
      //   this.array.push("Center.status ='" + searchstatus + "'");
      // }
  
      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
  
  
      }
      this.where = this.StrWhere;
      if(this.selected == "2022-2023")
      this.service.GetPartnerCenterIDIlistPIF(this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
      });
  
    }
    else if(this.selected == '2021-2022') {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("Center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("Center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("Center.status ='" + searchstatus + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
  
  
      }
      this.where = this.StrWhere;
  
      this.service.OLDPartnerCenterListPIF(this.selected," and pgid in('P6')").subscribe((data: any) => {        
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
      });
  
    }
    else if(this.selected == '2022-2023') {
      if (searchstate != null && searchstate != "" && searchstate != "undefined" && searchstate != "") {
        this.array.push("Center.state in (" + this.getMultiSelectionWhere(searchstate.toString()) + ")");
      }
      if (searchcity != null && searchcity != "" && searchcity != "undefined" && searchcity != "") {
        this.array.push("Center.city in (" + this.getMultiSelectionWhere(searchcity.toString()) + ")");
      }
      if (searchstatus != null && searchstatus != "" && searchstatus != "undefined" && searchstatus != "") {
        this.array.push("Center.status ='" + searchstatus + "'");
      }

      if (this.array.length == 0) {
        this.where = this.StrWhere;
      }
      else if (this.array.length == 1) {
        this.StrWhere = " where " + this.array[0].toString();
      }
      else {
        this.StrWhere = " where " + this.array[0].toString();
        for (let i = 1; i < this.array.length; i++) {
          this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
        }
  
  
      }
      this.where = this.StrWhere;
  
      this.service.GetPartnerCenterIDIlistPIF(this.where).subscribe((data: any) => {
        this.PartnerCenterList = data;
        this.centercount = data.length;
        this.loading = false;
      });
  
    }


  }

  Export(searchstate, searchcity, searchstatus) {
    this.loading = true;
    if(this.selected == "2022-2023"){
        this.service.GetPartnerCenterIDIlistPIF(this.where).subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno':0,
            'Center ID': obj.centid==null?'':obj.centid,
            'Center Name': obj.centname==null?'':obj.centname,
            'State': obj.state==null?'':obj.state,
            'City': obj.city==null?'':obj.city,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno=i+1;
        }
        this.exService.exportAsExcelFile(new_list,' PIF Youth Based center List');
      });
    }
    else if(this.selected == "2021-2022"){
      this.service.OLDPartnerCenterListPIF(this.selected," and pgid in('P6')").subscribe((data: any) => {
      this.loading = false;
      const new_list = data.map(function (obj) {
        return {
          'Srno':0,
          'Center ID': obj.centid==null?'':obj.centid,
          'Center Name': obj.centname==null?'':obj.centname,
          'State': obj.state==null?'':obj.state,
          'City': obj.city==null?'':obj.city,
        };
      });
      for (let i = 0; i < new_list.length; i++) {
        new_list[i].Srno=i+1;
      }
      this.exService.exportAsExcelFile(new_list,' PIF Youth Based center List');
    });
  }

    
    

  }

  selectstate(st) {
    if (st._selected) {
      this.statelist.setValue([]);
    }
  }

  selectcity(ct) {
    if (ct._selected) {
      this.citylist.setValue([]);
    }
  }
  selectstatus(sts) {
    if (sts._selected) {
      this.statuslist.setValue([]);
    }
  }

}
