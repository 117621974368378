import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digitalenrolment',
  templateUrl: './digitalenrolment.component.html',
  styleUrls: ['./digitalenrolment.component.css']
})
export class DigitalenrolmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
