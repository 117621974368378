import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-baseline2021-std1',
  templateUrl: './baseline2021-std1.component.html',
  styleUrls: ['./baseline2021-std1.component.css']
})
export class Baseline2021Std1Component implements OnChanges {
  @Input('Examtype') Examtype;
  @Input('Standard') Standard;

  Partnerid;
  pgid;
  selected;
  loading = true;
  Baselinestd1;
  Baselinestd1count;

  BaselineStandard;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  c5 = new Array();
  c6 = new Array();
  c7 = new Array();
  c8 = new Array();
  c9 = new Array();
  c10 = new Array();
  c11 = new Array();
  compentencies;

  groupchart: any
  constructor(private service: MasterService, private router: Router) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }

  ngOnChanges() {
    this.service.SahyogiEvalCompetency(this.Partnerid, this.selected, this.pgid, this.Examtype, this.Standard).subscribe((data: any) => {
      this.Baselinestd1 = data;
      this.Baselinestd1count = data.length;      
      this.compentencies = new Array();
      data.forEach(y => {
        this.compentencies.push(y.c1);
        this.compentencies.push(y.c2);
        this.compentencies.push(y.c3);
        this.compentencies.push(y.c4);
        this.compentencies.push(y.c5);
        this.compentencies.push(y.c6);
        this.compentencies.push(y.c7);
        this.compentencies.push(y.c8);
        this.compentencies.push(y.c9);
        this.compentencies.push(y.c10);
        this.compentencies.push(y.c11);

      })
      var graph = document.getElementById("graph");
      if (data.length != 0) {
        graph.style.display = "block";
        this.BaselineStandard = new Chart('Standard1', {
          type: 'bar',
          data: {

            labels: ['Listen(Beginner)', 'Conversation(Beginner)', 'Letters Recognition(Basic)', 'Word Reading(Basic)', 'Vowels Recognition(Basic)','Small-Big(Beginner)','One-Many(Beginner)', 'Create Pattern and Arrange in Sequence(Beginner)','Counting and Number Recognition(Beginner)','Knowledge of Number 1 to 20(Basic)', 'Intrduction of Tens(Basic)'],
            datasets: [
              {
                label: "Compentencies",
                backgroundColor: '#f4c20d',
                borderColor: '#f4c20d',
                data: this.compentencies
              },
            ]
          },
          options: {
            scales: {
              xAxes: [{
                display: true,
                stacked: true,
                distribution: 'series',
                scaleLabel: {
                  display: true,
                  fontSize: 15,
                  labelString: "Compentencies"
                }
              }],
              yAxes: [{
                display: true,
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Count",
                }
              }],
            },
            title: {
              display: true,
              text: this.Examtype + 'Standard 1'
            },
            plugins: {
              datalabels: {

                display: function (context) {
                  return context.dataset.data[context.dataIndex] >= 1;
                },
                align: 'center',
                anchor: 'center',
                color: 'black',
                font: {
                  size: 14,
                }
              }
            }
          }
        })
      }
      else {
        graph.style.display = "none";
      }
      this.loading = false;
    })

  }

  downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard + "st Standard Baseline Summary.png";
  }


}
