import { Component, OnInit } from '@angular/core';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-partnerreportlist',
  templateUrl: './partnerreportlist.component.html',
  styleUrls: ['./partnerreportlist.component.css']
})
export class PartnerreportlistComponent implements OnInit {
  reportlist;
  loading = true;
  reportcount;
  p: number = 1;
  UserId;
  pgname;
  selectfiyear;
  pdfid;
  Viewpdf;
  pgid;
  acyear;
  constructor(private service: MasterService) {
    this.UserId = localStorage.getItem('UserId');
    this.pgname = localStorage.getItem('pgname');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');
    this.service.PartnerPDFList("Where Partner.partnerid='"+this.UserId+"' and PartnerPDF.pgid='"+this.pgid+"'").subscribe((data: any) => {
      this.reportlist = data;
      this.pdfid = data.pdfid;
      this.reportcount = data.length;
      this.loading = false;
    });

    this.service.FillDropDown("AcademicyearPartner", " academicyear", "academicyear", "where partnerid='" + this.UserId + "' order by academicyear desc").subscribe(
      (data: any) => {
        this.acyear = data;
      });

  }

  onselectyear(yearselected)
  {

    if(yearselected != ''){
      this.service.PartnerPDFList("Where Partner.partnerid='"+this.UserId+"' and academicyear='"+yearselected+"'").subscribe((data: any) => {
        this.reportlist = data;
        this.pdfid = data.pdfid;
        this.reportcount = data.length;
        this.loading = false;
      });
    }
    else{
      this.service.PartnerPDFList("Where Partner.partnerid='"+this.UserId+"' and PartnerPDF.pgid='"+this.pgid+"'").subscribe((data: any) => {
        this.reportlist = data;
        this.pdfid = data.pdfid;
        this.reportcount = data.length;
        this.loading = false;
      });
    }
    
  }
  viewpdf(value){    
    this.service.GetReportPDF(value).subscribe((data: any) =>
    {
      this.Viewpdf = data;
    });

  }

  ngOnInit(){}

}
