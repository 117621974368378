import { HttpClient } from '@angular/common/http';
import { Component, OnInit,EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';

@Component({
  selector: 'app-photoalbumdetails',
  templateUrl: './photoalbumdetails.component.html',
  styleUrls: ['./photoalbumdetails.component.css']
})
export class PhotoalbumdetailsComponent implements OnInit {
  public Addphotosform: FormGroup;
  images = [];
  files = 0;
  myFiles: string[] = []; 
  fileToUpload: File = null;
  imageUrl: string;
  partner;
  submited = new EventEmitter<string>();
  photos;
  paid;
  partnerid;
  albumname;
  loading = false;
  constructor(private HttpClient: HttpClient,private fb: FormBuilder,private service: MasterService,
    private route:ActivatedRoute,private router: Router) {
    this.paid = route.snapshot.params["id"];
    this.partnerid = route.snapshot.params["id1"];
    this.albumname = route.snapshot.params["id2"];
    console.log(this.partnerid)
   
    this.service.FillDropDown("PartnerProgram","distinct partnername","userid","inner join Partner on PartnerProgram.userid=Partner.partnerid inner join AcademicyearPartner on AcademicyearPartner.partnerid=PartnerProgram.userid where academicyear='2020-2021'").subscribe((data: any) => {
      this.partner = data;
    });

    this.service.GetPartnerAlbum(this.paid).subscribe((data: any) => {
      this.photos = data;
      
    });
   }

  ngOnInit(): void {
    this.Addphotosform = this.fb.group({
      filename: ['', Validators.required]
    });
  }


  getFileDetails(e) {  
    for (var i = 0; i < e.target.files.length; i++) {  
      this.myFiles.push(e.target.files[i]);
      // var filesize = e.target.files[0].size/1024/1024;
      //  this.files = Math.round(filesize); 
    }  
  }  

  AddPhotos(){
    this.loading = true;
    const formData = new FormData();  
    for (var i = 0; i < this.myFiles.length; i++) {  
      formData.append("fileUpload", this.myFiles[i]);  
    }

    this.HttpClient.post('http://localhost:24214/api//Partner/AddPartnerAlbum/'+ this.partnerid + '/' +  this.albumname , formData).subscribe(  
    // this.HttpClient.post('https://api.jankari.org.in/api/Partner/AddPartnerAlbum/'+ this.partnerid + '/' +  this.albumname , formData).subscribe(  
      data => {    
          if(data=='Upload Success'){
            alert("Photos Added Succesfuly");
            window.location.reload();
            this.router.navigate(['/PhotoAlbumDetails',this.paid,this.partnerid,this.albumname]);
            this.service.GetPartnerAlbum(this.paid).subscribe((data: any) => {
              this.photos = data;
            });
          }
          else{
            alert("Photos Added Succesfuly");
          }
          this.loading = false;
        });
        
  }

  Delete(padetid){
    this.loading = true;
    this.service.RemovePartnerAlbum(padetid).subscribe((res) => {
      this.submited.emit(res.toString());
      alert("Deleted Succesfully")
      this.service.GetPartnerAlbum(this.paid).subscribe((data: any) => {
        this.photos = data;
      });
      this.loading = false;
    });
  
  }

}
