import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { ExcelService } from 'app/services/excel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.css']
})
export class AttendanceListComponent implements OnInit {
  @ViewChild('TABLE', { static: true }) table: ElementRef;
  UserId;
  pgid;
  loaded;
  loading = false;
  array = [];
  StrWhere = "";
  pgname;
  p: number = 1;
  where = "";
  selectfiyear;
  searchmonth;
  searchyear;
  attendanceData;
  DataCount;
  schoolid;

  constructor(private exService: ExcelService, private service: MasterService, private route: ActivatedRoute) {
    this.UserId = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.pgname = localStorage.getItem('pgname');
    this.selectfiyear = localStorage.getItem('key');
    this.schoolid = route.snapshot.params['id'];

    // this.service.GetPartnerStudentAttendancelist("where school.schoolid = '" + this.schoolid + "'").subscribe((data: any) => {
    //   this.attendanceData = data;
    //   this.DataCount = data.length;
    //   this.loading = false;
    // });
  }

  ngOnInit(): void {

  }

  filterrecord(searchmonth, searchyear) {
    this.loading = true;
    this.array = [];
    this.where = "";
    this.StrWhere = "";

    if (searchmonth != null && searchmonth != "" && searchmonth != "undefined" && searchmonth != "") {
      this.array.push("month = '" + searchmonth + "'");
    }
    if (searchyear != null && searchyear != "" && searchyear != "undefined" && searchyear != "") {
      this.array.push("year = '" + searchyear + "'");
    }

    if (this.array.length == 0) {
      this.where = this.StrWhere;
    }
    else if (this.array.length == 1) {
      this.StrWhere = " where " + this.array[0].toString();
    }
    else {
      this.StrWhere = " where " + this.array[0].toString();
      for (let i = 1; i < this.array.length; i++) {
        this.StrWhere = this.StrWhere + " and " + this.array[i].toString();
      }
    }
    this.where = this.StrWhere;
    if (this.pgid == 'P1') {
      if (this.selectfiyear == '2024-2025') {
        this.service.GetPartnerStudentAttendancelist(this.where + " and school.schoolid = '" + this.schoolid + "'").subscribe((data: any) => {
          this.attendanceData = data;
          this.DataCount = data.length;
          this.loading = false;
        });
      }
      if (this.selectfiyear == '2023-2024') {
        this.service.GetPartnerStudentAttendancelist2023(this.where + " and school.schoolid = '" + this.schoolid + "'").subscribe((data: any) => {
          this.attendanceData = data;
          this.DataCount = data.length;
          this.loading = false;
        });
      }
    }
    else if (this.pgid == 'P22') {
      this.service.GetPartnerStudentAttendanceEnglishlist(this.where + " and school.schoolid = '" + this.schoolid + "'").subscribe((data: any) => {
        this.attendanceData = data;
        this.DataCount = data.length;
        this.loading = false;
      });
    }
  }

  ExportReport(searchmonth, searchyear) {
    this.loading = true;
    if (this.pgid == 'P1') {

      this.service.GetPartnerStudentAttendancelist(this.where + " and school.schoolid = '" + this.schoolid + "'").subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'School Name': obj.schoolname == null ? '' : obj.schoolname,
            'Student Name': obj.studname == null ? '' : obj.studname,
            'Gender': obj.gender == null ? '' : obj.gender,
            'Standard': obj.standard == null ? '' : obj.standard,
            'Division': obj.division == null ? '' : obj.division,
            'Grade A1 (100)': obj.a1grade == null ? '' : obj.a1grade,
            'Grade A (99 >= 76)': obj.agrade == null ? '' : obj.agrade,
            'Grade B (75 >= 51)': obj.bgrade == null ? '' : obj.bgrade,
            'Grade C (50 >= 35)': obj.cgrade == null ? '' : obj.cgrade,
            'Grade D (Below 35)': obj.dgrade == null ? '' : obj.dgrade,
            'Grade E (Comp Class Actual Working Days = 0)': obj.egrade == null ? '' : obj.egrade,
            'Grade E1 (Students whose Planned Working Days = 0)': obj.e1grade == null ? '' : obj.e1grade,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Student Attendance List');
      });
    }
    else if (this.pgid == 'P22') {

      this.service.GetPartnerStudentAttendanceEnglishlist(this.where + " and school.schoolid = '" + this.schoolid + "'").subscribe((data: any) => {
        this.loading = false;
        const new_list = data.map(function (obj) {
          return {
            'Srno': 0,
            'State': obj.state == null ? '' : obj.state,
            'City': obj.city == null ? '' : obj.city,
            'School Name': obj.schoolname == null ? '' : obj.schoolname,
            'Student Name': obj.studname == null ? '' : obj.studname,
            'Gender': obj.gender == null ? '' : obj.gender,
            'Standard': obj.standard == null ? '' : obj.standard,
            'Division': obj.division == null ? '' : obj.division,
            'Grade A1 (100)': obj.a1grade == null ? '' : obj.a1grade,
            'Grade A (99 >= 76)': obj.agrade == null ? '' : obj.agrade,
            'Grade B (75 >= 51)': obj.bgrade == null ? '' : obj.bgrade,
            'Grade C (50 >= 35)': obj.cgrade == null ? '' : obj.cgrade,
            'Grade D (Below 35)': obj.dgrade == null ? '' : obj.dgrade,
            'Grade E (Comp Class Actual Working Days = 0)': obj.egrade == null ? '' : obj.egrade,
            'Grade E1 (Students whose Planned Working Days = 0)': obj.e1grade == null ? '' : obj.e1grade,
          };
        });
        for (let i = 0; i < new_list.length; i++) {
          new_list[i].Srno = i + 1;
        }
        this.exService.exportAsExcelFile(new_list, 'Student Attendance List');
      });
    }
  }

}
