import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from 'app/services/master.service';
import 'chart.piecelabel.js';

@Component({
  selector: 'app-gendergraph',
  templateUrl: './gendergraph.component.html',
  styleUrls: ['./gendergraph.component.css']
})

export class GendergraphComponent implements OnInit {
  
  UserId;
  centid;
  selected;
  pgid;
  boys = 0;
  girls =0;
  student =0;
  loading = true;
  gendersummary;
  gendersummaryCount;
  public pieChartLabels: Array<string> = ['Boys', 'Girls'];
  public pieChartData: any[];
  pieChartColor: any = [
    {
      backgroundColor: ['#A7E8F1', '#F1A7E3']
    }
  ]
  public piechartOptions: any = {
    pieceLabel: {
      render: function (args) {
        const label = args.label,
          value = args.value;
        return value;
      }
    }
  };
  public pieChartType: string;
  public barChartType: string;
  public barChartType1: string;
  selectfiyear;

  constructor(private service: MasterService, private router: Router, private route: ActivatedRoute) {
    
    this.UserId = localStorage.getItem('UserId');
    this.centid = route.snapshot.params["id"];
    //this.selected = localStorage.getItem('academicyear');
    this.pgid = localStorage.getItem('pgid');
    this.selectfiyear = localStorage.getItem('key');

    if (this.selectfiyear == "2024-2025") {
      // gender wise summary new data
 
      if(this.UserId =='PT40' && this.pgid == 'P1'){
        service.GetPartnerCitywiseEnrollmentSummary("where #stud.partnerid='" + this.UserId + "' and #stud.pgid='" + this.pgid + "' and #stud.city not in ('Bangalore')").subscribe((res: any) => {
          this.gendersummary = [];
          this.gendersummary = res;

          for (let j = 0; j < this.gendersummary.length; j++) {
            this.boys += this.gendersummary[j].boys;
            this.girls += this.gendersummary[j].girls;
            this.student += this.gendersummary[j].student;
          }

          this.pieChartData = [
            ((this.boys * 100) / (this.student)).toFixed(2)
            , ((this.girls * 100) / (this.student)).toFixed(2)];
          this.loading = false;
        });
      }
      else if(this.pgid == 'P22'){
        service.GetPartnerGenderEnrollmentSummaryEnglish("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: any) => {
          this.gendersummary = [];
          this.gendersummary = res;

          for (let j = 0; j < this.gendersummary.length; j++) {
            this.boys += this.gendersummary[j].boys;
            this.girls += this.gendersummary[j].girls;
            this.student += this.gendersummary[j].student;
          }

          this.pieChartData = [
            ((this.boys * 100) / (this.student)).toFixed(2)
            , ((this.girls * 100) / (this.student)).toFixed(2)];
          this.loading = false;
        })
      }
      else{
        service.GetPartnerGenderEnrollmentSummary("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: any) => {
          this.gendersummary = res;
          this.gendersummaryCount = res.length;
          if(this.gendersummaryCount){
            this.pieChartData = [
              ((res[0].boys * 100) / (res[0].student)).toFixed(2)
              , ((res[0].girls * 100) / (res[0].student)).toFixed(2)];
          }
          this.loading = false;
        })
      }

    }
    else if (this.selectfiyear == "2017-2018" || this.selectfiyear == "2018-2019" || this.selectfiyear == "2019-2020" || this.selectfiyear == "2020-2021" || this.selectfiyear == "2021-2022"|| this.selectfiyear == "2022-2023" || this.selectfiyear == "2023-2024") {
      
      // gender summary
      service.SahyogiCALData(this.UserId, this.selectfiyear, this.pgid).subscribe((res: any) => {
        this.gendersummary = res;
       this.gendersummaryCount = res.length;
       if(this.gendersummaryCount){
        this.pieChartData = [
          ((res[0].boys * 100) / (res[0].student)).toFixed(2)
          , ((res[0].girls * 100) / (res[0].student)).toFixed(2)];
       }
        this.loading = false;
      })

    }
    else if (this.selectfiyear == null) {

      this.selectfiyear = "2024-2025";
      // gender wise summary new data
      service.GetPartnerGenderEnrollmentSummary("where partnerid='" + this.UserId + "' and pgid='" + this.pgid + "'").subscribe((res: any) => {
        this.gendersummary = res;
        this.pieChartData = [
          ((res[0].boys * 100) / (res[0].student)).toFixed(2)
          , ((res[0].girls * 100) / (res[0].student)).toFixed(2)];
        this.loading = false;
      })

    }

  }

  ngOnInit(): void {
    this.pieChartType = 'pie';
    this.barChartType = 'bar';
    this.barChartType1 = 'bar';
  }

  downloadCanvasss(event) {
    var anchor = event.target;
    
    anchor.href = document.getElementsByTagName('canvas')[2].toDataURL();
    anchor.download = "GenderGraph.png";
  }
  
}
