import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-theoryassissment',
  templateUrl: './theoryassissment.component.html',
  styleUrls: ['./theoryassissment.component.css']
})
export class TheoryassissmentComponent implements OnChanges {
  @Input('ExamType') ExamType;
  selected 
  constructor() { 
  this.selected = localStorage.getItem('key');    
   }
  ngOnChanges(): void {
  }

}
