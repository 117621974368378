import { Component, Input, OnChanges } from '@angular/core';
import { MasterService } from 'app/services/master.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-baseline2021-std11',
  templateUrl: './baseline2021-std11.component.html',
  styleUrls: ['./baseline2021-std11.component.css']
})
export class Baseline2021Std11Component implements OnChanges {

  @Input('Examtype') Examtype;
  @Input('Standard') Standard;
  
  Partnerid;
  pgid;
  selected;
  loading = true;

  BaselineStandard:any;
  c1 = new Array();
  c2 = new Array();
  c3 = new Array();
  c4 = new Array();
  compentencies;
  Baselinestd6to12;
  Baselinestd6to12count;
 
  constructor(private service: MasterService) {
    this.Partnerid = localStorage.getItem('UserId');
    this.pgid = localStorage.getItem('pgid');
    this.selected = localStorage.getItem('key');

  }

  ngOnChanges() {
    this.service.SahyogiEvalCompetency(this.Partnerid,this.selected,this.pgid,this.Examtype,this.Standard).subscribe((data:any) => {
      this.Baselinestd6to12 = data;
      this.Baselinestd6to12count = data.length;
      this.loading = false;      
      this.compentencies = new Array();
      data.forEach(y => {
         this.compentencies.push(y.c1);
         this.compentencies.push(y.c2);
         this.compentencies.push(y.c3);
         this.compentencies.push(y.c4);
         this.compentencies.push(y.c5);
       })
 
       var graph = document.getElementById("graph");
       if(data.length != 0){
         graph.style.display = "block";
       this.BaselineStandard = new Chart('Standard6to12', {
         type: 'bar',
         data: {
          labels: ['Coding(Beginner)','Cloud Computing(Beginner)','Coding(Basic)'],
           datasets: [
             {
               label: "Compentencies",
               backgroundColor: '#f4c20d',
               borderColor: '#f4c20d',
               data: this.compentencies
             },
           ]
         },
         options: {
          scales: {
            xAxes: [{
              display: true,
              stacked: true,
              distribution: 'series',
            }],
            yAxes: [{
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Count",
              }
            }],
          },
           title: {
             display: true,
             text: this.Examtype +'Standard'+ this.Standard
           },
           plugins: {
             datalabels: {
               display: function (context) {
                 return context.dataset.data[context.dataIndex] >= 1;
               },
               align: 'center',
               anchor: 'center',
               color: 'black',
               font: {
                 size: 14,
               }
             }
           }
         }
       })
      }
      else {
        graph.style.display = "none";
  
      }
      this.loading = false;
     })
   }

   downloadCanvas(event) {
    var anchor = event.target;
    anchor.href = document.getElementsByTagName('canvas')[3].toDataURL();
    anchor.download = this.Standard+"th Standard Baseline Summary.png";
  }

}
